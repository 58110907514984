// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".M65dm{display:flex;align-items:center;justify-content:center;padding-top:40px;padding-bottom:26px;flex-direction:column}.JXPzc{width:54px;height:54px;border-radius:27px;display:flex;align-items:center;justify-content:center;color:var(--color-text-secondary);font-size:14px;font-weight:500;position:relative;user-select:none;margin-bottom:18px}._4qwRj{width:54px;height:54px;position:absolute;left:0px;top:0px;transform:rotate(-90deg);user-select:none}._4qwRj circle{user-select:none;stroke-dasharray:150px;stroke-linecap:round;stroke-width:3px;stroke:#2383eb;fill:none}", "",{"version":3,"sources":["webpack://./src/tonstarter/pages/Project/airdrop/Diamonds/components/Game/components/Timer/Timer.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA,CACA,mBAAA,CACA,qBAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iCAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CACA,gBAAA,CACA,kBAAA,CAGF,QACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,QAAA,CACA,OAAA,CACA,wBAAA,CACA,gBAAA,CAEA,eACE,gBAAA,CACA,sBAAA,CACA,oBAAA,CACA,gBAAA,CACA,cAAA,CACA,SAAA","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-top: 40px;\n  padding-bottom: 26px;\n  flex-direction: column;\n}\n\n.timer {\n  width: 54px;\n  height: 54px;\n  border-radius: 27px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: var(--color-text-secondary);\n  font-size: 14px;\n  font-weight: 500;\n  position: relative;\n  user-select: none;\n  margin-bottom: 18px;\n}\n\n.circle {\n  width: 54px;\n  height: 54px;\n  position: absolute;\n  left: 0px;\n  top: 0px;\n  transform: rotate(-90deg);\n  user-select: none;\n\n  circle {\n    user-select: none;\n    stroke-dasharray: 150px;\n    stroke-linecap: round;\n    stroke-width: 3px;\n    stroke: rgba(35, 131, 235, 1);\n    fill: none;\n  }\n}\n\n.message {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "M65dm",
	"timer": "JXPzc",
	"circle": "_4qwRj"
};
export default ___CSS_LOADER_EXPORT___;
