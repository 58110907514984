import { createAsyncThunk } from '@reduxjs/toolkit'

import { ApiService, TonService } from '../../services'
import { TaskType } from '../../utils/ticketsProcessor'
import { LockupDetails } from './userSlice'

export const fetchMe = createAsyncThunk(
  'user/fetchMe',
  async (params, { rejectWithValue }) => {
    const { ok, data, error } = await ApiService.get({
      endpoint: '/users/me',
    })

    if (!ok) {
      return rejectWithValue(error)
    }

    return data
  }
)

export const fetchTicketContractState = createAsyncThunk<
  string,
  { contractAddress: string }
>('user/ticketContractState', async (params, { rejectWithValue }) => {
  const { ok, data, error } = await TonService.fetchContractState(params)

  if (!ok) {
    return rejectWithValue(error)
  }

  return data.state
})

export const fetchTicketContractStatus = createAsyncThunk<
  string,
  { contractAddress: string }
>('user/ticketContractStatus', async (params, { rejectWithValue }) => {
  const { ok, data, error } = await TonService.callGetStateMethod(params)

  if (!ok) {
    return rejectWithValue(error)
  }

  return data.statusCode
})

export const fetchTickets = createAsyncThunk<
  TaskType[],
  { projectId: number; telegramId?: string | null; address?: string | null }
>(
  'user/tickets',
  async ({ projectId, telegramId, address }, { rejectWithValue }) => {
    const { ok, data, error } = await ApiService.get({
      params: { telegramId, address },
      endpoint: `/projects/${projectId}/tickets`,
    })

    if (!ok) {
      return rejectWithValue(error)
    }

    return data
  }
)

//difference from the `fetchTickets` action is that if we have an error we do not reject `asyncThunk`
export const pollTickets = createAsyncThunk<
  TaskType[],
  { projectId: number; telegramId?: string | null; address?: string | null }
>('user/pollTickets', async ({ projectId, telegramId, address }) => {
  const { ok, data } = await ApiService.get({
    params: { telegramId, address },
    endpoint: `/projects/${projectId}/tickets`,
  })

  if (!ok) {
    return null
  }

  return data
})

export const fetchTokensAmount = createAsyncThunk<
  string,
  { contractAddress: string }
>('user/getTokensAmount', async (params, { rejectWithValue }) => {
  const { ok, data, error } = await TonService.callGetPurchasedTokensMethod(
    params
  )

  if (!ok) {
    return rejectWithValue(error)
  }

  return data.tokensAmount
})

export const checkMyTasks = createAsyncThunk<boolean, { projectId: number }>(
  'user/checkMyTasks',
  async ({ projectId }, { rejectWithValue }) => {
    const { ok, data, error } = await ApiService.post({
      endpoint: `/projects/${projectId}/check-my-tasks`,
      data: {},
    })

    if (!ok) {
      return rejectWithValue(error)
    }

    return data
  }
)

export const fetchLockupDetails = createAsyncThunk<
  LockupDetails,
  { userWalletAddress: string, lockupContract: string }
>('user/fetchLockupDetails', async (params, { rejectWithValue }) => {
  const { ok, lockupDetails, error } = await TonService.getLockUpDetails(
    params
  )

  if (!ok) {
    return rejectWithValue(error)
  }

  return lockupDetails
})

export const fetchLockupPeriods = createAsyncThunk<
  number[],
  { lockupContract: string }
>('user/fetchLockupPeriods', async (params, { rejectWithValue }) => {
  const { ok, lockupPeriods, error } = await TonService.getLockupPeriods(
    params
  )

  if (!ok) {
    return rejectWithValue(error)
  }

  return lockupPeriods
})
