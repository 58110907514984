export const news = [
  {
    image: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/stonfi/blog1.png',
    href: 'https://blog.ston.fi/and-some-more-fun-facts-from-the-world-of-cryptocurrencies-part-1/',
    title: 'STON.fi: from S to i',
    description:
      'All-in-One DEX Guide about: what DEX is and how it works, how to exchange tokens on STON.fi, how to earn on STON.fi (liquidity providing), and other important part of STON.fi’s system.',
  },
  {
    image: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/stonfi/blog2.png',
    href: 'https://blog.ston.fi/scary-beasts-fomo-and-fud/',
    title: 'Scary beasts FOMO and FUD',
    description:
      'Today, we will talk about the fears that every newcomer faces when diving into the world of cryptocurrency. These fears even have names with their own abbreviations.',
  },
  {
    image: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/stonfi/blog3.png',
    href: 'https://blog.ston.fi/what-is-an-airdrop/',
    title: 'What is an airdrop?',
    description: 'In the cryptocurrency market, an airdrop is a free distribution of tokens by a blockchain platform. This is how platform owners attract new users and increase interest in trading.',
  },
]
