import React from 'react'

import { Image, Link, Text, useMobileLayout } from '@design-system'

import styles from '../components/About/About.scss'

export const DeDustToken = () => {
  const isMobileLayout = useMobileLayout()

  const extraHeaderType = isMobileLayout ? 'header-32' : 'header-44'
  const headerType = isMobileLayout ? 'title-24' : 'title-28'
  const subheaderType = isMobileLayout ? 'title-20' : 'title-24'
  const paragraphType = isMobileLayout ? 'paragraph-16' : 'paragraph-20'
  const paragraphColor = 'secondary'
  const supplyTokenomicsHeader = ['Airdrop', 'ICO №1 burned', 'ICO №2 sold', 'ICO №2 burned', 'DEX listing', 'DEX listing burned', 'Bug Bounty', 'Marketing', 'Developing', 'CEX listing', 'Team', 'Grants']
  const supplyTokenomicsContent = ['485,500','2,100,000','1,011,232','1,088,768','1,000,000','1,100,000','214,500','2,100,000','1,900,000','2,100,000','2,650,000','5,250,000']
  const supplyHeader = ['Initial supply', 'Current supply', 'Burned']
  const supplyContent = ['21,000,000', '16,686,615', '4,313,385']
  const circulationHeader = ['Circulating Supply', 'Others']
  const circulationContent = ['3,038,052', '13,648,563']

  return (
    <>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Token info
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          <b>Ticker:</b> $SCALE<br></br>
          <b>Supply:</b> 21,000,000<br></br>
          <b>Contract:</b> <Link textType={paragraphType} href="https://tonscan.org/jetton/EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE"><code>EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE</code></Link>
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Initial supply tokenomics
        </Text>
        
        <Image
          className={styles.themeImg}
          src="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/dedust/token-initial-supply-tokenomics.png"
          alt="Scale token initial supply tokenomics"
          lightbox={{
            images: [
              {
                alt: 'Scale token initial supply tokenomics',
                src: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/dedust/token-initial-supply-tokenomics.png',
                className: styles.themeImg
              },
            ],
          }}
        />
        <table className={styles.table}>
          <tbody>
            {supplyTokenomicsHeader.map((el, i) => {
              return (<tr key={i}>
                <th>{el}</th>
                <td>{supplyTokenomicsContent[i]}</td>
              </tr>)
            })}
          </tbody>
        </table>

        <Image
          className={styles.themeImg}
          src="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/dedust/token-initial-supply.png"
          alt="Scale token initial supply"
          lightbox={{
            images: [
              {
                alt: 'Scale token initial supply',
                src: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/dedust/token-initial-supply.png',
                className: styles.themeImg
              },
            ],
          }}
        />

        <table className={styles.table}>
          <tbody>
            {supplyHeader.map((el, i) => {
              return (<tr key={i}>
                <th>{el}</th>
                <td>{supplyContent[i]}</td>
              </tr>)
            })}
          </tbody>
        </table>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          SCALE’s Circulation
        </Text>

        <Image
          className={styles.themeImg}
          src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/dedust/token-circulation.png"
          alt="Scale token circulation"
          lightbox={{
            images: [
              {
                alt: 'Scale token circulation',
                src: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/dedust/token-circulation.png',
                className: styles.themeImg
              },
            ],
          }}
        />
        <table className={styles.table}>
          <tbody>
            {circulationHeader.map((el, i) => {
              return (<tr key={i}>
                <th>{el}</th>
                <td>{circulationContent[i]}</td>
              </tr>)
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}
