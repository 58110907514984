import React from "react"

import { Text } from '@design-system'

import styles from '../../AirdropSidebar.scss'

interface TokensProps {
  projectSlug: string
  projectTicker: string
  reward: string
  isOpen: boolean
}

export const Tokens = ({projectSlug, projectTicker, reward, isOpen} : TokensProps) : JSX.Element => {
  switch(projectSlug) {
    default: 
      return (
        <div className={styles.reward} style={{ marginTop: '16px' }}>
          <div>
            <Text className={styles.rewardAmount}>{reward} {projectTicker}</Text>
            <Text className={styles.rewardLabel}>your tokens</Text>
          </div>
          <div>
            <span className={styles.tada}>{isOpen ? '🔓' : '🔒'}</span>
          </div>
        </div>
      )
  }
}