import Config from '@config'
import { isWebApp } from '@design-system'
import { AnalyticEventName, ApiService, AuthService } from '@services'
import {
  getSessionId,
  getUTMPayload,
  getWalletBalance,
  getWalletState,
  removeUTMPayload,
} from './utils'

window.ga = window.ga || {
  getAll: () => [
    {
      get: (key: string) => 'ga_not_initialized',
    },
  ],
}

interface AnalyticsProvider {
  track(
    eventName: AnalyticEventName,
    params?: { [key: string]: string | number }
  ): void

  sendAnalytics(
    endpoint: string,
    data: { [key: string]: string | number }
  ): void
}

class ApiProvider implements AnalyticsProvider {
  track(
    eventName: AnalyticEventName,
    params: { [key: string]: string | number } = {}
  ): void {
    if (!window.ga.getAll) {
      return
    }

    const endpoint = AuthService.isAuth() ? '/stat/user' : '/stat/all'
    const ga = window.ga.getAll()[0]

    this.sendAnalytics(endpoint, {
      event: eventName,
      timestamp: Math.floor(new Date().getTime() / 1000),
      url: location.pathname,
      client_id: ga.get('clientId'),
      page_title: document.title,
      session_id: getSessionId(),
      wallet_balance: getWalletBalance() || '',
      wallet_state: getWalletState() || '',
      source: isWebApp() ? 'twa' : 'website',
      payload: JSON.stringify(params),
    })
  }

  sendAnalytics(endpoint: string, data: { [key: string]: string | number }) {
    ApiService.post({ endpoint, data })
  }
}

export class AnalyticsService {
  private static provider: AnalyticsProvider = new ApiProvider()

  static track(
    eventName: AnalyticEventName,
    params?: { [key: string]: string | number }
  ) {
    if (Config.initAnalytic) {
      this.provider.track(eventName, params)
    }
  }

  static trackItEasy(
    eventName: AnalyticEventName,
    params: { projectId: string }
  ) {
    if (Config.initAnalytic) {
      this.provider.sendAnalytics('/stat/authorised', {
        event: eventName,
        source: isWebApp() ? 'twa' : 'website',
        project_id: params.projectId,
      })
    }
  }

  static trackUTM(isAuth: boolean) {
    const refPayload = getUTMPayload()

    if (refPayload && !refPayload.ref) {
      this.provider.track('page_shown', refPayload)
      removeUTMPayload()
    }

    if (refPayload && !refPayload.is_authed && refPayload.ref && isAuth) {
      this.provider.track('referal_invited', refPayload)
      removeUTMPayload()
    }
  }
}
