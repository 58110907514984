export const advisors = [
    {
      image:
        'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/justin.jpeg',
      name: 'Justin Hyun',
      role: 'Head of Incubation at TON Foundation',
      linkedin: 'https://www.linkedin.com/in/justinhyun/',
    },
    {
      image:
        'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/cyril.jpeg',
      name: 'Kirill Malev',
      role: 'Junior Partner at First Stage Labs',
      linkedin: 'https://www.linkedin.com/in/kirillmalev/',
    },
  ]
  