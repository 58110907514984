import { Text, useMobileLayout } from '@design-system'
import * as React from 'react'

import styles from './Member.scss'

export function Member({
  className,
  avatar,
  name,
  role,
  button = null,
}: {
  className?: string
  avatar: string | JSX.Element
  name: string
  role: string
  button?: JSX.Element | null
}): JSX.Element {
  const isMobileLayout = useMobileLayout()

  return (
    <div>
      <div className={styles.avatar}>
        {typeof avatar === 'string' ? <img src={avatar} /> : null}
        {typeof avatar !== 'string' ? (
          <div className={styles.videoAvatar}>{avatar}</div>
        ) : null}
      </div>
      <Text type={isMobileLayout ? 'title-20' : 'title-24'}>{name}</Text>
      <Text
        type={isMobileLayout ? 'paragraph-16' : 'paragraph-20'}
        color="tertiary"
        spacingBottom="16"
      >
        {role}
      </Text>
      {button}
    </div>
  )
}
