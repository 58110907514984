import React from 'react'

import { mobileOrTablet } from '../../utils/mobileOrTablet'
import {
  WalletButtonTonHub,
  WalletButtonTonkeeper,
} from '../WalletButtons/WalletButtons'
import styles from './ConnectWalletModal.scss'

interface ConnectWalletModalProps {
  onConnect(walletType: 'tonkeeper' | 'tonhub'): void
  tonkeeperLoginLink: string
}

export function ConnectWalletModal({
  onConnect,
  tonkeeperLoginLink,
}: ConnectWalletModalProps) {
  const isMobileOrTablet = mobileOrTablet()

  return (
    <div className={styles.root}>
      <div className={styles.icon} />
      <div className={styles.title}>Connect a wallet</div>
      <div className={styles.subtitle}>
        Select a crypto wallet from the list below to participate in the
        project.
      </div>

      <WalletButtonTonkeeper
        className={styles.itemButton}
        label="Tonkeeper"
        onClick={() => onConnect('tonkeeper')}
        href={isMobileOrTablet ? tonkeeperLoginLink : ''}
      />

      <WalletButtonTonHub
        className={styles.itemButton}
        label="Tonhub"
        onClick={() => {
          onConnect('tonhub')
        }}
      />

      <div className={styles.tip}>
        Don't have a crypto wallet?
        <br />
        <a
          href="https://docs.tonstarter.com/common-questions-about-ton/how-to-create-a-ton-wallet"
          target="_blank"
        >
          Learn about wallets
        </a>
      </div>
    </div>
  )
}
