import React from 'react'

import styles from './Partners.scss'

export const Partners = () => (
  <section className={styles.sectionPartners}>
    <h3 className={styles.sectionTitle}>Partners</h3>
    <div className={styles.grid}>
      <div className={styles.item}>
        <a className={styles.preview} href="https://fslabs.io" target="_blank">
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="64" height="64" rx="12" />
            <path d="M16 42.78V21H21.5V42.78H16ZM18.304 35.1V31H28.5V35.1H18.304ZM18.304 26V21L30 21V26L18.304 26Z" />
            <path d="M42.2924 42.78C40.9124 42.78 39.6624 42.62 38.5424 42.3C37.4424 41.96 36.4624 41.48 35.6024 40.86C34.7424 40.22 33.9724 39.47 33.2924 38.61L36.3824 35.1C37.3824 36.44 38.3824 37.34 39.3824 37.8C40.4024 38.26 41.4524 38.49 42.5324 38.49C43.0724 38.49 43.5624 38.42 44.0024 38.28C44.4424 38.14 44.7824 37.94 45.0224 37.68C45.2624 37.4 45.3824 37.06 45.3824 36.66C45.3824 36.36 45.3024 36.09 45.1424 35.85C45.0024 35.61 44.8024 35.4 44.5424 35.22C44.2824 35.04 43.9724 34.88 43.6124 34.74C43.2524 34.58 42.8724 34.45 42.4724 34.35C42.0724 34.23 41.6624 34.13 41.2424 34.05C40.0824 33.79 39.0724 33.47 38.2124 33.09C37.3524 32.71 36.6324 32.25 36.0524 31.71C35.4924 31.15 35.0724 30.52 34.7924 29.82C34.5124 29.1 34.3724 28.3 34.3724 27.42C34.3724 26.48 34.5824 25.62 35.0024 24.84C35.4424 24.04 36.0224 23.36 36.7424 22.8C37.4824 22.22 38.3324 21.78 39.2924 21.48C40.2524 21.16 41.2524 21 42.2924 21C43.6724 21 44.8524 21.15 45.8324 21.45C46.8324 21.73 47.6824 22.14 48.3824 22.68C49.1024 23.22 49.7124 23.87 50.2124 24.63L47.0624 27.66C46.6424 27.14 46.1824 26.71 45.6824 26.37C45.2024 26.03 44.6824 25.77 44.1224 25.59C43.5824 25.41 43.0224 25.32 42.4424 25.32C41.8424 25.32 41.3224 25.4 40.8824 25.56C40.4624 25.7 40.1324 25.91 39.8924 26.19C39.6524 26.45 39.5324 26.77 39.5324 27.15C39.5324 27.49 39.6324 27.78 39.8324 28.02C40.0324 28.26 40.3024 28.48 40.6424 28.68C40.9824 28.86 41.3724 29.02 41.8124 29.16C42.2524 29.28 42.7124 29.38 43.1924 29.46C44.2924 29.68 45.2824 29.97 46.1624 30.33C47.0624 30.69 47.8324 31.13 48.4724 31.65C49.1324 32.17 49.6324 32.79 49.9724 33.51C50.3324 34.21 50.5124 35.03 50.5124 35.97C50.5124 37.43 50.1524 38.67 49.4324 39.69C48.7324 40.69 47.7624 41.46 46.5224 42C45.3024 42.52 43.8924 42.78 42.2924 42.78Z" />
          </svg>
        </a>
        <div className={styles.descriptionContainer}>
          <div className={styles.descriptionTitle}>First Stage Labs</div>
          <div className={styles.descriptionText}>
            Venture studio focused on building and scaling Web3-startups.
          </div>
        </div>
      </div>
      <div className={styles.item}>
        <a
          className={styles.preview}
          href="https://society.ton.org"
          target="_blank"
        >
          <svg
            width="51"
            height="50"
            viewBox="0 0 51 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.98096 11.4024L48.9528 11.4023"
              stroke="url(#paint0_linear_5004_32837)"
              strokeWidth="3.16808"
            />
            <path
              d="M1.98096 11.4024L48.9528 11.4023"
              stroke="#0C0C0C"
              strokeWidth="3.16808"
            />
            <path
              d="M25.54 48.745V2"
              stroke="url(#paint1_linear_5004_32837)"
              strokeWidth="3.16808"
            />
            <path d="M25.54 48.745V2" stroke="#0C0C0C" strokeWidth="3.16808" />
            <path
              d="M37.004 30.0898L3.10254 12.2314"
              stroke="url(#paint2_linear_5004_32837)"
              strokeWidth="3.16808"
              strokeLinecap="square"
            />
            <path
              d="M37.004 30.0898L3.10254 12.2314"
              stroke="#0C0C0C"
              strokeWidth="3.16808"
              strokeLinecap="square"
            />
            <path
              d="M9.4082 2.5752L25.0374 47.72"
              stroke="url(#paint3_linear_5004_32837)"
              strokeWidth="3.16808"
              strokeLinecap="square"
            />
            <path
              d="M9.4082 2.5752L25.0374 47.72"
              stroke="#0C0C0C"
              strokeWidth="3.16808"
              strokeLinecap="square"
            />
            <path
              d="M31.4878 38.959L48.4372 13.1553"
              stroke="url(#paint4_linear_5004_32837)"
              strokeWidth="3.16808"
              strokeLinecap="square"
            />
            <path
              d="M31.4878 38.959L48.4372 13.1553"
              stroke="#0C0C0C"
              strokeWidth="3.16808"
              strokeLinecap="square"
            />
            <path
              d="M2.6311 11.4775H48.9495"
              stroke="url(#paint5_linear_5004_32837)"
              strokeWidth="3.16808"
              strokeLinecap="square"
            />
            <path
              d="M2.6311 11.4775H48.9495"
              stroke="#0C0C0C"
              strokeWidth="3.16808"
              strokeLinecap="square"
            />
            <path
              d="M25.5265 2.47168L25.5261 48.0718"
              stroke="url(#paint6_linear_5004_32837)"
              strokeWidth="3.16808"
              strokeLinecap="square"
            />
            <path
              d="M25.5265 2.47168L25.5261 48.0718"
              stroke="#0C0C0C"
              strokeWidth="3.16808"
              strokeLinecap="square"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5004_32837"
                x1="41.0862"
                y1="11.4024"
                x2="41.0862"
                y2="11.4022"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.170743" stopColor="#FF927A" />
                <stop offset="0.488588" stopColor="#7AFFD7" />
                <stop offset="0.713207" stopColor="#55D7E0" />
                <stop offset="0.920633" stopColor="#67EDFF" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_5004_32837"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.170743" stopColor="#FF927A" />
                <stop offset="0.488588" stopColor="#7AFFD7" />
                <stop offset="0.713207" stopColor="#55D7E0" />
                <stop offset="0.920633" stopColor="#67EDFF" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_5004_32837"
                x1="31.3263"
                y1="28.2337"
                x2="-6.19781"
                y2="8.48915"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.170743" stopColor="#FF927A" />
                <stop offset="0.488588" stopColor="#7AFFD7" />
                <stop offset="0.713207" stopColor="#55D7E0" />
                <stop offset="0.920633" stopColor="#67EDFF" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_5004_32837"
                x1="22.4199"
                y1="43.0277"
                x2="0.532515"
                y2="40.9274"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.170743" stopColor="#FF927A" />
                <stop offset="0.488588" stopColor="#7AFFD7" />
                <stop offset="0.713207" stopColor="#55D7E0" />
                <stop offset="0.920633" stopColor="#67EDFF" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_5004_32837"
                x1="45.5986"
                y1="36.2771"
                x2="22.4122"
                y2="32.0555"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.170743" stopColor="#FF927A" />
                <stop offset="0.488588" stopColor="#7AFFD7" />
                <stop offset="0.713207" stopColor="#55D7E0" />
                <stop offset="0.920633" stopColor="#67EDFF" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_5004_32837"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.170743" stopColor="#FF927A" />
                <stop offset="0.488588" stopColor="#7AFFD7" />
                <stop offset="0.713207" stopColor="#55D7E0" />
                <stop offset="0.920633" stopColor="#67EDFF" />
              </linearGradient>
              <linearGradient
                id="paint6_linear_5004_32837"
                x1="25.4562"
                y1="40.4347"
                x2="25.9461"
                y2="40.432"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.170743" stopColor="#FF927A" />
                <stop offset="0.488588" stopColor="#7AFFD7" />
                <stop offset="0.713207" stopColor="#55D7E0" />
                <stop offset="0.920633" stopColor="#67EDFF" />
              </linearGradient>
            </defs>
          </svg>
        </a>
        <div className={styles.descriptionContainer}>
          <div className={styles.descriptionTitle}>TON Society</div>
          <div className={styles.descriptionText}>
            Space where builders get started, create, meet capital.
          </div>
        </div>
      </div>
      <div className={styles.item}>
        <a className={styles.preview} href="https://ston.fi" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
          <path d="M32.8215 40.7527C33.1475 40.5269 33.599 40.6718 33.7327 41.0453L38.8018 55.192C38.9647 55.6469 38.554 56.0994 38.0858 55.9808L19.4225 51.2499C18.9206 51.1226 18.8016 50.4634 19.2273 50.1686L32.8215 40.7527Z" fill="white"/>
          <path d="M36.3842 37.0343C36.3611 36.9682 36.3492 36.8985 36.3492 36.8282V22.4007C36.3492 21.9926 35.965 21.6994 35.582 21.815L19.7343 26.5998C19.3514 26.7156 18.9672 26.4223 18.9672 26.0142V16.69C18.9672 16.4994 19.0542 16.3198 19.2027 16.2043L39.8542 0.12507C40.1412 -0.0984021 40.5551 -0.0130729 40.7349 0.306632L53.2104 22.4951C53.2987 22.6523 53.3142 22.8415 53.2526 23.0114L42.7493 51.9885C42.5554 52.5231 41.814 52.519 41.6259 51.9825L36.3842 37.0343Z" fill="white"/>
          <path d="M13.4429 1.72465C13.5421 1.62704 13.6723 1.56737 13.8107 1.55603L32.7751 0.00222417C33.3674 -0.0463117 33.6565 0.711819 33.1835 1.07333L17.0192 13.4264C16.8696 13.5408 16.675 13.5773 16.4944 13.525L5.85011 10.4427C5.41228 10.3159 5.27315 9.76188 5.5987 9.4416L13.4429 1.72465Z" fill="white"/>
          <path d="M2.65609 14.0767C2.70176 13.709 3.0631 13.4694 3.41678 13.5723L14.7771 16.8783C15.0333 16.9528 15.2096 17.189 15.2096 17.4574V30.8424C15.2096 31.2453 15.5949 31.5348 15.9788 31.4204L31.7471 26.7185C32.1309 26.6041 32.5162 26.8936 32.5162 27.2965V35.7983C32.5162 35.9959 32.4202 36.1808 32.2591 36.2934L14.5729 48.6663C14.3422 48.8277 14.0312 48.8061 13.8248 48.6144L0.192671 35.947C0.0506846 35.8151 -0.0193358 35.6223 0.00463002 35.4293L2.65609 14.0767Z" fill="white"/>
        </svg>
        </a>
        <div className={styles.descriptionContainer}>
          <div className={styles.descriptionTitle}>STON.fi</div>
          <div className={styles.descriptionText}>
            Decentralized exchange built on the TON blockchain
          </div>
        </div>
       </div>
    </div>
  </section>
)
