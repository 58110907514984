// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u7KQA{padding-bottom:16px;text-align:center}.u7KQA svg{border-radius:24px;margin-bottom:7px}.u7KQA div:last-child{margin-top:4px}.WtmxW{background:var(--color-fill-05);border-radius:16px;padding:28px 16px 16px;margin:16px 0 22px}.WtmxW.Vdssl{background:none;padding:0}._9gAkD{cursor:pointer;margin-top:22px;margin-bottom:5px}._40ARN{color:var(--color-text-tertiary);margin-bottom:8px;font-size:14px;line-height:20px}._40ARN:last-child{margin-bottom:0}.WMDa7{background-color:rgba(255,255,255,.06);border-radius:16px;padding:12px 16px;margin-bottom:16px}", "",{"version":3,"sources":["webpack://./src/tonstarter/pages/Project/airdrop/Diamonds/components/GlintAdopter/GlintAdopter.scss"],"names":[],"mappings":"AAGA,OACE,mBAAA,CACA,iBAAA,CAEA,WACE,kBAAA,CACA,iBAAA,CAGF,sBACE,cAAA,CAIJ,OACE,+BAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAEA,aACE,eAAA,CACA,SAAA,CAIJ,QACE,cAAA,CACA,eAAA,CACA,iBAAA,CAGF,QACE,gCAAA,CACA,iBAAA,CACA,cAAA,CACA,gBAAA,CAEA,mBACE,eAAA,CAIJ,OACE,sCAAA,CACA,kBAAA,CACA,iBAAA,CACA,kBAAA","sourcesContent":["@use 'styles/mixins/theme.scss' as themeMix;\n@use 'styles/mixins/breakpoints.scss' as breakMix;\n\n.head {\n  padding-bottom: 16px;\n  text-align: center;\n\n  svg {\n    border-radius: 24px;\n    margin-bottom: 7px;\n  }\n\n  div:last-child {\n    margin-top: 4px;\n  }\n}\n\n.board {\n  background: var(--color-fill-05);\n  border-radius: 16px;\n  padding: 28px 16px 16px;\n  margin: 16px 0 22px;\n\n  &.embedded {\n    background: none;\n    padding: 0;\n  }\n}\n\n.header {\n  cursor: pointer;\n  margin-top: 22px;\n  margin-bottom: 5px;\n}\n\n.row {\n  color: var(--color-text-tertiary);\n  margin-bottom: 8px;\n  font-size: 14px;\n  line-height: 20px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n}\n\n.body {\n  background-color: rgba(255, 255, 255, 0.06);\n  border-radius: 16px;\n  padding: 12px 16px;\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": "u7KQA",
	"board": "WtmxW",
	"embedded": "Vdssl",
	"header": "_9gAkD",
	"row": "_40ARN",
	"body": "WMDa7"
};
export default ___CSS_LOADER_EXPORT___;
