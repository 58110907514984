// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8N3AP{max-width:100%;max-height:100%;vertical-align:middle}._8N3AP.J-gxS{min-width:32px;width:32px;height:32px}._8N3AP.wP6TK{min-width:100%;width:100%;height:100%}.jnWCs{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/shared/design-system/components/Image/Image.scss"],"names":[],"mappings":"AAAA,QACE,cAAA,CACA,eAAA,CACA,qBAAA,CAEA,cACE,cAAA,CACA,UAAA,CACA,WAAA,CAGF,cACE,cAAA,CACA,UAAA,CACA,WAAA,CAIJ,OACE,cAAA","sourcesContent":[".root {\n  max-width: 100%;\n  max-height: 100%;\n  vertical-align: middle;\n\n  &.squareSize-32 {\n    min-width: 32px;\n    width: 32px;\n    height: 32px;\n  }\n\n  &.squareSize-100\\% {\n    min-width: 100%;\n    width: 100%;\n    height: 100%;\n  }\n}\n\n.clickable {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_8N3AP",
	"squareSize-32": "J-gxS",
	"squareSize-100%": "wP6TK",
	"clickable": "jnWCs"
};
export default ___CSS_LOADER_EXPORT___;
