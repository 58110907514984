import React from 'react'
import { formatCurrency } from '@utils'

import styles from './Table.scss'

interface TableProps {
  allocationUSD: number
  tokenUSD?: number
  serviceCharge?: string
  ath?: string | null
}

export const Table = ({
  allocationUSD,
  tokenUSD,
  serviceCharge,
  ath,
}: TableProps): JSX.Element => (
  <table className={styles.table}>
    <tbody>
      <tr className={styles.tableRow}>
        <th className={styles.tableHead} scope="row">
          Allocation:
        </th>
        <td className={styles.tableCell}>
          {allocationUSD ? `$50 – $${allocationUSD}` : 'TBA'}
        </td>
      </tr>
      {tokenUSD && (
        <tr className={styles.tableRow}>
          <th className={styles.tableHead} scope="row">
            Price per token:
          </th>
          <td className={styles.tableCell}>
            {formatCurrency('usd', tokenUSD, 2)}
          </td>
        </tr>
      )}
      {serviceCharge && (
        <tr className={styles.tableRow}>
          <th className={styles.tableHead} scope="row">
            Service charge:
          </th>
          <td className={styles.tableCell}>{serviceCharge}</td>
        </tr>
      )}
      {ath && (
        <tr className={styles.tableRow}>
          <th className={styles.tableHead} scope="row">
            ATH ROI
          </th>
          <td className={styles.tableCell}>{ath ? ath : 'TBA'}</td>
        </tr>
      )}
    </tbody>
  </table>
)
