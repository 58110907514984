import socialImage from 'images/social/welcomeToTon.png'
import React from 'react'
import Helmet from 'react-helmet'

export const HiHelmet = () => (
  <Helmet>
    <title>Welcome to TON</title>
    <meta
      name="description"
      content="Fundraise and launch your project on TON"
    />

    <meta property="og:title" content="Welcome to TON" />
    <meta
      property="og:description"
      content="Fundraise and launch your project on TON"
    />
    <meta property="og:locale" content="en_US" />
    <meta property="og:url" content="https://tonstarter.com/hi/" />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="Tonstarter" />
    <meta property="og:image" content={socialImage} />

    <meta itemProp="name" content="Welcome to TON" />
    <meta
      itemProp="description"
      content="Fundraise and launch your project on TON"
    />
    <meta itemProp="image" content={socialImage} />
  </Helmet>
)
