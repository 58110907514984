import React from 'react'

import classNames from 'classnames'

import styles from './Cards.scss'

const projects = [
  {
    id: 'fton-ido',
    title: 'Road to $FTON launch',
    url: 'https://t.me/community_bot/join?startapp=id_494',
    description: '',
    badge: {
      text: 'Whitelist',
      status: 'whitelist',
    },
    coverUrl:
      'https://cdn.tonstarter.com/projects/fanton/fantont_road_to_ido.png',
    details: [{ key: 'Reward Pool', value: `IDO WLs` }],
    categories: ['whitelist'],
  },
  {
    id: 'storm-ido',
    title: 'The gateway to the Storm IDO',
    url: 'https://t.me/community_bot/join?startapp=id_464',
    description: '',
    badge: {
      text: 'Whitelist',
      status: 'whitelist',
    },
    coverUrl: 'https://cdn.tonstarter.com/projects/storm/storm_wl.png',
    details: [{ key: 'Reward Pool', value: `IDO WLs` }],
    categories: ['whitelist'],
  },
  {
    id: 'bemo',
    title: 'bemo',
    url: 'https://t.me/community_bot/bemo',
    description:
      'Get your initial stXP bonus and then convert them to $BMO tokens when bemo passes its milestones!',
    badge: {
      text: 'Community',
      status: 'community',
    },
    coverUrl:
      'https://cobuild.ams3.cdn.digitaloceanspaces.com/api/admin/user_10/1695878331689.png',
    details: [{ key: 'Reward Pool', value: '10M stXP' }],
    categories: ['community', 'open'],
  },
  {
    id: 'xrocket',
    title: 'xRocket',
    url: 'https://t.me/community_bot/xRocket',
    description:
      'Join the revolution in CEXes market with xRocket and win a share of reward pool!',
    badge: {
      text: 'Community',
      status: 'community',
    },
    coverUrl:
      'https://cobuild.ams3.cdn.digitaloceanspaces.com/api/admin/user_10/1695850856039.png',
    details: [{ key: 'Reward Pool', value: 'Tokens and NFTs' }],
    categories: ['community', 'open'],
  },
  {
    id: 'fanton-airdrop',
    url: 'https://t.me/community_bot/fanton',
    linkInNewTab: true,
    title: 'Fanton',
    badge: {
      text: 'Community',
      status: 'community',
    },
    description: 'Fanton is the first Fantasy Football game in Telegram',
    coverUrl:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/fanton/fanton-community-cover-cropped.jpg',
    details: [{ key: 'Reward Pool', value: '$30,000' }],
    categories: ['community', 'soon'],
  },
  {
    id: 'evaa-private',
    url: 'private/evaa',
    title: 'Evaa Protocol',
    description: 'Crypto lending and borrowing protocol',
    badge: {
      text: '🔓 Angel round',
      status: 'private-finished',
    },
    coverUrl:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/evaa/evaa_banner_black.jpg',
    details: [{ key: 'Total raised', value: '$130,000' }],
    categories: ['private'],
  },
  {
    id: 'storm',
    title: 'Storm Trade',
    description: 'Perpetual futures trading on Storm decentralized platform',
    badge: {
      text: 'Community',
      status: 'community',
    },
    coverUrl:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/storm/storm_homepage_banner.png',
    details: [{ key: 'Reward Pool', value: '30,000 STORM' }],
    categories: ['community', 'open'],
  },
  {
    id: 'tonatlas',
    title: 'TON Atlas',
    badge: {
      text: 'Community',
      status: 'community',
    },
    description: 'Explore famous TON projects in the metaverse',
    coverUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/atlas/large-cover.jpg',
    details: [{ key: 'Rewards Pool', value: 'NFTs' }],
    categories: ['community', 'soon'],
  },
  {
    id: 'fanton-private',
    url: 'private/fanton',
    title: 'Fanton private',
    description: 'Fanton is the first Fantasy Football game in Telegram',
    badge: {
      text: '🔓 Private Sale',
      status: 'private',
    },
    coverUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-cover.jpg',
    details: [{ key: 'Private Sale', value: '200,000 TON' }],
    categories: ['private'],
  },
  {
    id: 'tondiamonds',
    title: 'TON Diamonds',
    badge: {
      text: 'Finished',
      status: 'finished',
    },
    description:
      'Marketplace on TON blockchain for high quality NFT collections and digital artists',
    coverUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/diamonds/large-cover.jpg',
    details: [{ key: 'Rewards Pool', value: '30,300 GLINT' }],
    categories: ['airdrop'],
  },
  {
    id: 'dedust',
    title: 'DeDust.io',
    badge: {
      text: 'Finished',
      status: 'finished',
    },
    description:
      'Cutting-edge decentralized exchange (DEX) built on TON blockchain',
    coverUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/dedust/large-cover.jpg',
    details: [{ key: 'Rewards Pool', value: '250,000 SCALE' }],
    categories: ['airdrop'],
  },
  {
    id: 'stonfi',
    title: 'STON.fi',
    badge: {
      text: 'Finished',
      status: 'finished',
    },
    description:
      'STON.fi is an AMM with zero fees, low slippage, easy interface, and TON wallet integration',
    coverUrl:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/stonfi/large-cover.jpg',
    details: [{ key: 'Rewards Pool', value: '100 special NFTs' }],
    categories: ['airdrop'],
  },
  {
    id: 'punks',
    title: 'Punks',
    badge: {
      text: 'Finished',
      status: 'finished',
    },
    description: 'NOT DEAD',
    coverUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/punks/large-cover.jpg',
    details: [{ key: 'Rewards Pool', value: '100,000 PUNK' }],
    categories: ['airdrop'],
  },
  {
    id: 'fanton',
    title: 'Fanton',
    description: 'Fanton is the first Fantasy Football game in Telegram',
    badge: {
      text: 'Finished',
      status: 'finished',
    },
    coverUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/large-cover.jpg',
    details: [{ key: 'Rewards Pool', value: '234 NFTs' }],
    categories: ['airdrop'],
  },
  {
    id: 'megaton',
    title: 'Megaton',
    description:
      'Megaton Finance is an AMM-based DEX on TON mainnet that provides various revenue generation opportunities',
    badge: {
      text: 'Finished',
      status: 'finished',
    },
    coverUrl:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/large-cover.jpg',
    details: [{ key: 'Rewards Pool', value: '$500,000' }],
    categories: ['airdrop'],
  },
  {
    id: 'fanzee',
    title: 'Fanzee',
    description:
      'Fan engagement platform for organisations in the sports and entertainment industries',

    badge: {
      text: 'Finished',
      status: 'finished',
    },
    coverUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanzee/large-cover.jpg',
    details: [
      { key: 'IDO Allocation', value: '100,000 FNZ' },
      { key: 'ATH Price', value: '+270%' },
    ],
    categories: ['ido'],
  },
]

interface CardsProps {
  filter: string
  showPrivate?: boolean
}

export const Cards = ({ filter = 'all', showPrivate }: CardsProps) => {
  const filteredPrivateSale = projects.filter((p) => {
    if (p.id === 'evaa-waitlist') return true
    if (p.id === 'evaa-private') return true

    return !showPrivate && p.categories.includes('private') ? false : true
  })

  const projectsToRender =
    filter === 'all'
      ? filteredPrivateSale
      : filteredPrivateSale.filter((p) => p.categories.includes(filter))

  return (
    <div className={classNames(styles.cards)}>
      {!projectsToRender.length ? (
        <div className={styles.emptyState}>There is nothing here yet ✨</div>
      ) : null}
      {projectsToRender.map((p) => (
        <a
          className={classNames(styles.card)}
          href={p.url ? p.url : `/project/${p.id}`}
          key={p.id}
          target={p.linkInNewTab ? '_blank' : '_self'}
        >
          <div className={styles.cover}>
            <img
              decoding="async"
              loading="lazy"
              src={p.coverUrl}
              alt={`${p.title} cover image`}
            />
          </div>

          <div
            className={classNames(
              styles.badge,
              p.badge.status === 'private' && styles.badgePrivate,
              p.badge.status === 'private-finished' &&
                styles.badgePrivateFinished,
              p.badge.status === 'open' && styles.badgeOpen,
              p.badge.status === 'community' && styles.badgeCommunity,
              p.badge.status === 'soon' && styles.badgeSoon,
              p.badge.status === 'finished' && styles.badgeFinished,
              p.badge.status === 'whitelist' && styles.badgeWhitelist
            )}
          >
            {p.badge.text}
          </div>

          <div className={styles.title}>{p.title}</div>
          <div className={styles.description}>{p.description}</div>
          {p.details.map((d, i) => (
            <div className={styles.details} key={i}>
              <div className={styles.separator} />
              <div className={styles.row}>
                <div className={styles.label}>{d.key}</div>
                <div className={styles.value}>{d.value}</div>
              </div>
            </div>
          ))}
        </a>
      ))}
    </div>
  )
}
