import cx from 'classnames'
import React, { forwardRef } from 'react'

import { Image, ImageProps } from '../../Image'

const SwiperLazyImage = forwardRef<
  HTMLImageElement,
  React.ImgHTMLAttributes<HTMLImageElement> & ImageProps
>(({ ...props }, ref) => (
  <React.Fragment>
    <Image className={cx('swiper-lazy')} ref={ref} {...props} />
  </React.Fragment>
))

export default SwiperLazyImage
