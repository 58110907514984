import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import { Icon, Tooltip } from '@design-system'
import { beautifyDate } from '@utils'
import { getProjectStatusTitle, ProjectStatus } from '@store'
import styles from './Stages.scss'

type StepStatus = 'done' | 'active' | 'coming'

const tooltipTitles = [
  "The project has announced its IDO. You can apply for the Waitlist as soon as the IDO's status is upgraded to “Applications opened”.",
  'The application period is open. You can apply to be on the Waitlist for a chance at winning an allocation.',
  'The application period is closed. We are no longer accepting new participants for this IDO.',
  'The token allocations are distributed among randomly selected applicants. You will be notified by the bot in case you won one. Please make sure your wallet has enough funds for your allocation buyout.',
  'The token sale is open. You can now buy out your allocation. Please make sure you are on time to complete your swap before the sale closes.',
  'The token sale is over. All allocations purchased will be distributed according to the lockups and vesting period rules set for this project.',
]

const Step = ({
  title,
  date,
  state,
  tooltip,
}: {
  title: string
  date: string
  state: StepStatus
  tooltip: string
}) => {
  return (
    <div
      className={classNames(styles.step, {
        [`${styles.stepDone}`]: state === 'done',
        [`${styles.stepActive}`]: state === 'active',
        [`${styles.stepComing}`]: state === 'coming',
      })}
    >
      <div className={styles.stepHeader}>
        <div className={styles.stepPoint}></div>
        <div className={styles.stepLine}></div>
        <div className={styles.stepLineDone}></div>
      </div>
      <div className={styles.stepTitle}>
        <span>{title}</span>
        <Tooltip style="light" title={tooltip} position="bottom">
          <Icon name="infoFilled" />
        </Tooltip>
      </div>
      <div className={styles.stepDate}>{date}</div>
    </div>
  )
}

export const MobileStep = ({ status }: { status: ProjectStatus }) => {
  const currentStepIndex = Object.keys(ProjectStatus).indexOf(status)

  return (
    <div className={classNames(styles.stepMobile)}>
      <p className={styles.stepMobileTitle}>IDO status</p>
      <div className={styles.stepMobileStatus}>
        <div className={styles.stepMobileStatusName}>
          {getProjectStatusTitle(status, false)}
        </div>
        <Tooltip
          style="light"
          title={tooltipTitles[currentStepIndex]}
          position="bottom"
        >
          <Icon name="infoFilled" />
        </Tooltip>
      </div>
    </div>
  )
}

interface StagesProps {
  status: ProjectStatus
  createdAt: string
  whitelistFinishDate: string
  whitelistStartDate: string
  projectLotteryDate: string
  tokenSaleDate: string
  tokenSaleFinishDate: string
}

const getStatusByIndex = (
  activeStepIndex: number,
  currentStepIndex: number
): StepStatus => {
  if (currentStepIndex > activeStepIndex) {
    return 'coming'
  }

  if (currentStepIndex < activeStepIndex) {
    return 'done'
  }

  return 'active'
}

const getDate = (date: string) => {
  if (!date || date === null) {
    return 'TBA'
  }

  return beautifyDate(date)
}

export const Stages = ({
  status,
  createdAt,
  whitelistStartDate,
  whitelistFinishDate,
  projectLotteryDate,
  tokenSaleDate,
  tokenSaleFinishDate,
}: StagesProps) => {
  const stepsLength = Object.keys(ProjectStatus).length
  const rootElem = useRef<HTMLDivElement>(null)
  const stepsElem = useRef<HTMLDivElement>(null)
  const [activeStepIndex, setActiveStepIndex] = useState<number>(
    Object.keys(ProjectStatus).indexOf(status)
  )
  // const isMobileViewport = useQueryPhoneOnly()

  useEffect(() => {
    const activeStepIndex = Object.keys(ProjectStatus).indexOf(status)
    setActiveStepIndex(activeStepIndex)

    let stepLength = 0

    if (stepsElem.current) {
      stepLength = (stepsElem.current.firstChild as any).offsetWidth
    }

    if (rootElem.current && activeStepIndex > 1) {
      rootElem.current.scrollLeft += (activeStepIndex - 1) * stepLength
    }

    if (rootElem.current && activeStepIndex === stepsLength - 2) {
      rootElem.current.scrollLeft = activeStepIndex * stepLength
    }
  }, [status])

  return (
    <div className={styles.section} ref={rootElem}>
      <div className={styles.steps} ref={stepsElem}>
        <Step
          title={getProjectStatusTitle(ProjectStatus.whitelistSoon)}
          date={getDate(createdAt)}
          state={getStatusByIndex(activeStepIndex, 0)}
          tooltip={tooltipTitles[0]}
        />
        <Step
          title={getProjectStatusTitle(ProjectStatus.whitelistOpen)}
          date={getDate(whitelistStartDate)}
          state={getStatusByIndex(activeStepIndex, 1)}
          tooltip={tooltipTitles[1]}
        />
        <Step
          title={getProjectStatusTitle(ProjectStatus.whitelistClosed)}
          date={getDate(whitelistFinishDate)}
          state={getStatusByIndex(activeStepIndex, 2)}
          tooltip={tooltipTitles[2]}
        />
        <Step
          title={getProjectStatusTitle(ProjectStatus.whitelistFinish)}
          date={getDate(projectLotteryDate)}
          state={getStatusByIndex(activeStepIndex, 3)}
          tooltip={tooltipTitles[3]}
        />
        <Step
          title={getProjectStatusTitle(ProjectStatus.live)}
          date={getDate(tokenSaleDate)}
          state={getStatusByIndex(activeStepIndex, 4)}
          tooltip={tooltipTitles[4]}
        />
        <Step
          title={getProjectStatusTitle(ProjectStatus.soldOut)}
          date={getDate(tokenSaleFinishDate)}
          state={getStatusByIndex(activeStepIndex, 5)}
          tooltip={tooltipTitles[5]}
        />
      </div>
    </div>
  )
}
