import React from 'react'

import {
  BaseElementMargin,
  BaseElementPadding,
  BaseElementSpacingBottom,
  FlexboxAttributes,
} from './types'

import classNames from 'classnames'
import styles from './BaseElement.scss'

export type ActionBaseProps = {
  onClick?: () => void
  onMouseOver?: () => void
  onMouseLeave?: () => void
  onMouseEnter?: () => void
  onMouseOut?: () => void
}

export interface BaseElementProps extends FlexboxAttributes, ActionBaseProps {
  as?: React.ElementType
  children?: React.ReactNode
  className?: string
  padding?: BaseElementPadding
  margin?: BaseElementMargin
  spacingBottom?: BaseElementSpacingBottom
}

export const BaseElement = React.forwardRef(
  (
    {
      as: Component = 'div',
      children,
      className,
      padding = '0-0-0-0',
      margin,
      direction = 'row',
      align,
      alignSelf,
      justify,
      grow = false,
      spacingBottom,
      wrap,
      ...rest
    }: BaseElementProps,
    ref
  ) => {
    return (
      <Component
        className={classNames(
          className,
          padding && styles[`padding-${padding}`],
          margin && styles[`margin-${margin}`],
          spacingBottom && styles[`spacing-bottom-${spacingBottom}`],
          direction && styles[`direction-${direction}`],
          grow && styles[`grow-${Number(grow)}`],
          justify && styles[`justify-${justify}`],
          align && styles[`align-${align}`],
          alignSelf && styles[`align-self-${alignSelf}`],
          wrap && styles.wrap
        )}
        ref={ref}
        {...rest}
      >
        {children}
      </Component>
    )
  }
)
