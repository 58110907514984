import { createSlice } from '@reduxjs/toolkit'

import Config from '@config'
import { RootState } from '../'
import { fetchProjectVote } from './projectsActions'
import { Project, ProjectCategory } from './types'

interface ProjectState {
  projects: Project[]
  activeProject: Project
}

const initialState: ProjectState = {
  projects: [],
  activeProject: {} as Project,
}

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setActiveProject: (state, params) => {
      state.activeProject = params.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchProjectVote.fulfilled, (state, action) => {
      const argegated = state.projects.map((project: Project) => {
        let communityRating = project.communityRating
        const addressToCompare = Config.isProd
          ? project.contractAddress
          : project.contractAddressTestnet

        if (addressToCompare === action.payload.contractAddress) {
          communityRating = action.payload.votes
        }

        return {
          ...project,
          communityRating,
        }
      })

      state.projects = argegated
    })
  },
})

export const projectsSelector = (
  state: RootState,
  category?: ProjectCategory | null
): Project[] => {
  const comingSoonProjects = [...state.projects.projects].filter(
    (p) => p.comingSoon
  )
  const releasedProjects = [...state.projects.projects].filter(
    (p) => !p.comingSoon
  )
  const sortedByRatingPojects = releasedProjects.sort((a, b) => {
    return a.expertRatingIndex >= b.expertRatingIndex ? -1 : 1
  })
  const allProjects = [...sortedByRatingPojects, ...comingSoonProjects]

  if (!category) {
    return allProjects
  }

  const categories = allProjects.filter((p) => p.category === category)

  return categories ? categories : []
}

export const { setActiveProject } = projectsSlice.actions

export const activeProjectSelector = (state: RootState): Project =>
  state.projects.activeProject

export default projectsSlice.reducer
