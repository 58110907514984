import React, { useEffect, useState } from 'react'
import styles from './PrivateSidebar.scss'
import { Text, getTGUserId } from '@design-system'
import { Follow } from '../../../../../Project/components/AirdropSidebar/components/Follow/Follow'
import { Allocation, Project, User, activeAllocationSelector, fetchAllocation, fetchPrivateAllocation, privateAllocationSelector, useAppDispatch, useAppSelector, walletTypeSelector } from '@store'
import { PrivateData, createAllocation } from '../PrivateProject'
import { sendTransaction } from '@services'
import { ton2Nano } from '@utils'
import BigNumber from 'bignumber.js'
import toast from 'react-hot-toast'
import { TrxState } from '../../../../../Project/components/AirdropSidebar/components/LockupView'
import { ApplyView } from './components/ApplyView'
import { PaidView } from './components/PaidView'
import { EvmPayView } from './components/EvmPayView'
import { VestingDetails } from './components/VestingDetails'

interface EvaaSidebarProps {
  activeProject: Project
  user: User | null
  data: PrivateData
}

export const PrivateSidebar = ({
  activeProject,
  user,
  data,
}: EvaaSidebarProps): JSX.Element => {
  const walletType = useAppSelector(walletTypeSelector)
  const showErrorMessage = (message: string) => toast.error(message)
  const privateAllocations = useAppSelector(privateAllocationSelector)
  const [isPayingEvm, setIsPayingEvm] = useState(false)
  const [amount, setAmount] = useState('')
  const [currency, setCurrency] = useState('')

  const currentAllocation = privateAllocations?.filter(alloc => (
    alloc.projectSlug === activeProject.slug
  ))

  const dispatch = useAppDispatch()

  async function handlePay(amount: string, setTrxState: React.Dispatch<React.SetStateAction<TrxState>>) {
    if (walletType) {
      setTrxState('loading')

      const totalUsd = BigNumber(amount).times(data.prices.tonPriceInUsd).toNumber()
      const totalTokens = BigNumber(amount).div(data.prices.tokenPriceInTon).toNumber()
      const nanoTONs = ton2Nano(amount)

      const {ok, error} = await sendTransaction({
        address: data.contractAddress,
        amount: nanoTONs,
        isMobileViewport: false,
        walletType: walletType
      })

      if (!ok) {
        showErrorMessage(error as string)
        setTrxState('initial')
        // trx cancelled
      } else {
        setTrxState('finished')
        if (user) {
          await createAllocation(user.id, {
            projectSlug: activeProject.slug,
            status: 'new',
            // ! safe?
            amountInTon: parseInt(amount),
            amountInUsd: totalUsd,
            amountInTokens: totalTokens,
          })

          dispatch(fetchPrivateAllocation())
        }
        // trx sent
      }
    }
  }

  async function handleEvmPay(amount: string, currency: string) {
    setIsPayingEvm(true)
    setAmount(amount)
    setCurrency(currency)
  }

  async function handleEvmPaid(address: string, network: string) {
    if (user) {
      await createAllocation(user.id, {
        projectSlug: activeProject.slug,
        status: 'new',
        // ! safe?
        amountInTon: 0,
        amountInUsd: parseInt(amount),
        amountInTokens: 0,

        payload: {
          evmAddress: address,
          evmCurrency: currency,
          evmNetwork: network,
        },
      })

      dispatch(fetchPrivateAllocation())
    }

    setIsPayingEvm(false)
  }

  useEffect(() => {
    const userTgId = getTGUserId()
    const isUserExists = user?.id || userTgId

    if (isUserExists && activeProject.slug) {
      dispatch(
        fetchAllocation({
          projectSlug: activeProject.slug,
          telegramId: user?.telegramId ?? userTgId,
          address: user?.friendlyAddress,
        })
      )
    }
  }, [user])

  const activeAllocation = useAppSelector(
    activeAllocationSelector
  ) as Allocation

  function chooseView() {
    if (currentAllocation?.length) {
      return (
        <PaidView
          activeProject={activeProject}
          data={data}
        />
      )
    }

    if (isPayingEvm) {
      return (
        <EvmPayView
          data={data}
          amount={amount}
          currency={currency}
          onSuccess={handleEvmPaid}
        />
      )
    }

    return (
      <ApplyView
        activeAllocation={activeAllocation}
        activeProject={activeProject}
        onPay={handlePay}
        onEvmPay={handleEvmPay}
        data={data}
      />
    )
  }

  return (
    <div className={styles.container}>
      {/* Flow bochka */}
      <div className={styles.bochka}>
        <h2 className={styles.title}>{activeProject.title}</h2>
        <Text color="tertiary" align="center" spacingBottom="16">
          🔓 Angel round
        </Text>

        <div className={styles.reward} style={{ marginTop: '16px' }}>
          <div>
            <Text type="title-20">
              $130,000
            </Text>
            <Text type="paragraph-16" color="tertiary">
              has been funded
            </Text>
          </div>
          <div>
            <span className={styles.tada}>🏁</span>
          </div>
        </div>

        <a
          href="https://t.me/ton_starter_support_bot"
          target="_blank"
          className={styles.supportLink}
        >
          Support chat
        </a>
      </div>

      <div className={styles.bochka}>
        <VestingDetails data={data} />
      </div>

      {/* Follow project bochka */}
      <div className={styles.bochka}>
        <Text color="tertiary" align="center">
          Follow {activeProject.title} project
        </Text>
        <Follow projectDetails={activeProject.projectDetails} />
      </div>
    </div>
  )
}