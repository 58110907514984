import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import Config from '@config'
import { Button, getTGUserId, useInterval } from '@design-system'
import {
  currentUserSelector,
  fetchAllocation,
  generateTokenForLogin,
  useAppSelector,
} from '@store'
import { openNewTab } from '@utils'

interface ApplyButtonProps {
  projectId: number
  allocationId: string
  projectSlug: string
}

export function ApplyButton({
  projectId,
  allocationId,
  projectSlug,
}: ApplyButtonProps): JSX.Element {
  const dispatch = useDispatch()
  const user = useAppSelector(currentUserSelector)
  const [isLoading, setIsLoading] = useState(false)
  const [pollingInterval, setPollingInterval] = useState<null | number>(null)
  const [stopPollingInterval, setStopPollingInterval] = useState<any>(null)
  useEffect(() => {
    return () => {
      clearInterval(stopPollingInterval)
      setStopPollingInterval(null)
    }
  }, [])

  useInterval(() => {
    dispatch(
      fetchAllocation({
        projectSlug,
        telegramId: user?.telegramId ?? getTGUserId(),
        address: user?.friendlyAddress,
      })
    )
  }, pollingInterval)

  const startPolling = () => {
    setPollingInterval(2000)

    // Stop polling after 1 min
    const foo = setInterval(() => stopPolling(), 1000 * 60)
    setStopPollingInterval(foo)
  }

  const stopPolling = () => {
    setPollingInterval(null)
    setIsLoading(false)
  }

  const handleClick = async () => {
    setIsLoading(true)

    const { ok, data: token } = await generateTokenForLogin()

    if (!ok || !token) {
      setIsLoading(false)
      return
    }

    startPolling()

    openNewTab(`https://t.me/${Config.botName}?start=ap_${projectId}_${token}`)
  }

  return (
    <Button onClick={handleClick} fullWidth loading={isLoading}>
      Apply for IDO
    </Button>
  )
}

{
  /* <Button onClick={() => applyForWhitelist(projectId)} fullWidth>
Apply for IDO
</Button> */
}
