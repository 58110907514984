import React, { ComponentType, useState } from 'react'

import { Tab, TabPanel, Tabs } from '@design-system'
import { Project, ProjectDetails } from '@store'

import styles from './About.scss'
import { PrivateData } from '../PrivateProject'
import { diffInDays } from '@utils'

interface AboutProps {
  projectDetails: ProjectDetails
  DetailsComponent: ComponentType<any>
  MoreComponent: ComponentType<any>
  imageCarousel?: JSX.Element
  moreTabName?: string
  project: Project
  data: PrivateData
  Tokenomics?: ComponentType<any>
}

export function About({
  projectDetails,
  DetailsComponent,
  MoreComponent,
  moreTabName,
  project,
  imageCarousel,
  Tokenomics,
  data,
}: AboutProps): JSX.Element {
  let activeTabInitialState = 0
  let startIndex = 0

  if (
    window.location.hash.indexOf('faq') !== -1 &&
    activeTabInitialState !== 1
  ) {
    activeTabInitialState = 1
  }

  const [activeTab, setActiveTab] = useState(activeTabInitialState)
  const handleChangeTab = (newIndex: number) => setActiveTab(newIndex)

  const tabs = [
    <Tab label="About the project" key="about" />,
    <Tab label={moreTabName || `More`} key="more" />,
  ]

  const tabsContent = [
    <TabPanel
      className={styles.tabPanel}
      value={activeTab}
      index={startIndex}
      key={1}
    >
      {DetailsComponent && <DetailsComponent />}
    </TabPanel>,
    <TabPanel
      className={styles.tabPanel}
      value={activeTab}
      index={startIndex + 1}
      key={2}
    >
      {MoreComponent && <MoreComponent />}
    </TabPanel>,
  ]

  if (Tokenomics) {
    tabs.push(
      <Tab label="Token details" key="token" />
    )
    tabsContent.push(
      <TabPanel
        className={styles.tabPanel}
        value={activeTab}
        index={startIndex + 2}
        key={3}
      >
        {Tokenomics && <Tokenomics />}
      </TabPanel>,
    )
  }

  const today = new Date()
  const daysLeft = diffInDays(data.dates.end, today)

  return (
    <div>
      <div className={styles.imageWrapper}>
        {/*<div className={styles.statusActive}>⚡️ Active {daysLeft} more days</div>*/}
        {imageCarousel ? (
          <div className={styles.carousel}>{imageCarousel}</div>
        ) : (
          <div className={styles.banner}>
            <img
              decoding="async"
              loading="lazy"
              src={data.cover}
              alt="Banner image"
            />
          </div>
        )}
      </div>
      <Tabs
        onChange={handleChangeTab}
        value={activeTab}
        variant="text"
        fullWidth
      >
        {tabs}
      </Tabs>

      {tabsContent}
    </div>
  )
}
