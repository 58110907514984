export const team = [
  {
    image:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tondiamonds/vladimir.jpeg',
    name: 'Vladimir Makhov',
    role: 'Chief Executive Officer',
    tg: 'https://t.me/makhoov',
    details: [
      'Founder of TON Diamonds',
      'Co-founder of TON Apps, TON News',
      'Ex-Head of VK Dating',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tondiamonds/andrei.jpeg',
    name: 'Andrei Blinov',
    role: 'Chief Product Officer',
    tg: 'https://t.me/andrrblinov',
    details: [
      'Head of Product at TON Diamonds',
      'Serial tech entrepreneur',
      'Software Engineer',
    ],
  },
]
