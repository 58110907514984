// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7UKpN{width:140px;height:140px;border-radius:24px;margin-bottom:16px;overflow:hidden;background-color:var(--color-bg-tertiary)}.YD2T0{margin-top:-30px;margin-left:-30px}", "",{"version":3,"sources":["webpack://./src/shared/design-system/components/Member/Member.scss"],"names":[],"mappings":"AAAA,QACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,yCAAA,CAGF,OACE,gBAAA,CACA,iBAAA","sourcesContent":[".avatar {\n  width: 140px;\n  height: 140px;\n  border-radius: 24px;\n  margin-bottom: 16px;\n  overflow: hidden;\n  background-color: var(--color-bg-tertiary);\n}\n\n.videoAvatar {\n  margin-top: -30px;\n  margin-left: -30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "_7UKpN",
	"videoAvatar": "YD2T0"
};
export default ___CSS_LOADER_EXPORT___;
