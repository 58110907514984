import React from 'react'

import { Button, Text } from '@design-system'
import { openNewTab } from '@utils'
import FantonPNG from './assets/fantonNFT.png'

import styles from '../../AirdropSidebar.scss'

const tgIcon = (
  <svg
    width="15"
    height="26"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {' '}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.04999 5.65769C5.06225 3.9096 7.73775 2.75714 9.07638 2.20037C12.8986 0.61061 13.6928 0.334431 14.2104 0.325291C14.3243 0.323302 14.5789 0.351527 14.7438 0.485343C14.9884 0.683835 14.9898 1.11469 14.9626 1.39985C14.7555 3.57616 13.8593 8.85743 13.4033 11.2949C13.2104 12.3263 12.8305 12.6722 12.4627 12.706C11.6635 12.7795 11.0566 12.1778 10.2824 11.6704C9.07111 10.8763 8.38677 10.382 7.21093 9.60715C5.85207 8.71168 6.73298 8.21954 7.50737 7.41519C7.71006 7.20471 11.2316 4.00158 11.2997 3.711C11.3083 3.67465 11.3162 3.53917 11.2357 3.46767C11.1552 3.39616 11.0365 3.42057 10.9508 3.44003C10.8293 3.46761 8.89413 4.74668 5.14536 7.27718C4.59607 7.65438 4.09855 7.83814 3.65281 7.82852C3.16136 7.81793 2.21611 7.55068 1.51337 7.32224C0.651452 7.04208 -0.0335917 6.89391 0.0260849 6.41811C0.0571597 6.17021 0.398445 5.91677 1.04999 5.65769Z"
      fill="#fff"
    />{' '}
  </svg>
)

const StonFiLogo = ({className}: {className: string | undefined}) => {
  return (<svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M24.6324 30.5645C24.877 30.3952 25.2155 30.5039 25.3158 30.784L29.1177 41.394C29.2399 41.7352 28.9318 42.0746 28.5806 41.9857L14.5832 38.4375C14.2068 38.342 14.1175 37.8476 14.4368 37.6265L24.6324 30.5645Z"/>
    <path d="M27.3045 27.7757C27.2871 27.7261 27.2782 27.6739 27.2782 27.6211V16.8005C27.2782 16.4944 26.9901 16.2745 26.7028 16.3612L14.8171 19.9499C14.5299 20.0367 14.2417 19.8167 14.2417 19.5106V12.5175C14.2417 12.3746 14.307 12.2398 14.4183 12.1532L29.907 0.0938023C30.1222 -0.0738015 30.4326 -0.00980464 30.5675 0.229973L39.9241 16.8713C39.9903 16.9892 40.0019 17.1311 39.9557 17.2586L32.0782 38.9913C31.9329 39.3923 31.3768 39.3892 31.2357 38.9868L27.3045 27.7757Z"/>
    <path d="M10.0984 1.29349C10.1728 1.22028 10.2704 1.17553 10.3743 1.16702L24.5975 0.00166812C25.0418 -0.0347337 25.2586 0.533864 24.9038 0.804994L12.7806 10.0698C12.6684 10.1556 12.5225 10.183 12.387 10.1438L4.40383 7.83198C4.07546 7.7369 3.97111 7.3214 4.21527 7.08119L10.0984 1.29349Z"/>
    <path d="M2.00842 10.5574C2.04267 10.2816 2.31368 10.1019 2.57894 10.1791L11.0992 12.6586C11.2913 12.7145 11.4236 12.8916 11.4236 13.0929V23.1316C11.4236 23.4338 11.7125 23.651 12.0005 23.5652L23.8267 20.0387C24.1145 19.9529 24.4035 20.17 24.4035 20.4723V26.8486C24.4035 26.9968 24.3315 27.1355 24.2107 27.2199L10.946 36.4996C10.773 36.6206 10.5397 36.6044 10.3849 36.4606L0.16086 26.9601C0.0543708 26.8612 0.00185562 26.7166 0.0198299 26.5719L2.00842 10.5574Z"/>
  </svg>)
}

interface WinnerViewProps {
  projectTitle: string
  amountOfTokensWon: number
  projectTicker: string
  projectSlug: string
}

export const WinnerView = ({
  projectTitle,
  amountOfTokensWon,
  projectTicker,
  projectSlug
}: WinnerViewProps): JSX.Element => {
  const FollowTonstarterButton = () => {
    return <>
      <Button
        className={styles.button}
        fullWidth
        icon={tgIcon}
        onClick={() => {
          openNewTab('https://t.me/ton_starter')
        }}
        >
        Follow Tonstarter
    </Button>
      <Text className={styles.tip}>
        Subscribe to our channel for updates
      </Text>
    </>
  }

  const Reward = () => {
    switch(projectSlug) {
      case 'fanton':
        return (
          <>
            <div className={styles.nftWinnerContainer}>
              <div>
                <Text className={styles.rewardAmount}>You won 🏆</Text>
                <Text className={styles.rewardLabel}>NFT will be minted soon</Text>
              </div>
              <div>
                <img src={FantonPNG} width={100} className={styles.animatedNFT}></img>
              </div>
            </div>
            <FollowTonstarterButton />
          </>
        )
      case 'punks':
        return (
          <>
            <div className={styles.winnerView}>
              <div>
                <Text type="title-20">
                  {amountOfTokensWon} $PUNK
                </Text>
                <Text type="paragraph-16" color="tertiary">
                  is yours in Punk City
                </Text>
              </div>
              <div>
                <div className={styles.coin}></div>
                {/* <img src={PunkPNG} width={80}></img> */}
              </div>
            </div>
            <Button
              className={styles.button}
              fullWidth
              icon={tgIcon}
              onClick={() => {
                openNewTab('https://t.me/PunkCity2094bot')
              }}
              >
              Enter the Punk City
            </Button>
            <Text className={styles.tip}>
              20% of tokens are available,<br></br>the rest to be unlocked soon
            </Text>
          </>
        )
      case 'stonfi':
        return (
          <>
            <div className={styles.winnerView}>
              <div>
                <Text className={styles.rewardAmount}>You won 💎</Text>
                <Text className={styles.rewardLabel}>NFT will be on your wallet soon</Text>
              </div>
              <div className={styles.layers}>
                <StonFiLogo className={styles.glitchNFT} />
                <StonFiLogo className={styles.glitchNFTBefore} />
                <StonFiLogo className={styles.glitchNFTAfter} />
              </div>
            </div>
            <FollowTonstarterButton />
          </>
        )
      case 'dedust':
        return (
          <>
            <div className={styles.reward} style={{ marginTop: '16px' }}>
              <div>
                <Text className={styles.rewardAmount}>{amountOfTokensWon} {projectTicker}</Text>
                <Text className={styles.rewardLabel}>your tokens</Text>
              </div>
              <div>
                <span className={styles.tada}>🗝️</span>
              </div>
            </div>
            <Button
              className={styles.button}
              fullWidth
              disabled
            >
              Claim 6 Jun
            </Button>
            <Text className={styles.tip}>
              Please wait until the date<br></br>to claim your tokens
            </Text>
          </>
        )
      default: 
        return (
          <>
            <div className={styles.reward} style={{ marginTop: '16px' }}>
              <div>
                <Text className={styles.rewardAmount}>{amountOfTokensWon} {projectTicker}</Text>
                <Text className={styles.rewardLabel}>will be on your wallet shortly</Text>
              </div>
              <div>
                <span className={styles.tada}>🎉</span>
              </div>
            </div>
            <FollowTonstarterButton />
          </>
        )
    }
  }

  return (
    <div className={styles.bochka}>
      <h2 className={styles.title}>
        {projectTitle}
      </h2>
      <Text color="tertiary" align="center" spacingBottom='16'>
        Congrats!
      </Text>
      <Reward />
    </div>
  )
}
