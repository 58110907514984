import { ProjectCategory } from '../store'

const categories = {
  defi: 'DeFi',
  marketplace: 'Marketplace',
  funding: 'Funding',
  wallet: 'Wallet',
  socialfi: 'SocialFi',
  tools: 'Tools',
}

export const getCategoryLabel = (category: ProjectCategory) => {
  return categories[category]
}

export const heyBots = async (value: number, id: string | undefined) => {
  const encoder = new TextEncoder();
  const concat = `${id}_${value.toString()}_d7446c36ddcc2eb05e1dcc96`
  const data = encoder.encode(concat);
  const hash = await window.crypto.subtle.digest('SHA-512', data);
  return Array.from(new Uint8Array(hash)).map(b => b.toString(16).padStart(2, '0')).join('');
}

