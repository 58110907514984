import React from 'react'

import { Skeleton } from '@design-system'

export const SidebarSkeleton = (): JSX.Element => {
  return (<div style={{display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '16px'}}>
    <div style={{height: '286px'}}>
      <Skeleton height="100" radius='16px'/>
    </div>
    <div style={{height: '118px'}}>
      <Skeleton height="100" radius="16px" />
    </div>
  </div>)
}
