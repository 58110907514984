import React from 'react'

import styles from './FundProgress.scss'

export const FundProgress = (): JSX.Element => {
  return (
    <div className={styles.bochka}>
      <p className={styles.note}>Funding goal reached</p>
      <div className={styles.amountWrap}>
        <>
          <div className={styles.amountNow}>$100,000</div>
          <div className={styles.amountOf}>of $100,000</div>
        </>
      </div>
      <div className={styles.progressWrap}>
        <div className={styles.progress}>
          <div className={styles.progressPath}></div>
          <div className={styles.progressLine}></div>
        </div>
      </div>
    </div>
  )
}
