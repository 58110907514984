export const roadmap = [
  {
    Q: 'Q2-Q4',
    year: '2022',
    actions: [
      'DEX release',
      'SCALE token ICO',
      '“My Portfolio” release',
      'Bug Bounty program',
      'TON Connect v2 integration',
    ],
  },
  {
    Q: 'Q1',
    year: '2023',
    actions: [
      'Integration with Coinmarketcap and Defilama',
      'SafePal integration',
      'First place in Hack-a-TONx with DoraHacks',
    ],
  },
  {
    Q: 'Q2',
    year: '2023',
    actions: [
      'DeDust v2 is released',
      'Total Value Locked has reached $1M',
      // 'Farming pools are released',
      // 'SCALE buyback for protocol\'s fees',
    ],
  },
]
