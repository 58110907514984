import clsx from 'classnames'
import React, { CSSProperties } from 'react'

import styles from './Icon.scss'
import libraryStyles from './Library.scss'
import { IconColor, IconSize } from './types'
import { images } from './utils'

interface IconProps {
  colorStroke?: IconColor
  colorFill?: IconColor
  color?: IconColor
  name: string
  size?: IconSize
  className?: string
  isDisabled?: boolean
  width?: string
  height?: string
  style?: CSSProperties
}

export function Icon({
  colorStroke,
  colorFill,
  color = 'primary',
  name,
  width,
  height,
  className,
  isDisabled,
  style,
  size,
  ...rest
}: IconProps): JSX.Element {
  const classList = clsx(
    styles.root,
    color && libraryStyles[`color-${color}`],
    colorStroke && styles[`color-stroke-${colorStroke}`],
    colorFill && styles[`color-fill-${colorFill}`],
    isDisabled && styles.disabled,
    size && styles[`size-${size}`],
    libraryStyles[`name-${name}`],
    className
  )

  return React.createElement(images[name], {
    className: classList,
    style,
  })
}
