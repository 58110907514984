import React from 'react'

import hiStyles from '../../HiPage.scss'
import styles from './Partners.scss'
// import richCats from './images/rich-cats.png'

const partners = [
  {
    title: 'TOP Labs',
    link: 'https://top.co/',
    description: 'An ecosystem venture builder supporting early stage projects on TON',
  },
  {
    title: 'TON Foundation',
    link: 'https://ton.org/grants/',
    description: 'Helps the ecosystem grow and develop',
  },
  {
    title: 'TON Society',
    link: 'https://society.ton.org/',
    description: 'A space where builders get started and meet capital',
  },
  {
    title: 'TONcoin.Fund',
    link: 'https://www.toncoin.fund/',
    description: 'A $250M TON ecosystem fund that invests in strong teams',
  },
]

export const Partners = () => {
  return (
    <div className={styles.root}>
      <div className={hiStyles.sectionTitle}>Our backers and partners</div>
      <div className={styles.cards}>
        {partners.map((p, i) => (
          <div className={styles.card} key={i}>
            <div className={styles.title}><a href={p.link} target="_blank">{p.title}</a></div>
            <div className={styles.description}>{p.description}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
