import React from 'react'

import { Image } from '@design-system'
import { Project } from '@store'
import styles from './Banner.scss'

export const Banner = ({ project }: { project: Project }): JSX.Element => {
  const isLoaded = project.id && project.status

  if (!isLoaded) {
    return <div className={styles.skeleton}></div>
  }

  return (
    <div className={styles.root}>
      <Image
        src={project.projectDetails.bannerPng as string}
        borderRadius="16px"
        alt={`${project.title} cover image`}
      />
    </div>
  )
}
