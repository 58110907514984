import React from 'react'

import { Page } from '@design-system'
import styles from './legal.scss'

export const CookiePolicyPage = (): JSX.Element => {
  return (
    <Page>
      <Page.Header />
      <Page.Body>
        <div className={styles.root}>
          <p className={styles.title}>TONSTARTER COOKIE POLICY</p>
          <p className={styles.row}>
            Date of original publication: May, 10, 2023
          </p>
          <p className={styles.header}>Preamble</p>
          <p className={styles.row}>
            Tonstarter, referred to as the Ecosystem Operator ("Company", "We",
            "we", "Us") understands that the collection of Personal data
            involves a great deal of trust on your part.
          </p>
          <p className={styles.row}>
            The Ecosystem Operator reserves the right at any time to: make any
            changes to the Cookies policy. Such will be effective immediately
            after posting the modified cookies policy on{' '}
            <a href="https://tonstarter.com" target="_blank">
              https://tonstarter.com
            </a>{' '}
            such also applies with the relevant Tonstarter 1) Disclaimer, 2)
            Terms of Use and 3) Privacy policy.
          </p>
          <p className={styles.row}>
            Please refer to this Cookie Policy carefully to know more about the
            Personal Data collected and processed using Cookies. This Cookies
            Policy should be read and understood in conjunction with Tonstarter
            Website's Privacy Policy and Terms of Use. Capitalised terms not
            defined in this Cookies Policy have the meanings set forth in the
            Privacy Policy and/or in the Terms of Use. By visiting the Website
            as a Visitor, you Consent to the practices described below.
          </p>
          <p className={styles.row}>
            Please refrain from visiting the Website as a Visitor if you do not
            accept this Cookies Policy, the Privacy Policy and/or the Terms of
            Use. Our website uses cookies to distinguish you from other users,
            provide a better experience when you browse our website, and improve
            our site's performance and usefulness.
          </p>
          <p className={styles.row}>
            By continuing to browse the site, you are agreeing to our use of
            cookies. A cookie is a small data file that websites can place on
            your hard drive when you visit. A cookie file can contain
            information such as a user ID that tracks the pages you've visited
            within that site. The cookies on this website are primarily used to
            recognize that a user has visited the website previously and to
            track user traffic patterns. The only type of cookies used by the
            Ecosystem operator are Google Analytics and Plausible.
          </p>
          <p className={styles.row}>
            We do not correlate this information with data about individual
            users, nor do we share this information or sell it to any third
            party. If you prefer not to receive cookies through the site,you can
            set your browser to warn you before accepting cookies and refuse the
            cookie when your browser alerts you to its presence.
          </p>
          <p className={styles.row}>
            You also can refuse all cookies by turning them off in your browser.
            You do not need to have cookies turned on to use any pages within
            our website. For more information about cookies, including how to
            set your browser to reject cookies, visit www.allaboutcookies.org.
          </p>
          <p className={styles.header}>1. Definitions</p>
          <p className={styles.row}>
            The following terms, as used herein shall have the following
            meanings respectively unless inconsistent with the subject or
            context. Other capitalised terms may be defined elsewhere in this
            Agreement and, unless otherwise indicated, shall have such meaning
            throughout this Agreement.
          </p>
          <p className={styles.row}>
            1.1. "Consent" shall mean any freely given, specific, informed and
            unambiguous indication of which the Data subject, by a statement or
            by a clear affirmative action, signifies agreement to the processing
            of Personal data relating to him or her.
          </p>
          <p className={styles.row}>
            1.2. "Cookie(s)" means a piece of information that is placed
            automatically on your computer's hard drive when you access the
            Website. The Cookies uniquely identifies your browser to the server.
            Cookies allow the Ecosystem Operator to store information on the
            server and the User's preferences (for example language preferences,
            technical information, click or path information, etc.) to help make
            the Website experience better for you and to conduct Website
            analysis and performance review. The types of Cookies are further
            explained in this Cookies Policy.
          </p>
          <p className={styles.row}>
            1.3. "Data Controller" shall mean the natural or legal person, who,
            alone or jointly with others, determines the purposes and means of
            the Processing of Personal data and who is in charge of this
            Processing.
          </p>
          <p className={styles.row}>
            1.4. "Data Subject" shall mean the natural or legal persons whose
            data is processed, in the context of this Cookies Policy, the
            Visitor.
          </p>
          <p className={styles.row}>
            1.5. "Disclosure" shall mean making Personal data accessible, for
            example by permitting access, transmission, or publication.
          </p>
          <p className={styles.row}>
            1.6. "Personal Data Breach" shall mean a breach of security leading
            to the accidental or unlawful destruction, loss, or alteration of -
            or to the unauthorised disclosure of, or access to - Personal data
            transmitted, stored, or otherwise processed.
          </p>
          <p className={styles.row}>
            1.7. "Personal Data" shall mean any information relating to an
            identified or identifiable natural person; an identifiable natural
            person is one who can be identified, directly or indirectly, in
            particular by reference to an identifier such as a name, an
            identification number, location data, an online identifier or to one
            or more factors specific to the physical, physiological, genetic,
            mental, economic, cultural or social identity of that natural
            person.
          </p>
          <p className={styles.row}>
            1.8. "Process" shall mean any operation with Personal data,
            irrespective of the means applied and the procedure, and in
            particular the collection, storage, use, revision, disclosure,
            archiving or destruction of data.
          </p>
          <p className={styles.row}>
            1.9. "Processing" shall mean any operation or set of operations - by
            automated and other means - that is performed upon Personal data or
            sets of Personal data, such as collecting, recording, organising,
            structuring, storing, adapting or altering, retrieving, consulting,
            using, disclosing by transmitting, disseminating or otherwise making
            available, aligning or combining, or erasing.
          </p>
          <p className={styles.row}>
            1.10. "Recipient" means third, public authority, agency, or other
            body - that is, someone or something other than the Data subject or
            the Company - to which the Personal data is disclosed.
          </p>
          <p className={styles.row}>
            1.11. "Third Parties" shall mean any person who is not bound by a
            contract of employment or service agreement with Tonstarter or any
            entity affiliated with Tonstarter and any person who is not a
            Visitor.
          </p>
          <p className={styles.row}>
            1.12. "User" shall mean a person which has connected their wallet
            for the use of the website
          </p>
          <p className={styles.row}>
            1.13. "Visitor" shall mean any individual or legal entity browsing
            the Website.
          </p>
          <p className={styles.row}>
            1.14. "Website" or "Tonstarter Website" shall mean this website
            accessible at the following URL:{' '}
            <a href="https://tonstarter.com" target="_blank">
              https://tonstarter.com
            </a>
          </p>
          <p className={styles.row}>
            Except where the context requires otherwise, this agreement will be
            interpreted as follows:
          </p>
          <ul>
            <li>
              Heading are for convenience only and shall not affect the
              construction or interpretation of any provision of this agreement;
            </li>
            <li>
              Where a word or phrase is defined, other parts of speech and
              grammatical forms and the cognate variations of that word or
              phrase shall have corresponding meanings;
            </li>
            <li>
              Words importing the singular shall include plural and vice versa;
            </li>
            <li>
              Reference to articles and schedules are to articles and schedules
              of this agreement;
            </li>
            <li>
              All words (whether gender specific or gender neutral) shall be
              deemed to include each of the masculine, feminine and neutral
              genders;
            </li>
            <li>
              The ejusdem generis (of the same kind) rule will not apply to the
              interpretation of this agreement, accordingly, include and
              including will be read without limitation;
            </li>
            <li>
              A reference to any document (including this agreement) is to that
              document as amended, consolidated, supplemented, novated or
              replaced from time to time in terms thereof;
            </li>
            <li>
              A reference to a statute or statutory provision includes, to the
              extent applicable at any relevant time;
            </li>
            <li>
              That statute or statutory provision as from time to time
              consolidated, modified, re-enacted or replaced by any other
              statute or statutory provision whether before or after the date of
              this agreement, and any subordinate legislation or regulation made
              under the relevant statute or statutory provision;
            </li>
            <li>
              References to writing include any mode of reproducing words in a
              legible and non-transitory form and shall include email or other
              electronic communication.
            </li>
          </ul>
          <p className={styles.header}>2. Types of cookies</p>
          <p className={styles.row}>2.1. We use the following cookies: </p>
          <p className={styles.row}>
            2.1.1 Strictly necessary cookies: These are Cookies that are
            required for the operation of the Website. They include, for
            example, Cookies that enable you to log into secure areas of the
            Website and enter into transactions. These are used to recognize you
            when you return to our website, enabling us to personalise our
            content for you, greet you by name and remember your preferences
            (for example, your choice of language or region).
          </p>
          <p className={styles.row}>
            2.1.1.1. Name Type Expiration Time Description. CookieControl
            Essential 30 days Saves cookie preferences after accepting cookies.
            RequestVerificationToken. Essential Session.
          </p>
          <p className={styles.row}>
            2.1.1.2 This is an anti forgery cookie. It is designed to stop
            unauthorised posting of content to a website, known as Cross-site
            request forgery. It holds no information about the user and is
            destroyed on closing the browser.
          </p>
          <p className={styles.row}>
            2.1.2 Analytical/performance cookies: They allow us to recognise and
            count the number of Visitors and to see how Visitors move around the
            Website when they are using it. This helps us to improve the way the
            Website works, for example, by ensuring that users are finding what
            they are looking for easily. We use Google Analytics Cookies which
            collects information anonymously. It reports Website trends without
            identifying individual visitors. You can opt out of Google Analytics
            without affecting how you visit the Website. For more information on
            Google Analytics, please click here.
          </p>
          <p className={styles.row}>2.1.2.2.</p>
          <p className={styles.row}>
            Name: -ga; Type: Analytics; Expiration Time: 2 years; Description:
            Used to distinguish users.
          </p>
          <p className={styles.row}>
            Name: -ga; Type: Analytics; Expiration Time: 24 hours; Description:
            Used to distinguish users.
          </p>
          <p className={styles.row}>
            Name: -ga; Type: Analytics; Expiration Time: 1 minute; Description:
            Used to throttle request rate.
          </p>
          <p className={styles.row}>
            2.1.3. Targeting cookies: These Cookies record your visit to the
            Website, the pages you have visited and the links you have followed.
            We will use this information to make the Website and the advertising
            displayed on it more relevant to your interests. We may also share
            this information with Third parties for this purpose. The targeting
            cookie used is Plausible. Plausible stores first-party cookies on
            your user's browser. Cookies are either set by the Plausible script,
            or by visiting{' '}
            <a href="https://plausible.io/" target="_blank">
              Plausible Website
            </a>
          </p>
          <p className={styles.row}>
            2.1.4. When we use the Plausible script on our website, Plausible
            cookies are responsible for displaying the correct content to our
            users without personally identifying anyone.When you visit Plausible
            website, it is important to note that the Plausible script is
            enabled on this website.
          </p>
          <p className={styles.row}>
            2.1.5. By using Plausible, you don't need to have any GDPR, CCPA or
            PECR prompts and you don't need a complex privacy policy about your
            use of analytics and cookies. With Plausible, you are not tracking
            any personal data after all. Your visitors can enjoy your site
            without any annoyances and distractions, for more information visit{' '}
            <a href="https://plausible.io/" target="_blank">
              Plausible
            </a>
          </p>

          <p className={styles.header}>3. Personal Data collection</p>
          <p className={styles.row}>
            3.1. When you access our Website we may collect the following Data
            automatically:
          </p>
          <ul>
            <li>Internet Protocol (IP) address, OS;</li>
            <li>
              Browser, device information (device ID, hardware model,etc.);
            </li>
            <li>Country, location, language, GPS;</li>
            <li>
              Time of access to website, clickstream data, time stamps, page
              response time, occurring errors duration of visits to pages;
            </li>
            <li>
              Services you searched/viewed, information about the relationship
              of pages (scrolling, clicks and mouse hovering), page interaction
              information;
            </li>
            <li>Google Analytics; cookies</li>
            <li>Other technical info that does not reveal your identity.</li>
          </ul>

          <p className={styles.row}>
            3.2. When you click your digital wallet on the Website, in addition
            to Data shown in Clause "3.1."" we may collect:
          </p>
          <ul>
            <li>Public blockchain data;</li>
            <li>Wallet type and identification data;</li>
            <li>Transactions data;</li>
            <li>Stored smart contracts data;</li>
            <li>
              Other information which may become available to us directly or
              through third party service providers.
            </li>
          </ul>

          <p className={styles.row}>
            3.3. When you link your Telegram account on the Website, in addition
            to Data shown in Clause "3.1."" we may collect the following Data:
          </p>
          <ul>
            <li>
              All information which is displayed in your Telegram (Phone number,
              Profile picture, Name, Links to third party resources, etc.).
            </li>
          </ul>

          <p className={styles.header}>4. Use of Google Analytics</p>
          <p className={styles.row}>
            4.1. Most Web browsers are set up to accept cookies, although you
            can reset your browser to refuse all Cookies or to indicate when a
            cookie is being sent. Note, however, that some portions of the
            Website may not work properly if you refuse cookies.
          </p>
          <p className={styles.row}>
            4.2. The Website uses Google Analytics, an Internet site analysis
            service supplied by Google Inc. ("Google"). Google Analytics uses
            Cookies which are text files placed on your computer to help to
            analyse the use made of the Website by its users. The Data subject
            generated by the Cookies concerning your use of the Website
            (including your IP address) will be forwarded to, and stored by,
            Google on servers located outside of Bermuda. Google will use this
            information to evaluate your use of the Website, compile reports on
            site activity for its publisher and provide other services relating
            to the activity of the Website and the use of the internet. Google
            may release these data to Third Parties if there is a legal
            obligation to do so or when the Third Parties process these data for
            the account of Google including, in particular, the publisher of the
            Website. Google will not cross-reference your IP address with any
            other data held by Google.
          </p>
          <p className={styles.row}>
            4.3. You may deactivate the use of Cookies by selecting appropriate
            parameters on your navigator. However, deactivation of this kind
            might prevent the use of certain functions of the Website. By using
            the Website, you specifically Consent to the Processing of your
            Personal data by Google under the conditions and for the purposes
            described above.
          </p>

          <p className={styles.header}>5. Third-party disclosure</p>
          <p className={styles.row}>
            5.1. Please note that Third Parties (including, for example,
            advertising networks and providers of external services like web
            traffic analysis services) may also use cookies, over which we have
            no control. These Cookies are likely to be analytical or performance
            Cookies or targeting cookies.
          </p>

          <p className={styles.header}>6. Blocking cookies</p>
          <p className={styles.row}>
            6.1. You block Cookies by activating the setting on your browser
            that allows you to refuse the setting of all or some cookies.
            However, if you use your browser settings to block all Cookies
            (including essential cookies) you may not be able to access all or
            parts of the Website.
          </p>

          <p className={styles.header}>7. Contact</p>
          <p className={styles.row}>
            7.1. If you wish to contact us please feel free to do so at the
            following email address: support@tonstarter.com
          </p>
        </div>
      </Page.Body>
      <Page.Footer />
    </Page>
  )
}
