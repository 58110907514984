import React from 'react'

import { Image, Text, useMobileLayout } from '@design-system'
import { Arrow, TelegramPlane } from '../icons'
import { partners } from './data/partners'
import { team } from './data/team'

import styles from '../components/About/About.scss'
import { advisors } from './data/advisors'

export const PunkDetails = () => {
  const isMobileLayout = useMobileLayout()

  const extraHeaderType = isMobileLayout ? 'header-32' : 'header-44'
  const headerType = isMobileLayout ? 'title-24' : 'title-28'
  const subheaderType = isMobileLayout ? 'title-20' : 'title-24'
  const paragraphType = isMobileLayout ? 'paragraph-16' : 'paragraph-16'
  const paragraphColor = 'secondary'

  return (
    <>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Product
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          PunkCity – the first game launched on $punk with thousands of players
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Game rules
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          <b>Destroy your enemies</b>
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          Compete with other players and get a reward! Punk City's CyberArena is
          a PvP turn-based competitive game inspired by "rock-paper-scissors."
          There are five different weapon types, and each weapon within a
          category has its own unique perk, properties, and playstyle.
        </Text>

        <Text type={paragraphType} color={paragraphColor}>
          <b>Punks — locals of the City</b>
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          Holders of TON Punks can tether their NFTs to in-game profiles,
          enabling access to a staking mechanism that provides daily rewards.
          Moreover, NFT owners participate in unique airdrops and enjoy separate
          prize pools during in-game events.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Key features
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          <ul>
            <li>
              PunkCity is a PVP mechanic game launched through telegram bot
            </li>
            <li>
              All matches are running by smart- contracts that guarantee
              fairness for both players
            </li>
            <li>
              Webapp-fast game load, because the load speed affects user
              conversion and retention
            </li>
            <li>
              Killing feature is a 1 click access to the game within the
              messenger
            </li>
          </ul>
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Stats
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          <b>6200</b> monthly users
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          <b>14 000</b> monthly on-chain transactions
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          <b>$50,000</b> last month game turnover
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="32">
          Core team
        </Text>
        <div className={styles.team}>
          {team.map((t, i) => (
            <div className={styles.teamMember} key={i}>
              <div className={styles.teamAvatar}>
                <img src={t.image} className={styles.imageCover} />
              </div>
              <Text type={subheaderType}>{t.name}</Text>
              <Text
                type={paragraphType}
                color="tertiary"
                spacingBottom={isMobileLayout ? '8' : '12'}
              >
                {t.role}
              </Text>
              {t.details.map((a, j) => (
                <Text
                  type={paragraphType}
                  color={paragraphColor}
                  spacingBottom="16"
                  key={j}
                >
                  {a}
                </Text>
              ))}
              {t.tg && (
                <a className={styles.teamLink} href={t.tg} target="_blank">
                  <Text type={paragraphType} color={paragraphColor}>
                    Telegram
                  </Text>
                  <TelegramPlane className={styles.icon} />
                </a>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Roadmap
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="24">
          We are targeting to move 10 million web 2.0 users to web3 through
          games
        </Text>

        <Image
          src="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/punks/roadmap.png?v=1"
          alt="TON Punks roadmap"
          lightbox={{
            images: [
              {
                alt: 'Megaton roadmap',
                src: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/punks/roadmap.png?v=1',
              },
            ],
          }}
        />
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Praxis platform
        </Text>

        <Text type={paragraphType} color={paragraphColor} spacingBottom="24">
          <b>In a nutshell</b> it is GameFi Platform for Building P2E Games in
          Telegram
        </Text>

        <Text type={paragraphType} color={paragraphColor} spacingBottom="24">
          <b>The problem</b>
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="24">
          <ul>
            <li>It’s hard to raise money for independent game builder</li>
            <li>Small games can’t attract enough liquidity for their token</li>
          </ul>
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="24">
          <b>The solution</b>
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="24">
          <ul>
            <li>A GameFi ecosystem fund new games</li>
            <li>
              One token for the whole ecosystem, with the shared liquidity
              between all games
            </li>
          </ul>
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Partners
        </Text>

        <div className={styles.richLinks}>
          {partners.map((p) => (
            <a
              className={styles.richLink}
              href={p.href}
              target="_blank"
              key={p.id}
            >
              <img className={styles.richLinkIcon} src={p.image} />
              <Text type={paragraphType} color={paragraphColor}>
                {p.title}
              </Text>
              <Arrow className={styles.richLinkArrow} />
            </a>
          ))}
        </div>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Advisors
        </Text>
        <div className={styles.team}>
          {advisors.map((t, i) => (
            <div className={styles.teamMember} key={i}>
              <div className={styles.teamAvatar}>
                <img src={t.image} />
              </div>
              <Text type={subheaderType}>{t.name}</Text>
              <Text
                type={paragraphType}
                color="tertiary"
                spacingBottom={isMobileLayout ? '8' : '12'}
              >
                {t.role}
              </Text>
              {t.linkedin && (
                <a
                  className={styles.teamLink}
                  href={t.linkedin}
                  target="_blank"
                >
                  <Text type={paragraphType} color={paragraphColor}>
                    LinkedIn
                  </Text>
                  <Arrow />
                  {/* <img src={arrow} /> */}
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
