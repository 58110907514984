import React, { useEffect, useState } from 'react'

import { PaymentModal, SuccessPaymentModal } from '@components'
import { Button, useModal } from '@design-system'
import { AnalyticsService } from '@services'
import { store, useAppDispatch, useAppSelector } from '@store'
import { fetchAvailableTokens, fetchClaimState } from '@store/allocations'
import { walletAddressSelector, walletTypeSelector } from '@store/wallet'
import { ClaimState, WalletType } from '@store'
import { ApplyFormStep } from '../ApplyForm'
import { Tip } from '../Tip/Tip'
import styles from './ClaimForm.scss'
import { normalizeTokens } from './helpers'
import { getTipMessage } from './tipHelpers'

interface ClaimFormProps {
  step: ApplyFormStep
  idoContractAddress: string
}

const paymentDetailsTable = [
  ['Network fee', '0.6 TON'],
  [],
  ['Total amount', '0.6 TON'],
]

export const FUNZEE_TOKEN_DECIMALS = 9
export const FUNZEE_TOKEN_SYMBOL = 'FNZ'

export const ClaimForm = ({
  step,
  idoContractAddress,
}: ClaimFormProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const walletAddress = useAppSelector(walletAddressSelector)
  const [claimState, setClaimState] = useState<ClaimState | null>(null)
  const paymentModal = useModal(PaymentModal, store)
  const successPaymentModal = useModal(SuccessPaymentModal, store)
  const walletType = useAppSelector(walletTypeSelector)
  const [readyToClaim, setReadyToClaim] = useState(false)
  const [checkPaymentInterval, setCheckPaymentInterval] = useState<any>()

  const fetchAvailableTokensRequest = async () => {
    const { payload } = await dispatch(
      fetchAvailableTokens({
        walletAddress,
        contractAddress: idoContractAddress,
      })
    )

    setClaimState(payload as ClaimState)
  }

  const fetchClaimStateRequest = async () => {
    const { payload } = await dispatch(
      fetchClaimState({
        walletAddress,
        contractAddress: idoContractAddress,
      })
    )

    if (payload) {
      setReadyToClaim(true)
    }
  }

  useEffect(() => {
    return () => clearInterval(checkPaymentInterval)
  }, [])

  useEffect(() => {
    if (step === ApplyFormStep.vesting) {
      fetchClaimStateRequest()
    }

    fetchAvailableTokensRequest()
  }, [step])

  const {
    availableToClaimTokens,
    allTokens,
    claimedTokens,
    firstClaimDate,
    secondClaimDate,
  } = normalizeTokens(claimState)

  const handleClaim = () => {
    AnalyticsService.track('claim_button_clicked', {
      button_type: showNextClaimButton ? 'stage_2' : 'stage_1',
    })

    paymentModal.open({
      title: `Claim ${availableToClaimTokens}`,
      subtitle: `Make confirmation with ${
        walletType === WalletType.tonKeeper ? 'Tonkeeper' : 'TonHub'
      }`,
      detailsTable: paymentDetailsTable,
      walletType,
      toPayInNanoTon: '600000000',
      address: idoContractAddress,
      buttonLabelPrefix: 'Confirm with',
      tipMessage:
        'Please confirm the claim and wait a little while for confirmation',
    })

    checkPaymentPolling()
  }

  const disabledClick = () => {
    AnalyticsService.track('claim_disable_clicked')
  }

  paymentModal.onClose(() => {
    setCheckPaymentInterval(null)
    clearInterval(checkPaymentInterval)
  })

  const checkPaymentPolling = () => {
    const checkPaymentInterval = setInterval(async () => {
      const { payload } = await dispatch(
        fetchAvailableTokens({
          walletAddress,
          contractAddress: idoContractAddress,
        })
      )

      if (payload.claimedTokens !== '0' && payload.availableTokens == '0') {
        clearInterval(checkPaymentInterval)

        AnalyticsService.track('claim_button_clicked', {
          button_type: showNextClaimButton ? 'stage_2' : 'stage_1',
        })

        successPaymentModal.open({
          title: `Claimed ${availableToClaimTokens} ${FUNZEE_TOKEN_SYMBOL}`,
          iconType: 'checkmark',
          subtitle: `Check ${
            walletType === WalletType.tonKeeper ? 'Tonkeeper' : 'TonHub'
          } wallet to make sure tokens are there.`,
        })

        paymentModal.close()
      }
    }, 5000)

    setCheckPaymentInterval(checkPaymentInterval)
  }

  const tipMessage = getTipMessage({
    step,
    claimState,
    availableToClaimTokens,
  })

  const showCompletedState = claimState && claimedTokens === allTokens
  const showClaimButton =
    readyToClaim && claimState && availableToClaimTokens !== 0
  const showClaimedTokensTip = readyToClaim && claimedTokens !== 0
  const showNextClaimButton =
    readyToClaim && claimedTokens !== 0 && claimedTokens !== allTokens

  if (showCompletedState) {
    return (
      <div className={styles.bochka}>
        <div className={styles.successTitle}>✅</div>
        <div className={styles.successTitle}>Withdrawal completed</div>
        <div className={styles.successSubtitle}>
          All tokens were sent to your wallet.
        </div>
      </div>
    )
  }

  return (
    <div className={styles.bochka}>
      <div className={styles.subtitle}>My tokens</div>
      <div className={styles.title}>{allTokens}</div>
      {showClaimedTokensTip && (
        <div className={styles.claimedTitle}>
          Claimed {claimedTokens} {FUNZEE_TOKEN_SYMBOL}
        </div>
      )}

      <Tip message={tipMessage} />
      {showClaimButton && (
        <Button fullWidth onClick={handleClaim}>
          Claim {availableToClaimTokens} {FUNZEE_TOKEN_SYMBOL}
        </Button>
      )}
      {!showClaimButton && showNextClaimButton && (
        <Button disabled fullWidth onClick={disabledClick}>
          Claim {secondClaimDate}
        </Button>
      )}
      {!showClaimButton && !showNextClaimButton && (
        <Button disabled fullWidth onClick={disabledClick}>
          Claim {firstClaimDate}
        </Button>
      )}
    </div>
  )
}
