import React from 'react'
import QRCode from 'react-qr-code'

import { Button } from '../../design-system'
import { useAppSelector } from '../../store'
import { themeSelector } from '../../store/app/appSlice'

import { mobileOrTablet } from '../../utils/mobileOrTablet'

import styles from './ConnectWalletQrModal.scss'

interface ConnectTonkeeperQrModalProps {
  loginLink: string | null
  onClose: (result?: string) => void
}

export function ConnectTonkeeperQrModal({
  onClose,
  loginLink,
}: ConnectTonkeeperQrModalProps) {
  const isMobileOrTablet = mobileOrTablet()
  const handleBack = () => onClose('back')
  const theme = useAppSelector(themeSelector)
  const bgColor = '#fff'
  const fgColor = '#000'

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Connect
        <br />
        Tonkeeper
      </div>
      {!isMobileOrTablet && (
        <div className={styles.subtitle}>
          Scan QR code with your mobile phone
          <br /> or Tonkeeper app
        </div>
      )}
      {isMobileOrTablet && (
        <div className={styles.subtitle}>Login via Tonkeeper in progress..</div>
      )}

      {!isMobileOrTablet && (
        <div className={styles.qr}>
          {loginLink && (
            <QRCode
              value={loginLink}
              size={300}
              level="L"
              bgColor={bgColor}
              fgColor={fgColor}
            />
          )}
        </div>
      )}

      <Button onClick={handleBack} className={styles.backButton}>
        Back
      </Button>
    </div>
  )
}
