import React from 'react'
import QRCode from 'react-qr-code'
import { useTonhubConnect } from 'react-ton-x'

import { Button } from '../../design-system'
import { useAppSelector } from '../../store'
import { themeSelector } from '../../store/app/appSlice'
import { mobileOrTablet } from '../../utils/mobileOrTablet'
import styles from './ConnectWalletQrModal.scss'

interface ConnectTonhubQrModalProps {
  onClose: (result?: string) => void
}

export function ConnectTonhubQrModal({ onClose }: ConnectTonhubQrModalProps) {
  const isMobileOrTablet = mobileOrTablet()
  const handleBack = () => onClose('back')
  const theme = useAppSelector(themeSelector)
  const bgColor = theme === 'dark' ? '#000' : '#fff'
  const fgColor = theme === 'dark' ? '#fff' : '#000'
  const tonhubConnect = useTonhubConnect()

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Connect
        <br />
        Tonhub
      </div>
      {!isMobileOrTablet && (
        <div className={styles.subtitle}>
          Scan QR code with your mobile phone
          <br /> or Tonhub app
        </div>
      )}
      {isMobileOrTablet && (
        <div className={styles.subtitle}>Login via Tonhub in progress..</div>
      )}

      {!isMobileOrTablet && (
        <div className={styles.qr}>
          {tonhubConnect.state.link && (
            <QRCode
              value={tonhubConnect.state.link}
              size={300}
              level="M"
              bgColor={bgColor}
              fgColor={fgColor}
            />
          )}
        </div>
      )}

      <Button onClick={handleBack} className={styles.backButton}>
        Back
      </Button>
    </div>
  )
}
