// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rUYUR{margin-left:5px}.RErsb{background:var(--color-fill-05);padding:12px 16px;border-radius:16px;width:100%;margin:16px 0}.RErsb tr{display:flex;margin-bottom:16px;justify-content:space-between}.RErsb tr:last-child{margin-bottom:0}.RErsb th{font-weight:500;font-size:16px;line-height:24px;color:var(--color-text-tertiary)}.RErsb td{font-weight:500;font-size:16px;line-height:24px;color:var(--color-text-primary)}.cEbGf{color:var(--palette-green)}", "",{"version":3,"sources":["webpack://./src/tonstarter/pages/Project/components/AirdropSidebar/components/SoldOutView/SoldOutView.scss"],"names":[],"mappings":"AAEA,OACE,eAAA,CAGF,OACE,+BAAA,CACA,iBAAA,CACA,kBAAA,CACA,UAAA,CACA,aAAA,CACA,UACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,qBACE,eAAA,CAGJ,UACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,gCAAA,CAEF,UACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,+BAAA,CAGJ,OACE,0BAAA","sourcesContent":["@use 'styles/mixins/theme.scss' as themeMix;\n\n.buttonIcon {\n  margin-left: 5px;\n}\n\n.table {\n  background: var(--color-fill-05);\n  padding: 12px 16px;\n  border-radius: 16px;\n  width: 100%;\n  margin: 16px 0;\n  tr {\n    display: flex;\n    margin-bottom: 16px;\n    justify-content: space-between;\n    &:last-child {\n      margin-bottom: 0;\n    }\n  }\n  th {\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 24px;\n    color: var(--color-text-tertiary);\n  }\n  td {\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 24px;\n    color: var(--color-text-primary);\n  }\n}\n.winnersPercent {\n  color: var(--palette-green);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonIcon": "rUYUR",
	"table": "RErsb",
	"winnersPercent": "cEbGf"
};
export default ___CSS_LOADER_EXPORT___;
