import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import Config from '@config'
import { TokenService } from '@services'
import { getProjectStatusTitle, isProjectActive, Project } from '@store'
import { formatCurrency } from '@utils'

import styles from './ProjectItem.scss'

export const ProjectItemSkeleton = () => {
  return (
    <div className={styles.root}>
      <div className={styles.skeletonCover} />
      <div className={styles.info}>
        <div className={styles.skeletonTitle} />
        <div className={styles.skeletonText} />
        <div className={styles.skeletonText} />
        <div className={styles.skeletonText} />
      </div>
    </div>
  )
}

const FundGoal = ({ project }: { project: Project }) => {
  if (project.isAirdrop) {
    const tokenDetails = TokenService.getTokenByProject(project.slug)
    const tokenTicker = tokenDetails.ticker
    const formatedFundsGoal = new Intl.NumberFormat('en-US').format(
      project.fundsGoal
    )

    let poolValue = formatedFundsGoal + ' ' + tokenTicker

    if (project.slug === 'megaton') {
      poolValue = formatCurrency('usd', project.fundsRaised)
    }

    return (
      <>
        <span>Rewards Pool</span>
        <span>{project.fundsGoal > 0 ? poolValue : 'TBD'}</span>
      </>
    )
  }

  return (
    <>
      <span>Total raise</span>
      <span>
        {project.fundsGoal > 0
          ? formatCurrency('usd', project.fundsGoal)
          : 'TBD'}
      </span>
    </>
  )
}

export const ProjectItem = ({ project }: { project: Project }) => {
  const link = `/project/${project.slug}`

  const bannerAvifUrl = project.projectDetails
    ? project.projectDetails.bannerAvif
    : ''

  const bannerWebpUrl = project.projectDetails
    ? project.projectDetails.bannerWebp
    : ''

  const bannerPngUrl = project.projectDetails
    ? project.projectDetails.bannerPng
    : ''

  const isFanZee = project.id === 7843 && Config.isProd

  return (
    <Link className={styles.root} to={`${link}`}>
      <div className={styles.cover}>
        <picture>
          <source srcSet={bannerAvifUrl} type="image/avif" />
          <source srcSet={bannerWebpUrl} type="image/webp" />
          <img
            decoding="async"
            loading="lazy"
            src={bannerPngUrl}
            alt={`${project.title} cover image`}
          />
        </picture>
      </div>
      <div className={styles.info}>
        <span className={classNames(styles.status, isProjectActive(project.status, project.isAirdrop) ? styles.statusActive : '')}>
          {getProjectStatusTitle(project.status, project.isAirdrop)}
        </span>
        {isFanZee ? <span className={styles.ath}>ATH +270%</span> : null}
        <h3 className={styles.title}>{project.title}</h3>
        {!!project.description && (
          <div className={styles.description}>{project.description}</div>
        )}
        <div className={styles.fundsGoal}>
          <FundGoal project={project} />
        </div>
      </div>
    </Link>
  )
}
