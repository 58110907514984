import React from 'react'

import { ConnectWalletButton } from '@components'
import Config from '@config'
import { Button } from '@design-system'
import { Allocation, AllocationStatus, generateTokenForLogin } from '@store'
import { openNewTab } from '@utils'
import { ApplyButton } from './ApplyButton'
import { ApplyFormStep } from './ApplyForm'
import { SubscribeButton } from './SubscribeButton'

export const applyForKyc = async (projectId: number) => {
  const response = await generateTokenForLogin()

  if (response) {
    openNewTab(`https://t.me/${Config.botName}?start=kyc_project_${projectId}`)
  } else {
    throw new Error('Error while generating token for KYC')
  }
}

export const getActionButtonByStep = ({
  step,
  isAuth,
  projectId,
  projectSlug,
  allocation,
}: {
  step: ApplyFormStep
  isAuth: boolean
  projectId: number
  projectSlug: string
  allocation: Allocation
}): JSX.Element | null => {
  if (!isAuth) {
    return <ConnectWalletButton title="Connect wallet" fullWidth />
  }

  if (step === ApplyFormStep.comingSoon) {
    return (
      <SubscribeButton
        allocationId={allocation.id}
        projectId={projectId}
        projectSlug={projectSlug}
      />
    )
  }

  if (step === ApplyFormStep.applicationsOpened) {
    if (allocation.status === AllocationStatus.whitelistSoon) {
      return (
        <ApplyButton
          allocationId={allocation.id}
          projectId={projectId}
          projectSlug={projectSlug}
        />
      )
    }

    if (
      allocation.status === AllocationStatus.whitelistStarted ||
      allocation.status === AllocationStatus.whitelistCompleted
    ) {
      return (
        <Button fullWidth disabled>
          Applied
        </Button>
      )
    }

    if (allocation.status === AllocationStatus.kycRequired) {
      return (
        <Button onClick={() => applyForKyc(projectId)} fullWidth>
          Complete KYC
        </Button>
      )
    }

    return null
  }

  if (
    step === ApplyFormStep.applicationsClosed ||
    step === ApplyFormStep.allocationsDraw
  ) {
    if (allocation.id) {
      return (
        <Button fullWidth disabled>
          Applied
        </Button>
      )
    }
  }

  return null
}
