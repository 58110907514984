import React from 'react'

import { Button, Page } from '@design-system'
import { Advisors } from './components/Advisors/Advisors'
import { Compare } from './components/Compare/Compare'
import { Guides } from './components/Guides/Guides'
import { HiHelmet } from './components/HiHelmet/HiHelmet'
import { Ido } from './components/Ido/Ido'
import { Intro } from './components/Intro/Intro'
import { Partners } from './components/Partners/Partners'
import { Projects } from './components/Projects/Projects'
import { TonFuture } from './components/TonFuture/TonFuture'
import { WhatWeDo } from './components/WhatWeDo/WhatWeDo'
import { WhyTon } from './components/WhyTon/WhyTon'
import { WhyTonstarter } from './components/WhyTonstarter/WhyTonstarter'
import styles from './HiPage.scss'

// <meta property="og:title" content="Telegram Login Widget">
{
  /* <meta property="og:image" content="https://core.telegram.org/file/811140220/1a02/WFoFUXRl_C8.20012/170c02fae7a0c638aa"> */
}
{
  /* <meta property="og:description" content="The Telegram login widget is a simple way to authorize users on your website. */
}
// Check out this post for a general overview…">

export const HiPage = (): JSX.Element => {
  return (
    <Page>
      <Page.Head />
      <HiHelmet />
      <Page.Header
        mode="light"
        action={
          <Button
            as="a"
            href="https://forms.tonstarter.com/build?source=hi"
            target="_blank"
          >
            Apply here
          </Button>
        }
      />
      <Page.Body>
        <Intro />

        <div className={styles.section} id="why-ton-blockchain">
          <WhyTon />
        </div>

        <div className={styles.section}>
          <TonFuture />
        </div>

        <div className={styles.section}>
          <Projects />
        </div>

        <div className={styles.section} id="why-tonstarter">
          <WhyTonstarter />
        </div>

        <div className={styles.section}>
          <Ido />
        </div>

        <div className={styles.section} id="launch-with-tonstarter">
          <WhatWeDo />
        </div>

        <div className={styles.section} id="tech-documentation">
          <Guides />
        </div>

        <div className={styles.section}>
          <Compare />
        </div>

        <div className={styles.section}>
          <Advisors />
        </div>

        <div className={styles.section} id="backers-and-partners">
          <Partners />
        </div>
      </Page.Body>
      <Page.Footer />
    </Page>
  )
}
