import React from 'react'
import { MenuItem, MenuItemProps } from './MenuItem'

interface MenuItemLinkProps extends Omit<MenuItemProps, 'href'> {
  to?: string
  target?: string
  children?: React.ReactNode
}

export function MenuItemLink({
  to,
  target,
  children,
  ...rest
}: MenuItemLinkProps) {
  return (
    <MenuItem as="a" href={to} target={target} {...rest}>
      {children}
    </MenuItem>
  )
}
