import React from 'react'

import styles from './Projects.scss'
import { Carousel } from '../../../Home/components/Carousel'
import gg from './images/gg.png'
import tonkeeper from './images/tonkeeper.png'
import tonplay from './images/tonplay.png'
import stickerface from './images/stickerface.png'
import tonwhales from './images/tonwhales.png'
import tonapps from './images/tonapps.png'

const projects = [
  {
    title: 'Getgems',
    image: gg,
    description:
      'Welcome to the largest NFT marketplace based on The Open Network',
    link: 'https://getgems.io',
    details: [
      { label: 'Total raise', value: '740K TON' },
      { label: 'ITEMS sold', value: '2.5K' },
    ],
  },
  {
    title: 'Tonkeeper',
    image: tonkeeper,
    description:
      'Tonkeeper is the easiest way to store, send, and receive Toncoin',
    link: 'https://tonkeeper.com',
    details: [
      { label: 'Total raise', value: '740K TON' },
      { label: 'ITEMS sold', value: '2.5K' },
    ],
  },
  {
    title: 'Ton Play',
    image: tonplay,
    description:
      'TON Play is a TON-based GameFi Platform — the place where games go web3 on TON',
    link: 'https://tonplay.io/',
    details: [
      { label: 'Total raise', value: '740K TON' },
      { label: 'ITEMS sold', value: '2.5K' },
    ],
  },
  {
    title: 'Ton Whales',
    image: tonwhales,
    description: 'Staking pool, mining pool, mobile wallet Tonhub',
    link: 'https://tonwhales.com',
    details: [
      { label: 'Total raise', value: '740K TON' },
      { label: 'ITEMS sold', value: '2.5K' },
    ],
  },
  {
    title: 'Others',
    image: tonapps,
    description: 'Find most popular dapps and apps on TON Blockchain',
    link: 'https://ton.app',
    details: [
      { label: 'Total raise', value: '740K TON' },
      { label: 'ITEMS sold', value: '2.5K' },
    ],
  },
]

const swiperBreakpoints = {
  600: {
    slidesPerView: 2.5,
    spaceBetween: 5,
  },
  960: {
    slidesPerView: 3.5,
    spaceBetween: 16,
  },
}

export const Projects = () => {
  return (
    <div className={styles.root}>
      <Carousel
        type="successProjects"
        title="Projects on TON"
        description=""
        data={projects}
        breakpoints={swiperBreakpoints}
      />
    </div>
  )
}
