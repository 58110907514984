import { UserRejectsError } from '@tonconnect/ui-react'
import { TonhubConnector } from 'ton-x'

import config from '@config'
import {
  LocalStorageService,
  TonConnectService,
} from '@services'
import { WalletType } from '@store'

interface TrxParams {
  amount: string
  address: string
}

export const trxTonhub = ({ amount, address }: TrxParams) => {
  return new Promise<{ ok: boolean; error?: string }>(async (resolve) => {
    const connector = new TonhubConnector({
      network: config.isProd ? 'mainnet' : 'testnet',
    })

    const request = {
      seed: LocalStorageService.getItem('tonhub-session', ''),
      appPublicKey: LocalStorageService.getItem('wallet-pubkey', ''),
      to: address,
      value: amount,
      timeout: 5 * 60 * 1000,
    }

    const { type } = await connector.requestTransaction(request)

    if (type === 'success') {
      resolve({ ok: true })
    }
    if (type === 'rejected') {
      resolve({
        ok: false,
        error:
          'You rejected the transaction. Please confirm it to continue',
      })
    } else {
      resolve({
        ok: false,
        error: 'Transaction rejected',
      })
    }
  })
}

export const trxTonConnect = ({ amount, address }: TrxParams) => {
  const now = new Date()
  return new Promise<{ ok: boolean; error?: string }>(async (resolve) => {
    const transaction = {
      validUntil: now.getTime() + now.getTimezoneOffset() * 60 * 2000, // 2min
      messages: [
        {
          address,
          amount,
        },
      ],
    }

    if (!TonConnectService.getConnector().connected) {
      await TonConnectService.getConnector().restoreConnection()
    }

    try {
      await TonConnectService.getConnector().sendTransaction(transaction)

      resolve({ ok: true })
    } catch (e) {
      if (e instanceof UserRejectsError) {
        resolve({
          ok: false,
          error:
            'You rejected the transaction. Please confirm it to continue',
        })
      } else {
        resolve({
          ok: false,
          error: 'Transaction rejected',
        })
      }
    }
  })
}

const sendTransactionMobile = ({
  address,
  walletType,
  amount,
}: {
  address: string
  walletType: WalletType
  amount: string
}) => {
  const paymentLink =
    walletType === WalletType.tonKeeper
      ? `https://app.tonkeeper.com/transfer/${address}?amount=${amount}`
      : `https://tonhub.com/transfer/${address}?amount=${amount}`

  if (walletType === 'tonkeeper') {
    location.href = paymentLink
  }

  if (walletType === 'tonhub') {
    window.location.assign(paymentLink)
  }

  return new Promise<{ ok: boolean; error?: string }>((resolve) => {
    resolve({ ok: true })
  })
}

export const sendTransaction = async ({
  address,
  amount,
  walletType,
  isMobileViewport,
}: {
  address: string
  amount: string
  isMobileViewport: boolean
  walletType: WalletType
}): Promise<{ ok: boolean; error?: string }> => {
  return trxTonConnect({ amount, address })
}