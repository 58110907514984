import React, { forwardRef, useState } from 'react'
import classNames from 'classnames'

import styles from './Image.scss'
import { ImageSquareSizeType } from './Image.types'
import { Lightbox, LightboxImages } from '../Lightbox'

export type ImageProps = {
  src: string
  className?: string
  lazy?: boolean
  onLoad?: (e?: unknown) => void
  squareSize?: ImageSquareSizeType
  asDataSrc?: boolean
  width?: string
  height?: string
  borderRadius?: string
  lightbox?: {
    images: LightboxImages
  }
  onClick?: () => void
}

export const Image = forwardRef<
  HTMLImageElement,
  ImageProps & React.ImgHTMLAttributes<HTMLImageElement>
>(
  (
    {
      src,
      className,
      alt = '',
      lazy,
      squareSize,
      width,
      height,
      asDataSrc,
      borderRadius,
      lightbox,
      onClick,
    },
    ref
  ) => {
    const [lightboxOpen, setLightboxOpen] = useState(false)

    const handleClick = () => {
      if (onClick) {
        onClick()
      }

      if (lightbox) {
        setLightboxOpen(true)
      }
    }

    return (
      <>
        {lightbox ? (
          <Lightbox
            isOpen={lightboxOpen}
            images={lightbox.images}
            onClose={() => setLightboxOpen(false)}
          />
        ) : null}
        <img
          src={asDataSrc ? undefined : src}
          data-src={asDataSrc ? src : undefined}
          className={classNames(
            styles.root,
            squareSize && styles[`squareSize-${squareSize}`],
            lightbox && styles.clickable,
            className && className
          )}
          alt={alt}
          ref={ref}
          loading={lazy ? 'lazy' : undefined}
          style={{
            width,
            height,
            borderRadius,
          }}
          onClick={handleClick}
        />
      </>
    )
  }
)

Image.displayName = 'Image'
