import React, { useEffect, useState } from 'react'

import { Content, Text, Skeleton, useMobileLayout } from '@design-system'
import {
  fetchProjectLeaders,
  isAuthSelector,
  useAppSelector,
  walletAddressSelector,
} from '@store'
import classNames from 'classnames'

import styles from './LeaderBoard.scss'

type LeaderboardItem = {
  position: number
  address: string
  points: number
  isCurrentUser: boolean
}

const getEmoji = (i: number) => {
  if (i === 0) {
    return <span className={styles.medal}>🥇</span>
  }

  if (i === 1) {
    return <span className={styles.medal}>🥈</span>
  }

  if (i === 2) {
    return <span className={styles.medal}>🥉</span>
  }
}

const LeaderboardTable = ({leaders, isMobileLayout} : {leaders: LeaderboardItem[], isMobileLayout: Boolean}) => {
  return (<>{
    leaders.map((l : LeaderboardItem, i) => (
      <div
        className={classNames(styles.row, l.isCurrentUser && styles.active)}
        key={i}
      >
        <Text color="tertiary" className={styles.number}>
          { l.position } {getEmoji(i)}
        </Text>
        <Text type="title-16">{l.address}</Text>
        {l.isCurrentUser && !isMobileLayout ? (
          <Text type="title-16" color="fourth">
            &nbsp;&nbsp;&nbsp;—&nbsp;&nbsp;you
          </Text>
        ) : null}
        <Text type="title-16" className={styles.pointsHeader}>
          {l.points}
        </Text>
      </div>
    ))
  }</>
  )
}

const LeaderboardTableSkeleton = () => {
  return (<div style={{display: 'flex', gap: '12px', flexDirection: 'column', padding: '16px 24px'}}>
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </div>)
}

export const LeaderBoard = ({ projectId, limit = 30 }: { projectId: string, limit: number }) => {
  const isMobileLayout = useMobileLayout()
  const address = useAppSelector(walletAddressSelector)
  const isAuth = useAppSelector(isAuthSelector)

  const [leaders, setLeaders] = useState([])

  const fetchBoard = async () => {
    const { ok, data } = await fetchProjectLeaders(projectId, address, limit)

    if (ok) {
      setLeaders(data)
    }
  }

  useEffect(() => {
    fetchBoard()
  }, [isAuth])

  return (
    <Content
      background="secondary"
      borderRadius="16"
      padding={isMobileLayout ? '0-0-0-0' : '16-0'}
      column
    >
      <div className={styles.row}>
        <Text color="tertiary" className={styles.number}>
          #
        </Text>
        <Text color="tertiary">Participants</Text>
        <Text color="tertiary" className={styles.pointsHeader}>
          Points
        </Text>
      </div>
      
      {
        leaders.length ? <LeaderboardTable leaders={leaders} isMobileLayout={isMobileLayout} /> : <LeaderboardTableSkeleton />
      }

    </Content>
  )
}
