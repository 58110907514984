import React from 'react'

import classNames from 'classnames'
import hiStyles from '../../HiPage.scss'
import styles from './Guides.scss'

const guides = [
  {
    title: 'Introduction to Tonstarter',
    description:
      'Tonstarter is the first investment platform based on The Open Network for retail venture investing in tokens of promising projects, enabling teams to raise capital in a decentralized, safe, and user-friendly environment.',
    link: 'https://docs.tonstarter.com',
  },
  {
    title: 'Steps to work with us',
    description:
      'Submit your project, 30 minute preliminary meeting, pass initial screening and then let’s start cooperation!',
  },
  {
    title: 'How we help to get investments',
    description:
      'We work closely with many funds and investors and have a strong network in the TON ecosystem, so after the preparatory work, we will connect the project with everyone.',
  },
  {
    title: 'Project selection criteria',
    description:
      'We already have high standards when choosing projects, only 30 projects from all will get into this program. But we are constantly adding new ones to the long list as the ecosystem develops.',
  },
]

const docs = [
  {
    title: 'Introduction to\u00A0TON',
    description:
      'The Open Network is a fast, secure, and scalable blockchain project focused on handling millions of transactions per second.',
    link: 'https://ton.org/docs/learn/introduction',
  },
  {
    title: 'Start building on TON',
    description:
      'Discover how the TON blockchain and its wider ecosystem work, as well as how to develop decentralized apps and create smart contracts.',
    link: 'https://ton.org/dev',
    emphasis: true,
  },
  {
    title: 'Aspects of the TON blockchain',
    description:
      'Here are six unique aspects of the TON blockchain that will surprise Solidity developers.',
    link: 'https://society.ton.org/six-unique-aspects-of-ton-blockchain-that-will-surprise-solidity-developers',
  },
  {
    title: 'Asynchronous smart contracts',
    description:
      'Learn how TON, the next generation blockchain, solves one of the main problem because it has an asynchronous actor architecture.',
    link: 'https://telegra.ph/Its-time-to-try-something-new-Asynchronous-smart-contracts-03-25',
  },
  {
    title: 'Write your first smart contract',
    description:
      'Here’s a step-by-step guide on writing your first smart contract in FunC.',
    link: 'https://blog.ton.org/step-by-step-guide-for-writing-your-first-smart-contract-in-func',
  },
]

const cards = (data: { [key: string]: any }, solid = false) => {
  return (
    <div className={styles.right}>
      {data.map((g, i) => {
        return g.link !== undefined ? (
          <a
            href={g.link}
            target="_blank"
            className={classNames(
              styles.card,
              styles.cardGray,
              g.emphasis && styles.cardEmphasis
            )}
            key={i}
          >
            <span className={styles.icon}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.7083 0.291748C15.2606 0.291748 15.7083 0.739463 15.7083 1.29175V11.7917C15.7083 12.344 15.2606 12.7917 14.7083 12.7917C14.156 12.7917 13.7083 12.344 13.7083 11.7917L13.708 3.70475L1.70711 15.7072C1.34662 16.0677 0.779392 16.0954 0.387101 15.7904L0.292893 15.7072C-0.0976311 15.3167 -0.0976311 14.6835 0.292893 14.293L12.293 2.29175H4.20833C3.6955 2.29175 3.27283 1.90571 3.21506 1.40837L3.20833 1.29175C3.20833 0.739463 3.65605 0.291748 4.20833 0.291748H14.7083Z"
                  fill="black"
                />
              </svg>
            </span>
            <div className={styles.cardTitle}>{g.title}</div>
            <div className={styles.cardSubtitle}>{g.description}</div>
          </a>
        ) : (
          <div className={styles.card} key={i}>
            <div className={styles.cardTitle}>{g.title}</div>
            <div className={styles.cardSubtitle}>{g.description}</div>
          </div>
        )
      })}
    </div>
  )
}

export const Guides = () => {
  return (
    <div className={styles.root}>
      {/* <div className={classNames(styles.row, styles.rowBgWhite)}>
        <div className={styles.left}>
          <div className={hiStyles.sectionTitle}>Guides and instructions</div>
          <div className={hiStyles.sectionSubtitle}>
            Our goal is to help attract investment in strong teams with the
            vision to build a successful project on The Open Network.
          </div>
        </div>

        <div className={styles.right}>{cards(guides, true)}</div>
      </div> */}

      <div className={classNames(styles.row, styles.rowBgWhite)}>
        <div className={styles.before} />
        <div className={styles.left}>
          <div className={hiStyles.sectionTitle}>Tech documentation</div>
          <div className={hiStyles.sectionSubtitle}>
            Find everything you need to know about the technical side of
            projects on TON
          </div>
        </div>

        <div className={styles.right}>{cards(docs)}</div>
      </div>
    </div>
  )
}
