import React, { useState } from 'react'

import { ProjectCategory } from '@store'
import { progressiveImage } from '@utils'

import classNames from 'classnames'
import styles from './CategoryItem.scss'

export const CategoryItem = ({ category }: { category: ProjectCategory }) => {
  const [progressiveImg, setProgressiveImg] = useState<
    'avif' | 'webp' | 'png' | null
  >(null)

  progressiveImage().then(setProgressiveImg)

  return (
    <a
      className={styles.root}
      href="https://tonstarter.com/hi/"
      target="_blank"
    >
      <div
        className={classNames(
          styles.cover,
          progressiveImg && styles[progressiveImg],
          category.id && styles[category.id]
        )}
      ></div>
      {/* <div className={styles.cover}>
        <picture className={styles.coverImage}>
          <source srcSet={require(`images/project-categories/${category.id}.avif`)} type="image/avif" />
          <source srcSet={require(`images/project-categories/${category.id}.webp`)} type="image/webp" />
          <img decoding="async" loading="lazy" src={require(`images/project-categories/${category.id}.jpg`)} alt={`${category.title} category cover`} />
        </picture>
      </div> */}
      <div className={styles.info}>
        <h3 className={styles.title}>{category.title}</h3>
        <span className={styles.link}>Submit your project</span>
      </div>
    </a>
  )
}
