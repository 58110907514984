// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aBD3U td{font-size:16px;padding:18px 8px;text-align:center}body[data-theme=dark] .aBD3U{color:#fff;background-color:var(--body-color)}", "",{"version":3,"sources":["webpack://./src/shared/design-system/components/Table/TableFooter.scss","webpack://./src/shared/design-system/styles/mixins/theme.scss"],"names":[],"mappings":"AAGE,UACE,cAAA,CACA,gBAAA,CACA,iBAAA,CCaO,6BDTP,UAAA,CACA,kCAAA","sourcesContent":["@use '../../styles/mixins/theme.scss' as theme;\n\n.root {\n  & td {\n    font-size: 16px;\n    padding: 18px 8px;\n    text-align: center;\n  }\n\n  @include theme.dark {\n    color: #fff;\n    background-color: var(--body-color);\n  }\n}\n","@mixin light() {\n  // @media (prefers-color-scheme: light) {\n  //   @at-root #{selector-nest('body:not([data-theme=light]):not([data-theme=dark])', &)} {\n  //     @content;\n  //   }\n  // }\n\n  @at-root #{selector-nest('body[data-theme=light]', &)} {\n    @content;\n  }\n}\n\n@mixin dark() {\n  // @media (prefers-color-scheme: dark) {\n  //   @at-root #{selector-nest('body:not([data-theme=light]):not([data-theme=dark])', &)} {\n  //     @content;\n  //   }\n  // }\n\n  @at-root #{selector-nest(\"body[data-theme='dark']\", &)} {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "aBD3U"
};
export default ___CSS_LOADER_EXPORT___;
