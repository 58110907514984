import { Button, Text } from '@design-system'
import { useAppSelector, walletAddressSelector } from '@store'
import { openNewTab } from '@utils'
import classNames from 'classnames'
import React from 'react'

import styles from './GlintAdopter.scss'

export const GlintAdopter = ({ embedded }: { embedded: boolean }) => {
  const walletAddress = useAppSelector(walletAddressSelector)

  const handleClick = () => {
    if (walletAddress) {
      openNewTab(`https://ton.diamonds/${walletAddress}`)
    } else {
      openNewTab('https://ton.diamonds/glint-coin#sale')
    }
  }

  return (
    <div className={classNames(styles.board, embedded && styles.embedded)}>
      <div className={classNames(styles.head)}>
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48 0H0V48H48V0ZM8.57227 24.0002C17.0933 24.0002 24.001 17.0925 24.001 8.57143C24.001 17.0925 30.9087 24.0002 39.4297 24.0002C30.9087 24.0002 24.001 30.9079 24.001 39.4289C24.001 30.9079 17.0933 24.0002 8.57227 24.0002Z"
            fill="white"
          />
        </svg>

        <Text type="paragraph-16">Become a TON Diamonds adopter</Text>
        <Text type="paragraph-14">
          Join Glint Coin utility token farming
        </Text>
      </div>

      <div className={styles.body}>
        <div className={styles.row}>Every Diamond farms Glint Coin daily</div>
        <div className={styles.row}>+100 Glint and +5% boost for 2 weeks</div>
        <div className={styles.row}>+10% lifetime farming speed boost</div>
      </div>

      <Button
        fullWidth
        variant={embedded ? 'primary' : 'contrast'}
        as="a"
        href="https://ton.diamonds/glint-coin#sale"
        target="_blank"
      >
        Buy TON Diamonds NFT
      </Button>
    </div>
  )
}
