import classNames from 'classnames'
import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Icon } from '@design-system'

import styles from './Input.scss'
import { copyToClipboard } from '@utils'

interface InputProps {
  value: string | undefined
  label: string
  disabled?: boolean
  allowDecimal?: boolean
  symbol?: string
  placeholder?: string
  hint?: string
  onMax?: () => void
  secondaryValue?: string
  validators?: { (value: string | undefined): string | null }[]
  onValidate?: (isValid: boolean) => void
  onChange?: (value: string) => void
  showCopyButton?: boolean
}

const INPUT_REGEXP = RegExp(`^\\d*(?:\\\\[.])?\\d*$`)

export const Input = ({
  value,
  label,
  symbol,
  disabled = false,
  allowDecimal = true,
  onChange,
  hint,
  placeholder,
  secondaryValue,
  onMax,
  validators = [],
  onValidate,
  showCopyButton = false,
  ...rest
}: InputProps): JSX.Element => {
  const [error, setError] = useState<string | null>(null)
  const inputElem = useRef<HTMLInputElement>(null)
  const [copyText, setCopyText] = useState('Copy')

  const classList = classNames(
    styles.root,
    disabled && styles.disabled,
    error && styles.hasError
  )

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value)
  }, [ onChange ])

  const handleFocus = () => {
    if (!disabled && inputElem?.current) {
      inputElem.current.focus()
    }
  }

  const handleCopy = () => {
    copyToClipboard(value ?? '')
    setCopyText('Copied')
    setTimeout(() => setCopyText('Copy'), 1000)
  }

  return (
    <div className={classList} {...rest}>
      <div className={styles.label}>{label}</div>
      <div className={styles.inputWrapper} onClick={handleFocus}>
        <div className={styles.inputLeft}>
          <div className={styles.textWrapper}>
            <input
              ref={inputElem}
              onChange={handleChange}
              value={value}
              className={styles.input}
              inputMode={allowDecimal ? 'decimal' : 'numeric'}
              autoComplete="off"
              autoCorrect="off"
              type="text"
              spellCheck="false"
              disabled={disabled}
              placeholder={placeholder}
            />
          </div>
        </div>

        { showCopyButton && 
          <div className={styles.maxButton} onClick={handleCopy}>
            {copyText}
          </div>
        }
      </div>
      {error && <div className={styles.error}>{error}</div>}
      {hint && <div className={styles.hint}>{hint}</div>}
    </div>
  )
}
