import React from 'react'

import {
  Button,
  Content,
  Icon,
  Link,
  Member,
  Text,
  useMobileLayout,
} from '@design-system'
import { openNewTab } from '@utils'
import { Project } from '../store'

export const data: Project = {
  comingSoon: false,
  id: 'tonDiamonds',
  name: 'TON Diamonds',
  slug: 'tondiamonds',
  iconUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tondiamonds/logo.svg',
  logoUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tondiamonds/logo.svg',
  logoDarkUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tondiamonds/logo.svg',
  urlTitle: 'ton.diamonds',
  urlLink: 'https://ton.diamonds',
  expertRatingLevel: '2',
  expertRatingIndex: 80,
  expertRatingMarker: 'A',
  communityRating: '',
  contractAddress: 'EQCaoIKuBOLHXZqU19Ib14o5AKe9boMxP6IGyd88jceBz3ro',
  contractAddressTestnet: 'EQCaoIKuBOLHXZqU19Ib14o5AKe9boMxP6IGyd88jceBz3ro',
  reportUrl:
    'https://tonstarter.notion.site/TON-Diamonds-2ea6aa4a0694402fbcc08b0f0bf195d6',
  category: 'marketplace',
  updatedAt: '8 Apr 2023',
  reports: [
    {
      reportType: 'reportV2',
      updatedAt: '21 Apr 2023',
      totalRate: '80%',
      values: {
        valueProposition: '83%',
        tokenomics: 'n/a',
        team: '73%',
        regulatory: 'n/a',
        community: '76%',
        tech: '93%',
        nft: '73%',
      },

      reviewers: [
        {
          title: 'Artem Gribanov',
          subtitle: 'Report',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/artem_gribanov.jpeg',
        },
        {
          title: 'Dario Tarantini',
          subtitle: 'Tech',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/dario.jpeg',
        },
        {
          title: 'Sergey, Tonstarter',
          subtitle: 'Report',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/sergey.png',
        },
        {
          title: 'Eugene Anisei',
          subtitle: 'Review',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/eugene_anisei.jpeg',
        },
      ],
    },
  ],
}

export const quote = {
  author: 'Dario Tarantini',
  authorAvatarUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/dario.jpeg',
  text: 'This project is a marketplace built on the TON blockchain that focuses on high quality NFT collections and digital artists. The platform allows users to buy, sell, and explore the world of cryptocurrency through NFTs. TON Diamonds is also a collection, being the first NFT collection created for TON and is designed to offer more than just visual appeal. It provides users with the opportunity to gain prestige, privileges, and membership in a private club, which opens up unexpected opportunities within the TON ecosystem. As a result, TON Diamonds is not just a work of art, but a gateway to exclusive benefits and opportunities within the TON blockchain community.',
}

export function aboutProject(): JSX.Element {
  const isMobileViewport = useMobileLayout()

  const headerType = isMobileViewport ? 'title-24' : 'title-28'
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'
  const textSpacingBottom = isMobileViewport ? '32' : '40'

  return (
    <>
      <Text type={textType} spacingBottom="12" color="secondary">
        The{' '}
        <Link
          textType={textType}
          href="https://ton.diamonds/"
          target="_blank"
          animatedUnderline={false}
        >
          TON Diamonds
        </Link>{' '}
        is a marketplace and auction house for NFTs (non-fungible tokens) on the
        TON blockchain. It is a platform where global digital artists can
        showcase and sell their artwork in the form of NFTs. TON Diamonds
        holders have exclusive privileges, such as early access to new
        collections, discounts, and invitations to VIP events.
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Some of the most popular artists on TON Diamonds include Sheidlina,
        Pokras Lampas, and Brickspacer. TON Diamonds also features a weekly
        ranking of the most popular collections by floor price and volume of
        sales.
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        In addition to buying and selling NFTs, TON Diamonds also hosts auctions
        for artworks from world-famous digital creators. The platform also
        features a section for recent trades, allowing users to keep track of
        the latest deals on the marketplace.
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Overall, TON Diamonds provides a platform for artists and collectors to
        engage in the growing world of digital art and NFTs, while also offering
        exclusive perks for its community of users.
      </Text>

      <Text type={headerType} spacingBottom="16">
        Roadmap
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        TON Diamonds has plans for 2023 on their{' '}
        <a href="https://ton.diamonds/community">Community page</a>. The plans
        include:
        <ul>
          <li>
            Real diamonds - Gifting real precious stones to Big Diamonds holders
          </li>
          <li>
            Merch Store - A private merch store with branded items and presents
            for community members
          </li>
          <li>3D Diamonds - Launching a 3D collection of diamonds</li>
          <li>
            DEX - Adding a DEX tool with the best conditions on the market
            exclusively for our holders
          </li>
          <li>
            DAO - Allowing our holders to make decisions regarding the
            marketplace development and vote on new collections and artists
          </li>
        </ul>
      </Text>

      <Text type={headerType} spacingBottom="24">
        Contacts
      </Text>

      <Content
        spacingChild={isMobileViewport ? '40' : '240'}
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Andrei Blinov"
          role="Chief Technology Officer"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tondiamonds/andrei.jpeg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() => openNewTab('https://t.me/andrrblinov')}
            >
              Telegram
            </Button>
          }
        />

        <Member
          name="Vladimir Makhov"
          role="Chief Executive Officer"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tondiamonds/vladimir.jpeg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() => openNewTab('https://t.me/makhoov')}
            >
              Telegram
            </Button>
          }
        />
      </Content>
    </>
  )
}

export function tokenDetails(): JSX.Element {
  const isMobileViewport = useMobileLayout()
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'

  return (
    <Text type={textType} color="secondary">
      The TON Diamonds has their own NFT-collection of Diamonds, which provides
      certain token capabilities, such as:
      <ul>
        <li>
          Access to the TON Diamonds NFT collection, which serves as a gateway
          to exploring the world of Web3 and allows holders to enjoy privileges
          within the TON ecosystem.
        </li>
        <li>
          Reduced or zero fees for trading on the TON Diamonds marketplace, with
          0% for Big Diamonds, 1% for Medium ones, and 2% for Small ones.
        </li>
        <li>
          Priority access to sales of partner collections and increased chances
          of winning in an airdrop.
        </li>
        <li>
          Access to a private group where holders can communicate with TON
          Diamonds founders, contribute to the project's development, and
          participate in special contests.
        </li>
      </ul>
    </Text>
  )
}
