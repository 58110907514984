import { createAsyncThunk } from '@reduxjs/toolkit'

import { ApiService, TonService } from '@services'

export const getWalletInfo = createAsyncThunk<{ [key: string]: any }, string>(
  'wallet/getWalletInfo',
  async (walletAddress, { rejectWithValue }) => {
    const { ok, data, error } = await TonService.fetchWalletInfo({
      walletAddress,
    })

    if (!ok) {
      return rejectWithValue(error)
    }

    return data
  }
)

export const getOwnerAndPublicKey = createAsyncThunk<{
  publicKey: string
  address: string
}>(
  'wallet/getOwnerAndPublicKey',
  async (params_papams, { rejectWithValue }) => {
    const { ok, data, error } = await ApiService.get({
      endpoint: '/current-owner',
    })

    if (!ok) {
      return rejectWithValue(error)
    }

    return data as any
  }
)
