export const team = [
  {
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/jake.jpg',
    name: 'Jake Li',
    role: 'Chairman',
    linkedin: '',
    details: [
      'Founder of PATI Games',
      'Founder of Natris',
      'Founder of Ozys',
      'Founder of DXM',
      'Bachelor, Computer Science and Engineering at Seoul National University',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/roi.jpg',
    name: 'Roi Choi',
    role: 'CEO',
    linkedin: '',
    details: [
      'Rolringhejeu',
      'CPO of DXM',
      'Bachelor, Computer Science and Engineering at Seoul National University',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/gisung.jpg',
    name: 'Gisung Choi',
    role: 'CFO',
    linkedin: 'https://www.linkedin.com/in/gi-sung-choi-a0a39aa1/',
    details: [
      'KCP',
      'Danal',
      'Style Nanda',
      'CFO of DXM',
      'Bachelor, School of Business Administration at Chung-Ang University',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/jongsic.jpg',
    name: 'Jongsic Choi',
    role: 'CTO',
    linkedin: 'https://www.linkedin.com/in/jongsic/',
    details: [
      'EST soft',
      'PATI Games',
      'CTO of V8',
      'CTO of DXM',
      'Bachelor, Computer Science and Engineering at Seoul National University',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/kyungsoo.jpg',
    name: 'Kyungsoo Ra',
    role: 'CCO',
    linkedin: 'https://www.linkedin.com/in/kyungsoora/',
    details: [
      'National Intelligence Service Korea',
      'Secretary to the Speaker of the House of Representatives',
      'Secretary of the National Assembly',
      'Bachelor, Sociology at London School of Economics',
    ],
  },
]
