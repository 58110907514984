export const roadmap = [
  {
    Q: 'Dust',
    year: '2017',
    actions: [
      'Dust 17:15',
      'Floating Eyes 07:07',
      'Kokoti 09:32',
      'Outro 03:07',
    ],
  }
]
