export const team = [
  {
    teamLogo: true,
    name: 'STON.fi team',
    role: '',
    ln: 'https://www.linkedin.com/search/results/people/?currentCompany=%5B%2285625344%22%5D&origin=COMPANY_PAGE_CANNED_SEARCH&sid=X5_',
    tg: 'https://t.me/stonfichat',
    details: [
      'Read more on LinkedIn about the project’s team.',
    ],
  },
  {
    image: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/stonfi/mrStonks.png',
    name: 'Mr. Stonks',
    role: 'Ambassador',
    tg: 'https://t.me/addstickers/MrSton',
    details: [
      'An active member of the STON.fi community bridging the gap between the project and its users, he’s becoming popular among the community.',
    ],
  },
]
