import { createSlice } from '@reduxjs/toolkit'

import { AuthService, LocalStorageService } from '@services'
import { RootState } from '..'
import {
  getAuthWebAppSession,
  getTonConnectSession,
  getTonhubTonConnectSession,
} from './authActions'

interface AuthState {
  isAuth: boolean
}

const initialState: AuthState = {
  isAuth: AuthService.isAuth(),
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut: (state) => {
      AuthService.clearCredentials()

      LocalStorageService.setItem('wallet-state', null)
      LocalStorageService.setItem('tonhub-session', null)
      LocalStorageService.setItem('wallet-pubkey', null)

      state.isAuth = false
    },
  },
  extraReducers(builder) {
    builder.addCase(getTonConnectSession.fulfilled, (state, action) => {
      state.isAuth = action.payload
    })
    builder.addCase(getTonhubTonConnectSession.fulfilled, (state, action) => {
      state.isAuth = action.payload.isAuth
      LocalStorageService.setItem(
        'wallet-pubkey',
        action.payload.walletPublicKey
      )
      LocalStorageService.setItem('tonhub-session', action.payload.sessionSeed)
    })
    builder.addCase(getAuthWebAppSession.fulfilled, (state, action) => {
      state.isAuth = action.payload
    })
  },
})

export const { signOut } = authSlice.actions
export const isAuthSelector = (state: RootState): boolean => {
  return state.auth.isAuth
}
export default authSlice.reducer
