import { Content, Icon, Text, useToggle } from '@design-system'
import { useAppSelector, walletAddressSelector } from '@store'
import { openNewTab } from '@utils'
import classNames from 'classnames'
import React from 'react'

import styles from './Farming.scss'

export const Farming = () => {
  const [isOpen, toggleOpen] = useToggle(true)
  const walletAddress = useAppSelector(walletAddressSelector)

  const handleClick = () => {
    if (walletAddress) {
      openNewTab(`https://ton.diamonds/${walletAddress}`)
    } else {
      openNewTab('https://ton.diamonds/glint-coin#sale')
    }
  }

  return (
    <div onClick={handleClick} className={styles.root}>
      <Content
        spacingChild="8"
        onClick={toggleOpen}
        align="center"
        justify="center"
        className={styles.header}
        padding="0-16"
      >
        <Text type="paragraph-16">Glint coin & boosts</Text>
        <Content alignSelf="end">
          <Icon
            colorFill="tertiary"
            name="chevron-up"
            className={classNames(styles.chevron, isOpen && styles.chevronDown)}
          />
        </Content>
      </Content>

      <div className={classNames(styles.list, !isOpen && styles.listClosed)}>
        <div className={classNames(styles.board)}>
          <div className={classNames(styles.head)}>
            <div className={classNames(styles.left)}>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  cx="20"
                  cy="20"
                  rx="20"
                  ry="20"
                  fill="white"
                  fillOpacity="0.05"
                />
                <g clipPath="url(#clip0_1054_66603)">
                  <mask
                    id="mask0_1054_66603"
                    style={{ maskType: 'luminance' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="40"
                    height="41"
                  >
                    <path d="M0 0.400024H40V40.4H0V0.400024Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_1054_66603)">
                    <mask
                      id="mask1_1054_66603"
                      style={{ maskType: 'luminance' }}
                      maskUnits="userSpaceOnUse"
                      x="8"
                      y="10"
                      width="24"
                      height="21"
                    >
                      <path
                        d="M8.80005 10.6H31.2001V30.2H8.80005V10.6Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask1_1054_66603)">
                      <mask
                        id="mask2_1054_66603"
                        style={{ maskType: 'alpha' }}
                        maskUnits="userSpaceOnUse"
                        x="8"
                        y="10"
                        width="24"
                        height="21"
                      >
                        <path
                          d="M31.2001 16.48L20.0001 30.2L8.80005 16.48L11.32 12.56L13.7 11.44L16.7801 10.6H23.2201L26.3001 11.44L28.6801 12.56L31.2001 16.48Z"
                          fill="#4400FF"
                        />
                        <path
                          d="M31.2001 16.48L20.0001 30.2L8.80005 16.48L11.32 12.56L13.7 11.44L16.7801 10.6H23.2201L26.3001 11.44L28.6801 12.56L31.2001 16.48Z"
                          fill="#7646F9"
                        />
                      </mask>
                      <g mask="url(#mask2_1054_66603)">
                        <mask
                          id="mask3_1054_66603"
                          style={{ maskType: 'luminance' }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="40"
                          height="41"
                        >
                          <path
                            d="M0 0.400024H40V40.4H0V0.400024Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask3_1054_66603)">
                          <path
                            d="M0 0.400024H40V40.4H0V0.400024Z"
                            fill="#926BFD"
                          />
                          <path
                            d="M20.0001 30.2L31.2001 16.48L28.6801 12.56L26.3001 11.44L23.2201 10.6H16.7801L13.7 11.44L11.32 12.56L8.80005 16.48L20.0001 30.2Z"
                            fill="#7646F9"
                          />
                          <path
                            d="M16.08 14.52H20V19L16.08 14.52Z"
                            fill="#C1ABFF"
                          />
                          <path
                            d="M23.92 14.52H20V19L23.92 14.52Z"
                            fill="#D3C4FF"
                          />
                          <path
                            d="M16.08 19H20L18.04 16.76L16.08 19Z"
                            fill="#9A75FF"
                          />
                          <path
                            d="M23.92 19H20L21.96 16.76L23.92 19Z"
                            fill="#BEA8FF"
                          />
                          <path
                            d="M13.35 16.97L16.08 19L18.04 16.76L13.35 16.97Z"
                            fill="#865CFF"
                          />
                          <path
                            d="M26.65 16.97L23.92 19L21.96 16.76L26.65 16.97Z"
                            fill="#AC8FFF"
                          />
                          <path
                            d="M16.08 14.52L18.04 16.76L13.35 16.97L16.08 14.52Z"
                            fill="#7745FF"
                          />
                          <path
                            d="M23.92 14.52L21.96 16.76L26.65 16.97L23.92 14.52Z"
                            fill="#885EFF"
                          />
                          <path
                            d="M8.80005 16.48L12.3 18.16L16.08 19L13.35 16.97L8.80005 16.48Z"
                            fill="#9A75FF"
                          />
                          <path
                            d="M12.3 18.16L16.08 19L13.35 16.97L12.3 18.16Z"
                            fill="#AC8FFF"
                          />
                          <path
                            d="M31.2 16.48L27.7 18.16L23.92 19L26.65 16.97L31.2 16.48Z"
                            fill="#9D79FF"
                          />
                          <path
                            d="M10.62 14.94L13.35 16.97L16.08 14.52L10.62 14.94Z"
                            fill="#8F66FF"
                          />
                          <path
                            d="M29.38 14.94L26.65 16.97L23.92 14.52L29.38 14.94Z"
                            fill="#9D79FF"
                          />
                          <path
                            d="M27.7 18.16L23.92 19L26.65 16.97L27.7 18.16Z"
                            fill="#BEA8FF"
                          />
                          <path
                            d="M12.44 13.4L16.08 14.52L10.62 14.94L12.44 13.4Z"
                            fill="#8052FF"
                          />
                          <path
                            d="M27.56 13.4L23.92 14.52L29.38 14.94L27.56 13.4Z"
                            fill="#8052FF"
                          />
                          <path
                            d="M8.80005 16.4801L10.62 14.9401L13.35 16.9701L8.80005 16.4801Z"
                            fill="#6C38FF"
                          />
                          <path
                            d="M31.2 16.4801L29.38 14.9401L26.65 16.9701L31.2 16.4801Z"
                            fill="#6C38FF"
                          />
                          <path
                            d="M20 33L23.92 19L27.7 18.16L20 33Z"
                            fill="#8960F9"
                          />
                          <path
                            d="M20 33L31.2 16.48L27.7 18.16L20 33Z"
                            fill="#7141F9"
                          />
                          <path
                            d="M16.08 19L20 33L23.92 19H16.08Z"
                            fill="#AC8FFF"
                          />
                          <path d="M23.92 19H20V33L23.92 19Z" fill="#BEA8FF" />
                          <path d="M20 19H23.92L20 22.5V19Z" fill="#D2C2FF" />
                          <path d="M20 19H16.08L20 22.5V19Z" fill="#BEA8FF" />
                          <path
                            d="M20 33L16.08 19L12.3 18.16L20 33Z"
                            fill="#7949F9"
                          />
                          <path
                            d="M20.0001 33L8.80005 16.48L12.3 18.16L20.0001 33Z"
                            fill="#5F28F9"
                          />
                          <path
                            d="M11.32 12.5601L8.80005 16.4801L12.44 13.4001L11.32 12.5601Z"
                            fill="#7949F9"
                          />
                          <path
                            d="M28.6801 12.5601L31.2001 16.4801L27.5601 13.4001L28.6801 12.5601Z"
                            fill="#8154F9"
                          />
                          <path
                            d="M23.2199 10.6H16.7799L13.6999 11.44L11.3199 12.56L12.4399 13.4L16.0799 14.52H23.9199L27.5599 13.4L28.6799 12.56L26.2999 11.44L23.2199 10.6Z"
                            fill="#9F7DFF"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_1054_66603">
                    <rect
                      width="26"
                      height="26"
                      fill="white"
                      transform="translate(7 7)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Buy a new NFT:
            </div>
            <div className={classNames(styles.right)}>
              <Text>
                +100 Glint{' '}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.00007 0.0903931C3.63172 0.0903931 0.0904541 3.63164 0.0904541 7.99999C0.0904541 12.3684 3.63172 15.9096 8.00007 15.9096C12.3685 15.9096 15.9096 12.3684 15.9096 7.99999C15.9096 3.63164 12.3685 0.0903931 8.00007 0.0903931ZM3.25431 7.99999C5.87531 7.99999 8.00007 5.87525 8.00007 3.25423C8.00007 5.87525 10.1248 7.99999 12.7458 7.99999C10.1248 7.99999 8.00007 10.1247 8.00007 12.7458C8.00007 10.1247 5.87531 7.99999 3.25431 7.99999Z"
                    fill="white"
                  />
                </svg>
              </Text>
              <Text>+5% boost</Text>
            </div>
          </div>

          <div className={styles.row}>
            <Text type="paragraph-14">
              For each new diamonds (bonuses sums up):
            </Text>
          </div>
          <div className={styles.row}>+100 Glint</div>
          <div className={styles.row}>+5% extra boost for 2 weeks</div>
          <div className={styles.row}>
            +10% total boost for all new diamonds
          </div>
        </div>
      </div>
    </div>
  )
}
