import classNames from 'classnames'
import * as React from 'react'

import stylesTypography from '../../styles/common/typography.scss'
import { TextType } from '../core'
import styles from './Text.scss'
import { TextAlign, TextColor, TextSpacingBottom, TextSpacingTop, TextWeight } from './types'

interface TextProps {
  align?: TextAlign
  as?: React.ElementType
  children: React.ReactNode
  className?: string
  color?: TextColor
  noWrap?: boolean
  type?: TextType
  uppercase?: boolean
  weight?: TextWeight
  spacingBottom?: TextSpacingBottom
  spacingTop?: TextSpacingTop
}

const Text = ({
  as: Component = 'div',
  children,
  type = 'paragraph-16',
  weight,
  color,
  noWrap,
  align,
  className,
  uppercase,
  spacingBottom,
  spacingTop,
  ...rest
}: TextProps): JSX.Element => {
  const classList = classNames(
    styles.root,
    type && stylesTypography[`typeface-${type}`],
    weight && styles[`weight-${weight}`],
    color && color !== 'primary' && styles[`color-${color}`],
    align && styles[`align-${align}`],
    spacingBottom && styles[`spacingBottom-${spacingBottom}`],
    spacingTop && styles[`spacingTop-${spacingTop}`],
    noWrap && styles.noWrap,
    uppercase && styles.uppercase,
    className
  )

  return (
    <Component className={classList} {...rest}>
      {children}
    </Component>
  )
}

Text.displayName = 'Text'

export default Text
