export const roadmap = [
  {
    phase: 'Phase I',
    actions: [
      {
        title: 'Cross-chain: TON <–> TRON',
        text: 'Swaps between TON and USDT on TRON will be available on the DEX without bridges and wrapped tokens.'
      },
      {
        title: 'SDK cross-chain',
        text: 'The STON.fi SDK will support cross-chain operations. Any project will be able to integrate cross-chain operations.'
      },
      
    ],
  },
  {
    phase: 'Phase II',
    actions: [
      {
        title: 'Cross-chain: Polygon, other EVM chains',
        text: 'More blockchain networks will be integrated to the STON.fi DEX protocol.'
      },
      {
        title: 'Stableswap routing',
        text: 'Swap synthetic assets via an optimal path and the stableswap invariant. This opens access to any high-volume swap and lower fees and does not limit the size of the swap to the liquidity amount.'
      },
    ],
  },
  {
    phase: 'Phase III',
    actions: [
      {
        title: 'Cross-chain: Trustful protocol',
        text: 'Full cross-chain protocol implementation with all assets from integrated networks.'
      },
      {
        title: 'Extra chain',
        text: 'A new network will be added in the cross-chain protocol.'
      },
    ],
  },
  {
    phase: 'Phase IV',
    actions: [
      {
        title: 'Telegram bot with cross-chain swaps',
        text: 'A Telegram-bot with a user-friendly interface will provide all Telegram users cross-chain operations.'
      },
      {
        title: 'DAO governance',
        text: 'A DAO governance protocol will be launched.'
      },
    ],
  },
  {
    phase: 'Phase V',
    actions: [
      {
        title: 'Limit order book',
        text: 'Limit orders qualitatively expand the capabilities of trading and provide the ability to form buy or sell orders at a fixed price.'
      },
      {
        title: 'Margin trading',
        text: 'Margin trading is an advanced financial product that refers to the practice of using borrowed funds from an AMM to trade assets, which serves as the collateral for the loan from the AMM.'
      },
      {
        title: 'Gasless swaps',
        text: 'This feature allows for transaction fees to be paid on the blockchain with any tokens, meaning you’ll no longer need native tokens for various operations.'
      },
    ],
  },
]
