import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { ConnectWalletButtonMenu, ConnectWalletModalWrapper } from '@components'
import { Button, useModal } from '@design-system'
import {
  getWalletInfo,
  isAuthSelector,
  store,
  useAppDispatch,
  useAppSelector,
  walletAddressSelector,
  walletBalanceSelector,
} from '@store'
import { collapseAddress } from '@utils'
import styles from './ConnectWalletButton.scss'
import { useTonConnectUI } from '@tonconnect/ui-react'

interface ConnectWalletButtonProps {
  size?: 'tiny'
  fullWidth?: boolean
  mobileMenu?: boolean
  title?: string
  className?: string
  handleOpenModal?(): void
}

export function ConnectWalletButton({
  fullWidth = false,
  mobileMenu = false,
  title = 'Connect wallet',
  className,
  size,
  handleOpenModal,
}: ConnectWalletButtonProps) {
  const dispatch = useAppDispatch()
  const connectWalletModal = useModal(ConnectWalletModalWrapper, store)

  const isAuth = useAppSelector(isAuthSelector)
  const walletBalance = useAppSelector(walletBalanceSelector)
  const walletAddress = useAppSelector(walletAddressSelector)

  const [tonConnectUI] = useTonConnectUI()

  const handleLogin = () => {
    tonConnectUI.connectWallet()
  }

  useEffect(() => {
    if (walletAddress) {
      dispatch(getWalletInfo(walletAddress))
    }
  }, [walletAddress])

  const [showDropDown, setShowDropDown] = useState(false)

  return (
    <div className={styles.root}>
      {!isAuth && (
        <Button
          onClick={handleLogin}
          fullWidth={fullWidth}
          className={className}
          size={size}
        >
          {title}
        </Button>
      )}
      {isAuth && (
        <>
          <div
            className={classNames(
              styles.connectWrapper,
              mobileMenu && styles.mobileMenu
            )}
          >
            <div
              className={styles.address}
              onClick={() => setShowDropDown(true)}
            >
              {collapseAddress(walletAddress, 4)}
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 1L5 5L1 1"
                  stroke="#496AEB"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            <ConnectWalletButtonMenu
              walletAddress={walletAddress}
              isOpen={showDropDown}
              walletBalance={walletBalance}
              onClose={() => setShowDropDown(false)}
            />
          </div>
        </>
      )}
    </div>
  )
}
