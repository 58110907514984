import React from 'react'

import {
  Button,
  Content,
  Icon,
  Link,
  Member,
  Text,
  useMobileLayout,
} from '@design-system'
import { openNewTab } from '@utils'
import { Project } from '../store'

export const data: Project = {
  id: 'stonfi',
  name: 'STON.fi',
  slug: 'stonfi',
  iconUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/stonfi/stonfi-32.svg',
  logoUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/stonfi/stonfi-logo.svg',
  logoDarkUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/stonfi/stonfi-dark-logo.svg',
  urlTitle: 'ston.fi',
  urlLink: 'https://ston.fi',
  expertRatingLevel: '2',
  expertRatingIndex: 85,
  expertRatingMarker: 'A',
  communityRating: '',
  contractAddress: 'EQCqkQnxrOySR1iEI_TJ-0QF2xy1JcpcaK5bO6psTKbQVsLk',
  contractAddressTestnet: 'EQCqkQnxrOySR1iEI_TJ-0QF2xy1JcpcaK5bO6psTKbQVsLk',
  fundamentalReportUrl:
    'https://tonstarter.notion.site/STON-fi-fundamental-5a0438907c7f4f91b5b5d4678e97fe52',
  technicalReportUrl:
    'https://tonstarter.notion.site/STON-fi-technical-07f974cc3c354f3c9fdc88ba8aa6e72a',
  category: 'defi',
  updatedAt: '17 Feb 2023',
  reports: [
    {
      reportType: 'fundamental',
      updatedAt: '17 Feb 2023',
      totalRate: '90%',
      values: {
        valueProposition: '88%',
        tokenomics: 'n/a',
        team: '93%',
        governance: 'n/a',
        regulatory: 'n/a',
      },

      reviewers: [
        {
          title: 'Deep Ton',
          subtitle: 'Report',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/deepton.png',
        },
        {
          title: 'Sergey, Tonstarter',
          subtitle: 'Review',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/sergey.png',
        },
      ],
    },
    {
      reportType: 'technical',
      updatedAt: '17 Feb 2023',
      totalRate: '80%',
      values: {
        codeAndTeam: '90%',
        docs: '85%',
        testing: '78%',
        security: '60%',
        accessControls: '84%',
      },

      reviewers: [
        {
          title: 'Vladimir, Tonstarter',
          subtitle: 'Report',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/vladimir.jpeg',
        },
        {
          title: 'Sergey, Tonstarter',
          subtitle: 'Review',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/sergey.png',
        },
      ],
    },
  ],
}

export const quote = {
  author: 'Vladimir, Tonstarter',
  authorAvatarUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/vladimir.jpeg',
  text:
    'Ston.fi is an exceptional project that boasts of a proficient and dedicated team that drives it.' +
    "\n\nThe open code base of smart contracts and clear documentation outlining how the contracts and the DEX function demonstrate the project's transparency. I appreciate the non-technical language used in the protocol and some parts of the documentation, explaining why the contracts are mutable and rules for changing them." +
    '\n\nThe team is always available to address queries and feedback on various social media platforms, and they have an informative blog for community members to learn about blockchain, DEX, and other related topics.' +
    "\n\nSton.fi's open GitHub account shows they are committed to creating inclusive contracts such as the Jetton Vote System, and the project keeps growing and providing regular updates." +
    '\n\nOverall, Ston.fi is an impressive project, and I am excited to see how they continue to evolve and grow.',
}

export function aboutProject(): JSX.Element {
  const isMobileViewport = useMobileLayout()

  const headerType = isMobileViewport ? 'title-24' : 'title-28'
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'
  const textSpacingBottom = isMobileViewport ? '32' : '40'

  return (
    <>
      <Text type={textType} spacingBottom="12" color="secondary">
        <Link
          textType={textType}
          href="https://ston.fi/"
          target="_blank"
          animatedUnderline={false}
        >
          STON.fi
        </Link>{' '}
        is a decentralized automated market maker (AMM) built on the TON
        blockchain providing virtually zero fees, low slippage, an extremely
        easy interface, and direct integration with TON wallets (from main
        website)
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        STON.fi was founded in 2022. It aims at building a user-friendly crypto
        exchange for mass-adoption through access to Telegram audience. Putting
        a high premium on the Community, STON.fi represents a DEX with a human
        face, providing users with fast support and taking into account their
        opinions. An architecture of TON blockchain with sharding allows STON.fi
        DEX users conduct millions of transactions per second. (from
        documentation)
      </Text>
      <Text type={headerType} spacingBottom="24">
        Contacts
      </Text>

      <Content
        spacingChild={isMobileViewport ? '40' : '240'}
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Mike Fedorov"
          role="Chief Executive Officer"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/stonfi/mikef.jpeg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() => openNewTab('https://t.me/zavhoz')}
            >
              Telegram
            </Button>
          }
        />

        <Member
          name="Vyacheslav Baranov"
          role="Chief Technology Officer"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/stonfi/slavik-baranov.jpg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() => openNewTab('https://t.me/SlavikBaranov')}
            >
              Telegram
            </Button>
          }
        />
      </Content>
      <Content spacingChild="240" spacingBottom="48">
        <Member
          name="Maksim Komarinets"
          role="Community Manager"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/stonfi/max-com.jpg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() => openNewTab('https://t.me/angry_max_im')}
            >
              Telegram
            </Button>
          }
        />
      </Content>
    </>
  )
}

export function tokenDetails(): JSX.Element {
  const isMobileViewport = useMobileLayout()
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'

  return (
    <Text type={textType} color="secondary">
      STON.Fi's token has not been released yet, although it is planned to be
      released in the future.{' '}
    </Text>
  )
}
