import classNames from 'classnames'
import React from 'react'

import { Content, Icon, Text, useToggle } from '@design-system'

import { User } from '@store'
import { TaskType } from '@utils'
import { TwitterLogin } from '../TwitterLogin/TwitterLogin'
import styles from './List.scss'

interface ListProps {
  tasks: TaskType[]
  taskGroups: string[]
  user: User | null
  showPoints: boolean
}

interface ListItemProps {
  task: TaskType
  showPoints: boolean
}

const ListItem = ({ task, showPoints }: ListItemProps): JSX.Element | null => {
  const isDone = task.done && styles.listItemDone
  const isHoverable = task.link && styles.listItemHoverable

  return (
    <a
      href={task.link}
      target="_blank"
      className={classNames(
        styles.listItem,
        isDone,
        isHoverable,
        !showPoints && styles.listItemCheckbox
      )}
    >
      <div className={styles.listItemMain}>
        <Text color="secondary">{task.title}</Text>
        {showPoints ? (
          <div className={styles.listItemValue}>
            {task.max !== 1 &&
              task.count > 0 &&
              !task.isDynamic &&
              task.count + '×'}
            {task.done && (task.count <= 1 || task.isDynamic)
              ? task.userPoints
              : task.taskPoints}
            <div className={styles.listItemIcon}></div>
          </div>
        ) : (
          <div className={styles.listItemValue}>
            <div className={styles.listItemIcon}></div>
          </div>
        )}
      </div>
      <Text
        type="paragraph-14"
        color="tertiary"
        className={styles.listItemDescription}
      >
        {task.description}
      </Text>
    </a>
  )
}

export const List = ({
  tasks,
  taskGroups,
  user,
  showPoints = true,
}: ListProps): JSX.Element => {
  if (taskGroups.length) {
    return (
      <>
        {taskGroups.map((group, i) => {
          const tasksInGroup = tasks.filter((task) => group === task.groupTitle)
          const [isOpen, toggleOpen] = useToggle(true)
          let twitterLoginRendered = false

          if (!tasksInGroup.length) {
            return <div key={`${i}-c`}></div>
          }

          if (group === 'Stones') {
            return <span key={`${i}-e`}></span>
          }

          if (group === 'Game') {
            return <span key={`${i}-e`}></span>
          }

          return (
            <div key={`${i}-d`}>
              <Content
                spacingChild="8"
                onClick={toggleOpen}
                align="center"
                justify="center"
                className={styles.header}
                padding="0-16"
              >
                <Text type="paragraph-16">{group}</Text>
                <Content alignSelf="end">
                  <Icon
                    colorFill="tertiary"
                    name="chevron-up"
                    className={classNames(
                      styles.chevron,
                      isOpen && styles.chevronDown
                    )}
                  />
                </Content>
              </Content>

              <div
                className={classNames(
                  styles.list,
                  !isOpen && styles.listClosed
                )}
              >
                {tasksInGroup.map((task, i) => {
                  const isTwitterLogin =
                    task.type === 'twitter_subscription' && !user?.twitterId

                  if (isTwitterLogin && !twitterLoginRendered) {
                    twitterLoginRendered = true
                    return (
                      <TwitterLogin
                        className={classNames(
                          styles.listItem,
                          styles.listTwitterLogin
                        )}
                        key={'twittor'}
                      />
                    )
                  }

                  if (!isTwitterLogin) {
                    return <ListItem task={task} key={`${i}-a`} />
                  }

                  return null // Skip rendering the Twitter login task again
                })}
              </div>
            </div>
          )
        })}
      </>
    )
  } else {
    return (
      <>
        <Text type="paragraph-16">Tasks</Text>
        <div className={styles.list}>
          {tasks &&
            tasks.map((task, i) => {
              return (
                <ListItem task={task} key={`${i}-b`} showPoints={showPoints} />
              )
            })}
        </div>
      </>
    )
  }
}
