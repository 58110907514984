//taken from https://avif.io/blog/tutorials/css/#howcssworks
const check_webp_feature = (callback: (arg0: boolean) => void) => {
  var img = new Image()

  img.onload = function () {
    var result = img.width > 0 && img.height > 0
    callback(result)
  }

  img.onerror = function () {
    callback(false)
  }

  img.src =
    'data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA=='
}

export const progressiveImage = async (): Promise<'avif' | 'webp' | 'png'> => {
  const avif = new Image()

  avif.src =
    'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A='

  return await new Promise(async (resolve, reject) => {
    avif.onload = () => {
      return resolve('avif')
    }
    avif.onerror = () => {
      check_webp_feature((isSupported: boolean) => {
        if (isSupported) {
          return resolve('webp')
        }
        return resolve('png')
      })
    }
  })
}
