import { createAsyncThunk } from "@reduxjs/toolkit";

import { ApiService, TonService } from "../../services";
import { Allocation, ClaimState, PrivateAllocation } from "./types";

export const fetchAllocations = createAsyncThunk<Allocation[]>(
  "allocations/fetchAllocations",
  async (params, { rejectWithValue }) => {
    const { ok, data, error } = await ApiService.get({
      params,
      endpoint: "/users/allocations"
    });

    if (!ok) {
      return rejectWithValue(error);
    }

    return data as Allocation[];
  }
);

export const fetchAllocation = createAsyncThunk<
  Allocation,
  { projectSlug: string, telegramId?: string | null, address?: string | null }
>(
  "allocations/fetchAllocation",
  async ({ projectSlug, address, telegramId }, { rejectWithValue }) => {
    const { ok, data, error } = await ApiService.get({
      params: { telegramId, address },
      endpoint: `/projects/slug/${projectSlug}/allocation`
    });

    if (!ok) {
      return rejectWithValue(error);
    }

    return data as Allocation;
  }
);

export const joinAllocation = createAsyncThunk<
  Allocation,
  { projectId: number; tonAmount: number }
>(
  "allocations/fetchAllocation",
  async ({ projectId, tonAmount }, { rejectWithValue }) => {
    const { ok, data, error } = await ApiService.post({
      data: { tonAmount },
      endpoint: `/projects/${projectId}/join/`
    });

    if (!ok) {
      return rejectWithValue(error);
    }

    return data as Allocation;
  }
);

export const claimAllocation = createAsyncThunk<
  Allocation,
  { projectId: number; tonAmount: number }
>(
  "allocations/fetchAllocation",
  async ({ projectId, tonAmount }, { rejectWithValue }) => {
    const { ok, data, error } = await ApiService.post({
      data: { tonAmount },
      endpoint: `/projects/${projectId}/claim/`
    });

    if (!ok) {
      return rejectWithValue(error);
    }

    return data as Allocation;
  }
);

export const checkActiveAllocationPayment = createAsyncThunk<
  boolean,
  { walletAddress: string; contractAddress: string }
>(
  "allocations/checkActiveAllocationPayment",
  async (params, { rejectWithValue }) => {
    const { ok, data, error } = await TonService.fetchIsPurchased(params);

    if (!ok) {
      return rejectWithValue(error);
    }

    return data as boolean;
  }
);

export const fetchAvailableTokens = createAsyncThunk<
  ClaimState,
  { walletAddress: string; contractAddress: string }
>("allocations/fetchAvailableTokens", async (params, { rejectWithValue }) => {
  const { ok, data, error } = await TonService.fetchAvailableTokens(params);

  if (!ok) {
    return rejectWithValue(error);
  }

  return data as any;
});

export const fetchClaimState = createAsyncThunk<
  boolean,
  { walletAddress: string; contractAddress: string }
>("allocations/fetchClaimState", async (params, { rejectWithValue }) => {
  const { ok, data, error } = await TonService.fetchClaimState(params);

  if (!ok) {
    return rejectWithValue(error);
  }

  return data;
});

export const activateAllocation = createAsyncThunk<
  Allocation,
  { projectId: number }
>(
  "allocations/activateAllocation",
  async ({ projectId }, { rejectWithValue }) => {
    const { ok, data, error } = await ApiService.post({
      data: "",
      endpoint: `/projects/${projectId}/activate-allocation/`
    });

    if (!ok) {
      return rejectWithValue(error);
    }

    return data as Allocation;
  }
);

export const fetchPrivateAllocation = createAsyncThunk<
  PrivateAllocation
>(
  "allocations/fetchPrivateAllocation",
  async (params, { rejectWithValue }) => {
    const { ok, data, error } = await ApiService.get({
      endpoint: `/users/private-allocations`
    });

    if (!ok) {
      return rejectWithValue(error);
    }

    return data as PrivateAllocation;
  }
);