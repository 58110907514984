import React, { ComponentType, useState } from 'react'

import { Tab, TabPanel, Tabs } from '@design-system'
import { Project, ProjectDetails } from '@store'
import { LeaderBoard } from '../Leaderboard/LeaderBoard'

import styles from './About.scss'

interface AboutProps {
  projectDetails: ProjectDetails
  DetailsComponent?: ComponentType<any>
  MoreComponent?: ComponentType<any>
  imageCarousel?: JSX.Element
  moreTabName?: string
  project: Project
  communityLink?: string
}

export function About({
  projectDetails,
  DetailsComponent,
  MoreComponent,
  moreTabName,
  project,
  imageCarousel,
  communityLink,
}: AboutProps): JSX.Element {
  let activeTabInitialState = 0
  let startIndex = 0
  const renderLeaderboard =
    project.leaderboardAmount && project.leaderboardAmount > 0

  if (
    renderLeaderboard &&
    window.location.hash.indexOf('leaderboard') !== -1 &&
    activeTabInitialState !== 2
  ) {
    activeTabInitialState = 2
  } else if (
    window.location.hash.indexOf('faq') !== -1 &&
    activeTabInitialState !== 1
  ) {
    activeTabInitialState = 1
  }

  const [activeTab, setActiveTab] = useState(activeTabInitialState)
  const handleChangeTab = (newIndex: number) => setActiveTab(newIndex)

  let tabs = [
    <Tab label="About the project" key="about" />,
    <Tab label={moreTabName || `Token details`} key="token" />,
  ]

  if (!DetailsComponent || !MoreComponent) {
    tabs = []
  }

  const tabsContent = [
    <TabPanel
      value={activeTab}
      index={startIndex}
      key={1}
    >
      {DetailsComponent && <DetailsComponent />}
    </TabPanel>,
    <TabPanel
      value={activeTab}
      index={startIndex + 1}
      key={2}
    >
      {MoreComponent && <MoreComponent />}
    </TabPanel>,
  ]

  if (renderLeaderboard) {
    tabsContent.push(
      <TabPanel
        value={activeTab}
        index={startIndex + 2}
        key={3}
      >
        <LeaderBoard
          projectId={String(project.id)}
          limit={project.leaderboardAmount || 50}
        />
      </TabPanel>
    )
    tabs.push(<Tab label="Leaderboard" key="leaderboard" />)
  }

  return (
    <div>
      {imageCarousel ? (
        <div className={styles.carousel}>{imageCarousel}</div>
      ) : (
        <div className={styles.banner}>
          <picture>
            {projectDetails.bannerAvif && (
              <source srcSet={projectDetails.bannerAvif} type="image/avif" />
            )}
            {projectDetails.bannerWebp && (
              <source srcSet={projectDetails.bannerWebp} type="image/webp" />
            )}
            <img
              decoding="async"
              loading="lazy"
              src={projectDetails.bannerPng}
              alt="Banner image"
            />
          </picture>
        </div>
      )}
      {tabs.length ? (
        <Tabs
          className={styles.tabs}
          onChange={handleChangeTab}
          value={activeTab}
          variant="text"
          fullWidth
        >
          {tabs}
        </Tabs>
      ) : null}
      {tabsContent}
    </div>
  )
}
