import React from 'react'

import { Content, Text } from '@design-system'

import styles from '../../AirdropSidebar.scss'

export const RewardPool = ({
  rewardPool,
  slug,
}: {
  rewardPool: string
  slug?: string
}) => {
  const Doodle = () => {
    switch (slug) {
      case 'fanton':
        return (
          <div>
            <span className={styles.fantonGoal}>🥅</span>
            <span className={styles.fantonBall}>⚽️</span>
          </div>
        )
      case 'one':
        return (
          <div className={styles.punch}>
            <span className={styles.punchFirework}>💥</span>
            <span className={styles.punchLeft}>🤜</span>
            <span className={styles.punchRight}>🤛</span>
          </div>
        )
      case 'punks':
        return <div className={styles.punkDiamond}></div>
      case 'stonfi':
        return (
          <img
            src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/stonfi/stonStone.png"
            width="60"
            alt="stone"
            className={styles.stonStone}
          />
        )
      case 'dedust':
        return (
          <div className={styles.dedust}>
            <img
              src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/dedust/korobochka.png"
              width="60"
              alt="Gift box"
              className={styles.korobochka}
            />
            {/* <img src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/dedust/monetochka.png" width="30" alt="SCALE coin" className={styles.monetochka} /> */}
          </div>
        )
      default:
        return (
          <div>
            <span className={styles.giftBig}>🎁</span>
            <span className={styles.giftSmall}>🎁</span>
          </div>
        )
    }
  }

  if (slug === 'tondiamonds') {
    return (
      <div className={styles.reward}>
        <Content justify="between" align="center" row grow>
          <Content column>
            <Text>10% boost + Glint</Text>
            <Text>for each new 💎 NFT</Text>
            <Text type="paragraph-14" color="tertiary">
              Rewards pool{' '}
            </Text>
          </Content>

          <Content padding="0-16">
            <svg
              width="69"
              height="40"
              viewBox="0 0 69 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1102_126861"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="36"
                y="0"
                width="33"
                height="40"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M36.9004 36.1254C40.982 31.9745 43.5 26.2813 43.5 20C43.5 13.7187 40.982 8.02546 36.9004 3.87465C40.214 1.43891 44.3058 0 48.7337 0C59.7794 0 68.7337 8.9543 68.7337 20C68.7337 31.0457 59.7794 40 48.7337 40C44.3058 40 40.214 38.5611 36.9004 36.1254Z"
                  fill="#D9D9D9"
                />
              </mask>
              <g mask="url(#mask0_1102_126861)">
                <ellipse
                  cx="49.4163"
                  cy="19.8746"
                  rx="19.8746"
                  ry="19.8746"
                  fill="white"
                />
                <path
                  d="M44.9579 28.7695C44.2665 28.7695 43.5985 28.3242 43.5985 27.5859C43.5985 27.1758 43.7157 26.918 43.9149 26.625L48.743 19.4766L53.5946 12.1523C53.993 11.5547 54.3212 11.3086 55.0009 11.3086C55.7274 11.3086 56.2899 11.8125 56.2899 12.5273C56.2899 12.8438 56.161 13.1367 55.9618 13.4414L51.3094 20.3672L46.2587 27.9609C45.8602 28.5586 45.5204 28.7695 44.9579 28.7695ZM43.4344 20.7305C40.9266 20.7305 39.3212 18.9609 39.3212 15.9961C39.3212 13.0195 40.9384 11.3203 43.4344 11.3203C45.9188 11.3203 47.536 13.0078 47.536 15.9961C47.536 18.9492 45.9305 20.7305 43.4344 20.7305ZM43.4344 18.5273C44.1141 18.5273 44.5829 17.7891 44.5829 15.9961C44.5829 14.2031 44.1141 13.5234 43.4344 13.5234C42.743 13.5234 42.2743 14.2031 42.2743 16.0078C42.2743 17.7891 42.743 18.5273 43.4344 18.5273ZM56.5126 28.7695C54.0048 28.7695 52.411 27 52.411 24.0352C52.411 21.0586 54.0282 19.3594 56.5126 19.3594C58.9969 19.3594 60.6141 21.0469 60.6141 24.0352C60.6141 26.9766 59.0204 28.7695 56.5126 28.7695ZM56.5126 26.5547C57.204 26.5547 57.661 25.8281 57.661 24.0352C57.661 22.2305 57.204 21.5508 56.5126 21.5508C55.8212 21.5508 55.3641 22.2305 55.3641 24.0352C55.3641 25.8281 55.8212 26.5547 56.5126 26.5547Z"
                  fill="#282829"
                />
              </g>
              <rect x="0.5" width="40" height="40" rx="20" fill="white" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 19.9999C14.232 19.9999 20.5 13.7319 20.5 5.99988C20.5 13.7319 26.768 19.9999 34.5 19.9999C26.768 19.9999 20.5 26.2679 20.5 33.9999C20.5 26.2679 14.232 19.9999 6.5 19.9999Z"
                fill="#282829"
              />
            </svg>
          </Content>
        </Content>
      </div>
    )
  }

  return (
    <div className={styles.reward}>
      <div>
        <Text className={styles.rewardLabel}>Rewards pool</Text>
        <Text className={styles.rewardAmount}>{rewardPool}</Text>
      </div>
      <Doodle />
    </div>
  )
}
