import { Content, Text } from '@design-system'
import React from 'react'

import styles from './Lose.scss'

export const Lose = () => {
  return (
    <Content align="center" justify="center" grow className={styles.root}>
      <div className={styles.icon} />
      <Text align="center" color="fourth">
        Next sessions starts at 10:00 UTC, 14:00 UTC, 18:00 UTC
      </Text>
    </Content>
  )
}
