import React from 'react'

import {
  Button,
  Content,
  Icon,
  Image,
  Link,
  Member,
  Text,
  useMobileLayout,
} from '@design-system'
import { openNewTab } from '@utils'
import { Project } from '../store'

export const data: Project = {
  id: 'dedust',
  name: 'DeDust',
  slug: 'dedust',
  iconUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/dedust/dedust.png',
  logoUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/dedust/dedust.png',
  logoDarkUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/dedust/dedust.png',
  urlTitle: 'dedust.io',
  urlLink: 'https://dedust.io',
  expertRatingLevel: '4',
  expertRatingIndex: 59,
  expertRatingMarker: 'B',
  communityRating: '',
  contractAddress: 'EQCkY6rYY_O2b18nheU9cS4L9JP6MttfG20Yaok-AcyvCBCI',
  contractAddressTestnet: 'EQCkY6rYY_O2b18nheU9cS4L9JP6MttfG20Yaok-AcyvCBCI',
  fundamentalReportUrl:
    'https://tonstarter.notion.site/DeDust-Fundamental-41113dd5c654492b8806362534ac8b5a',
  technicalReportUrl: '',
  category: 'defi',
  updatedAt: '3 Mar 2023',
  reports: [
    {
      reportType: 'fundamental',
      updatedAt: '3 Mar 2023',
      totalRate: '59%',
      values: {
        valueProposition: '74%',
        tokenomics: '40%',
        team: '65%',
        governance: 'n/a',
        regulatory: 'n/a',
      },

      reviewers: [
        {
          title: 'Deep Ton',
          subtitle: 'Report',
          link: 'https://t.me/DeepTon',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/deepton.png',
        },
        {
          title: 'Sergey Ch',
          subtitle: 'Review',
          link: 'https://twitter.com/serega_ton',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/sergey.png',
        },
      ],
    },
  ],
}

export const quote = {
  type: 'gpt',
  text: "In summary, DeDust is good in terms of what it offers and the size of its target market. However, it could do better by having a stronger tokeneconomic model, and being more visible to the public (open source code, etc.). Unfortunately, we don't have enough information about governance and regulatory aspects to provide a complete evaluation. We gave DeDust a total score of 98 out of 165, which shows potential. But there are areas that need more development and improvement.",
}

export function aboutProject(): JSX.Element {
  const isMobileViewport = useMobileLayout()

  const headerType = isMobileViewport ? 'title-24' : 'title-28'
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'
  const textSpacingBottom = isMobileViewport ? '32' : '40'

  return (
    <>
      <Text type={textType} spacingBottom="12" color="secondary">
        <Link
          textType={textType}
          href="https://dedust.io/"
          target="_blank"
          animatedUnderline={false}
        >
          DeDust
        </Link>{' '}
        is a decentralized exchange (DEX) that operates on the TON (Telegram
        Open Network) blockchain. It allows users to trade cryptocurrencies
        without the need for intermediaries such as centralized exchanges or
        banks.
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        DeDust DEX uses a unique trading mechanism called "Automated Market
        Making" (AMM) which eliminates the need for order books and enables
        users to trade against liquidity pools. This means that users can trade
        cryptocurrencies instantly and with low fees, without having to wait for
        an order to be matched with a counterparty.
      </Text>
      <Text type={headerType} spacingBottom="24">
        Contacts
      </Text>

      <Content
        spacingChild={isMobileViewport ? '40' : '240'}
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Nick Nekilov"
          role="Founder, Developer"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/dedust/nick.jpeg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() => openNewTab('https://t.me/NickNekilov')}
            >
              Telegram
            </Button>
          }
        />
      </Content>
    </>
  )
}

export function tokenDetails(): JSX.Element {
  const isMobileViewport = useMobileLayout()
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'

  return (
    <>
      <Text type={textType} color="secondary" spacingBottom="24">
        <b>Initial Supply:</b> 21,000,000 SCALE.
        <br></br>
        <b>Current supply:</b> 16,811,232 SCALE.
      </Text>
      <Text type={textType} color="secondary" spacingBottom="24">
        <ul>
          <li>
            <Link
              textType={textType}
              href="https://tonapi.io/transaction/39b34c1b943e6a73c20a78bb7ab3bae7e092b3fbd1e9d931783da7b4d390e0cf"
            >
              Grants:
            </Link>{' '}
            24.9% or 5.25M coins.
          </li>
          <li>
            <Link
              textType={textType}
              href="https://tonapi.io/transaction/6c5339c86eaab58f653344c6915030c94135f19de09fca6f54024c9d6a7b6428"
            >
              Team:
            </Link>{' '}
            12.6% or 2.65M coins.
          </li>
          <li>
            <Link
              textType={textType}
              href="https://tonapi.io/transaction/3c040fe0cc440800bca7fc938e5400808c453689b152fe81658e5a38190ef50c"
            >
              CEX:
            </Link>{' '}
            10% or 2.1M coins.
          </li>
          <li>
            <Link
              textType={textType}
              href="https://tonapi.io/transaction/62a410c142c8e55c890af0622c99bb2d7d92510c95e4309762393f1e755177e3"
            >
              Marketing:
            </Link>{' '}
            10% or 2.1M coins.
          </li>
          <li>
            <Link
              textType={textType}
              href="https://tonapi.io/transaction/ca7d0decc21e9ebe90044a8480515d601fb15bf15a222e6fe106829bd7c87bda"
            >
              Development:
            </Link>{' '}
            9% or 1.9M coins.
          </li>
          <li>Bug Bounty: 1% or 214.5K coins.</li>
          <li>
            <Link
              textType={textType}
              href="https://tonapi.io/transaction/48a538859510be36ec25084a58e1489e502e9e8f8bfaf2e8b864a7f646471e6f"
            >
              DEX listing (burned):
            </Link>{' '}
            5.2% or 1.05M coins.
          </li>
          <li>
            <Link
              textType={textType}
              href="https://tonapi.io/transaction/562ba44355a4a6d17c526e686873379e9119746d882341de9c01702e5dc4d37b"
            >
              DEX listing:
            </Link>{' '}
            5.2% or 1.05M coins.
          </li>
          <li>ICO #1: 10% (burned) or 2.1M coins.</li>
          <li>ICO #2: 4.8% (sale) or 1,011,231.83 coins.</li>
          <li>ICO #2: 5.2% (burned) or 18,876,817 coins.</li>
          <li>
            <Link
              textType={textType}
              href="https://tonapi.io/transaction/1581d0c8ad5dd70628cb060db2c07ffa91830fc00673924a21a1bae4e9b93e6b"
            >
              AirDrop:
            </Link>{' '}
            2.3% or 485.5K coins.
          </li>
        </ul>
      </Text>
      <Image
        src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/dedust/tokenomics.jpeg"
        alt="DeDust tokenomics figure"
        borderRadius="16px"
      ></Image>
    </>
  )
}
