// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cF7Yk td:first-of-type,.cF7Yk th:first-of-type{padding-left:0}.cF7Yk td:last-of-type,.cF7Yk th:last-of-type{padding-right:0}.oaPqS{width:100%;table-layout:fixed;position:relative;border-collapse:collapse;border-radius:16px;background-color:var(--color-bg-secondary)}.oaPqS ._7f5w4{font-weight:500;border-bottom:1px solid var(--color-separator)}.oaPqS ._7f5w4 th{font-weight:500;font-size:14px;line-height:20px;color:var(--color-text-tertiary);vertical-align:bottom}.oaPqS tr:last-of-type{border-bottom:none}._944dH{position:sticky;top:0;background:#fff;border-radius:inherit;z-index:2}", "",{"version":3,"sources":["webpack://./src/shared/design-system/components/Table/Table.scss"],"names":[],"mappings":"AAGE,gDAEE,cAAA,CAGF,8CAEE,eAAA,CAIJ,OACE,UAAA,CACA,kBAAA,CACA,iBAAA,CACA,wBAAA,CACA,kBAAA,CACA,0CAAA,CAEA,eACE,eAAA,CACA,8CAAA,CAEA,kBACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,gCAAA,CAEA,qBAAA,CAIJ,uBACE,kBAAA,CAIJ,QACE,eAAA,CACA,KAAA,CACA,eAAA,CACA,qBAAA,CACA,SAAA","sourcesContent":["@use '../../styles/mixins/theme.scss' as theme;\n\n.headerRow {\n  & td:first-of-type,\n  & th:first-of-type {\n    padding-left: 0;\n  }\n\n  & td:last-of-type,\n  & th:last-of-type {\n    padding-right: 0;\n  }\n}\n\n.table {\n  width: 100%;\n  table-layout: fixed;\n  position: relative;\n  border-collapse: collapse;\n  border-radius: 16px;\n  background-color: var(--color-bg-secondary);\n\n  & .head {\n    font-weight: 500;\n    border-bottom: 1px solid var(--color-separator);\n\n    th {\n      font-weight: 500;\n      font-size: 14px;\n      line-height: 20px;\n      color: var(--color-text-tertiary);\n      // background-color: var(--color-bg-secondary);\n      vertical-align: bottom;\n    }\n  }\n\n  tr:last-of-type {\n    border-bottom: none;\n  }\n}\n\n.fixedHeaders {\n  position: sticky;\n  top: 0;\n  background: white;\n  border-radius: inherit;\n  z-index: 2;\n}\n\n.body {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerRow": "cF7Yk",
	"table": "oaPqS",
	"head": "_7f5w4",
	"fixedHeaders": "_944dH"
};
export default ___CSS_LOADER_EXPORT___;
