import {
  currentUserSelector,
  fetchMe,
  isAuthSelector,
  useAppDispatch,
  useAppSelector,
} from '@store'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

type PrivateInvestorRouteProps = {
  redirectPath?: string
  component: JSX.Element
}

export const PrivateInvestorRoute = ({
  redirectPath = '/',
  component,
}: PrivateInvestorRouteProps) => {
  const isAuth = useAppSelector(isAuthSelector)
  const currentUser = useAppSelector(currentUserSelector)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchMe())
    } else {
      navigate(redirectPath)
    }
  }, [isAuth])

  if (!currentUser || !isAuth) {
    return null
  }

  if (currentUser && !currentUser.isPrivateInvestor) {
    // Yes, Yes, I know.
    setTimeout(() => {
      navigate(redirectPath)
    }, 10)

    return null
  }

  return component
}
