import React, { useState } from 'react'

import { Button, Page } from '@design-system'
import classNames from 'classnames'
import no from './no.png'
import styles from './VotingPage.scss'
import yeah from './yeah.png'

export const VotingPage = (): JSX.Element => {
  const [result, setResult] = useState<null | boolean>(null)

  return (
    <Page>
      <Page.Head />

      <Page.Header
        mode="light"
        action={
          <Button
            as="a"
            href="https://forms.tonstarter.com/build?source=hi"
            target="_blank"
          >
            Apply here
          </Button>
        }
      />
      <Page.Body>
        <div className={styles.root}>
          <p className={styles.title}>
            Tonstarter Web3.0 Decentralized Voting ML Crypto Algorythm (beta)
          </p>
          <p className={styles.question}>
            Let's launch Tonstarter into the Space?
          </p>
          <div className={styles.voting}>
            <img
              src={yeah}
              onClick={() => setResult(true)}
              className={classNames(result === true && styles.active)}
            />
            <img
              src={no}
              onClick={() => setResult(false)}
              className={classNames(result === false && styles.activeNo)}
            />
          </div>
        </div>
        <div className={styles.result}>
          {result === true ? 'Yeah! Yeah!! Yeah!11' : null}
          {result === false ? 'No-no-no!! No... No.' : null}
        </div>
      </Page.Body>
      <Page.Footer />
    </Page>
  )
}
