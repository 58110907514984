import React from 'react'

import {
  Button,
  Content,
  Icon,
  Member,
  Text,
  useMobileLayout,
} from '@design-system'
import { openNewTab } from '@utils'
import { Project } from '../store'
import styles from '../pages/ProjectPage/ProjectPage.scss'

export const data: Project = {
  comingSoon: false,
  id: 'tonhub',
  name: 'Tonhub',
  slug: 'tonhub',
  iconUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonhub/tonhub.png',
  logoUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonhub/tonhub.png',
  logoDarkUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonhub/tonhub.png',
  urlTitle: 'Tonhub',
  urlLink: 'https://tonhub.com',
  expertRatingLevel: '2',
  expertRatingIndex: 80,
  expertRatingMarker: 'A',
  communityRating: '',
  contractAddress: 'EQDVWdXaEH28nKHf485LGX4CUl0PJ_mHzRiU1PReth0VkvnP',
  contractAddressTestnet: 'EQDVWdXaEH28nKHf485LGX4CUl0PJ_mHzRiU1PReth0VkvnP',
  reportUrl: 'https://tonstarter.notion.site/Tonhub-58227f8fc60940dab89232ebbc5b3e33?pvs=4',
  category: 'wallet',
  updatedAt: '29 Jun 2023',
  reports: [
    {
      reportType: 'reportV2',
      updatedAt: '29 Jun 2023',
      totalRate: '80%',
      values: {
        valueProposition: '69%',
        tokenomics: 'n/a',
        team: '83%',
        regulatory: '92%',
        community: '64%',
        tech: '65%',
        wallet: '96%',
      },

      reviewers: [
        {
          title: 'Daniil',
          subtitle: 'Report',
          link: 'https://t.me/crypt_danya',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/daniil.jpeg',
        },
        {
          title: 'Shreshtha Kumar',
          subtitle: 'Review',
          link: 'https://twitter.com/Web3writes',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/ShreshthaKumar.jpeg',
        },
      ],
    },
  ],
}

export const quote = {
  author: 'Daniil',
  authorLink: 'https://t.me/crypt_danya',
  authorTitle: 'Report',
  authorAvatarUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/daniil.jpeg',
  text: "Tonhub is a convenient and secure cryptocurrency wallet designed for TON and other tokens. The storage-free wallet provides full control over private keys and supports biometric and PIN login for easy and secure access. Overall, Tonhub provides a high level of usability with its minimalist interface and advanced security features.",
}

export function aboutProject(): JSX.Element {
  const isMobileViewport = useMobileLayout()

  const headerType = isMobileViewport ? 'title-24' : 'title-28'
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'
  const textSpacingBottom = isMobileViewport ? '32' : '40'

  return (
    <>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Tonhub is a cryptocurrency wallet that offers a user-friendly interface and a range of features for TON and other digital asset holders. It provides a reliable solution for users seeking a convenient and versatile platform.
      </Text>
      
      <Text type={headerType} spacingBottom="24">
        Problem and Solution
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Tonhub aims to address the need for a comprehensive wallet solution that supports various TON standards, including NFTs, FTs, and Web3 applications. Tonhub serves as a functional wallet for users seeking a reliable and user-friendly platform.
      </Text>
      
      <Text type={headerType} spacingBottom="24">
        Contacts
      </Text>

      <Content
        className={styles.teamContent}
        spacingChild="40"
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Sergei Iudin"
          role="CEO"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonhub/SergeyOfficial.png"
          button={<>
            <Button
              variant="secondary"
              icon={<Icon name="outer-link" colorFill="primary" />}
              onClick={() =>
                openNewTab('https://www.linkedin.com/in/sergei-iudin-4a2749240/')
              }
              style={{marginBottom: '10px'}}
              >
              LinkedIn
            </Button>
            <br></br>
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() =>
                openNewTab('https://t.me/velen98')
              }
              >
              Telegram
            </Button>
          </>}
        />
        <Member
          name="Dan Volkov"
          role="CTO"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonhub/DanOfficial.png"
          button={<>
            <Button
              variant="secondary"
              icon={<Icon name="outer-link" colorFill="primary" />}
              onClick={() =>
                openNewTab('https://www.linkedin.com/in/dvlkv/')
              }
              style={{marginBottom: '10px'}}
              >
              LinkedIn
            </Button>
            <br></br>
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() =>
                openNewTab('https://t.me/dvlkv')
              }
              >
              Telegram
            </Button>
          </>}
        />
        <Member
          name="Vladislav Zhovnitsky"
          role="Software Engineer"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonhub/Vladislav.jpeg"
          button={<>
            <Button
              variant="secondary"
              icon={<Icon name="outer-link" colorFill="primary" />}
              onClick={() =>
                openNewTab('https://www.linkedin.com/in/vladislav-zhovnitsky-217229220/')
              }
              style={{marginBottom: '10px'}}
              >
              LinkedIn
            </Button>
          </>}
        />
      </Content>
    </>
  )
}

export function tokenDetails(): JSX.Element {
  const isMobileViewport = useMobileLayout()
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'

  return (
    <Text type={textType} color="secondary">
      Tonhub does not have its own personal token. While the TON Whale team has an $AMBRA token, it is not applicable to Tonhub in any way.
    </Text>
  )
}
