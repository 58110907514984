import React from 'react'
import { Image } from '@design-system'
import styles from '../components/About/About.scss'
import { Text } from '@design-system'
import { Arrow } from '../icons/Arrow'

export const EvaaWaitlistToken = () => {
  return (
    <>
      <div className={styles.row}>
        <Image
          className={styles.themeImg}
          src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/evaa/evaa_tokenomics.png"
          alt="Evaa Tokenomics"
          lightbox={{
            images: [
              {
                alt: 'Evaa Tokenomics',
                src: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/evaa/evaa_tokenomics.png',
                className: styles.themeImg
              },
            ],
          }}
        />

        <a
          className={styles.teamLink}
          href="https://docs.google.com/spreadsheets/d/1IwTkR35RXjWXfCuDcgMIyiMu_z-_qGeu0MjTIRUZC_Y/edit#gid=0"
          target="_blank"
        >
          <Text type="paragraph-16" color="secondary">
            Details
          </Text>
          <Arrow />
        </a>
      </div>
    </>
  )
}
