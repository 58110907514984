import classNames from 'classnames'
import React from 'react'

import { Link, Text, useMobileLayout } from '@design-system'
import styles from '../components/About/About.scss'
import { Arrow } from '../icons'
import { Image } from '@design-system'

export const StormDetails = () => {
  const headerType = 'title-24'
  const paragraphType = 'paragraph-16';
  const paragraphColor = 'secondary'

  return (
    <>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Product
        </Text>

        <Text type={paragraphType} color={paragraphColor} spacingBottom='16'>
          Storm Trade is a decentralized leveraged trading exchange that allows you to trade any kind of assets both in Web App and Telegram App. Combining trading, gamification and passive yield, Storm allows you to earn anywhere there is Wi-Fi.
        </Text>

        <Image
          src="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/storm/storm_problem.jpg"
          alt="Evaa Team image"
          borderRadius="16px"
          lightbox={{
            images: [
              {
                alt: 'Evaa Team 1st image',
                src: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/storm/storm_problem.jpg',
              },
            ],
          }}
        />
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Pros
        </Text>

        <ul>
          <li>
            <p>
              <b>Trade Diversification: </b>
              Storm Trade offers users the ability to trade a wide range of assets, extending beyond cryptocurrencies to include forex and commodities.
            </p>
          </li>
          <li>
            <p>
              <b>Security and Control: </b>
              Users benefit from a secure and decentralized trading environment, ensuring the safety of their funds. They have full control over their assets, instilling confidence in their trading experience.
            </p>
          </li>
          <li>
            <p>
              <b>Affordability and Accessibility: </b>
              Storm Trade makes trading affordable by offering low fees. Its integration with the  Telegram messenger makes trading accessible to a global audience.
            </p>
          </li>
        </ul>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Milestones
        </Text>

        <Text type={paragraphType} color={paragraphColor}>
          <Text spacingBottom="4" type={paragraphType}>🟣 Jun 2023 - First testnet launch </Text>
          <Text spacingBottom="4" type={paragraphType}>🟣 Aug 2023 - Second testnet launch | Early Birds program</Text>
          <Text spacingBottom="4" type={paragraphType}>🟣 Sep 2023 - Mainnet launch </Text>
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Bussiness model
        </Text>

        <Text type={paragraphType} spacingTop='8'>
          By simplifying decentralized trading and making it accessible to Telegram users around the world, Storm Trade aims to become one of the leading exchanges for trading futures.
        </Text>

        <Text type={paragraphType} spacingBottom='16'>
          Low fees combined with security and full user control over their funds makes Storm an indispensable assistant in the world of trading.
        </Text>

        <Image
          src="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/storm/storm_bussiness.jpg"
          alt="Evaa Team image"
          borderRadius="16px"
          lightbox={{
            images: [
              {
                alt: 'Evaa Team 1st image',
                src: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/storm/storm_bussiness.jpg',
              },
            ],
          }}
        />
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Key activities
        </Text>

        <ul>
          <li>
            <p>
              <b>TonStarter Community Bot: </b>
              Storm Trade leverages the TonStarter community bot to engage with its user base, provide updates, and gather feedback for continuous improvement.
            </p>
          </li>
          <li>
            <p>
              <b>Testnet: </b>
              Continuous development and testing on a dedicated testnet ensure a robust and reliable trading platform.
            </p>
          </li>
          <li>
            <p>
              <b>Multitier Referral Program: </b>
              Encouraging user referrals through a multitier referral program helps grow the user base organically.
            </p>
          </li>
          <li>
            <p>
              <b>Thoughtful Staking Model: </b>
              The staking model not only attracts users but also ensures liquidity and participation in revenue distribution.
            </p>
          </li>
        </ul>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Achievements
        </Text>

        <Text type={paragraphType} color={paragraphColor}>
          <Text spacingBottom="4" type={paragraphType}>🟣 Apr 2023 - 🥈 place in Hack-a-TONx</Text>
          <Text spacingBottom="4" type={paragraphType}>🟣 Aug 2023 - 🥈 place in TWA challenge </Text>
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom='16'>
          Roadmap
        </Text>

        <Image
          src="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/storm/storm_roadmap.jpg"
          alt="Evaa Team image"
          borderRadius="16px"
          lightbox={{
            images: [
              {
                alt: 'Evaa Team 1st image',
                src: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/storm/storm_roadmap.jpg',
              },
            ],
          }}
        />

        <Text type="title-20" spacingBottom='8' spacingTop='16'>
          Stage I. Formation
        </Text>

        <Text spacingBottom="4" type={paragraphType}>
            Aug 2023 - Testnet v2. Early birds program
        </Text>

        <Text spacingBottom="4" type={paragraphType}>
            New features appearance:
        </Text>

        <ul>
          <li>Storm community bot by TonStarter</li>
          <li>Partial liquidations</li>
          <li>Limit orders</li>
          <li>Stop Loss & Take Profit orders</li>
          <li>Liquidity provision</li>
        </ul>

        <Text type="title-20" spacingBottom='8'>
          Stage II. Inspection
        </Text>

        <Text spacingBottom="4" type={paragraphType}>
            Sep 2023 - Audit of smart contracts 
        </Text>

        <Text spacingBottom="4" type={paragraphType}>
            New features appearance:
        </Text>

        <ul>
          <li>Multitier referral program</li>
          <li>Genesis NFT mint</li>
        </ul>

        <Text type="title-20" spacingBottom='8'>
          Stage III. Becoming
        </Text>

        <Text spacingBottom="4" type={paragraphType}>
            Sep 2023 - Mainnet v1. First steps
        </Text>

        <Text spacingBottom="4" type={paragraphType}>
            New features appearance:
        </Text>

        <ul>
          <li>Reward points</li>
          <li>Rewards for testers</li>
          <li>Bot deep-link integration</li>
        </ul>

        <Text type="title-20" spacingBottom='8'>
          Stage IV. Existence
        </Text>

        <Text spacingBottom="4" type={paragraphType}>
            Oct 2023 - TGE. Mainnet v2. Evolution
        </Text>

        <Text spacingBottom="4" type={paragraphType}>
            New features appearance:
        </Text>

        <ul>
          <li>Trading pairs increasing</li>
          <li>NFT achievements</li>
          <li>Trading tournaments</li>
        </ul>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Team
        </Text>

        <Image
          src="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/storm/storm_team.png"
          alt="Evaa Team image"
          borderRadius="16px"
          lightbox={{
            images: [
              {
                alt: 'Evaa Team 1st image',
                src: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/storm/storm_team.png',
              },
            ],
          }}
        />

        {/* <a
          className={styles.teamLink}
          href="https://www.canva.com/design/DAFhyVXo5GE/CWcUse_YNtR3JYwIW9wQJg/edit?utm_content=DAFhyVXo5GE&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
          target="_blank"
        >
          <Text type={paragraphType} color={paragraphColor}>
            Details
          </Text>
          <Arrow />
        </a> */}
      </div>
    </>
  )
}
