import classNames from 'classnames'
import React from 'react'

import { Button, Icon, Tooltip } from '@design-system'
import hiStyles from '../../HiPage.scss'
import styles from './Compare.scss'

const explanationMessages = {
  blockTime:
    "Block time is how much time it takes a network's miners or validators to verify the transactions of one block and produce a new block on that blockchain.",
  timeToFinality:
    'Time-to-finality is how much time it takes for a reasonable guarantee that a transaction is executed and will not be reversed or changed.',
  simpleTransaction:
    "Simple transactions require little data and don't change the state of blockchain accounts, providing that the total state of all accounts fits into a computer's RAM.",
  complexTransaction: 'Complex transactions require a high data load.',
  shardingSupport:
    'Sharding is the process of splitting a database horizontally to spread the load. It helps scale a blockchain by reducing technical requirements for nodes. With shardchains, validators only need to run data for the shard they are validating, not the entire network.',
  crossShard: 'Interaction among shardchains.',
}

export const Compare = () => {
  return (
    <div>
      <div className={hiStyles.sectionTitle}>Blockchain analysis</div>
      <div className={hiStyles.sectionSubtitle}>
        TON, Solana and Ethereum 2.0 are examples of Proof of Stake blockchains.
        But key metrics and implementation differences mean TON comes out ahead
        on performance, creator support and design.
      </div>
      <div className={styles.buttonWrapper}>
        <Button as="a" href="https://ton.org/analysis" target="_blank">
          Read more
        </Button>
      </div>
      <div className={styles.tableWrapper}>
        <div className={styles.table}>
          <div className={styles.tableColumn}>
            <div className={styles.tableRow}></div>
            <div className={styles.tableRow}>
              <div>
                Block time{' '}
                <Tooltip title={explanationMessages.blockTime}>
                  <Icon name="infoFilled" />
                </Tooltip>
              </div>
            </div>
            <div className={styles.tableRow}>
              <div>
                Time-to-finality{' '}
                <Tooltip title={explanationMessages.timeToFinality}>
                  <Icon name="infoFilled" />
                </Tooltip>
              </div>
            </div>
            <div className={styles.tableRow}>
              <div>
                Simple transaction
                <br />
                performance{' '}
                <Tooltip title={explanationMessages.simpleTransaction}>
                  <Icon name="infoFilled" />
                </Tooltip>
              </div>
            </div>
            <div className={styles.tableRow}>
              <div>
                Complex transaction
                <br />
                performance{' '}
                <Tooltip title={explanationMessages.complexTransaction}>
                  <Icon name="infoFilled" />
                </Tooltip>
              </div>
            </div>
            <div className={styles.tableRow}>
              <div>
                Sharding support{' '}
                <Tooltip title={explanationMessages.shardingSupport}>
                  <Icon name="infoFilled" />
                </Tooltip>
              </div>
            </div>
            <div className={styles.tableRow}>
              <div>
                Cross-shard communication{' '}
                <Tooltip title={explanationMessages.crossShard}>
                  <Icon name="infoFilled" />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className={styles.tableColumn}>
            <div className={styles.tableRow}>
              <div
                className={classNames(styles.tableIconTon, styles.tableIcon)}
              />
              TON
            </div>
            <div className={styles.tableRow}>
              <div>5 sec.</div>
            </div>
            <div className={styles.tableRow}>
              <div>Under 6 sec.</div>
            </div>
            <div className={styles.tableRow}>
              <div>High</div>
            </div>
            <div className={styles.tableRow}>
              <div>High</div>
            </div>
            <div className={styles.tableRow}>
              <div>
                Max. 2<span className={styles.extent}>60</span> shards
                <br />
                per workchain
              </div>
            </div>
            <div className={styles.tableRow}>
              <div>Near-instant</div>
            </div>
          </div>
          <div className={styles.tableColumn}>
            <div className={styles.tableRow}>
              <div
                className={classNames(styles.tableIconEth, styles.tableIcon)}
              />
              Ethereum 2.0
            </div>
            <div className={styles.tableRow}>
              <div>12 sec.</div>
            </div>
            <div className={styles.tableRow}>
              <div>
                <a href="https://ethos.dev/beacon-chain" target="_blank">
                  10–15 min.
                </a>
              </div>
            </div>
            <div className={styles.tableRow}>
              <div>Potentially high</div>
            </div>
            <div className={styles.tableRow}>
              <div>Low</div>
            </div>
            <div className={styles.tableRow}>
              <div>
                Max. to 2<span className={styles.extent}>6</span> shards
              </div>
            </div>
            <div className={styles.tableRow}>
              <div>Slow time-to-finality</div>
            </div>
          </div>
          <div className={styles.tableColumn}>
            <div className={styles.tableRow}>
              <div
                className={classNames(styles.tableIconSol, styles.tableIcon)}
              />
              Solana
            </div>
            <div className={styles.tableRow}>1 sec.</div>
            <div className={styles.tableRow}>6.4 sec.</div>
            <div className={styles.tableRow}>High</div>
            <div className={styles.tableRow}>
              <a
                href="https://decrypt.co/91117/solana-user-experience-not-what-it-should-be-co-founder-anatoly"
                target="_blank"
              >
                Very low
              </a>
            </div>
            <div className={styles.tableRow}>None</div>
            <div className={styles.tableRow}>None</div>
          </div>
        </div>
      </div>
    </div>
  )
}
