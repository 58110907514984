import { createSlice } from '@reduxjs/toolkit'

import { AnalyticsService, LocalStorageService } from '@services'
import { getTheme, setTheme } from '@utils'
import { RootState } from '..'

interface AppState {
  theme: 'dark' | 'light'
  isConnecWalletModalOpen: boolean
}

const initialState: AppState = {
  theme: getTheme(),
  isConnecWalletModalOpen: false,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openConnectWalletModal: (state) => {
      state.isConnecWalletModalOpen = true
    },
    closeConnectWalletModal: (state) => {
      state.isConnecWalletModalOpen = false
    },
    toggleTheme: (state) => {
      const newTheme =
        LocalStorageService.getItem('theme') === 'light' ? 'dark' : 'light'
      setTheme(newTheme)

      AnalyticsService.track('theme_changed', {
        theme_type: newTheme,
      })

      state.theme = newTheme
    },
  },
})

export const { toggleTheme, openConnectWalletModal, closeConnectWalletModal } =
  appSlice.actions

export const themeSelector = (state: RootState): string => state.app.theme
export const isConnecWalletModalOpenSelector = (state: RootState): boolean =>
  state.app.isConnecWalletModalOpen

export default appSlice.reducer
