import React, { useMemo, useState } from 'react'

import { Button, Text, TokenInput, useModal } from '@design-system'
import styles from '../../PrivateSidebar.scss'
import { Project, store } from '@store'
import { getValidators } from '../../../../../Project/components/Sidebar/components/Swap/helpers'
import { PrivateDisclaimerModal } from '../../../PrivateDisclaimerModal'
import { TrxState } from '../../../../../Project/components/AirdropSidebar/components/LockupView'
import { PrivateData } from '../../../../'
import { tonToTokenByTokenPrice } from '@utils'

interface ApplyViewProps {
  activeProject: Project
  onPay(amount: string, setTrxState: React.Dispatch<React.SetStateAction<TrxState>>): Promise<void>,
  data: PrivateData
}

export const ApplyView = ({
  activeProject,
  onPay,
  data,
}: ApplyViewProps): JSX.Element => {
  const [amount, setAmount] = useState("5000")
  const [trxState, setTrxState] = useState<TrxState>('initial')

  const paymentDisclaimerModal = useModal(PrivateDisclaimerModal, store)

  const handleAgree = () => {
    if (amount) {
      paymentDisclaimerModal.open({
        data,
        project: activeProject.title,
        amount: amount === '' ? '0' : amount,
        onClose: (result?: string | undefined) => {
          if (result === 'true') {
            paymentDisclaimerModal.close()
            onPay(amount, setTrxState)
          }
        }
      })
    }
  }

  const [isFormValid, setIsFormValid] = useState(true)

  const disableButton = !isFormValid || !amount || trxState === 'loading'

  const tokensAmount = useMemo(() => {
    const formatter = new Intl.NumberFormat('en-US')
    const tokens = tonToTokenByTokenPrice(amount, data.prices.tokenPriceInTon)
    return tokens === 'NaN' ? '0' : formatter.format(parseInt(tokens))
  }, [amount])

  return (
    <div className={styles.viewWrapper}>
      <TokenInput
        label=""
        value={amount}
        onChange={(value) => setAmount(value)}
        onValidate={setIsFormValid}
        // hint={`Minimum amount ${data.limits.min} TON`}
        placeholder="0"
        allowDecimal={false}
        symbol="TON"
        validators={getValidators({
          tonMinValue: data.limits.min,
          tonMaxValue: data.limits.max,
        })}
        secondaryValue={`${tokensAmount} ${data.ticker}`}
      />

      <div className={styles.applyButton}>
        <Button
          loadingIcon={trxState === 'loading'}
          fullWidth
          disabled={disableButton}
          onClick={handleAgree}
        >
          {trxState === 'loading' ? 'Open your wallet' : 'Confirm'}
        </Button>

        <Text type="paragraph-14" weight="regular" color="tertiary" align="center">Next step: verification</Text>
      </div>
    </div>
  )
}
