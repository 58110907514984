import React from 'react'

import { PrivateProject } from '../../PrivateProject'
import { FantonDetails } from './FantonDetails'
import { FantonGameDetails } from './FantonGameDetails'
import { privateData } from './data/privateData'
import { themeSelector, useAppSelector } from '@store'
import { SwiperSlide } from 'swiper/react'
import SwiperCarousel from '../../../../../shared/design-system/components/SwiperCarousel'
import { useMobileLayout } from '@design-system'
import { FantonTokenomics } from './FantonTokenomics'

const slides = [
  'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/slider/done.jpeg',
  'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/slider/metrics.jpeg',
  'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/slider/telegram.jpeg',
]

export const FantonPage = () => {
  const isMobileLayout = useMobileLayout()

  return <PrivateProject
    data={privateData}
    slug='fanton'
    DetailsComponent={FantonDetails}
    MoreComponent={FantonGameDetails}
    moreTabName="Game details"
    Tokenomics={FantonTokenomics}
    imageCarousel={
      <SwiperCarousel
        slidesPerView={1}
        withButtons={true}
        pagination={true}
        spaceBetween={16}
        initialSlide={0}
        lazy={{ loadPrevNext: true }}
        childrenGrow={false}
        fullHeight={true}
      >
        <SwiperSlide>
          <SwiperCarousel.LazyImage
            src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-cover.png"
            lightbox={{
              images: [
                {
                  alt: 'Fanton',
                  src: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-cover.png',
                },
              ],
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <SwiperCarousel.LazyImage
            src={slides[0]}
            lightbox={{
              images: [
                {
                  alt: 'Stonfi',
                  src: slides[0],
                },
              ],
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <SwiperCarousel.LazyImage
            src={slides[1]}
            lightbox={{
              images: [
                {
                  alt: 'Stonfi',
                  src: slides[1],
                },
              ],
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <SwiperCarousel.LazyImage
            src={slides[2]}
            lightbox={{
              images: [
                {
                  alt: 'Stonfi',
                  src: slides[2],
                },
              ],
            }}
          />
        </SwiperSlide>
      </SwiperCarousel>
    }
  />
}