import Big from 'big.js'
import React, { useEffect, useState } from 'react'

import { useQueryTabletLandscapeUp } from '@design-system'
import {
  activeProjectSelector,
  getFundProgress,
  ProjectStatus,
  useAppDispatch,
  useAppSelector,
} from '@store'
import {
  calculateTokenPriceInUsd,
  formatCurrency,
  getPercentage,
  greaterThan,
  nano2Ton,
} from '@utils'
import { MobileStep } from '../../../Stages'
import styles from './FundProgress.scss'

export const FundProgress = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const [fundRaised, setFundRaised] = useState<null | string>(null)
  const currentAmount = fundRaised ? Number(new Big(fundRaised).toFixed(0)) : 0
  const activeProject = useAppSelector(activeProjectSelector)

  const { fundsGoal, walletAddress, status } = activeProject

  const isSoldOut = status === ProjectStatus.soldOut
  const currentAmountFormatted = formatCurrency('USD', currentAmount)
  const fundGoalFormatted = formatCurrency('USD', fundsGoal)
  const progressLineWidth = isSoldOut
    ? `100%`
    : `${getPercentage(currentAmount, fundsGoal)}%`
  const isTabletLandscapeUpViewport = useQueryTabletLandscapeUp()

  const fetchFundGoal = async () => {
    const response = (await dispatch(
      getFundProgress(walletAddress as string)
    )) as any
    const balance = response.payload.balance as string

    const fundRaiseInUSD = calculateTokenPriceInUsd(
      '2',
      nano2Ton(balance)
    )

    const fundRaisedLimited = greaterThan(fundRaiseInUSD, fundsGoal.toString())
      ? fundsGoal.toString()
      : fundRaiseInUSD

    setFundRaised(fundRaisedLimited)
  }

  useEffect(() => {
    fetchFundGoal()
  }, [])

  return (
    <div className={styles.bochka}>
      {!isTabletLandscapeUpViewport ? (
        <div>{<MobileStep status={activeProject.status} />}</div>
      ) : null}

      <p className={styles.note}>
        {isSoldOut ? 'Funding goal reached' : 'Total amount raised'}
      </p>
      <div className={styles.amountWrap}>
        {fundRaised && isSoldOut && (
          <>
            <div className={styles.amountNow}>{fundGoalFormatted}</div>
            <div className={styles.amountOf}>of {fundGoalFormatted}</div>
          </>
        )}
        {fundRaised && !isSoldOut && (
          <>
            <div className={styles.amountNow}>{currentAmountFormatted}</div>
            <div className={styles.amountOf}>of {fundGoalFormatted}</div>
          </>
        )}
        {!fundRaised && (
          <div className={styles.skeletonContainer}>
            <div className={styles.skeletonLine20}></div>
          </div>
        )}
      </div>
      <div className={styles.progressWrap}>
        <div className={styles.progress}>
          <div className={styles.progressPath}></div>
          <div
            className={styles.progressLine}
            style={{ width: progressLineWidth }}
          ></div>
        </div>
      </div>
    </div>
  )
}
