import axios, { AxiosRequestHeaders } from 'axios'

import config from '@config'
import { store } from '@store'
import { signOut } from '../store/auth'
import { disconnectWallet } from '../store/wallet'
import { AuthService } from './AuthService'

const apiHost = config.apiHost

export interface ApiServiceResponse {
  error?: string
  metadata?: { [key: string]: any }
  data?: any
  ok: boolean
}

export default function authHeader(): AxiosRequestHeaders {
  const accessToken = AuthService.getCredentials().accessToken

  if (accessToken) {
    return { Authorization: 'Bearer ' + accessToken }
  } else {
    return {}
  }
}

axios.interceptors.response.use(
  (response): ApiServiceResponse => {
    const transformedResponse: ApiServiceResponse = {
      ok: response.data.ok ? response.data.ok : false,
    }

    const error = response.data.error
    const data = response.data.data

    if (error) {
      transformedResponse.error = error
    }

    if (data) {
      transformedResponse.data = data
    }

    return transformedResponse
  },
  (error) => {
    let errorCode = 'something-went-wrong'

    if (!error.status && error.message === 'Network Error') {
      errorCode = 'network-error'
    }

    if (error.response && error.response.status === 401) {
      store.dispatch(signOut())
      store.dispatch(disconnectWallet())
    }

    if (error.response && error.response.status === 404) {
      errorCode = 'not-found'
    }

    return {
      ok: false,
      data: null,
      error: errorCode,
    }
  }
)

export const ApiService = {
  get: ({
    endpoint,
    params,
  }: {
    endpoint: string
    params?: any
  }): Promise<any> => {
    const response = axios({
      params,
      method: 'GET',
      url: `${apiHost}${endpoint}`,
      headers: { ...authHeader() },
    })

    response.catch((error) => {
      console.log('catch', error)
    })

    return response
  },

  post: ({ endpoint, data }: { endpoint: string; data: any }): Promise<any> => {
    return axios({
      method: 'POST',
      url: `${apiHost}${endpoint}`,
      headers: { ...authHeader() },
      data,
    })
  },

  patch: ({
    endpoint,
    data,
  }: {
    endpoint: string
    data: any
  }): Promise<any> => {
    return axios({
      method: 'PATCH',
      url: `${apiHost}${endpoint}`,
      data,
      headers: { ...authHeader() },
    })
  },

  put: ({
    endpoint,
    data,
  }: {
    endpoint: string
    data: any
  }): Promise<any> => {
    return axios({
      method: 'PUT',
      url: `${apiHost}${endpoint}`,
      data,
      headers: { ...authHeader() },
    })
  },

  delete: ({ endpoint }: { endpoint: string }): Promise<any> => {
    return axios({
      method: 'DELETE',
      url: `${apiHost}${endpoint}`,
      headers: { ...authHeader() },
    })
  },
}
