export const news = [
  {
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/ozysxton.webp',
    source: 'Newswire.ca',
    href: 'https://www.newswire.ca/news-releases/the-ton-foundation-bets-on-a-cross-chain-future-by-connecting-to-ozys-orbit-bridge-874413493.html',
    description:
      "The TON Foundation have today expressed their faith in a cross-chain future by announcing the connection of the TON (The Open Network) blockchain to Ozys' Orbit Bridge.",
  },
  {
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/cmc-news.png',
    source: 'Coinmarketcap',
    href: 'https://coinmarketcap.com/community/articles/638876c97acf9e394c6af43a',
    description:
      'The TON Foundation bets on a cross-chain future by connecting to Ozys’ Orbit Bridge',
  },
]
