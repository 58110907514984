import React from 'react'

import hiStyles from '../../HiPage.scss'
import styles from './Advisors.scss'
import at from './images/anton-tsivarev.png'
import jh from './images/justin-hyun.png'
import tk from './images/tal-kol.png'

const partners = [
  {
    image: jh,
    title: 'Justin Hyun',
    description: (
      <span>
        Head of Incubation at TON Foundation, Ex-BlockFi, Ex-AlixPartners
      </span>
    ),
  },
  {
    image: tk,
    title: 'Tal Kol',
    description: (
      <span>Co-Founder at Orbs, investors of Toncoin.Fund, Ex-Wix.com</span>
    ),
  },
  {
    image: at,
    title: 'Anthony Tsivarev',
    description: (
      <span>
        Director of Developer Relations at TON
      </span>
    ),
  },
]

export const Advisors = () => {
  return (
    <div className={styles.root}>
      <div className={hiStyles.sectionTitle}>Our advisors</div>
      <div className={styles.cards}>
        {partners.map((p, i) => (
          <div className={styles.card} key={i}>
            <div className={styles.image}>
              <img src={p.image} />
            </div>
            <div className={styles.title}>{p.title}</div>
            <div className={styles.description}>{p.description}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
