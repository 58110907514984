import React from 'react'
import { Link } from 'react-router-dom'

import styles from './PrivateProjectItem.scss'
import { diffInDays } from '@utils'
import { PrivateData } from '../../../PrivateProject'

interface PrivateProjectItemProps {
  project: any
  data: PrivateData
}

export const PrivateProjectItem = ({
  project,
  data,
}: PrivateProjectItemProps) => {
  const link = `/private/${project.slug}`

  const bannerAvifUrl = project.projectDetails
    ? project.projectDetails.bannerAvif
    : ''

  const bannerWebpUrl = project.projectDetails
    ? project.projectDetails.bannerWebp
    : ''

  const bannerPngUrl = project.cover

  const today = new Date()
  const daysLeft = diffInDays(data.dates.end, today)

  return (
    <Link className={styles.root} to={`${link}`}>
      <div className={styles.cover}>
        <picture>
          <source srcSet={bannerAvifUrl} type="image/avif" />
          <source srcSet={bannerWebpUrl} type="image/webp" />
          <img
            decoding="async"
            loading="lazy"
            src={bannerPngUrl}
            alt={`${project.title} cover image`}
          />
        </picture>
      </div>
      <div className={styles.info}>
        {/*<span className={styles.statusActive}>⚡️ Active {daysLeft} more days</span>*/}
        <span className={styles.statusType}>🔓 Private Sale</span>
        <h3 className={styles.title}>{project.title}</h3>
        {!!project.description && (
          <div className={styles.description}>{project.description}</div>
        )}
        <div className={styles.fundsGoal}>
          <p>Private Sale</p>
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" fill="none"><g filter="url(#a)"><path fill="url(#b)" fillRule="evenodd" d="M3.922 0h8.156c.533 0 .994 0 1.361.034.371.034.796.111 1.16.376.493.359.811.909.875 1.515.047.448-.097.854-.253 1.193-.155.335-.385.734-.651 1.196L8.78 14.35a.9.9 0 0 1-1.56 0L1.43 4.314c-.266-.462-.496-.861-.65-1.196C.623 2.78.479 2.373.526 1.925A2.15 2.15 0 0 1 1.401.41C1.766.145 2.19.068 2.56.034 2.928 0 3.39 0 3.921 0ZM2.449 1.874a.35.35 0 0 0-.131.228c.003.017.018.092.096.263.116.25.303.577.596 1.085l4.09 7.089V1.8H3.963c-.586 0-.963 0-1.237.026-.188.017-.26.042-.277.048ZM8.9 1.8v8.739l4.09-7.09c.293-.507.48-.834.596-1.084.079-.17.094-.246.096-.263a.35.35 0 0 0-.131-.228 1.142 1.142 0 0 0-.276-.048C13 1.801 12.623 1.8 12.037 1.8H8.9Z" clipRule="evenodd"/></g><defs><linearGradient id="b" x1="16.462" x2=".908" y1="0" y2=".579" gradientUnits="userSpaceOnUse"><stop stopColor="#F07632"/><stop offset="1" stopColor="#E84E4E"/></linearGradient><filter id="a" width="46.966" height="46.8" x="-15.483" y="-16" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse"><feFlood floodOpacity="0" result="BackgroundImageFix"/><feGaussianBlur in="BackgroundImageFix" stdDeviation="8"/><feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_229_114"/><feBlend in="SourceGraphic" in2="effect1_backgroundBlur_229_114" result="shape"/></filter></defs></svg> 
            <span>{data.fund}</span>
          </p>
        </div>
      </div>
    </Link>
  )
}
