import React, { useEffect, useMemo, useState } from 'react'

import {
  PaymentDisclaimerModal,
  PaymentModal,
  SuccessPaymentModal,
} from '@components'
import { Button, TokenInput, useModal } from '@design-system'
import { AnalyticsService } from '@services'
import {
  checkActiveAllocationPayment,
  projectSaleInfoSelector,
  store,
  useAppDispatch,
  useAppSelector,
  walletAddressSelector,
  walletBalanceSelector,
  WalletType,
  walletTypeSelector,
} from '@store'
import {
  calculateTokenPriceInUsd,
  mobileOrTablet,
  tokenToTonByTokenPrice,
  ton2Nano,
  tonToTokenByTokenPrice,
} from '@utils'
import {
  calculateServiceChargeOf,
  calculateTotalAmount,
  getValidators,
  normalizeProjectandAllocationData,
} from './helpers'
import styles from './Swap.scss'

interface AllocationFormProps {
  idoContractAddress: string
  tonPriceInUsd: string
  allocationMaxTokens: number
}

const networkTonFee = '0.1'
const SERVICE_CHARGE = '5'

export const getPaymentDetailsTable = ({
  tokenInputValue,
  tokenSymbol,
  totalTonAmount,
  tonAmount,
  serviceCharge,
}: {
  [key: string]: string
}) => {
  return [
    ['Tokens you swap', `${tokenInputValue} ${tokenSymbol}`],
    [],
    ['Amount you pay', `${tonAmount} TON`],
    ['Network fee', `${networkTonFee} TON`],
    ['Service charge', `${serviceCharge} TON`],
    [],
    ['Total amount', `${totalTonAmount} TON`],
  ]
}

export const Swap = ({
  idoContractAddress,
  tonPriceInUsd,
  allocationMaxTokens,
}: AllocationFormProps) => {
  const walletBalanceInNanoTon = useAppSelector(walletBalanceSelector)
  const projectSaleInfo = useAppSelector(projectSaleInfoSelector)
  const walletType = useAppSelector(walletTypeSelector)
  const walletAddress = useAppSelector(walletAddressSelector)
  const paymentDisclaimerModal = useModal(PaymentDisclaimerModal, store)
  const dispatch = useAppDispatch()

  const [tonInputValue, setTonInputValue] = useState<string>('')
  const [tokenInputValue, setTokenInputValue] = useState<string>('')
  const [checkPaymentInterval, setCheckPaymentInterval] = useState<any>()
  const [isFormValid, setIsFormValid] = useState(true)
  const paymentModal = useModal(PaymentModal, store)
  const successPaymentModal = useModal(SuccessPaymentModal, store)
  const isMobileOrTablet = mobileOrTablet()

  const tokenPriceInNanoTon = projectSaleInfo.tokenPriceInNanoTon
    ? projectSaleInfo.tokenPriceInNanoTon
    : '0'
  const amountTokensLeft = projectSaleInfo.amountTokensLeft
    ? projectSaleInfo.amountTokensLeft
    : '0'

  const normalizedData = useMemo(
    () =>
      normalizeProjectandAllocationData({
        tokenPriceInNanoTon,
        tonPriceInUsd,
        walletBalanceInNanoTon,
        amountTokensLeft,
        allocationInToken: allocationMaxTokens.toString(),
      }),
    [walletBalanceInNanoTon, tokenPriceInNanoTon, tonPriceInUsd]
  )

  const {
    tokenSymbol,
    tokenPriceInTon,
    allocationInTon,
    allocationInUSD,
    tonMaxValue,
    tonMinValue,
    minAllocationInUSD,
    minAllocationInTon,
  } = normalizedData

  const secondaryValue = calculateTokenPriceInUsd(tonPriceInUsd, tonInputValue)

  useEffect(() => {
    return () => clearInterval(checkPaymentInterval)
  }, [])

  useEffect(() => {
    if (tonMaxValue !== '0.00' && parseInt(tonMaxValue) !== 0) {
      handleTonInputValueChange(tonMaxValue)
    }
  }, [tonMaxValue])

  const syncTokenAmountInPair = (tonValue: string) => {
    let tokenAmount = ''

    if (tonValue) {
      tokenAmount = tonToTokenByTokenPrice(tonValue, tokenPriceInTon)
      tokenAmount = tokenAmount === '0' ? '' : tokenAmount
    }

    setTokenInputValue(tokenAmount)
  }

  const syncTonAmountInPair = (tokenValue: string) => {
    let tonAmount = ''

    if (tokenValue) {
      tonAmount = tokenToTonByTokenPrice(tokenValue, tokenPriceInTon)
      tonAmount = tonAmount === '0' ? '' : tonAmount
    }

    setTonInputValue(tonAmount)
  }

  const handleTonInputValueChange = (tonValue: string) => {
    setTonInputValue(tonValue)
    syncTokenAmountInPair(tonValue)
  }

  const handleJettonAmountChange = (tokenValue: string) => {
    setTokenInputValue(tokenValue)
    syncTonAmountInPair(tokenValue)
  }

  paymentDisclaimerModal.onClose((isAgreed: boolean | undefined) => {
    if (isAgreed) {
      handleJoinPoolAndPay()
    }
  })

  const handleJoinPoolAndPay = async () => {
    if (!tonInputValue) {
      return
    }

    const totalTonAmount = calculateTotalAmount(
      tonInputValue,
      SERVICE_CHARGE,
      '0.09'
    )

    const walletName =
      walletType === WalletType.tonKeeper ? 'Tonkeeper' : 'TonHub'

    const paymentSubtitle = isMobileOrTablet
      ? `Pay ${totalTonAmount} TON in ${walletName}`
      : `Scan to pay ${totalTonAmount} TON in ${walletName}`

    AnalyticsService.track('payment_modal_opened')
    paymentModal.open({
      title: 'Payment',
      subtitle: paymentSubtitle,
      detailsTable: getPaymentDetailsTable({
        tokenInputValue,
        tokenSymbol,
        totalTonAmount,
        tonAmount: tonInputValue,
        serviceCharge: calculateServiceChargeOf(tonInputValue, SERVICE_CHARGE),
      }),
      walletType,
      toPayInNanoTon: ton2Nano(totalTonAmount),
      address: idoContractAddress,
      tipMessage: 'Make the payment and wait a little while for confirmation',
    })

    checkPaymentPolling()
  }

  const handleSwap = () => {
    if (tonInputValue) {
      paymentDisclaimerModal.open()
    }
  }

  paymentModal.onClose(() => {
    setCheckPaymentInterval(null)
    clearInterval(checkPaymentInterval)
  })

  const checkPaymentPolling = () => {
    const checkPaymentInterval = setInterval(async () => {
      const response = await dispatch(
        checkActiveAllocationPayment({
          walletAddress,
          contractAddress: idoContractAddress,
        })
      )

      if (response.payload) {
        clearInterval(checkPaymentInterval)

        AnalyticsService.track('payment_done')
        successPaymentModal.open({
          title: 'Payment accepted',
          iconType: 'tada',
          subtitle: (
            <>
              You have purchased{' '}
              <span>
                &nbsp;{tokenInputValue} {tokenSymbol}&nbsp;
              </span>
              tokens
            </>
          ),
        })

        paymentModal.close()
      }
    }, 5000)

    setCheckPaymentInterval(checkPaymentInterval)
  }

  const disableButton = !isFormValid || !tonInputValue

  return (
    <div>
      <div className={styles.inputWrapper}>
        <TokenInput
          label="TON"
          value={tonInputValue}
          placeholder="0"
          symbol="TON"
          onChange={handleTonInputValueChange}
          onMax={() => handleTonInputValueChange(tonMaxValue)}
          validators={getValidators({ tonMaxValue, tonMinValue })}
          onValidate={setIsFormValid}
          secondaryValue={`~$${secondaryValue}`}
          hint={`Min. allocation is ${minAllocationInTon} TON ~ $${minAllocationInUSD}`}
        />
      </div>
      <div className={styles.inputWrapper}>
        <TokenInput
          label={tokenSymbol}
          value={tokenInputValue}
          onChange={handleJettonAmountChange}
          symbol={tokenSymbol}
          placeholder="0"
          allowDecimal={false}
        />
      </div>

      <Button fullWidth onClick={handleSwap} disabled={disableButton}>
        Swap
      </Button>
    </div>
  )
}
