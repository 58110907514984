export * from './PaymentDisclaimerModal'
import React, { useState } from 'react'

import { Button } from '../../design-system'
import styles from './PaymentDisclaimerModal.scss'

interface PaymentDisclaimerModalProps {
  onClose: (result?: string) => void
  children?: Array<JSX.Element | string | number> | JSX.Element | string
}

export function PaymentDisclaimerModal({
  onClose,
  children,
}: PaymentDisclaimerModalProps) {
  const handlePayment = () => {
    if (isValid) {
      onClose('true')
    }
  }
  const [isValid, setIsValid] = useState(false)

  return (
    <div className={styles.root}>
      <div className={styles.title}>Disclaimer</div>
      <div className={styles.subtitle}>
        Read disclaimer before go to payment
      </div>

      <div className={styles.content}>
        { children ? children :
          <>
            Tonstarter is a decentralized peer-to-peer protocol that people can use
            to create liquidity and trade tokens. There are multiple versions of the
            Tonstarter protocol, each of which is made up of free, public,
            open-source or source-available software including a set of smart
            contracts that are deployed on TON Mainnet. Your use of the Tonstarter
            protocol involves various risks, including, but not limited to, losses
            while digital assets are being supplied to the Tonstarter protocol and
            losses due to the fluctuation of prices of tokens in a trading pair or
            liquidity pool. Before using the Tonstarter protocol, you should review
            the relevant documentation to make sure you understand how the
            Tonstarter protocol works. Additionally, just as you can access email
            protocols, such as SMTP, through multiple email clients, you can access
            the Tonstarter protocol through multiple web and mobile interfaces. You
            are responsible for conducting your own diligence on those interfaces to
            understand the fees and risks involved.
            <br />
            <br />
            THE TONSTARTER PROTOCOL IS PROVIDED ”AS IS” AT YOUR OWN RISK, AND
            WITHOUT WARRANTIES OF ANY KIND. The Tonstarter protocol is run by smart
            contracts deployed on the TON Mainnet. No developer or entity involved
            in creating the Tonstarter protocol will be liable for any claims or
            damages whatsoever associated with your use, inability to use, or your
            interaction with other users of the Tonstarter protocol, including any
            direct, indirect, incidental, special, exemplary, punitive or
            consequential damages, or loss of profits, cryptocurrencies, tokens, or
            anything else of value.
            <br />
            <br />
            Restricted Jurisdictions: Afghanistan, Australia, Canada, Cuba,
            Democratic People’s Republic of North Korea, South Korea, Islamic
            Republic of Iran, Libya, People’s Republic of China, South Sudan, The
            United States of America, Sudan (North), Syria, Crimea and Venezuela.
          </>
        }
      </div>

      <div className={styles.footer}>
        <label className={styles.footerLabel} htmlFor="disclaimerCheckbox">
          <input
            className={styles.footerCheckbox}
            type="checkbox"
            id="disclaimerCheckbox"
            onChange={(e) => setIsValid(e.target.checked)}
          />
          <div>
            I have read and agreed to this disclaimer
          </div>
        </label>
        <Button onClick={handlePayment} fullWidth disabled={!isValid}>
          Go to payment
        </Button>
      </div>
    </div>
  )
}
