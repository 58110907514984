import React from 'react'

export default ({className}: {className: string}) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
        fill="inherit"
      />
      <path
        d="M36.4789 15.9413C36.0456 15.191 35.2386 14.725 34.3721 14.725H16.4109L18.7434 18.765H31.5879L25.1656 29.8888L27.4981 33.9288L36.4789 18.3738C36.9121 17.6235 36.9121 16.6915 36.4789 15.9413Z"
        fill="white"
      />
      <path
        d="M23.9992 31.9088L16.4107 18.765H16.4177L14.0857 14.725H13.6264C12.7602 14.725 11.9529 15.191 11.5197 15.9413C11.0864 16.6915 11.0867 17.6235 11.5197 18.3738L21.8924 36.34C22.3254 37.0903 23.1327 37.5563 23.9989 37.5563C24.8652 37.5563 25.6724 37.0903 26.1057 36.34L26.3339 35.9448L24.0017 31.9043L23.9992 31.9088ZM22.6069 34.32C22.6074 34.319 22.6082 34.318 22.6087 34.317C22.6082 34.318 22.6077 34.319 22.6069 34.32Z"
        fill="white"
      />
    </svg>
  )
}