export const team = [
  {
    image:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/vadim.png',
    name: 'Vadim',
    role: 'COO',
    linkedin: 'https://www.linkedin.com/in/kostomarov/',
    details: [
      'Founder, led Advertising Product with $200M in revenue',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/eldar.png',
    name: 'Eldar',
    role: 'CPO',
    linkedin: 'https://www.linkedin.com/in/khayretdinov/',
    details: [
      'Founder, ex-Head of Gaming at Sberbank, led Gamification Platform with 50M MAU',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/alexey.png',
    name: 'Alexey',
    role: 'CTO',
    details: [
      '10+ years of creating high-load IT services in top IT companies like Kaspersky',
    ],
  },
]
