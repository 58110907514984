import React from 'react'

import { Text } from '@design-system'
import styles from '../../../Project/airdrop/components/About/About.scss'
import { Arrow } from '../../../Project/airdrop/icons'

export const FantonGameDetails = () => {
  const headerType = 'title-24'
  const paragraphType = 'paragraph-16';
  const paragraphColor = 'secondary'

  return (
    <>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Create your fantasy team
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          Create a virtual football team with NFT-cards of players performing in
          real matches. Earn points for your players' actions in the real world.
          For example, if Messi scored a goal, the Messi card gets points.
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          A team has 5 players: goalkeeper, defender, midfielder, striker and a
          reserve player. Their points are summed up. A team with the most
          points wins the tournament. You can use NFT cards of football players
          in the game to participate in tournaments. The winners get prizes such
          as Toncoin and NFT cards of football players.
        </Text>
      </div>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          NFT cards
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          There are 3 levels of cards; a level depends on a player's value, his
          age, rating of a club and a national team in which he plays. The
          better the player, the higher the level (Tier) of his NFT card. Cards
          are divided by rarity inside the tiers — Legendary, Epic, Common. The
          higher the rarity, the more points a football player’s card gets in
          the game, and the more valuable it is. The rarity of NFT affects the
          number of cards:
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
            <ul className={styles.list}>
            <li>Legendary — only one NFT card for a football player,</li>
            <li>Epic — three cards for a football player,</li>
            <li>
              Common (gray) cards — are unlimited non-NFT cards that allow players
              who haven’t bought NFTs yet to play the game.
            </li>
          </ul>
        </Text>
        <a
          className={styles.teamLink}
          href="https://fan-ton.com/en"
          target="_blank"
        >
          <Text type={paragraphType} color={paragraphColor}>
            Explore Fantasy Football
          </Text>
          <Arrow />
        </a>
      </div>
    </>
  )
}
