import { useMobileLayout } from '@design-system'
import React from 'react'

import styles from './Logo.scss'

export function Logo(): JSX.Element {
  const isMobileViewport = useMobileLayout()

  return (
    <div className={styles.root}>
      <div className={styles.root}>ton/starter</div>
    </div>
  )
}
