import React from 'react'

import { Page } from '@design-system'
import styles from './legal.scss'

export const DisclaimerPage = (): JSX.Element => {
  return (
    <Page>
      <Page.Header />
      <Page.Body>
        <div className={styles.root}>
          <p className={styles.title}>Disclaimer</p>
          <p className={styles.row}>
            Tonstarter is a decentralized peer-to-peer protocol that people can
            use to create liquidity and trade tokens. There are multiple
            versions of the Tonstarter protocol, each of which is made up of
            free, public, open-source or source-available software including a
            set of smart contracts that are deployed on TON Mainnet. Your use of
            the Tonstarter protocol involves various risks, including, but not
            limited to, losses while digital assets are being supplied to the
            Tonstarter protocol and losses due to the fluctuation of prices of
            tokens in a trading pair or liquidity pool. Before using the
            Tonstarter protocol, you should review the relevant documentation to
            make sure you understand how the Tonstartere protocol works.
            Additionally, just as you can access email protocols, such as SMTP,
            through multiple email clients, you can access the Tonstarter
            protocol through multiple web and mobile interfaces. You are
            responsible for conducting your own diligence on those interfaces to
            understand the fees and risks involved.
          </p>
          <p className={styles.row}>
            THE TONSTARTER PROTOCOL IS PROVIDED "AS IS" AT YOUR OWN RISK, AND
            WITHOUT WARRANTIES OF ANY KIND. The Tonstarter protocol is run by
            smart contracts deployed on the TON Mainnet. Upgrades and
            modifications to the protocol are managed in a community-driven way
            by holders of the Tonstarter governance token. No developer or
            entity involved in creating the Tonstarter protocol will be liable
            for any claims or damages whatsoever associated with your use,
            inability to use, or your interaction with other users of the
            Tonstarter protocol, including any direct, indirect, incidental,
            special, exemplary, punitive or consequential damages, or loss of
            profits, cryptocurrencies, tokens, or anything else of value.
          </p>
        </div>
      </Page.Body>
      <Page.Footer />
    </Page>
  )
}
