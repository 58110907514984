import React, { useState } from 'react'
import QRCode from 'react-qr-code'

import { Button } from '../../design-system'
import { useAppSelector, WalletType } from '../../store'
import { themeSelector } from '../../store/app/appSlice'
import { mobileOrTablet } from '../../utils/mobileOrTablet'
import { WalletButtonTonHub, WalletButtonTonkeeper } from '../WalletButtons'
import styles from './PaymentModal.scss'

interface PaymentModalProps {
  onClose: () => void
  title: string
  subtitle: string
  tipMessage: string
  toPayInNanoTon: string
  walletType: string
  address: string
  buttonLabelPrefix: string
  detailsTable: { [key: string]: any }[]
}

const PayButton = ({
  walletType,
  onClick,
  buttonLabelPrefix = 'Pay in',
}: {
  walletType: string
  buttonLabelPrefix: string
  onClick(): void
}): JSX.Element => {
  return walletType === 'tonkeeper' ? (
    <WalletButtonTonkeeper
      label={`${buttonLabelPrefix} Tonkeeper`}
      onClick={onClick}
      iconPosition="start"
      isPayButton
    />
  ) : (
    <WalletButtonTonHub
      label={`${buttonLabelPrefix} TonHub`}
      onClick={onClick}
      iconPosition="start"
      isPayButton
    />
  )
}

export function PaymentModal({
  walletType,
  address,
  toPayInNanoTon,
  title,
  subtitle,
  tipMessage,
  detailsTable,
  buttonLabelPrefix,
}: PaymentModalProps) {
  const paymentLink =
    walletType === WalletType.tonKeeper
      ? `https://app.tonkeeper.com/transfer/${address}?amount=${toPayInNanoTon}`
      : `https://tonhub.com/transfer/${address}?amount=${toPayInNanoTon}`

  const handlePay = (walletType: string) => {
    setIsLoading(true)

    if (walletType === 'tonkeeper') {
      location.href = paymentLink
    }

    if (walletType === 'tonhub') {
      window.location.assign(paymentLink)
    }
  }
  const isMobileOrTablet = mobileOrTablet()
  const [isLoading, setIsLoading] = useState(isMobileOrTablet ? false : true)
  const theme = useAppSelector(themeSelector)

  const bgColor = theme === 'dark' ? '#222730' : '#fff'
  const fgColor = theme === 'dark' ? '#fff' : '#000'

  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>

      <div className={styles.grid}>
        {!isMobileOrTablet && (
          <div className={styles.qr}>
            <QRCode
              value={paymentLink}
              size={180}
              bgColor={bgColor}
              fgColor={fgColor}
            />
          </div>
        )}

        <div className={styles.gridRight}>
          {detailsTable.map((item, index) => {
            return item.length ? (
              <div className={styles.gridRow} key={index}>
                <div className={styles.gridLabel}>{item[0]}</div>
                <div className={styles.gridValue}>{item[1]}</div>
              </div>
            ) : (
              <div className={styles.gridDelimeter} key={index}></div>
            )
          })}
        </div>
      </div>

      <div className={styles.payButtonContainer}>
        <div className={styles.checkButtonPlaceholder}>
          <Button
            fullWidth
            variant="secondary"
            loading={isLoading}
            className={styles.checkButton}
          />
        </div>
        {tipMessage && !isMobileOrTablet && (
          <div className={styles.checkButtonTip}>{tipMessage}</div>
        )}
        {tipMessage && isMobileOrTablet && isLoading && (
          <div className={styles.checkButtonTip}>{tipMessage}</div>
        )}
        {isMobileOrTablet && (
          <PayButton
            walletType={walletType}
            onClick={() => handlePay(walletType)}
            buttonLabelPrefix={buttonLabelPrefix}
          />
        )}
      </div>
    </div>
  )
}
