// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qHSHm{transition:opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;top:0;left:0;background-color:#fff;border-radius:24px;position:absolute;overflow:hidden auto;min-width:16px;min-height:16px;padding:20px 24px;z-index:120;box-shadow:0px 1px 8px rgba(0,0,0,.04),0px 1px 3px rgba(0,0,0,.12)}._73UiI{position:absolute;left:0;top:0;right:0;bottom:0;z-index:100;background-color:rgba(0,0,0,.15)}", "",{"version":3,"sources":["webpack://./src/shared/design-system/components/Dropdown/Dropdown.scss"],"names":[],"mappings":"AAAA,OACE,0GAAA,CAEA,KAAA,CACA,MAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBAAA,CACA,oBAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CACA,WAAA,CACA,kEAAA,CAIF,QACE,iBAAA,CACA,MAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,WAAA,CACA,gCAAA","sourcesContent":[".menu {\n  transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n    transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n  top: 0;\n  left: 0;\n  background-color: rgb(255, 255, 255);\n  border-radius: 24px;\n  position: absolute;\n  overflow: hidden auto;\n  min-width: 16px;\n  min-height: 16px;\n  padding: 20px 24px;\n  z-index: 120;\n  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.12);\n  // opacity: 0;\n}\n\n.backdrop {\n  position: absolute;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 100;\n  background-color: rgba(0, 0, 0, 0.15);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "qHSHm",
	"backdrop": "_73UiI"
};
export default ___CSS_LOADER_EXPORT___;
