import React from 'react'

import { Button } from '@design-system'
import { Table } from '../../../Project/components/Sidebar/components/Table/Table'
import hiStyles from '../../HiPage.scss'
import { FundProgress } from '../FundProgress/FundProgress'
import styles from './Ido.scss'

export const Ido = () => {
  return (
    <div>
      <div className={hiStyles.sectionTitle}>Token sale on Tonstarter</div>

      <div className={styles.card}>
        <div className={styles.cardLeft}>
          <div className={styles.cardImage} />
        </div>
        <div className={styles.cardRight}>
          <FundProgress />
          <Table allocationUSD={300} tokenUSD={0.02} ath="270%" />
          <div className={styles.buttonWrapper}>
            <Button
              fullWidth
              as="a"
              href="https://tonstarter.com/project/fanzee"
              target="_blank"
            >
              Show more
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
