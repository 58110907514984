import { createAsyncThunk } from '@reduxjs/toolkit'

import { AnalyticsService, ApiService, AuthService } from '@services'
import { connectWallet } from '../wallet'
import { getWebAppInitDataString } from "@design-system";
import { connectWebAppWallet } from "@store";

export declare type TonhubWalletConfig = {
  address: string
  endpoint: string
  walletType: string
  walletConfig: string
  walletSig: string
  appPublicKey: string
}

export const getAuthWebAppSession = createAsyncThunk<
  boolean,
  { [key: string]: any }
>('auth/getAuthWebAppSession', async (params, { dispatch }) => {
  const { ok, data } = await ApiService.post({
    data: params,
    endpoint: '/auth/webapp-session',
  })

  if (ok && data.accessToken) {
    const { accessToken, refreshToken, address } = data

    AuthService.setCredentials({ accessToken, refreshToken })

    dispatch(connectWallet({ address, walletType: params.walletType }))

    AnalyticsService.track('automate_wallet_connected', {
      wallet_type: params.walletType,
    })

    return true
  }

  return false
})

export const generateTokenForLogin = async () => {
  return await ApiService.post({
    data: undefined,
    endpoint: '/auth/generate-tg-token',
  })
}

export const generateTonConnectPayload = async () => {
  return await ApiService.post({
    data: null,
    endpoint: '/auth/generate-payload',
  })
}

export const getTonConnectSession = createAsyncThunk<
  boolean,
  { wallet: { [key: string]: any }; walletType: string }
>('auth/getTonConnectSession', async ({ wallet, walletType }, { dispatch }) => {
  const { ok, data } = await ApiService.post({
    data: wallet,
    endpoint: '/auth/ton-proof',
  })

  if (ok && data.credentials && data.credentials.accessToken) {
    const { accessToken, refreshToken, address } = data.credentials

    AuthService.setCredentials({ accessToken, refreshToken })

    dispatch(
      connectWallet({ address: data.walletAddress, walletType: walletType })
    )
    const webAppInitData = getWebAppInitDataString()
    if (webAppInitData) {
      dispatch(connectWebAppWallet({ webAppData: webAppInitData }))
    }

    AnalyticsService.track('wallet_connected', {
      wallet_type: walletType,
    })

    return true
  }

  return false
})

export interface TonhubSignRequestFe {
  seed: string
  session: string
  appPublicKey: string
  network: 'testnet' | 'mainnet'
  walletConfig: TonhubWalletConfig
}

export const getTonhubTonConnectSession = createAsyncThunk<
  {
    walletPublicKey?: string
    sessionSeed?: string
    isAuth: boolean
  },
  { wallet: TonhubSignRequestFe; walletType: string }
>(
  'auth/getTonhubTonConnectSession',
  async ({ wallet, walletType }, { dispatch }) => {
    const { ok, data } = await ApiService.post({
      data: wallet,
      endpoint: '/auth/ton-auth-request-new',
    })

    if (ok && data.credentials && data.credentials.accessToken) {
      const { accessToken, refreshToken, address } = data.credentials

      AuthService.setCredentials({ accessToken, refreshToken })

      dispatch(
        connectWallet({ address: data.walletAddress, walletType: walletType })
      )
      const webAppInitData = getWebAppInitDataString()
      if (webAppInitData) {
        dispatch(connectWebAppWallet({ webAppData: webAppInitData }))
      }
      AnalyticsService.track('wallet_connected', {
        wallet_type: walletType,
      })

      return {
        walletPublicKey: wallet.appPublicKey,
        sessionSeed: wallet.seed,
        isAuth: true,
      }
    }

    return {
      isAuth: false,
    }
  }
)
