import { Link, Text, useMobileLayout } from '@design-system'
import React from 'react'

import { Project } from '../store'

export const data: Project = {
  id: 'startupMarket',
  name: 'Startup Market',
  slug: 'startupmarket',
  iconUrl:
    'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/openrating/startupmarket/startupMarket.svg',
  logoUrl:
    'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/openrating/startupmarket/startupMarket.svg',
  logoDarkUrl:
    'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/openrating/startupmarket/startupMarket.svg',
  urlTitle: 'startupmarket.io',
  urlLink: 'https://startupmarket.io',
  expertRatingLevel: '7',
  expertRatingIndex: 18,
  expertRatingMarker: 'D',
  communityRating: '',
  contractAddress: 'EQBVfD0AMebDOLe4qqj_sxPTm_5uCHjj4NN5KU8wtpnaV8-l',
  contractAddressTestnet: 'EQBVfD0AMebDOLe4qqj_sxPTm_5uCHjj4NN5KU8wtpnaV8-l',
  fundamentalReportUrl:
    'https://tonstarter.notion.site/Startup-Market-Fundamental-a556026ea45742b392385d9cd95fc0ea',
  category: 'funding',
  updatedAt: '21 Feb 2023',
  reports: [
    {
      reportType: 'fundamental',
      updatedAt: '21 Feb 2023',
      totalRate: '18%',
      values: {
        valueProposition: '25%',
        tokenomics: 'n/a',
        team: '8%',
        governance: 'n/a',
        regulatory: 'n/a',
      },

      reviewers: [
        {
          title: 'Nick, toncap.net',
          subtitle: 'Report',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/money-with-wings-180.png',
        },
        {
          title: 'Sergey, Tonstarter',
          subtitle: 'Review',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/sergey.png',
        },
      ],
    },
  ],
  hideInvest: true,
}

export const quote = {
  author: 'Nick, toncap.net',
  authorTitle: 'Report',
  authorAvatarUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/money-with-wings-180.png',
  text: 'Thank you for reading the answers I have given, in conclusion I would like to add a few words from myself. I like the idea of this project, it has the potential - it remains to add audiences so that users can support each other. The idea is not new, but I like the approach to project of the guys from the Startup Market, despite the low ratings. Some information was not available to me, in the future, if there is additional information, I will fill out the report again with the updated data.',
}

export function aboutProject(): JSX.Element {
  const isMobileViewport = useMobileLayout()

  const headerType = isMobileViewport ? 'title-24' : 'title-28'
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'
  const textSpacingBottom = isMobileViewport ? '32' : '40'

  return (
    <>
      <Text type={textType} spacingBottom="12" color="secondary">
        <Link
          href="https://startupmarket.io/"
          target="_blank"
          textType={textType}
          animatedUnderline={false}
        >
          Startup Market
        </Link>{' '}
        is a DeFi crowdfunding platform that allows projects to raise funds from
        the crowd and launch their ideas into real projects. The main advantage
        is that there are no legal agreements between parties; the project
        simply receives the funds that investors have decided to share with
        them.
      </Text>
      <Text type={textType} color="secondary">
        In addition, Startup Market offers two types of fundraising:
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        <ul>
          <li>
            Everything and more - Users can continue to fund the project even
            after the funding goal is met.
          </li>
          <li>
            All or nothing - Project ends when payment is complete. Time is
            unlimited.
          </li>
        </ul>
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        No projects have been launched yet, but the NFT collection has partially
        sold and there are 14 projects waiting for funding.
      </Text>
      <Text type={headerType} spacingBottom="8">
        Links
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        <ul>
          <li>
            <Link
              href="https://startupmarket.io/"
              target="_blank"
              textType={textType}
            >
              Website
            </Link>
          </li>
          <li>
            <Link
              href="https://twitter.com/StartupMarketio"
              target="_blank"
              textType={textType}
            >
              Twitter
            </Link>
          </li>
          <li>
            <Link
              href="https://t.me/StartupMarket_rus"
              target="_blank"
              textType={textType}
            >
              Telegram channel (RU)
            </Link>
          </li>
          <li>
            <Link
              href="https://t.me/Startup_Market_Bot"
              target="_blank"
              textType={textType}
            >
              Bot
            </Link>
          </li>
        </ul>
      </Text>
    </>
  )
}

export function tokenDetails(): JSX.Element {
  const isMobileViewport = useMobileLayout()
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'

  return (
    <Text type={textType} color="secondary">
      Startup Market token is not presented.
    </Text>
  )
}
