import React, { useEffect, useState } from 'react'
import type { SwiperProps } from 'swiper/react'
import { Swiper } from 'swiper/react'
import classNames from 'classnames'

// import IconButton from '../IconButton'

// import 'swiper/swiper.min.css'

import styles from './SwiperCarousel.scss'
import { SwiperLazyImage } from './SwiperLazyImage'

export type SwiperCarouselProps = SwiperProps & {
  className?: string
  children?: React.ReactNode
  currentSlide?: number
  withButtons?: boolean
  maxWidth?: '740'
  maxHeight?: '400' | '600'
  minHeight?: '100p'
  fullHeight?: boolean
  grow?: boolean
  childrenGrow?: boolean
  wrapperHeight?: '100p' | 'auto'
  mobileFullWidthWithPadding?: boolean
  clickForNextSlide?: boolean
  setParentInstance?: (e: SwiperActions) => void
  sidePadding?: 24
  innerButtons?: boolean
  borderRadius?: string
  swiperBreakpoints?: { [key: string]: any }
}

export interface SwiperActions {
  update: () => void
  slideTo: (index: number, speed?: number) => void
  slideNext: () => void
  slidePrev: () => void
  isBeginning: boolean
  isEnd: boolean
}

const swiperBreakpoints = {
  600: {
    slidesPerView: 2.5,
    spaceBetween: 5,
  },
  960: {
    slidesPerView: 1,
    spaceBetween: 15,
  },
}

const SwiperCarousel = ({
  className,
  children,
  borderRadius,
  swiperBreakpoints = {},
  spaceBetween,
  initialSlide = 0,
  slidesPerView,
  withButtons = true,

  currentSlide,
  maxWidth,
  maxHeight,
  minHeight,
  grow,
  childrenGrow,
  fullHeight,
  mobileFullWidthWithPadding,
  setParentInstance,
  sidePadding,
  innerButtons,
  wrapperHeight,
  ...props
}: SwiperCarouselProps) => {
  const [instance, setInstance] = useState<SwiperActions>()
  const [position, setPosition] = useState<'start' | '' | 'end' | 'startend'>(
    'start'
  )

  useEffect(() => {
    if (instance) {
      instance.update()

      if (instance.isEnd && instance.isBeginning) {
        setPosition('startend')
      } else if (instance.isBeginning) {
        setPosition('start')
      } else if (instance.isEnd) {
        setPosition('end')
      } else if (!instance.isEnd && !instance.isBeginning) {
        setPosition('')
      }
    }
  }, [instance, children])

  useEffect(() => {
    if (instance && currentSlide !== undefined) {
      instance.slideTo(currentSlide)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentSlide])

  return (
    <div
      className={classNames(
        styles.root,
        className && className,
        maxWidth && styles[`maxWidth-${maxWidth}`]
        // maxHeight && styles[`maxHeight-${maxHeight}`],
        // minHeight && styles[`minHeight-${minHeight}`],
        // sidePadding && styles[`side-padding-${sidePadding}`],
        // wrapperHeight && styles[`wrapper-height-${wrapperHeight}`],
        // fullHeight && styles.fullHeight,
        // grow && styles.grow,
        // childrenGrow && styles.childrenGrow,
        // mobileFullWidthWithPadding && styles.mobileFullWidthWithPadding,
        //
      )}
    >
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        initialSlide={initialSlide}
        breakpoints={swiperBreakpoints}
        // slidesPerView={slidesPerView}
        // loopedSlides={1}
        // threshold={10}
        onSwiper={(swiperInstance: SwiperActions) => {
          setInstance(swiperInstance)
          setParentInstance?.(swiperInstance)
        }}
        onActiveIndexChange={(swiperInstance: SwiperActions) => {
          if (swiperInstance.isBeginning) setPosition('start')
          else if (swiperInstance.isEnd) setPosition('end')
          else setPosition('')
        }}
        // {...props}
        style={{
          borderRadius,
        }}
      >
        {children}
      </Swiper>
      {withButtons && (
        <React.Fragment>
          {!position.includes('start') && (
            <button
              className={classNames(styles.arrow, styles.arrowPrev)}
              onClick={() => instance?.slidePrev()}
            >
              <svg
                width="51"
                height="20"
                viewBox="0 0 51 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.7071 0.792893C10.3166 0.402369 9.68342 0.402369 9.2929 0.792893L0.792896 9.29289C0.40237 9.68342 0.402374 10.3166 0.792896 10.7071L9.2929 19.2071C9.68342 19.5976 10.3166 19.5976 10.7071 19.2071C11.0976 18.8166 11.0976 18.1834 10.7071 17.7929L3.91422 11H50C50.5523 11 51 10.5523 51 10C51 9.44771 50.5523 9 50 9H3.91422L10.7071 2.20711C11.0976 1.81658 11.0976 1.18342 10.7071 0.792893Z"
                  fill="white"
                />
              </svg>
            </button>
          )}

          {!position.includes('end') && (
            <button
              className={classNames(styles.arrow, styles.arrowNext)}
              onClick={() => instance?.slideNext()}
            >
              <svg
                width="51"
                height="20"
                viewBox="0 0 51 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M40.2929 0.792893C40.6834 0.402369 41.3166 0.402369 41.7071 0.792893L50.2071 9.29289C50.5976 9.68342 50.5976 10.3166 50.2071 10.7071L41.7071 19.2071C41.3166 19.5976 40.6834 19.5976 40.2929 19.2071C39.9024 18.8166 39.9024 18.1834 40.2929 17.7929L47.0858 11H0.999999C0.447715 11 0 10.5523 0 10C0 9.44771 0.447715 9 0.999999 9H47.0858L40.2929 2.20711C39.9024 1.81658 39.9024 1.18342 40.2929 0.792893Z"
                  fill="white"
                />
              </svg>
            </button>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

SwiperCarousel.displayName = 'SwiperCarousel'
SwiperCarousel.LazyImage = SwiperLazyImage

export default SwiperCarousel
