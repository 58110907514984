import React from 'react'

export const TelegramPlane = ({className}: {className?: string | undefined}) => {
  return (<svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {' '}
    <path
      d="M4.23741 11.9414C9.06923 9.79202 12.2912 8.37501 13.9033 7.69038C18.5063 5.73562 19.4627 5.39605 20.0861 5.38472C20.2232 5.38238 20.5298 5.41707 20.7284 5.58159C20.8961 5.72051 20.9422 5.90818 20.9643 6.03989C20.9864 6.1716 21.0139 6.47164 20.992 6.70609C20.7426 9.38201 19.6633 15.8758 19.1142 18.8728C18.8818 20.141 18.4244 20.5662 17.9815 20.6078C17.0189 20.6983 16.288 19.9584 15.3558 19.3344C13.897 18.3581 13.0729 17.7503 11.6569 16.7976C10.0205 15.6965 11.0813 15.0914 12.0139 14.1024C12.258 13.8435 16.4988 9.90506 16.5809 9.54778C16.5912 9.5031 16.6007 9.33654 16.5038 9.24858C16.4069 9.16063 16.2639 9.19071 16.1606 9.21463C16.0143 9.24854 13.6839 10.8212 9.16938 13.9327C8.5079 14.3965 7.90875 14.6225 7.37193 14.6106C6.78013 14.5976 5.64175 14.269 4.79548 13.9881C3.75749 13.6436 2.93252 13.4615 3.00436 12.8764C3.04178 12.5717 3.45279 12.26 4.23741 11.9414Z"
      className={className}
    />{' '}
  </svg>)
}