import React from 'react'

import { Allocation, Project } from '@store'
import styles from '../../Sidebar.scss'
import { FundGoal } from '../FundGoal/FundGoal'
import { Swap } from '../Swap/Swap'
import { Table } from '../Table/Table'
import { Tip } from '../Tip/Tip'

interface SwapFormProps {
  activeProject: Project
  activeAllocation: Allocation
  isAuth: boolean
}

export const SwapForm = ({
  activeProject,
  activeAllocation,
  isAuth,
}: SwapFormProps): JSX.Element => {
  if (!isAuth) {
    return (
      <div className={styles.bochka}>
        <FundGoal fundGoal={activeProject.fundsGoal} />
        <Tip message="The application period is closed. We are no longer accepting new participants for this IDO." />
      </div>
    )
  }
  return (
    <div className={styles.bochka}>
      <FundGoal fundGoal={activeProject.fundsGoal} />
      <Tip message="Enter the amount of TON to redeem your IDO tokens before the Token sale ends." />
      <Table
        allocationUSD={activeProject.allocationUSD}
        tokenUSD={activeProject.tokenUSD}
      />
      <Swap
        idoContractAddress={activeProject.idoContractAddress}
        allocationMaxTokens={
          activeAllocation.maxTokens ? activeAllocation.maxTokens : 100
        }
        tonPriceInUsd={activeProject.fixedTonPrice}
      />
      <a
        href="https://t.me/ton_starter_support_bot"
        target="_blank"
        className={styles.supportLink}
      >
        Support chat
      </a>
    </div>
  )
}
