// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bkMIv{font-weight:600;margin-bottom:12px;margin-top:24px;text-transform:uppercase}.OxHJ-{font-weight:600;margin-bottom:12px;margin-top:24px;font-size:20px;text-transform:uppercase}.rRkfk{margin-bottom:32px}.rRkfk ul{margin:0;margin-bottom:12px}.rRkfk li{list-style-type:disc}.VW78I{margin-bottom:12px;max-width:800px}", "",{"version":3,"sources":["webpack://./src/tonstarter/pages/Legal/legal.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CACA,kBAAA,CACA,eAAA,CACA,wBAAA,CAGF,OACE,eAAA,CACA,kBAAA,CACA,eAAA,CACA,cAAA,CACA,wBAAA,CAGF,OACE,kBAAA,CAEA,UACE,QAAA,CACA,kBAAA,CAGF,UACE,oBAAA,CAIJ,OACE,kBAAA,CACA,eAAA","sourcesContent":[".header {\n  font-weight: 600;\n  margin-bottom: 12px;\n  margin-top: 24px;\n  text-transform: uppercase;\n}\n\n.title {\n  font-weight: 600;\n  margin-bottom: 12px;\n  margin-top: 24px;\n  font-size: 20px;\n  text-transform: uppercase;\n}\n\n.root {\n  margin-bottom: 32px;\n\n  ul {\n    margin: 0;\n    margin-bottom: 12px;\n  }\n\n  li {\n    list-style-type: disc;\n  }\n}\n\n.row {\n  margin-bottom: 12px;\n  max-width: 800px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "bkMIv",
	"title": "OxHJ-",
	"root": "rRkfk",
	"row": "VW78I"
};
export default ___CSS_LOADER_EXPORT___;
