import React from 'react'

import { Text, useMobileLayout } from '@design-system'
import styles from '../components/About/About.scss'
import { Arrow } from '../icons'

export const OneToken = () => {
  const isMobileLayout = useMobileLayout()

  const extraHeaderType = isMobileLayout ? 'header-32' : 'header-44'
  const headerType = isMobileLayout ? 'title-24' : 'title-28'
  const subheaderType = isMobileLayout ? 'title-20' : 'title-24'
  const paragraphType = isMobileLayout ? 'paragraph-16' : 'paragraph-20'
  const paragraphColor = 'secondary'

  return (
    <>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Reviews
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="40">
          <b>comaunite</b> That epic buildup! Favorite track: Dust.
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="40">
          <b>Soma Holiday</b> Discovered OMED and the EP when first released but inexplicably I’ve not had Mt Mntn in my life since. Until now. Dust is an incredible sonic journey. Beautiful, silky smooth psych. Favorite track: Dust.
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="40">
          <b>Rudolf Nisbeth</b> New to this band but I'm glad I found them. After hearing this album, I quickly went through their other offerings and purchased immediately. Yes, they're all that good. Favorite track: Floating Eyes.
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="40">
          <b>Chris Tragas</b> Amazing and truly stunning! A true musical journey.
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="40">
          <b>Michael Mueller</b> Low-slung, menacing, sinewy music. Dust is an out-and-out epic track; one the best of its kind. Love how these guys are prepared to let dynamics and restraint fuse into something this evocative.<br></br>
          If Pink Floyd's Set The Controls For the Heart of the Sun was re-fashioned to soundtrack a Western, you'd end up with something pretty close to this.
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="40">
          <b>Elliott Mariess</b> Mt Mtn unleash their slower lower doomy side on this album. loving it!
        </Text>
      </div>
      <div className={styles.row}>
        <a
          className={styles.teamLink}
          href="https://mtmountain.bandcamp.com/album/dust"
          target="_blank"
        >
          <Text type={paragraphType} color={paragraphColor}>
            Find more
          </Text>
          <Arrow />
        </a>
      </div>
    </>
  )
}
