import classNames from 'classnames'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { Button, Icon, Link, Menu, MenuItem, ThemeButton } from '@design-system'
import {
  collapseAddress,
  copyToClipboard,
  openNewTab,
  tonBalanceToDisplay,
} from '@utils'

import { HeaderProps } from '../Header'
import styles from './HeaderDesktop.scss'

interface HeaderDesktopProps {
  logo: JSX.Element
}

export function HeaderDesktop({
  className,
  classNameContainer,
  twitter,
  telegram,
  logo,
  links,
  walletBalance,
  walletAddress,
  onThemeToggle,
  onConnectWallet,
  onDisconnectWallet,
}: HeaderProps): JSX.Element {
  const [showDropDown, setShowDropDown] = useState(false)
  const [copyText, setCopyText] = useState('Copy address')

  const isAuth = walletAddress !== ('' || undefined || null)

  const handleConnect = () => {
    onConnectWallet()
  }

  const handleDisconnect = async () => {
    onDisconnectWallet()
    setShowDropDown(false)
  }

  const handleCopy = () => {
    copyToClipboard(walletAddress ? walletAddress : '')
    setCopyText('Copied')
    setTimeout(() => setCopyText('Copy address'), 1000)
  }

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.containerWrapper}>
        <div className={classNames(styles.container, classNameContainer)}>
          <Link href="/" target="_self" variant="text">{logo}</Link>
          <div className={styles.navigation}>
            {links.map((item) =>
              item.external ? (
                <a href={item.href} target="_blank" key={item.title}>
                  {item.title}
                </a>
              ) : (
                <NavLink key={item.title} to={item.href}>
                  {item.title}
                </NavLink>
              )
            )}
          </div>

          <div className={styles.actions}>
            {telegram ? (
              <Button
                onClick={() => openNewTab(telegram)}
                shape="icon-square"
                variant="secondary"
                className={styles.button}
                icon={<Icon name="telegram" colorFill="primary" />}
              ></Button>
            ) : null}
            {twitter ? (
              <Button
                onClick={() => openNewTab(twitter)}
                shape="icon-square"
                variant="secondary"
                className={styles.button}
                icon={<Icon name="twitter" colorFill="primary" />}
              ></Button>
            ) : null}

            {isAuth ? null : (
              <Button variant="contrast" onClick={handleConnect}>
                Connect wallet
              </Button>
            )}
            {isAuth ? (
              <Button
                variant="secondary"
                onClick={() => setShowDropDown(true)}
                iconPosition="end"
                icon={<Icon name="chevron-down" colorFill="secondary" />}
              >
                {collapseAddress(walletAddress, 4)}
              </Button>
            ) : null}
          </div>

          <ThemeButton onToggleTheme={onThemeToggle} />
        </div>
        <Menu
          className={styles.ddMenu}
          open={showDropDown}
          onClose={() => setShowDropDown(false)}
        >
          <MenuItem
            icon={<Icon name="coins" colorStroke="primary" />}
            highlightable={false}
          >
            {walletBalance ? tonBalanceToDisplay(walletBalance) : null} TON
          </MenuItem>
          <MenuItem type="delimeter" />
          <MenuItem icon={<Icon name="copy" />} onClick={handleCopy}>
            {copyText}
          </MenuItem>
          <MenuItem icon={<Icon name="logout" />} onClick={handleDisconnect}>
            Disconnect
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}
