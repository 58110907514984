import { useEffect, useState } from 'react'

const getReturnValues = (countDown: number) => {
  let days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  let hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  let minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  let seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  days = days <= 0 ? 0 : days
  hours = hours <= 0 ? 0 : hours
  minutes = minutes <= 0 ? 0 : minutes
  seconds = seconds <= 0 ? 0 : seconds

  return {
    days: days < 10 ? `0${days}` : days.toString(),
    hours: hours < 10 ? `0${hours}` : hours.toString(),
    minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
    seconds: seconds < 10 ? `0${seconds}` : seconds.toString(),
  }
}

export const useCountdown = (
  targetDate: string
): { days: string; hours: string; minutes: string; seconds: string } => {
  const countDownDate = new Date(targetDate).getTime()

  const [countDown, setCountDown] = useState<number>(
    countDownDate - new Date().getTime()
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDownDate - new Date().getTime() <= 0) {
        clearInterval(interval)
      }

      setCountDown(countDownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return getReturnValues(countDown)
}
