import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '..'
import {
  fetchProject,
  fetchProjectParticipants,
  fetchProjects,
  fetchProjectSaleInfo,
} from './projectsActions'
import { Project } from './types'

interface ProjectState {
  projects: Project[]
  activeProject: Project
  projectSaleInfo: {
    tokenPriceInNanoTon: number
    amountTokensLeft: number
  }
  participants: number
  status: 'idle' | 'loading' | 'fulfilled' | 'rejected'
  error: string | null
}

const initialState: ProjectState = {
  projects: [],
  activeProject: {} as Project,
  projectSaleInfo: {} as any,
  participants: 0,
  status: 'idle',
  error: null,
}

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProjects.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.status = 'fulfilled'
        state.projects = action.payload
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.status = 'rejected'
      })

    builder.addCase(fetchProject.fulfilled, (state, action) => {
      state.activeProject = action.payload
    })
    builder.addCase(fetchProjectSaleInfo.fulfilled, (state, action) => {
      const { tokenPriceInNanoTon, amountTokensLeft } = action.payload

      state.projectSaleInfo = {
        tokenPriceInNanoTon,
        amountTokensLeft,
      }
    })

    builder
      .addCase(fetchProjectParticipants.fulfilled, (state, action) => {
        state.participants = action.payload
      })
      .addCase(fetchProjectParticipants.rejected, (state, action) => {
        state.participants = 0
      })
  },
})

export const projectsSelector = (state: RootState): Project[] =>
  state.projects.projects

export const activeProjectSelector = (state: RootState): Project =>
  state.projects.activeProject

export const projectParticipants = (state: RootState): number =>
  state.projects.participants

export const projectSaleInfoSelector = (
  state: RootState
): { [key: string]: string | number } => state.projects.projectSaleInfo

export default projectsSlice.reducer
