import React, { useState } from 'react'

import { Button, Icon, Link, Menu, MenuItem, MenuItemLink } from '@design-system'
import { copyToClipboard, tonBalanceToDisplay } from '@utils'

import cn from 'classnames'
import { HeaderProps } from '../Header'
import styles from './HeaderMobile.scss'

export function HeaderMobile({
  className,
  logo,
  links,
  theme,
  walletBalance,
  walletAddress,
  onThemeToggle,
  onConnectWallet,
  onDisconnectWallet,
}: HeaderProps): JSX.Element {
  const [showDropDown, setShowDropDown] = useState(false)
  const [copyText, setCopyText] = useState('Copy address')

  const isAuth = walletAddress !== ('' || undefined || null)

  const handleConnect = () => {
    onConnectWallet()
  }

  const handleDisconnect = () => {
    onDisconnectWallet()
    setShowDropDown(false)
  }

  const handleCopy = () => {
    copyToClipboard(walletAddress ? walletAddress : '')
    setCopyText('Copied')
    setTimeout(() => setCopyText('Copy address'), 1000)
  }

  // const { slug } = useParams()

  return (
    <div className={cn(styles.root, className)}>
      <Link href="/" target="_self" variant="text">{logo}</Link>
      {/* <NavLink to="/">
        <Content justify="center">
          {slug ? (
            <>
              <Icon name="chevron-left" size="24" colorFill="primary" />
              &nbsp;
            </>
          ) : null}
          <Text type="title-16">Open Rating</Text>
        </Content>
      </NavLink> */}
      <Button
        icon={<Icon name="half-burger" colorStroke="primary" />}
        variant="hollow"
        shape="icon-square"
        onClick={() => setShowDropDown(true)}
        className={styles.burger}
      />
      <Menu
        className={styles.ddMenu}
        open={showDropDown}
        onClose={() => setShowDropDown(false)}
      >
        {!isAuth ? (
          <MenuItem icon={<Icon name="login" />} onClick={handleConnect}>
            Connect wallet
          </MenuItem>
        ) : null}
        {isAuth ? (
          <MenuItem
            icon={<Icon name="coins" colorStroke="primary" />}
            highlightable={false}
          >
            {walletBalance ? tonBalanceToDisplay(walletBalance) : null} TON
          </MenuItem>
        ) : null}
        {isAuth ? (
          <>
            <MenuItem type="delimeter" />
            <MenuItem icon={<Icon name="copy" />} onClick={handleCopy}>
              {copyText}
            </MenuItem>
            <MenuItem icon={<Icon name="logout" />} onClick={handleDisconnect}>
              Disconnect
            </MenuItem>
          </>
        ) : null}
        <MenuItem type="delimeter" />

        {links.map((item) => (
          <MenuItemLink
            key={item.title}
            to={item.href}
            target={item.external ? '_blank' : ''}
            icon={item.icon ? item.icon : null}
          >
            {item.title}
          </MenuItemLink>
        ))}

        <MenuItem
          icon={
            <Icon
              name={theme === 'dark' ? 'sun' : 'moon'}
              colorStroke="primary"
              size="24"
            />
          }
          onClick={() => {
            setShowDropDown(false)
            onThemeToggle()
          }}
        >
          {theme === 'dark' ? 'Light' : 'Dark'} mode
        </MenuItem>
      </Menu>
    </div>
  )
}
