import React from 'react'

import { Project, ProjectStatus } from '@store'
import { Countdown } from '../../../Countdown'

import styles from '../../Sidebar.scss'

interface TimerProps {
  project: Project
}

export const Timer = ({ project }: TimerProps): JSX.Element | null => {
  switch (project.status) {
    case ProjectStatus.whitelistSoon:
      return (
        <Countdown
          className={styles.bochka}
          title="Submisson will start in"
          final={project.whitelistStartDate}
        />
      )
    case ProjectStatus.whitelistOpen:
      return (
        <Countdown
          className={styles.bochka}
          title="Submissions closed"
          final={project.whitelistFinishDate}
        />
      )
    case ProjectStatus.whitelistClosed:
      return (
        <Countdown
          className={styles.bochka}
          title="Allocation draw in"
          final={project.projectLotteryDate}
        />
      )
    case ProjectStatus.whitelistFinish:
      return (
        <Countdown
          className={styles.bochka}
          title="Token sale will start in"
          final={project.tokenSaleDate}
        />
      )
    case ProjectStatus.live:
      return (
        <Countdown
          className={styles.bochka}
          title="Token sale will end in"
          final={project.tokenSaleFinishDate}
        />
      )
    default:
      return null
  }
}
