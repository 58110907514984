import React from 'react'

import { Image, Text } from '@design-system'

import styles from '../../../Project/airdrop/components/About/About.scss'
import { Arrow } from '../../../Project/airdrop/icons'

export const FantonDetails = () => {
  const headerType = 'title-24'
  const paragraphType = 'paragraph-16';
  const paragraphColor = 'secondary'

  return (
    <>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Key highlights
        </Text>

        <Text type={paragraphType} color={paragraphColor}>
          <ol>
            <li>
              Fanton emerged as the biggest game in the TON ecosystem, with its Monthly Active Users (MAU) skyrocketing from 5K to 55K in 3 months, along with a sharp increase in connected wallets to 10K and a total number of games exceeding 270,000.
            </li>
            <li>
              They generated $44K in revenue in April, driven by both paid tournaments and NFT sales.
            </li>
            <li>
              The number of paid teams using the Fanton platform has skyrocketed from 200 to over 2,000 per month.
            </li>
            <li>
              Fanton launched a solid product <a href="https://t.me/fantongamebot">t.me/fantongamebot</a>, supported by a thriving community of 30K followers.
            </li>
            <li>
              The team successfully recruited top talent, including a consultant from Zeptolab, who is their CPO, and a CMO from our competitor Fanteam.
            </li>
            <li>
              Fanton caught the eye of the world's leading media <a href="https://t.me/c/1872164559/5751">t.me/c/1872164559/5751</a> outlets worldwide and gained coverage within the TON ecosystem, <a href="https://t.me/toncoin/835">t.me/toncoin/835</a> further establishing our footprint in the industry.
            </li>
            <li>
              Added various top-up options including credit card checkout.
            </li>
            <li>
              Yolo Investments, First Stage Labs and Existential Capital already invested into Fanton
            </li>
            <li>
              Competitors: Sorare ($4B), Draft Kings ($11B)
            </li>
            <li>
              Private Sale valuation: <b>$11.5M</b>
            </li>
          </ol>
        </Text>
        <Image
          src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-advantages.png"
          alt="Fanton Advantages"
          borderRadius="16px"
          lightbox={{
            images: [
              {
                alt: 'Fanton Advantages',
                src: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-advantages.png',
              },
            ],
          }}
        />
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">Team</Text>
        <Image
          src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-team.png"
          alt="Fanton Team"
          borderRadius="16px"
          lightbox={{
            images: [
              {
                alt: 'Fanton Team',
                src: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-team.png',
              },
            ],
          }}
        />
        <br></br>
        <Text type={headerType} spacingBottom="16">
          Product
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          Fanton is the first play-to-earn game in the Fantasy Football genre that integrated in Telegram. 
          The game uses hand-drawn NFTs based on TON blockchain. Players can create fantasy football teams and participate in tournaments to win prizes such as NFTs and Toncoin. Players' score in the tournaments depends on the performance of footballers in real matches.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Problem
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
        Bring a user-friendly crypto product for the mass market — using Telegram Web Apps and a proven gameplay.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Solution
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          Users assemble a “dream team” of their favourite football players and if they play well, user wins. This makes emotions more bright. 
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          <ol>
            <li>Customers don’t need to download a mobile app. In 2 clicks customer can start the game. That's why customer acquisition cost is much lower – right now it’s 0,5$ per new customer.</li>
            <li>We could return users back with bot push-notifications which gives higher retention than web-site.</li>
            <li>Moreover we give customers free-to-play mode with prizes to understand a mechanic and convert to payment tournaments with better prizes.</li>
          </ol>
        </Text>
      </div>



      <div className={styles.row}>
        <Image
          src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-target.png"
          alt="Fanton Target"
          borderRadius="16px"
          lightbox={{
            images: [
              {
                alt: 'Fanton Target',
                src: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-target.png',
              },
            ],
          }}
        />
        <br></br>
        <Text type={headerType} spacingBottom="16">
          Business Model
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          Fanton operates on a freemium business model. 
          The core game is free-to-play, but players can play in paid tournaments for higher prizes. 
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          The paid tournaments are the main source of revenue, as players purchase tickets to enter the tournament and compete against other players for the prize pool.
          These tournaments provide a steady stream of revenue for Fanton.
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          Additionally, Fanton also earns revenue through the sale of NFTs. 
          The business model is designed to attract a large player base and engage them through a combination of free and paid content, ultimately leading to a sustainable and profitable revenue stream.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">Roadmap</Text>
        <Image
          src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-roadmap.png"
          alt="Fanton roadmap"
          borderRadius="16px"
          lightbox={{
            images: [
              {
                alt: 'Fanton roadmap',
                src: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-roadmap.png',
              },
            ],
          }}
        />
        <a
          className={styles.teamLink}
          href="https://fan-ton.com/en/#roadmap"
          target="_blank"
        >
          <Text type={paragraphType} color={paragraphColor}>
            Details
          </Text>
          <Arrow />
        </a>
      </div>
    </>
  )
}
