import { Content, Text } from '@design-system'
import React, { useEffect, useRef } from 'react'
import { Animate } from 'react-move'

import styles from './Timer.scss'

type TimerValue = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

const enterParams = {
  opacity: [1],
  timing: { duration: 350 },
}

export const Timer = ({
  hoursBefore = 1,
  text,
}: {
  hoursBefore: number
  text: string
}) => {
  const circleRef = useRef(null)

  const syncRef = () => {
    const v = (150 / 60) * (60 - hoursBefore) * -1
    circleRef.current.style['stroke-dashoffset'] = `${v}px`
  }

  useEffect(() => {
    syncRef()
  }, [hoursBefore])


  return (
    <Content align="center" justify="center" grow className={styles.root}>
      <Animate show={true} start={{ opacity: 0 }} enter={enterParams}>
        {({ opacity }) => {
          return (
            <div className={styles.timer} style={{ opacity }}>
              {hoursBefore} sec
              <svg className={styles.circle}>
                <circle r="24" cx="27" cy="27" ref={circleRef}></circle>
              </svg>
            </div>
          )
        }}
      </Animate>

      <Text align="center" color="fourth">
        {text}
      </Text>
    </Content>
  )
}
