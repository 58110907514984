export interface WebApp {
  BackButton: any
  MainButton: any
}

export interface WebAppUser {
  id: string
  username: string
  language_code: string
}

export interface WebAppChat {
  id: string
}

export interface WebAppInitData {
  user: WebAppUser
  chat: WebAppChat
}

export function isWebApp(): boolean {
  return window.Telegram && window.Telegram.WebApp.initData.length > 0
}

export const getTGUserId = () => {
  return window.Telegram?.WebApp?.initDataUnsafe?.user?.id
}

export const showTwaPopup = (message: any) => {
  window.Telegram?.WebApp.showPopup({ title: 'Debug', message })
}

export const getWebAppInitData = (): WebAppInitData =>
  window.Telegram?.WebApp?.initDataUnsafe as WebAppInitData

export const getWebAppInitDataString = (): string =>
  window.Telegram?.WebApp.initData

export function useWebApp(callback: (webApp: WebApp) => void) {
  if (isWebApp()) {
    const webApp = window.Telegram.WebApp as WebApp

    callback(webApp)
  }
}
