import { useMobileLayout } from '@design-system'
import {
  setDarkTheme,
  themeSelector,
  useAppDispatch,
  useAppSelector,
} from '@store'
import React, { useEffect } from 'react'

import { AirdropPage } from '../AirdropPage'
import { Stars } from './components/Stars'
import { DiamondsDetails } from './DiamondsDetails'
import { DiamondsToken } from './DiamondsToken'

export const Diamonds = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setDarkTheme())
  }, [])

  const theme = useAppSelector(themeSelector)
  const isMobileLayout = useMobileLayout()

  return (
    <>
      <Stars />
      <AirdropPage
        slug="tondiamonds"
        colour="#2383EB"
        DetailsComponent={DiamondsDetails}
        imageCarousel={
          <img src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/diamonds/inner-cover.png" />
        }
      />
    </>
  )
}
