import React, {ReactElement} from 'react'

import Moon from './icons/moon.svg'
import ThumbsUp from './icons/thumbsup.svg'
import Fire from './icons/fire.svg'
import Rocket from './icons/rocket.svg'
import Floppy from './icons/floppy.svg'
import Gem from './icons/gem.svg'
import Punk from './icons/punk.svg'

import styles from './Balance.scss'
import classNames from 'classnames'

interface BalanceProps {
  amount: number
  slug?: string
}

export const getIcon = (amount: number, slug?: string) : string | JSX.Element => {
  let icon : string | ReactElement = '🌱'
  // let icon = '🦶'

  if (amount >= 1500) {
    icon = '🪩'
  } else if (amount >= 1000) {
    icon = '🦅'
  } else if (amount > 400) {
    icon = '🏟️'
  } else if (amount > 200) {
    icon = '🥅'
  } else if(amount > 50) {
    icon = '⚽️'
  }

  switch(slug) {
    case 'punks':
      icon=<Moon />
      if (amount >= 1500) icon = <Floppy />
      else if (amount >= 1250) icon = <Punk />
      else if (amount >= 1000) icon = <Rocket />
      else if (amount >= 700) icon = <Gem />
      else if (amount >= 500) icon = <Fire />
      else if (amount >= 300) icon = <ThumbsUp />
      break;
    case 'stonfi':
      icon='🌱'
      if (amount >= 4000) icon = '💎'
      else if (amount >= 3000) icon = '🦅'
      else if (amount >= 1500) icon = '💍'
      else if (amount >= 900) icon = '🥌'
      else if (amount >= 600) icon = '🗿'
      else if (amount >= 300) icon = '🥑'
      break;
    case 'dedust':
      icon = <div className={classNames(styles.icon, styles.knife)}></div>
      if (amount >= 200) icon = <div className={classNames(styles.icon, styles.ak)}></div>
      else if (amount >= 160) icon = <div className={classNames(styles.icon, styles.sg)}></div>
      else if (amount >= 120) icon = <div className={classNames(styles.icon, styles.mac)}></div>
      else if (amount >= 70) icon = <div className={classNames(styles.icon, styles.desertEagle)}></div>
      else if (amount >= 50) icon = <div className={classNames(styles.icon, styles.glock)}></div>
  }

  return icon
}

export const Balance = ({amount, slug}: BalanceProps): JSX.Element => {
  const icon = getIcon(amount, slug)

  return (
    <div className={styles.root}>
      <span className={styles.container}>{icon} {amount} points</span>
    </div>
  )
}
