import { Wallet as TonConnectWallet } from '@tonconnect/ui-react'
import React, { useEffect, useState } from 'react'
import { RemoteConnectPersistance, TonhubConnectProvider } from 'react-ton-x'

import Config from '@config'
import { AnalyticsService, TonConnectService } from '@services'

import {
  getTonConnectSession,
  getTonhubTonConnectSession,
  isAuthSelector,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@store'
import { mobileOrTablet } from '@utils'
import {
  ConnectTonhubQrModal,
  ConnectTonkeeperQrModal,
  prepareTonConnectLink,
} from '../ConnectWalletQrModal'
import { ConnectWalletModal } from './ConnectWalletModal'
import styles from './ConnectWalletModal.scss'

export function ConnectWalletModalWrapper({
  onClose,
}: {
  onClose: (params?: any) => void
}) {
  const isMobileOrTablet = mobileOrTablet()
  const isAuth = useAppSelector((state: RootState) => isAuthSelector(state))
  const [connectWalletTarget, setConnectWalletTarget] = useState<null | string>(
    null
  )

  useEffect(() => {
    isAuth && onClose()
  }, [isAuth])

  const handleCloseQrModal = () => {
    setConnectWalletTarget(null)
  }

  const handleConnect = (walletType: 'tonkeeper' | 'tonhub') => {
    AnalyticsService.track('connect_wallet_modal_opened', {
      wallet_type: walletType,
    })

    setConnectWalletTarget(walletType)
  }

  const shouldRenderQrModal = !isMobileOrTablet && connectWalletTarget

  const shouldRenderTonkeeperQrModal =
    connectWalletTarget === 'tonkeeper' && !isMobileOrTablet
  const shouldRenderTonhubQrModal =
    connectWalletTarget === 'tonhub' && !isMobileOrTablet

  const [connectionState, setConnectionState] =
    useState<RemoteConnectPersistance>({
      type: 'initing',
    })

  useEffect(() => {
    if (connectionState.type === 'online') {
      const { walletConfig, session, seed } = connectionState

      dispatch(
        getTonhubTonConnectSession({
          wallet: {
            seed,
            session,
            appPublicKey: walletConfig.appPublicKey,
            network: Config.isProd ? 'mainnet' : 'testnet',
            walletConfig,
          },
          walletType: 'tonhub',
        })
      )
    }
  }, [connectionState])

  const [tonkeeperLoginLink, setTonkeeperLoginLink] = useState<null | string>(
    null
  )

  const dispatch = useAppDispatch()

  const [wallet, setWallet] = useState<TonConnectWallet | null>(
    TonConnectService.getConnector().wallet
  )

  const getTonkeeperLoginLink = async () => {
    const link = await prepareTonConnectLink()
    setTonkeeperLoginLink(link)
  }

  useEffect(() => {
    TonConnectService.getConnector().onStatusChange(setWallet, console.error)
    getTonkeeperLoginLink()
  }, [])

  useEffect(() => {
    if (wallet) {
      dispatch(getTonConnectSession({ wallet, walletType: 'tonkeeper' }))
    }
  }, [wallet])

  return (
    <TonhubConnectProvider
      network={Config.isProd ? 'mainnet' : 'testnet'}
      name="Tonstarter"
      url="https://tonstarter.com/"
      debug={false}
      connectionState={connectionState}
      setConnectionState={setConnectionState}
    >
      <div className={styles.rootWrapper}>
        {shouldRenderTonkeeperQrModal ? (
          <ConnectTonkeeperQrModal
            onClose={handleCloseQrModal}
            loginLink={tonkeeperLoginLink}
          />
        ) : null}
        {shouldRenderTonhubQrModal ? (
          <ConnectTonhubQrModal onClose={handleCloseQrModal} />
        ) : null}
        {!shouldRenderQrModal && (
          <ConnectWalletModal
            onConnect={handleConnect}
            tonkeeperLoginLink={tonkeeperLoginLink as string}
          />
        )}
      </div>
    </TonhubConnectProvider>
  )
}
