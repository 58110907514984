import React from 'react'

import { Project } from '@store'
import { ProjectItem, ProjectItemSkeleton } from '../ProjectItem'

import styles from './ProjectsList.scss'

interface ProjectsListProps {
  projects: Project[]
  isWebApp: boolean
}

export const ProjectsList = ({
  projects,
  isWebApp
}: ProjectsListProps): JSX.Element => {
  return (
    <section
      className={styles.projectsSection}
      id="projects"
    >
      {!isWebApp && <h2 className={styles.title}>Projects</h2> }
      <div className={styles.container}>
        {projects.length
          ? projects.map((project, index) => (
            <ProjectItem project={project as Project} key={index} />
          ))
          : [1, 2, 3].map((item) => (
            <ProjectItemSkeleton key={item} />
          ))}
        </div>
    </section>
  )
}
