import Big from 'big.js'

export const formatCurrency = (
  currencyCode: string,
  amount: number,
  maxFractionDigits?: number
): string => {
  const formattedCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: maxFractionDigits ?? 0,
  }).format(amount)

  return formattedCurrency
}

export const getPercentage = (current: number, of: number): number => {
  const percent = of / 100
  const result = current / percent

  return result > 100 ? 100 : result
}

export const multiply = (
  a: string,
  b: string,
  decimalPlace?: number
): string => {
  const result = new Big(a).mul(new Big(b))

  if (decimalPlace) {
    return result.toFixed(decimalPlace)
  }

  return result.toFixed()
}

export const divide = (a: string, b: string, decimalPlace?: number): string => {
  const result = new Big(a).div(new Big(b))

  if (decimalPlace) {
    return result.toFixed(decimalPlace)
  }

  return result.toFixed()
}
