import React from 'react'

import { Text, useMobileLayout } from '@design-system'
import { advisors } from './data/advisors'
import { news } from './data/news'
import { partners } from './data/partners'
import { roadmap } from './data/roadmap'
import { team } from './data/team'
import styles from '../components/About/About.scss'
import { Arrow } from '../icons'

export const MegatonDetails = () => {
  const isMobileLayout = useMobileLayout()

  const extraHeaderType = isMobileLayout ? 'header-32' : 'header-44'
  const headerType = isMobileLayout ? 'title-24' : 'title-28'
  const subheaderType = isMobileLayout ? 'title-20' : 'title-24'
  const paragraphType = isMobileLayout ? 'paragraph-16' : 'paragraph-16'
  const paragraphColor = 'secondary'

  return (
    <>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Product
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          Megaton Finance is an AMM-based DEX on TON mainnet that provides
          various revenue generation opportunities such as Swap, Yield Farming,
          and Dashboard. And it will be integrated with Orbit Bridge which
          connects 18 mainnets.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Notable Achievements
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          Megaton Finance is developed by Ozys, an all-around blockchain company
          with strengths in developing the largest DeFi (Decentralized Finance)
          protocols in South Korea.
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          Since 2018, Ozys has led a Web 3.0 adoption business based on
          blockchain in South Korea. Ozys has won recognition worldwide for
          developing and operating smart contract-based DeFi protocols. We
          continue to cooperate with various developers/projects to drive
          sustainable growth of our protocols.
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          KLAYswap, the largest AMM-based Decentralized Exchange (DEX) on
          Klaytn, is the prime of success. KLAYswap ranked 1st and also was able
          to garner more than 50% of the market share on the Klaytn chain.
          KLAYswap's MAU exceeded 1 million and the TVL reached an all-time high
          of $2.1B last year.
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          In addition, other protocols like Meshswap on Polygon, a cross-chain
          platform Orbit Bridge (supports 18 mainnets), a lending protocol Belt
          Finance on Binance Smart Chain, etc.
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          Ozys has built a connection with Justin Hyun (Head of Incubation at
          TON Foundation) and Kirill Malev (Junior Partner at FS Labs) through
          TEB and TON Ecosystem Builder. We've been sharing the potential and
          insights of the TON ecosystem based on Telegram's unique user
          experience.
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          At the same time, Ozys decided to collaborate to make a big step
          forward in the business relationship, including the development of
          Megaton Finance, a new AMM DEX, the connection between the TON
          ecosystem and on-chain users on multi-chain, and the development of
          Telegram Chatbot, a unique web-based messenger function. We can be
          game changers that change the paradigm of the blockchain industry.
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          Megaton Finance will play a key role in accelerating the strengthening
          of the TON ecosystem through integration with the TON-supported Orbit
          Bridge (Global Top 10 Bridge). It will open the global gateway to
          embracing not only multi-chain ecosystem users but also bringing the
          major blue chip crypto assets like ETH, BTC, USDT, and USDC to
          solidify the foundation of the TON mainnet ecosystem.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Business Model
        </Text>
        <Text type={subheaderType} color={paragraphColor} spacingBottom="16">
          Value Proposition
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="24">
          Transactions that occurred in each liquidity pool on Megaton finance
          will be distributed to liquidity providers.
        </Text>
        <Text type={subheaderType} color={paragraphColor} spacingBottom="16">
          Key Activities and Resources
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="24">
          Integration with Orbit Bridge will open the gateway to connect multi
          chains including EVM-based liquidity to Megaton finance.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Key Partners
        </Text>

        <div className={styles.richLinks}>
          {partners.map((p) => (
            <a
              className={styles.richLink}
              href={p.href}
              target="_blank"
              key={p.id}
            >
              <img className={styles.richLinkIcon} src={p.image} />
              <Text type={paragraphType} color={paragraphColor}>
                {p.title}
              </Text>
              <Arrow className={styles.richLinkArrow} />
            </a>
          ))}
        </div>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Roadmap
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="24">
          We will continue introducing new financial functions for TON (Toncoin)
          holders and those seeking new opportunities in various blockchain
          ecosystems. Starting with swap and yield farming features, we'll add
          totally advanced financial services such as lending, leverage farming,
          staking, governance, and more. Ultimately, the Telegram chatbot
          function developed by Ozys will be combined with the Megaton Finance
          protocol to realize the perfect combination with the Telegram
          messenger and embrace both existing DeFi users and 700 million
          Telegram users (based on MAU).
        </Text>

        {roadmap.map((r, i) => (
          <div className={styles.roadmapRow} key={i}>
            <Text type={extraHeaderType} spacingBottom="12">
              {r.Q}
              <span>{r.year}</span>
            </Text>
            {r.actions.map((a, j) => (
              <Text
                type={paragraphType}
                color={paragraphColor}
                spacingBottom="16"
                key={j}
              >
                {a}
              </Text>
            ))}
          </div>
        ))}
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="32">
          Core team
        </Text>
        <div className={styles.team}>
          {team.map((t, i) => (
            <div className={styles.teamMember} key={i}>
              <div className={styles.teamAvatar}>
                <img src={t.image} />
              </div>
              <Text type={subheaderType}>{t.name}</Text>
              <Text
                type={paragraphType}
                color="tertiary"
                spacingBottom={isMobileLayout ? '8' : '12'}
              >
                {t.role}
              </Text>
              {t.details.map((a, j) => (
                <Text
                  type={paragraphType}
                  color={paragraphColor}
                  spacingBottom="16"
                  key={j}
                >
                  {a}
                </Text>
              ))}
              {t.linkedin && (
                <a
                  className={styles.teamLink}
                  href={t.linkedin}
                  target="_blank"
                >
                  <Text type={paragraphType} color={paragraphColor}>
                    LinkedIn
                  </Text>
                  <Arrow />
                </a>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Advisors
        </Text>
        <div className={styles.team}>
          {advisors.map((t, i) => (
            <div className={styles.teamMember} key={i}>
              <div className={styles.teamAvatar}>
                <img src={t.image} />
              </div>
              <Text type={subheaderType}>{t.name}</Text>
              <Text
                type={paragraphType}
                color="tertiary"
                spacingBottom={isMobileLayout ? '8' : '12'}
              >
                {t.role}
              </Text>
              {t.linkedin && (
                <a
                  className={styles.teamLink}
                  href={t.linkedin}
                  target="_blank"
                >
                  <Text type={paragraphType} color={paragraphColor}>
                    LinkedIn
                  </Text>
                  <Arrow />
                </a>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="32">
          More about Megaton
        </Text>
        <div className={styles.news}>
          {news.map((t, i) => (
            <a href={t.href} className={styles.newsItem} key={i}>
              <div className={styles.newsImage}>
                <img src={t.image} />
              </div>
              <div className={styles.newsInfo}>
                <div className={styles.newsSource}>
                  <Arrow />
                  <Text type="title-16">{t.source}</Text>
                </div>
                <Text type="paragraph-16" color={paragraphColor}>
                  {t.description}
                </Text>
              </div>
            </a>
          ))}
        </div>
      </div>
    </>
  )
}
