import React from 'react'

import { Button } from '@design-system'
import classNames from 'classnames'
import hiStyles from '../../HiPage.scss'
import styles from './WhatWeDo.scss'

const benefits = [
  {
    title: 'Fundraising',
    icon: 'la',
    items: [
      'Fast track to grants of $50K+',
      'Pre-seed, Seed and Private rounds',
      'Ecosystem investors',
      'Public token sale',
    ],
  },
  {
    title: 'Support',
    icon: 'pls',
    items: [
      'Network: We connect you with the ecosystem',
      'PR and marketing support',
      'Incubation: product, tokenomics, marketing',
      'Partnerships with the key players',
    ],
  },
]

export const WhatWeDo = () => {
  const handleClick = () => {}
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={hiStyles.sectionTitle}>Launch on Tonstarter</div>
        <div className={hiStyles.sectionSubtitle}>
          We introduce projects the TON blockchain, help them to attract funding
          to launch on TON, and provide them with technical solutions for their
          IDOs
        </div>

        <div className={styles.cards}>
          {benefits.map((b, i) => (
            <div className={styles.card} key={i}>
              <div
                className={classNames(styles.cardIcon, {
                  [`${styles[b.icon]}`]: true,
                })}
              />
              <div className={styles.cardTitle}>{b.title}</div>
              <ul>
                {b.items.map((i, y) => (
                  <li key={y}>{i}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className={styles.launch}>
          <div className={styles.launchTitle}>Bring your project to TON</div>
          <div className={styles.launchSubtitle}>
            We will connect you with investors and help your project settle in
            the TON ecosystem
          </div>
          <div className={styles.launchButton}>
            <Button
              as="a"
              href="https://forms.tonstarter.com/build?source=hi"
              target="_blank"
            >
              Submit project
            </Button>
            <a
              className={styles.tgButton}
              href="https://t.me/shestakovmax"
              target="_blank"
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="48" rx="24" fill="#e4e4e4" />
                <g clipPath="url(#clip0_1_2261)">
                  <path
                    d="M24.0044 34.2942C29.6874 34.2942 34.2944 29.6872 34.2944 24.0042C34.2944 18.3212 29.6874 13.7142 24.0044 13.7142C18.3213 13.7142 13.7144 18.3212 13.7144 24.0042C13.7144 29.6872 18.3213 34.2942 24.0044 34.2942Z"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.3666 23.8926C21.3653 22.5903 23.3617 21.725 24.3641 21.3052C27.2172 20.1142 27.8169 19.9086 28.2025 19.9C28.2882 19.9 28.4767 19.9172 28.6052 20.02C28.708 20.1057 28.7337 20.217 28.7508 20.3027C28.768 20.3884 28.7851 20.5683 28.768 20.7054C28.6138 22.3333 27.9455 26.2831 27.6028 28.0995C27.4571 28.8706 27.1744 29.1276 26.9002 29.1533C26.3004 29.2047 25.8463 28.7592 25.2723 28.3822C24.3641 27.791 23.8586 27.4226 22.9761 26.84C21.9565 26.1717 22.6163 25.8033 23.1989 25.2035C23.3531 25.0493 25.9834 22.6503 26.0348 22.4361C26.0434 22.4104 26.0434 22.3076 25.9834 22.2562C25.9234 22.2048 25.8378 22.2219 25.7692 22.2391C25.675 22.2562 24.2356 23.2158 21.4339 25.1093C21.0226 25.392 20.6542 25.5291 20.3201 25.5205C19.9517 25.512 19.2491 25.3149 18.7179 25.1436C18.0753 24.9379 17.5612 24.8265 17.6041 24.4667C17.6298 24.2782 17.8868 24.0897 18.3666 23.8926Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_2261">
                    <rect
                      width="20.5714"
                      height="20.5714"
                      fill="white"
                      transform="translate(13.7144 13.7142)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
