import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import Config from '@config'
import {
  Button,
  getTGUserId,
  getWebAppInitData,
  isWebApp,
  useInterval,
} from '@design-system'
import {
  currentUserSelector,
  fetchAllocation,
  generateTokenForLogin,
  subscribeToProject,
  useAppSelector,
} from '@store'
import { openNewTab } from '@utils'

interface SubscribeButtonProps {
  projectId: number
  allocationId: string
  projectSlug: string
}

export function SubscribeButton({
  projectId,
  allocationId,
  projectSlug,
}: SubscribeButtonProps): JSX.Element {
  const dispatch = useDispatch()
  const user = useAppSelector(currentUserSelector)
  const [isLoading, setIsLoading] = useState(false)
  const [pollingInterval, setPollingInterval] = useState<null | number>(null)
  const [stopPollingInterval, setStopPollingInterval] = useState<any>(null)

  useEffect(() => {
    return () => {
      clearInterval(stopPollingInterval)
      setStopPollingInterval(null)
    }
  }, [])

  useInterval(() => {
    dispatch(
      fetchAllocation({
        projectSlug,
        telegramId: user?.telegramId ?? getTGUserId(),
        address: user?.friendlyAddress,
      })
    )
  }, pollingInterval)

  const startPolling = () => {
    setPollingInterval(2000)

    // Stop polling after 1 min
    const foo = setInterval(() => stopPolling(), 1000 * 60)
    setStopPollingInterval(foo)
  }

  const stopPolling = () => {
    setPollingInterval(null)
    setIsLoading(false)
  }

  const subscribeForWebApp = async (token: string) => {
    const { user } = getWebAppInitData()

    const payload = {
      projectId,
      token,
      username: user.username,
      telegramId: user.id,
      locale: user.language_code,
    }

    startPolling()

    const result = await dispatch(subscribeToProject(payload))

    if (!result) {
      stopPolling()
    }
  }

  const handleNotifyClick = async () => {
    setIsLoading(true)

    const { ok, data: token } = await generateTokenForLogin()

    if (!ok || !token) {
      setIsLoading(false)
      return
    }

    if (isWebApp()) {
      subscribeForWebApp(token)
    } else {
      startPolling()

      openNewTab(
        `https://t.me/${Config.botName}?start=pf_${projectId}_${token}`
      )
    }
  }

  return allocationId ? (
    <Button fullWidth disabled>
      Subscribed
    </Button>
  ) : (
    <Button onClick={handleNotifyClick} fullWidth loading={isLoading}>
      Notify me
    </Button>
  )
}
