import React, { useMemo, useState } from 'react'

import { Text, useMobileLayout, Button } from '@design-system'
import {
  Project,
  User,
  fetchPrivateAllocation,
  privateAllocationSelector,
  useAppDispatch,
  useAppSelector,
  walletTypeSelector,
} from '@store'

import { Follow } from '../../../Project/components/AirdropSidebar/components/Follow/Follow'
import styles from './PrivateSidebar.scss'
import { sendTransaction } from '@services'
import { toast } from 'react-hot-toast'
import { TrxState } from '../../../Project/components/AirdropSidebar/components/LockupView'
import { VestingDetails } from './components/VestingDetails'
import { PrivateData, createAllocation } from '../..'
import { ApplyView } from './components/ApplyView'
import { PaidView } from './components/PaidView'
import { calculateTokenPriceInUsd, divide, multiply, ton2Nano } from '@utils'
import BigNumber from 'bignumber.js'

interface PrivateSidebarProps {
  activeProject: Project
  user: User | null
  data: PrivateData
}

export const PrivateSidebar = ({
  activeProject,
  user,
  data,
}: PrivateSidebarProps): JSX.Element => {
  const walletType = useAppSelector(walletTypeSelector)
  const showErrorMessage = (message: string) => toast.error(message)
  const privateAllocations = useAppSelector(privateAllocationSelector)

  const currentAllocation = privateAllocations?.filter(alloc => (
    alloc.projectSlug === activeProject.slug
  ))

  const dispatch = useAppDispatch()

  async function handlePay(amount: string, setTrxState: React.Dispatch<React.SetStateAction<TrxState>>) {
    if (walletType) {
      setTrxState('loading')

      const totalUsd = BigNumber(amount).times(data.prices.tonPriceInUsd).toNumber()
      const totalTokens = BigNumber(amount).div(data.prices.tokenPriceInTon).toNumber()
      const nanoTONs = ton2Nano(amount)

      const {ok, error} = await sendTransaction({
        address: data.contractAddress,
        amount: nanoTONs,
        isMobileViewport: false,
        walletType: walletType
      })

      if (!ok) {
        showErrorMessage(error as string)
        setTrxState('initial')
        // trx cancelled
      } else {
        setTrxState('finished')
        if (user) {
          await createAllocation(user.id, {
            projectSlug: activeProject.slug,
            status: 'new',
            // ! safe?
            amountInTon: parseInt(amount),
            amountInUsd: totalUsd,
            amountInTokens: totalTokens,
          })

          dispatch(fetchPrivateAllocation())
        }
        // trx sent
      }
    }
  }

  return (
    <div className={styles.container}>
      {/* Flow bochka */}
      <div className={styles.bochka}>
        <h2 className={styles.title}>{activeProject.title}</h2>
        <Text color="tertiary" align="center" spacingBottom="16">
          🔓 Private Sale
        </Text>

        <div className={styles.infoWrapper}>
          {!currentAllocation?.length ? (
            <ApplyView
              activeProject={activeProject}
              onPay={handlePay}
              data={data}
            />
          ) : (
            <PaidView
              activeProject={activeProject}
              data={data}
            />
          )}
          
          <hr className={styles.hr}/>

          <VestingDetails data={data} />
        </div>
        <a
          href="https://t.me/ton_starter_support_bot"
          target="_blank"
          className={styles.supportLink}
        >
          Support chat
        </a>
      </div>

      {/* Follow project bochka */}
      <div className={styles.bochka}>
        <Text color="tertiary" align="center">
          Follow {activeProject.title} project
        </Text>
        <Follow projectDetails={activeProject.projectDetails} />
      </div>
    </div>
  )
}
