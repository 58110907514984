import React from 'react'

import { Page } from '@design-system'
import styles from './legal.scss'

export const TermsOfServicePage = (): JSX.Element => {
  return (
    <Page>
      <Page.Header />
      <Page.Body>
        <div className={styles.root}>
          <p className={styles.title}>TERMS OF USE</p>
          <p className={styles.row}>
            Date of original publication: August, 17, 2022
          </p>
          <p className={styles.row}>
            These Terms of Use ("Terms") including the Privacy Policy and any rules contained on the Website create a legally binding contract between the Visitors, the Users, the Participants, the Projects , and the Ecosystem Operator. In case of any discrepancy between these Terms and any other information displayed in any section of the Website these Terms shall prevail.
          </p>
          <p className={styles.row}>
            Any natural person, legal entity, or any organizational units
            with/without legal personality which by any applicable law are
            granted legal capacity accessing the Website or using any of our
            Services fully or partially may be referred in these Terms as
            "User", "You", "Yours".
          </p>
          <p className={styles.row}>
            "AML" is anti-money laundering procedures which shall be applied
            under the law;
          </p>
          <p className={styles.row}>
            "Company" is any legal entity which displayed information about IDO
            through the Website with prior Tonstarter approval;
          </p>
          <p className={styles.row}>"IDO" is Initial DEX offering;</p>
          <p className={styles.row}>
            "KYC" is Know Your Customer process of identifying and verifying the
            User identity before providing access to participate in IDOs or
            allow to access to the Platform/Services.
          </p>
          <p className={styles.row}>
            "Project" refer to all activities in upcoming IDO event;
          </p>
          <p className={styles.row}>
            "Purchase" is any transaction between User and Company related to
            purchase of Tokens and always carried out outside of the Platform,
            unless stated otherwise;
          </p>
          <p className={styles.row}>
            "Services" refers to any services provided by Tonstarter to Users
            through the Platform, excluding any services provided by Companies
            or any other third parties;
          </p>
          <p className={styles.row}>
            "Telegram", "TG" refers to an account and phone number used for
            access to Telegram Messenger;
          </p>
          <p className={styles.row}>"Token sale" is an IDO of a Project;</p>
          <p className={styles.row}>
            "Token" unless otherwise specified shall refer to utility token
            issued and intended to be used solely within related Projects;
          </p>
          <p className={styles.row}>
            "Wallet" is a digital wallet that stores cryptocurrency;
          </p>
          <p className={styles.row}>
            "Whitepaper" refers to Project and Token description, as well as
            rules of participation in the Token Sale. These terms are always
            determined solely by the Companies, Tonstarter has no control over
            such procedures.
          </p>
          <p className={styles.row}>
            PLEASE READ THESE TERMS CAREFULLY BEFORE ANY USE OF OUR SERVICES OR
            ACCESS OUR PLATFORM AS THEY AFFECT YOUR LEGAL RIGHTS. BY ACCEPTING
            THESE TERMS ELECTRONICALLY (FOR EXAMPLE, CLICKING "I AGREE" OR
            SIMILAR WORDING), ACCESSING OUR PLATFORM, LOGGING WITH YOUR DIGITAL
            WALLET ON THE PLATFORM OR BY ANY OTHER WAY OF INTERACTING WITH OUR
            PLATFORM OR SERVICES YOU AGREE TO BE BOUND BY THESE TERMS AND ALL
            DOCUMENTS OR POLICIES MENTIONED HEREINAFTER. YOU CANNOT ACCEPT THESE
            TERMS IN PART. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS, YOU MUST
            NOT ACCESS OUR PLATFORM OR SERVICES.
          </p>
          <p className={styles.row}>
            We reserve the right to update these Terms at any time and without
            any further warning at our sole discretion. By continuing using our
            Platform or Services (in any way mentioned in these Terms) after
            date of any amendments or updates you confirm acceptance of these
            Terms, as modified by such amendments or updates.
          </p>
          <p className={styles.header}>1. Service Description</p>
          <p className={styles.row}>
            Tonstarter is a launchpad platform which displays information about
            upcoming IDOs on TON blockchain and some information about possible
            ways to participate in the Token sale.
          </p>
          <p className={styles.row}>
            Tonstarter is in no way related to any Project or Company which
            launches any IDO on the Platform, or issuance of any Tokens or IDO
            launch by such Project or Company. Tonstarter also has no control
            over any stage of IDO and does not determine any rules for them.
          </p>
          <p className={styles.row}>
            Tonstarter never participates in any agreements, transactions or
            deals concluded between Users and Companies unless stated otherwise.
          </p>
          <p className={styles.row}>
            Tonstarter does not guarantee safety and profitability of any
            Purchases.
          </p>
          <p className={styles.row}>
            Tonstarter website will only display IDO results, we do not
            participate in any other procedures related to this action and are
            not liable for any results in this case. All claims with regard to
            IDO results shall be addressed to the Company which used the
            Platform to launch the Project.
          </p>
          <p className={styles.row}>
            Tonstarter shall not be liable for any failure to support work and
            availability of any of our Services or Platform and for any loss or
            damage it may cause to Users under any circumstances. 1.
          </p>

          <p className={styles.header}>
            2. Access to our Platform and Services
          </p>
          <p className={styles.row}>
            You may use our Services and have access to our Platform only if you
            can form a binding contract with Tonstarter.
          </p>
          <p className={styles.row}>
            You are not allowed to use our Services or to have access to our
            Platform if you are under 18 years old or under legal age
            established under applicable laws for entering binding contracts.
          </p>
          <p className={styles.row}>
            By accessing our Platform/Services fully or partially, you confirm
            that you fully understand and agree with these provisions:
          </p>
          <ul>
            <li>
              you are acting in your own interest and not in behalf of any other
              legal entity or natural person;
            </li>
            <li>
              you are fully aware of all risks you are taking by using,
              participating or accessing any of our Services;
            </li>
            <li>
              Tonstarter will never be liable for any loss or damage that may be
              caused directly or indirectly due interaction with our
              Platform/Services;
            </li>
            <li>
              assets which will be used to participate in the Projects are not
              associated with any unlawful activities, including but not limited
              to: fraud, crimes, money laundering, etc.;
            </li>
            <li>
              you warrant that you are not located in any jurisdiction that is
              subject to any applicable sanctions/restrictions preventing
              Tonstarter from providing you access to our Services/Platform.
              Your location is determined by your physical location at the point
              of accessing our Services/Platform unless otherwise will be stated
              by Us;
            </li>
            <li>
              you understand and fully agree that Tonstarter does not provide
              any Services related to security and never participate in
              transferring assets;
            </li>
            <li>
              you understand and fully agree that Tonstarter does not provide
              any financial or investment recommendations/advice. No information
              provided through our Website directly or indirectly or through a
              third parties websites/resources which you might reach via our
              Website (e.g., through hyperlinks) shall be considered as any
              advice or recommendation (investment, commercial, etc.);
            </li>
            <li>
              you may be required to pass applicable KYC/AML checks in order to
              receive access to our Services/Platform or to be allowed to
              participate in certain activities provided by Companies or third
              parties. Please bear in mind that successful pass of AML/KYC
              checks does not guarantee that you will be allowed to access any
              services provided by Tonstarter or by Companies or third parties.
              Due to applicable legal requirements, you may be subject to
              additional or repeated KYC/AML checks;
            </li>
            <li>
              you need to have some Toncoins to be able to use certain Services
              provided on the Platform;
            </li>
            <li>
              you need to have an active Telegram account to be able to use
              certain Services provided on the Platform;
            </li>
            <li>
              all transactions which are carried out by Users to participate in
              the Projects are final and there is not technical possibility to
              proceed any refund or exchange due to a nature of blockchain
              mechanism;
            </li>
            <li>
              Tonstarter cannot be considered as party to any contract concluded
              by Users with any third parties (including ones which are
              connected to IDOs). Relationships between Users and Companies or
              any third parties are subject to separate agreements;
            </li>
            <li>
              you cannot address any claims to us. We do not accept any
              responsibility for any qualities of any Tokens. All transactions
              and operations are made by Users at their own risk;
            </li>
            <li>
              you agree that Tonstarter has no obligation to become involved in
              any proceedings (according to any laws and court proceedings as
              well) that might occur between Users and Companies or any third
              parties (including government structures);
            </li>
            <li>
              Tonstarter does not accept any responsibility for availability or
              any qualities of any content, products, services, assets which are
              represented with the Website;
            </li>
            <li>
              you accessing or using Tonstarter Services/Platform on your own
              risk and you are solely liable for any damage or loss which may
              arise through such interaction;
            </li>
            <li>
              Tonstarter shall not be liable for any failure to support work and
              availability of any of its Services or Platform and for any loss
              and damage it may cause;
            </li>
            <li>
              you agree and understand all risks which may occur in process of
              operating on blockchain, and you fully realize that Tonstarter has
              no control of any transactions on blockchain. All transactions are
              carried out at your sole discretion and at your own risk.
            </li>
            <li>
              Tonstarter reserves the right at any time and at our sole
              discretion suspend/cancel/block the access to our
              Platform/Services fully or partially for any User for any time
              period without any prior warning and without any further
              explanation. You hereby acknowledge and agree that Tonstarter in
              this case will not be liable for any loss or damage that may occur
              because of these actions.
            </li>
          </ul>

          <p className={styles.header}>3. Risks</p>
          <p className={styles.row}>
            We do not promise or confirm that participation in any Project or
            Token sale will raise your capital or provide you with any financial
            benefits.
          </p>
          <p className={styles.row}>
            You should be aware that you may lose all of your assets while
            participating in Projects and that Purchase of Tokens cannot be
            considered as an investment. Crypto assets are unstable, are not
            controlled by any institutions and may lose all of their value with
            short period of time.
          </p>
          <p className={styles.row}>
            Prior accessing to any of our Services/Platform you should be aware
            of how blockchain technology and IDOs work.
          </p>
          <p className={styles.row}>
            By using any of our Services or by accessing our Platform you
            confirm that you examined the materials of IDO, that they are full
            and detailed, and that you have received all the necessary
            information to make your sole decision to proceed with a certain
            Purchase.
          </p>
          <p className={styles.row}>
            The fact that certain Project was allowed to be displayed on the
            Platform shall not be considered as proof of its reliability and do
            not impose any obligations on Tonstarter.
          </p>
          <p className={styles.header}>4. Requirements to Start</p>
          <p className={styles.row}>
            To use our Services and have full access to our Platform you should
            authorize your Wallet (Tonkeeper, TON Wallet or any other allowed
            digital wallet) by linking them via the TON Wallet browser extension
            to our Website. We will still have no access to your Wallet data or
            its contents.
          </p>
          <p className={styles.row}>
            When you are accessing our Platform/Services with your Wallet,
            please keep in mind that it happens with third-party services, and
            we cannot guarantee you security and are not liable for any damage
            or loss which might occur through such interaction as well.
          </p>
          <p className={styles.row}>
            By using our Services or accessing to our Platform, you acknowledge
            and agree to the following conditions:
          </p>
          <ul>
            <li>
              You will not provide access to the Wallet which was linked to the
              Platform or any related data (especially, if it might lead to any
              transactions) to any third party;
            </li>
            <li>
              You are fully responsible for all activities that happen on our
              Platform with your Wallet, even if these are carried out by third
              parties. To avoid any additional risks, you shall not use any
              technical options that allow you to save your password/recovery
              phrases or may lead to unauthorized access to your Wallet (for
              example auto filling in browser, saved password, etc.);
            </li>
            <li>
              You are fully responsible for the security of your Wallet which
              was linked to the Platform, even for unauthorized transactions;
            </li>
            <li>
              You are aware that third party services may charge fees and you
              might not be allowed to proceed with any transactions unless you
              are able to cover them (for example, gas/internet fee);
            </li>
            <li>
              Third party services may require authorizing certain amount of
              funds before proceeding any further activity. These actions are in
              no way connected with Tonstarter and are carried out on your own
              risk and at sole discretion;
            </li>
            <li>
              Due to the nature of blockchain technology in case you lose access
              to your Wallet connected with the Website we would not be able to
              transfer your Tokens to another wallet.
            </li>
            <li>
              To use our Services and have full access to our Platform you shall
              authorize your valid Telegram account so we will be able to
              contact you.
            </li>
            <li>
              We reserve the right at any time to request additional documents
              to verify your identity and proceed any additional checking
              procedures.
            </li>
          </ul>
          <p className={styles.header}>5. Tokens</p>
          <p className={styles.row}>
            You hereby acknowledge and agree that Purchase of Tokens does not
            refer to conclusion directly or indirectly of any agreement with
            Tonstarter. Tonstarter have no control over any processes related to
            Token sale/distribution, so we cannot guarantee you any protection
            in this case.
          </p>
          <p className={styles.row}>
            All transactions with regard to Purchase are carried out with use of
            smart contracts based on TON blockchain outside of Tonstarter
            Platform and such processes are in no way connected with Us.
          </p>
          <p className={styles.row}>
            Every IDO has its own requirements and rules for participation which
            you can usually explore through Project website. We will do our best
            to provide you full information about upcoming IDOs, but we will not
            be liable for any inaccuracy, incompleteness, errors in information
            displayed on the Website, so you will use this information at your
            own risk. Tonstarter will never provide you with any warranty
            regarding to value, specification, statues or any other qualities of
            Tokens.
          </p>
          <p className={styles.row}>
            Tokens distributed within Projects are utility tokens as established
            by Projects' Whitepapers. After Token sale ends, Project Tokens
            become available for a claim based on the terms of each individual
            IDO. Each project sets its own lockup or vesting period. Once the
            tokens are claimed, they can be exchanged on suggested decentralized
            exchanges (DEX).
          </p>
          <p className={styles.row}>
            User hereby acknowledges and agrees that Tonstarter has no
            obligation to provide him an opportunity to purchase Tokens. Only
            certain Users will be allowed to Purchase Tokens and they will be
            selected according to IDO rules. Tokens cannot be considered as
            legal tender or cannot be used in any way despite rules specified in
            IDO.
          </p>
          <p className={styles.row}>
            By purchasing Tokens User does not enter into any relationship with
            the Company or receive any rights with regard to any of its assets.
            Purchase of Tokens will only grant User with options specified in
            IDO documents.
          </p>
          <p className={styles.header}>6. Copyright and Licenses</p>
          <p className={styles.row}>
            Unless otherwise stated by Tonstarter all content displayed on the
            Platform including, without limitation: data, software, information,
            the Tonstarter name, logo, trademarks are intellectual property of
            Tonstarter and any use of it, fully or partially, without prior
            written permission of Tonstarter is strictly prohibited.
          </p>
          <p className={styles.row}>
            Except as expressly authorized by us, you agree not to copy, frame,
            modify, scrape, rent, lease, loan, sell, distribute or create
            derivative works based on the Platform or the Service content, in
            whole or in part.
          </p>
          <p className={styles.row}>
            You cannot refer to the Tonstarter (website, data, etc.) in order to
            make false image that you are anyway related to the us.
          </p>
          <p className={styles.row}>
            You grant Tonstarter a perpetual, non-exclusive, worldwide,
            sublicensable, free, transferable, license to use any of the content
            displayed by you on the Platform by all legal means.
          </p>
          <p className={styles.header}>7. Taxes</p>
          <p className={styles.row}>
            Users are solely responsible for paying any taxes that apply to
            their transactions and economic results of such transactions.
            Tonstarter is not required to provide any support to Users with
            regard to taxation or to cover any losses occur from any tax
            implications.
          </p>
          <p className={styles.header}>8. Privacy Policy</p>
          <p className={styles.row}>
            Before getting started you should study our Privacy policy here. If
            you start to use our Platform or Services, you are deemed to have
            accepted it as well.
          </p>
          <p className={styles.header}>9.Third Party Resources</p>
          <p className={styles.row}>
            The Platform may include links to other resources which we have no
            control of. The fact that such information is displayed on the
            Platform does not mean that Tonstarter approves content provided on
            such website or is in any way related to it.
          </p>
          <p className={styles.row}>
            Interaction with such resources is at the User's own risk and we do
            not accept any responsibility for any data, services, content or its
            availability, as well for any damage or loss which may arise during
            interaction with such content.
          </p>
          <p className={styles.row}>
            Tonstarter never owns rights to any content displayed on third party
            websites, unsell otherwise stated.
          </p>
          <p className={styles.header}>10. Termination</p>
          <p className={styles.row}>
            Tonstarter reserves the right at our sole discretion to
            suspend/block/cancel access to our Platform and/or Services for any
            time without any notice or further explanation for/or without any
            reason. You hereby acknowledge and agree that Tonstarter shall have
            no liability for any loss or damages that may occur because of these
            actions.
          </p>

          <p className={styles.header}>11. Disclaimer</p>
          <p className={styles.row}>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICES AND ALL
            INCLUDED CONTENT ARE PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTY
            OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TONSTARTER FURTHER
            DISCLAIMS ANY AND ALL LIABILITY RELATED TO YOUR ACCESS OR USE OF THE
            SERVICES OR ANY RELATED CONTENT. YOU ACKNOWLEDGE AND AGREE THAT ANY
            ACCESS TO OR USE OF OUR SERVICES/PLATFORM IS AT YOUR OWN RISK AND
            YOU WILL BE LIABLE FOR ANY CONSEQUENCES THAT MAY ARRISE OUT OF IT
            AND IN CONNECTION WITH THE PROCESS OF USE OF OUR WEBSITE/SERVICES.
          </p>
          <p className={styles.row}>
            WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO
            RESPONSIBILITY FOR ANY LOSSES, DAMAGES, OR CLAIMS ARISING FROM: (A)
            USER ERROR, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED
            ADDRESSES; (B) SERVER FAILURE OR DATA LOSS; (C) UNAUTHORIZED ACCESS
            OR USE; (D) ANY UNAUTHORIZED THIRD-PARTY ACTIVITIES, INCLUDING
            WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR
            OTHER MEANS OF ATTACK AGAINST THE SERVICE.
          </p>
          <p className={styles.row}>
            We are not responsible for sustained losses due to vulnerability or
            any kind of failure, abnormal behavior of software (e.g., wallet,
            smart contract), blockchains, crypto assets, or any other features.
            We are not responsible for sustained losses due to late reports by
            developers or representatives (or no report at all) of any issues
            with the blockchain supporting Tokens.
          </p>
          <p className={styles.row}>
            You acknowledge that information you store or transfer through our
            Services may become irretrievably lost or corrupted or temporarily
            unavailable due to a variety of causes, including but not limited to
            software failures, protocol changes by third-party providers,
            internet outages, force majeure event or other disasters including
            third party DDOS attacks, scheduled or unscheduled maintenance, or
            other causes either within or outside our control.
          </p>

          <p className={styles.header}>12. Limitation of Liability</p>
          <p className={styles.row}>
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT TONSTARTER WILL
            BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY
            INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE
            DAMAGES ARISING FROM THIS AGREEMENT, THE WEBSITE, PRODUCTS OR THIRD
            PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF
            REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
            LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, AND WHETHER CAUSED
            BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE,
            EVEN IF FORESEEABLE AND EVEN IF TONSTARTER HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE WEBSITE,
            PRODUCTS OR THIRD PARTY SITES AND PRODUCTS ARE AT YOUR OWN
            DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY
            DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA
            RESULTING THEREFROM.
          </p>
          <p className={styles.row}>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO
            EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF THE TONSTARTER
            ARISING OUT OF OR IN ANY WAY RELATED TO THIS AGREEMENT, THE ACCESS
            TO AND USE OF THE WEBSITE, CONTENT, OR ANY PRODUCTS OR SERVICES
            RECEIVED ON THE SITE EXCEED THE GREATER OF US$100.
          </p>

          <p className={styles.header}>13. Indemnification</p>
          <p className={styles.row}>
            By accessing or using our Platform or Services you hereby discharge
            to the maximum extent permitted by applicable law, acquit, and
            otherwise release the indemnified parties, from any and all
            allegations, counts, charges, debts, causes of action, claims and
            losses, relating in any way to the use of, or activities relating to
            the use of the Website, Services, Platform including, but not
            limited to, claims relating to the following: negligence, gross
            negligence, intentional interference with contract or advantageous
            business relationship, defamation, privacy, publicity,
            misrepresentation, any financial loss not due to the fault of the
            Website and Platform, false identities, fraudulent acts by others,
            invasion of privacy, release of personal information, failed
            transactions, purchases or functionality of the Website,
            unavailability of the Website, its functions and/or Services and any
            other technical failure that may result in inaccessibility to the
            Website, the Tonstarter Platform or Services, or any claim based on
            vicarious liability for torts committed by users encountered or
            transacted with through the Website and Services, including, but not
            limited to, fraud, computer hacking, theft or misuse of personal
            information, assault, battery, stalking, cheating, perjury,
            manslaughter, or murder.
          </p>
          <p className={styles.row}>
            The above list is intended to be illustrative only, and not
            exhaustive of the types or categories of claims released by you.
            This release is intended by the parties to be interpreted broadly in
            favor of Tonstarter, and thus any ambiguity shall be interpreted in
            a manner providing release of the broadest claims. This release is
            intended to be a full release of claims, and the parties acknowledge
            the legally binding nature of this provision, and the nature of the
            rights given up in connection therewith.
          </p>
          <p className={styles.header}>14. Governing Law and Venue</p>
          <p className={styles.row}>
            You agree that these Terms and your access to our Platform/Services
            will be governed by the laws of England and Wales, excluding its
            conflicts of law provisions. Any dispute related to these Terms that
            is not subject to binding arbitration, shall be resolved by the
            courts of Abu Dhabi, United Arab Emirates.
          </p>
          <p className={styles.header}>15. Severability</p>
          <p className={styles.row}>
            If any term, clause, or provision of these Terms is held invalid,
            unlawful or unenforceable under any applicable laws, then that term,
            clause, or provision will be severable from these Terms and will not
            affect the validity or enforceability of any remaining part of that
            term, clause, or provision, or any other term, clause, or provision
            of these Terms.
          </p>
        </div>
      </Page.Body>
      <Page.Footer />
    </Page>
  )
}
