import { ClaimState } from '@store'
import { ApplyFormStep } from '../ApplyForm'
import { FUNZEE_TOKEN_SYMBOL } from './ClaimForm'

export const getTipMessage = ({
  step,
  claimState,
  availableToClaimTokens,
}: {
  step: ApplyFormStep
  claimState: ClaimState | null
  availableToClaimTokens: string | number
}): string => {
  if (step === ApplyFormStep.tokenSale) {
    return `You can claim ${availableToClaimTokens} ${FUNZEE_TOKEN_SYMBOL} tokens after the vesting period starts.`
  }

  if (step === ApplyFormStep.vesting) {
    if (claimState?.availableTokens !== '0') {
      return `Claim ${availableToClaimTokens} ${FUNZEE_TOKEN_SYMBOL} tokens now.`
    }

    if (
      claimState?.claimedTokens !== '0' &&
      claimState?.claimedTokens !== claimState?.allTokens
    ) {
      return 'You can claim the rest of your tokens once the vesting period ends. Please check the countdown below.'
    }

    return 'The vesting period begun. Please wait until the date on the button below to claim your tokens.'
  }

  return ''
}
