import classNames from 'classnames'
import React from 'react'

import styles from './MenuItem.scss'
import { MenuItemType } from './types'

export interface MenuItemProps {
  as?: React.ElementType
  className?: string
  prefix?: React.ReactNode
  type?: MenuItemType
  onClick?(): void
  children?: React.ReactNode
  icon?: JSX.Element
  postfix?: JSX.Element
  iconPosition?: 'start' | 'end'
  highlightable?: boolean
  href?: string
  target?: string
}

export function MenuItem({
  as: Component = 'div',
  children,
  postfix,
  className,
  onClick,
  icon,
  type = 'text',
  iconPosition = 'start',
  highlightable = true,
  ...rest
}: MenuItemProps) {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <Component
      className={classNames(
        styles.root,
        styles[`type-${type}`],
        !!icon && styles['with-icon'],
        iconPosition === 'start' && styles.iconAtStart,
        iconPosition === 'end' && styles.iconAtEnd,
        !highlightable && styles.nonHighlightable,
        className
      )}
      onClick={handleClick}
      {...rest}
    >
      {!!icon && iconPosition === 'start' ? icon : null}
      {type === 'text' && <span className={styles.text}>{children}</span>}
      {!!icon && iconPosition === 'end' ? icon : null}
      {postfix ? postfix : null}
    </Component>
  )
}
