import React from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import * as Sentry from "@sentry/react";
import { store } from '@store'
import { App } from './App'
import Config from '@config'
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { TonConnectService } from '@services';

if(Config.isProd) {
  Sentry.init({
    dsn: "https://448e22ad329f4cd18ebdc22d72476765@o4505238869311488.ingest.sentry.io/4505238872653824",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const appContainer = document.getElementById('app')
const appJSX = (
  <Provider store={store}>
    <BrowserRouter>
      <TonConnectUIProvider connector={TonConnectService.getConnector()}>
        <App />
      </TonConnectUIProvider>
    </BrowserRouter>
  </Provider>
)

const root = createRoot(appContainer!)

if (appContainer?.hasChildNodes()) {
  hydrateRoot(appContainer, appJSX)
} else {
  root.render(appJSX)
}
