import React from 'react'

import { Image, Text, useMobileLayout } from '@design-system'
import styles from '../components/About/About.scss'

export const PunkTokenomics = () => {
  const isMobileLayout = useMobileLayout()

  const extraHeaderType = isMobileLayout ? 'header-32' : 'header-44'
  const headerType = isMobileLayout ? 'title-24' : 'title-28'
  const subheaderType = isMobileLayout ? 'title-20' : 'title-24'
  const paragraphType = isMobileLayout ? 'paragraph-16' : 'paragraph-20'
  const paragraphColor = 'secondary'

  return (
    <>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Tokenomics
        </Text>

        <Image
          src="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/punks/tokenomics.png"
          alt="TON Punks tokenomics"
          lightbox={{
            images: [
              {
                alt: 'TON Punks tokenomics',
                src: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/punks/tokenomics.png',
              },
            ],
          }}
        />
      </div>
    </>
  )
}
