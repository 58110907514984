// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gf1Im{display:flex;align-items:center;justify-content:center;min-height:100px;flex-direction:column;padding-top:30px;padding-bottom:24px;user-select:none;position:relative;-webkit-tap-highlight-color:rgba(0,0,0,0)}.gf1Im:active .sDGTt{-webkit-tap-highlight-color:rgba(0,0,0,0);transform:scale(1.2)}.gxnsa{position:relative;height:72px;width:72px;margin-bottom:12px}.sDGTt{margin-bottom:12px;position:absolute;z-index:10;top:0;transition:transform .15s ease-in-out;-webkit-tap-highlight-color:rgba(0,0,0,0)}._3Cjyq{z-index:20;position:absolute;top:0;-webkit-tap-highlight-color:rgba(0,0,0,0)}", "",{"version":3,"sources":["webpack://./src/tonstarter/pages/Project/airdrop/Diamonds/components/Game/components/Diamond/Diamond.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA,CACA,qBAAA,CACA,gBAAA,CACA,mBAAA,CACA,gBAAA,CACA,iBAAA,CACA,yCAAA,CAGE,qBACE,yCAAA,CACA,oBAAA,CAKN,OACE,iBAAA,CACA,WAAA,CACA,UAAA,CACA,kBAAA,CAGF,OACE,kBAAA,CACA,iBAAA,CACA,UAAA,CACA,KAAA,CACA,qCAAA,CACA,yCAAA,CAGF,QACE,UAAA,CACA,iBAAA,CACA,KAAA,CACA,yCAAA","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 100px;\n  flex-direction: column;\n  padding-top: 30px;\n  padding-bottom: 24px;\n  user-select: none;\n  position: relative;\n  -webkit-tap-highlight-color: transparent;\n\n  &:active {\n    .svg {\n      -webkit-tap-highlight-color: transparent;\n      transform: scale(1.2);\n    }\n  }\n}\n\n.inner {\n  position: relative;\n  height: 72px;\n  width: 72px;\n  margin-bottom: 12px;\n}\n\n.svg {\n  margin-bottom: 12px;\n  position: absolute;\n  z-index: 10;\n  top: 0;\n  transition: transform 0.15s ease-in-out;\n  -webkit-tap-highlight-color: transparent;\n}\n\n.lottie {\n  z-index: 20;\n  position: absolute;\n  top: 0;\n  -webkit-tap-highlight-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "gf1Im",
	"svg": "sDGTt",
	"inner": "gxnsa",
	"lottie": "_3Cjyq"
};
export default ___CSS_LOADER_EXPORT___;
