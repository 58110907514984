import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import {
  getTGUserId,
  Page,
  useQueryTabletLandscapeUp,
  useWebApp,
} from '@design-system'
import { AnalyticsService, TokenService } from '@services'
import {
  activeAllocationSelector,
  activeProjectSelector,
  Allocation,
  currentUserSelector,
  fetchAllocation,
  fetchProject,
  fetchProjectSaleInfo,
  useAppDispatch,
  useAppSelector,
} from '@store'
import { PageHeader } from '../../components'
import { ProjectInfo, Sidebar } from './components'
import { Banner } from './components/Banner/Banner'
import { Follow } from './components/Follow/Follow'
import styles from './Project.scss'

export const ProjectPage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const { slug } = useParams()
  const navigate = useNavigate()

  const activeProject = useAppSelector(activeProjectSelector)
  const activeAllocation = useAppSelector(activeAllocationSelector)
  const user = useAppSelector(currentUserSelector)

  const tokenDetails = TokenService.getTokenByProject(slug as string)
  const tokenTicker = tokenDetails.ticker

  useWebApp(({ BackButton }) => {
    BackButton.show()

    BackButton.onClick(() => {
      BackButton.hide()

      navigate('/')
    })
  })

  useEffect(() => {
    if (slug) {
      dispatch(fetchProject({ projectSlug: slug }))
    }
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const isUserExists = user?.id || getTGUserId()

    if (isUserExists && slug) {
      dispatch(
        fetchAllocation({
          projectSlug: slug,
          telegramId: user?.telegramId ?? getTGUserId(),
          address: user?.friendlyAddress,
        })
      )

      AnalyticsService.trackItEasy('page_shown', {
        projectId: slug,
      })
    }
  }, [user])

  useEffect(() => {
    const isUserExists = user?.id || getTGUserId()

    if (isUserExists && slug && activeProject.idoContractAddress) {
      dispatch(
        fetchAllocation({
          projectSlug: slug,
          telegramId: user?.telegramId ?? getTGUserId(),
          address: user?.friendlyAddress,
        })
      )

      dispatch(
        fetchProjectSaleInfo({
          contractAddress: activeProject.idoContractAddress,
        })
      )
    }
  }, [user, activeProject.idoContractAddress])

  const isLoaded = activeProject.id && activeProject.status
  const isTabletLandscapeUpViewport = useQueryTabletLandscapeUp()
  window.addEventListener('scroll', function () {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const opacity = 1 - scrollTop / 50

    if (opacity >= 0 && topSignElem.current) {
      topSignElem.current.style.opacity = `${opacity}`
    }
  })

  const topSignElem = useRef<HTMLDivElement>(null)

  return (
    <Page>
      <Page.Head />
      <PageHeader />
      <Page.Body withHeader>
        <div className={styles.topSign} ref={topSignElem}>
          ton-native launchpad
        </div>
        <div className={styles.root}>
          <div className={styles.topBar}>
            <div className={styles.logoWrapper}>
              {isLoaded && (
                <>
                  <img
                    src={`${activeProject.projectDetails.logo}?ver=1`}
                    alt="Project logo"
                    className={classNames(styles.logo, styles.light)}
                  />
                  <img
                    src={`${activeProject.projectDetails.logoDark}?ver=1`}
                    alt="Project logo"
                    className={classNames(styles.logo, styles.dark)}
                  />
                </>
              )}
            </div>
            {isTabletLandscapeUpViewport && activeProject.projectDetails && (
              <Follow projectDetails={activeProject.projectDetails} />
            )}
          </div>

          <div className={styles.grid}>
            <div className={styles.content}>
              <Banner project={activeProject} />
              <ProjectInfo
                project={activeProject}
                tokenDetails={tokenDetails}
              />
            </div>

            <div className={styles.aside}>
              <Sidebar
                activeAllocation={activeAllocation as Allocation}
                activeProject={activeProject}
                tokenTicker={tokenTicker}
              />
            </div>
          </div>
        </div>
      </Page.Body>
      <Page.Footer />
    </Page>
  )
}
