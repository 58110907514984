import { createAsyncThunk } from '@reduxjs/toolkit'

import { fetchProjectVoteApi } from './projectApi'
import { Project } from './types'

export const fetchProjectsVotes = createAsyncThunk<Project[], string[]>(
  'projects/fetchProjectSaleInfo',
  async (addresses, { rejectWithValue }) => {
    return []
  }
)

export const fetchProjectVote = createAsyncThunk<
  { [key: string]: any },
  string
>('projects/fetchProjectVote', async (contractAddress) => {
  const { data } = await fetchProjectVoteApi({ contractAddress })

  return {
    contractAddress,
    votes: data ? data.votes : 'n/a',
  }
})
