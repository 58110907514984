import { Header } from '@design-system'
import {
  disconnectWallet,
  getWalletInfo,
  openConnectWalletModal,
  signOut,
  themeSelector,
  toggleTheme,
  useAppDispatch,
  useAppSelector,
  walletAddressSelector,
  walletBalanceSelector,
} from '@store'
import React, { useEffect } from 'react'
import { Logo } from '../Logo/Logo'
import styles from './PageHeader.scss'
import { useTonConnectUI } from '@tonconnect/ui-react'

const links = [
  {
    icon: null,
    title: "Let's build",
    href: 'https://launch.tonstarter.com/?utm_source=TonstarterHeader',
    external: true,
  },
  {
    icon: null,
    title: 'Projects',
    href: '/#projects',
  },
  {
    icon: null,
    title: 'Documentation',
    href: 'https://docs.tonstarter.com',
    external: true,
  },
]

export const PageHeader = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const theme = useAppSelector(themeSelector)
  const walletBalance = useAppSelector(walletBalanceSelector)
  const walletAddress = useAppSelector(walletAddressSelector)
  const [tonConnectUI] = useTonConnectUI()

  useEffect(() => {
    if (walletAddress) {
      dispatch(getWalletInfo(walletAddress))
    }
  }, [walletAddress])

  const handleThemeToggle = () => {
    dispatch(toggleTheme())
  }

  const handleConnectWallet = () => {
    tonConnectUI.connectWallet()
  }

  const handleDisconnectWallet = () => {
    tonConnectUI.disconnect()
    dispatch(disconnectWallet())
    dispatch(signOut())
  }

  return (
    <Header
      twitter="https://twitter.com/ton_starter"
      telegram="https://t.me/ton_starter"
      theme={theme}
      logo={<Logo />}
      links={links}
      walletBalance={walletBalance}
      walletAddress={walletAddress}
      onThemeToggle={handleThemeToggle}
      onConnectWallet={handleConnectWallet}
      onDisconnectWallet={handleDisconnectWallet}
      className={styles.headerCustom}
      classNameContainer={styles.headerCustomContainer}
    />
  )
}
