import React from 'react'

import {
  Button,
  Content,
  Icon,
  Image,
  Link,
  Member,
  Text,
  useMobileLayout,
} from '@design-system'
import { openNewTab } from '@utils'
import { Project } from '../store'

export const data: Project = {
  id: 'megaton',
  name: 'Megaton Finance',
  slug: 'megaton',
  iconUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/megaton/logo.svg',
  logoUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/megaton/logo.svg',
  logoDarkUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/megaton/logo.svg',
  urlTitle: 'megaton.fi',
  urlLink: 'https://megaton.fi',
  expertRatingLevel: '4',
  expertRatingIndex: 59,
  expertRatingMarker: 'B',
  communityRating: '',
  contractAddress: 'EQB261Q-_gHsjU6ZtACQqU2xJ7s_7K73cXaLvKoYXsQRFnG-',
  contractAddressTestnet: 'EQB261Q-_gHsjU6ZtACQqU2xJ7s_7K73cXaLvKoYXsQRFnG-',
  fundamentalReportUrl:
    'https://tonstarter.notion.site/Megaton-Finance-Fundamental-d7bc32ec56734db1bfd4247f94811841',
  technicalReportUrl:
    'https://tonstarter.notion.site/Megaton-Finance-Technical-ab2e6cb387d64f248a8d1adbaafe820d',
  category: 'defi',
  updatedAt: '16 Apr 2023',
  reports: [
    {
      reportType: 'fundamental',
      updatedAt: '05 Apr 2023',
      totalRate: '75%',
      values: {
        valueProposition: '86%',
        tokenomics: '65%',
        team: '75%',
        governance: 'n/a',
        regulatory: 'n/a',
      },

      reviewers: [
        {
          title: 'Shreshtha Kumar',
          link: 'https://twitter.com/Web3writes',
          subtitle: 'Report',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/ShreshthaKumar.jpeg',
        },
        {
          title: 'Sergey Ch',
          link: 'https://twitter.com/serega_ton',
          subtitle: 'Review',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/sergey.png',
        },
      ],
    },
    {
      reportType: 'technical',
      updatedAt: '16 Apr 2023',
      totalRate: '46%',
      values: {
        codeAndTeam: '70%',
        docs: '100%',
        testing: '0%',
        security: '35%',
        accessControls: '50%',
      },

      reviewers: [
        {
          title: 'Criptic Hood',
          link: 'https://linktr.ee/CripticHood',
          subtitle: 'Report',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/criptichood.jpeg',
        },
        {
          title: 'Gregory, TEC Team',
          link: 'https://t.me/TheOpenNetworkExpertClub',
          subtitle: 'Review',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/gregory.jpeg',
        },
      ],
    },
  ],
}

export const quote = {
  author: 'Shreshtha Kumar',
  authorAvatarUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/ShreshthaKumar.jpeg',
  text: 'Megaton Finance is the first autonomous financial protocol on the TON network that provides yield farming opportunities, such as swaps and pair deposits. Users can now use TON (Toncoin), which was previously unavailable on the TON Network, in Megaton Finance. Megaton Finance has a structure that combines the AMM (Automated Market Maker) DEX business model prevalent in the DeFi ecosystem, the scalable multi-chain economy, and the unique characteristics of the TON network.',
}

export function aboutProject(): JSX.Element {
  const isMobileViewport = useMobileLayout()

  const headerType = isMobileViewport ? 'title-24' : 'title-28'
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'
  const textSpacingBottom = isMobileViewport ? '32' : '40'

  return (
    <>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Megaton Finance is a decentralized exchange (DEX) on the TON network
        that provides yield farming opportunities such as swaps and pair
        deposits. It is the first autonomous financial protocol on the TON
        network, audited by CertiK, and the only DEX on TON to receive this
        certification. Megaton Finance is owned by Ozys, a prominent blockchain
        company in South Korea, with a team that has a lot of market experience.
      </Text>

      <Text type={headerType} spacingBottom="24">
        Problem & Solution
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Megaton Finance solves the problem of inefficient and limited
        cryptocurrency trading on the TON network. Their innovative automated
        market maker (AMM) mechanism allows for free trading through liquidity
        pools created by providers. Additionally, Megaton Finance is adding
        tokens from other chains to TON, thereby giving users more investment
        options.
      </Text>

      <Text type={headerType} spacingBottom="24">
        Roadmap
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Megaton Finance plan to continue to enhance their platform by adding
        more tokens to their Swap section, and also by integrating with other
        blockchain networks through bridges. They are also working on developing
        a decentralized lending platform and a governance system for their
        platform.
      </Text>

      <Text type={headerType} spacingBottom="24">
        Team
      </Text>
      <Content
        spacingChild={isMobileViewport ? '40' : '240'}
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Jake Li"
          role="Chairman"
          avatar="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/jake.jpg"
        />

        <Member
          name="Roi Choi"
          role="CEO"
          avatar="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/roi.jpg"
        />
      </Content>
      <Content
        spacingChild={isMobileViewport ? '40' : '240'}
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Gisung Choi"
          role="CFO"
          avatar="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/gisung.jpg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="outer-link" colorFill="primary" />}
              onClick={() =>
                openNewTab('https://www.linkedin.com/in/gi-sung-choi-a0a39aa1/')
              }
            >
              LinkedIn
            </Button>
          }
        />

        <Member
          name="Jongsic Choi"
          role="CTO"
          avatar="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/jongsic.jpg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="outer-link" colorFill="primary" />}
              onClick={() => openNewTab('https://www.linkedin.com/in/jongsic/')}
            >
              LinkedIn
            </Button>
          }
        />
      </Content>
      <Content
        spacingChild={isMobileViewport ? '40' : '240'}
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Kyungsoo Ra"
          role="CCO"
          avatar="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/kyungsoo.jpg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="outer-link" colorFill="primary" />}
              onClick={() =>
                openNewTab('https://www.linkedin.com/in/kyungsoora/')
              }
            >
              LinkedIn
            </Button>
          }
        />
      </Content>

      <Text type={headerType} spacingBottom="8">
        Links
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        <ul>
          <li>
            <Link
              href="https://t.me/MegatonFinanceChannel"
              target="_blank"
              textType={textType}
            >
              Telegram channel
            </Link>
          </li>
          <li>
            <Link
              href="https://t.me/MegatonFinanceOfficial"
              target="_blank"
              textType={textType}
            >
              Telegram chat
            </Link>
          </li>
          <li>
            <Link
              href="https://twitter.com/Megaton_Fi"
              target="_blank"
              textType={textType}
            >
              Twitter
            </Link>
          </li>
          <li>
            <Link
              href="https://discord.com/invite/megatonfinance"
              target="_blank"
              textType={textType}
            >
              Discord
            </Link>
          </li>
        </ul>
      </Text>
    </>
  )
}

export function tokenDetails(): JSX.Element {
  const isMobileViewport = useMobileLayout()
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'
  const headerType = isMobileViewport ? 'title-24' : 'title-28'

  return (
    <>
      <Content spacingBottom="24">
        <Image
          src="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/tokenomics.png"
          alt="Megaton tokenomics"
          borderRadius="16px"
          lightbox={{
            images: [
              {
                alt: 'Megaton tokenomics',
                src: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/tokenomics.png',
              },
            ],
          }}
        />
      </Content>

      <Text type={headerType} spacingBottom="24">
        Utility
      </Text>
      <Text type={textType} color="secondary">
        MEGA can be distributed through ecosystem contributions such as pool
        deposits and staking, or acquired through transactions, and can be used
        as follows.
      </Text>
      <ol>
        <li>
          Users can receive inflation reward by using MEGA for pair deposit
          services.
        </li>
        <li>
          Users can participate in MEGA staking and receive MEGA inflation
          reward (TBD).
        </li>
        <li>
          MEGA can be used for governance voting on the protocol’s agenda (TBD).
        </li>
      </ol>
    </>
  )
}
