import React from 'react'

import styles from './Advisors.scss'

export const Advisors = () => {
  const badgeType = 'finished'

  return (
    <div className={styles.root}>
      <div className={styles.header}>our advisors</div>

      <div className={styles.advisors}>
        <div className={styles.advisor}>
          <div className={styles.avatar}>
            <img
              src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/tonstarter/assets/tal-kol.png"
              alt="Tal Kol"
            />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Tal Kol</div>
            <div className={styles.description}>
              Co-Founder at Orbs, investors of Toncoin.Fund, Ex-Wix.com
            </div>
          </div>
        </div>

        <div className={styles.advisor}>
          <div className={styles.avatar}>
            <img
              src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/tonstarter/assets/justin-hyun.png"
              alt="Justin Hyun"
            />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Justin Hyun</div>
            <div className={styles.description}>
              Head of Incubation at TON Foundation, Ex-BlockFi, Ex-AlixPartners
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
