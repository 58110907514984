import React from 'react'

import {
  Button,
  Content,
  Icon,
  Link,
  Member,
  Text,
  useMobileLayout,
} from '@design-system'
import { openNewTab } from '@utils'
import { Project } from '../store'

export const data: Project = {
  id: 'tonstarter',
  name: 'Tonstarter',
  slug: 'tonstarter',
  iconUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonstarter/tonstarter-icon.png',
  logoUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonstarter/tonstarter-logo.png',
  logoDarkUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonstarter/tonstarter-logo.png',
  urlTitle: 'tonstarter.com',
  urlLink: 'https://tonstarter.com',

  expertRatingLevel: '2',
  expertRatingIndex: 79,
  expertRatingMarker: 'A',
  communityRating: '',
  contractAddress: 'EQBb2Anuu_mCqcI_N6jrwQx1FG-KH6l1t3WvIFvgJdPzd1pz',
  contractAddressTestnet: 'EQBb2Anuu_mCqcI_N6jrwQx1FG-KH6l1t3WvIFvgJdPzd1pz',
  reportUrl:
    'https://tonstarter.notion.site/Tonstarter-cb2ac8a212e842ceb6e008c34fbcf2a7',
  technicalReportUrl: '',
  category: 'funding',
  updatedAt: '13 Jun 2023',
  reports: [
    {
      reportType: 'reportV2',
      updatedAt: '13 Jun 2023',
      totalRate: '91%',
      values: {
        valueProposition: '93%',
        tokenomics: 'n/a',
        team: '95%',
        regulatory: '84%',
        community: '84%',
        tech: '41%',
      },

      reviewers: [
        {
          title: 'Gregory, TEC Team',
          subtitle: 'Report',
          link: 'https://t.me/rpaHbco3HAHNR',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/gregory.jpeg',
        },
        {
          title: 'Criptic Hood',
          subtitle: 'Review',
          link: 'https://linktr.ee/CripticHood',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/criptichood.jpeg',
        },
      ],
    },
  ],
}

export const quote = {
  type: 'gpt',
  text: 'Tonstarter is a promising project that provides great opportunities for beginner crypto projects. Due to the integration into Telegram, Tonstarter has access to a very large audience, which means that it is potentially possible to attract large investments in crypto projects. The project has just started launching projects and has already achieved results',
}

export function aboutProject(): JSX.Element {
  const isMobileViewport = useMobileLayout()

  const headerType = isMobileViewport ? 'title-24' : 'title-28'
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'
  const textSpacingBottom = isMobileViewport ? '32' : '40'

  return (
    <>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        <Link
          textType={textType}
          href="https://tonstarter.com"
          target="_blank"
          animatedUnderline={false}
        >
          Tonstarter
        </Link>{' '}
        is a decentralized launchpad that serves as a platform for crypto
        startups and investors in the Ton ecosystem. It facilitates the
        fundraising process and user acquisition for Ton blockchain projects.
        With its emphasis on transparency, security, and integration with
        Telegram, Tonstarter provides a reliable and user-friendly solution for
        project teams and investors.
      </Text>

      <Text type={headerType} spacingBottom="12">
        Problem and Solution
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Tonstarter addresses the challenges faced by Ton ecosystem startups when
        it comes to securing funding and attracting users. By offering a
        decentralized launchpad, Tonstarter bridges the gap between project
        teams and investors, enabling them to connect in a transparent and
        secure environment. The integration with Telegram enhances accessibility
        and participation, providing a solution that meets the needs of Ton
        startups.
      </Text>

      <Text type={headerType} spacingBottom="12">
        Roadmap
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Tonstarter has made significant progress in its roadmap, including
        successfully closing a $1.5-million investment seed round. The launch of
        projects with impressive profits and the introduction of the community
        incentives program, Megaton, demonstrate Tonstarter's commitment to
        driving growth and innovation in the crypto industry. These achievements
        position Tonstarter as a dynamic and forward-thinking platform within
        the Ton ecosystem.
      </Text>

      <Text type={headerType} spacingBottom="12">
        Investments
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Tonstarter has attracted notable investments and formed strategic
        partnerships with entities such as First Stage Labs and Huobi. These
        investments reflect the recognition of Tonstarter's potential and its
        ability to provide value to the crypto industry. The collaboration with
        reputable organizations and investors further strengthens Tonstarter's
        position as a trusted platform for fundraising and investment in the Ton
        ecosystem.
      </Text>

      <Text type={headerType} spacingBottom="24">
        Contacts
      </Text>

      <Content
        spacingChild={isMobileViewport ? '40' : '240'}
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Alex Plotvinov"
          role="CEO"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonstarter/alex.jpeg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="outer-link" colorFill="primary" />}
              onClick={() =>
                openNewTab('https://www.linkedin.com/in/alexplotvinov/')
              }
            >
              LinkedIn
            </Button>
          }
        />
        <Member
          name="Valeriy Bukharkov"
          role="Head of Business Development"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonstarter/valeriy.jpeg"
          button={
            <>
              <Button
                variant="secondary"
                icon={<Icon name="telegram" colorFill="primary" />}
                onClick={() => openNewTab('https://t.me/valeriy_bv')}
              >
                Telegram
              </Button>
            </>
          }
        />
      </Content>
      <Content spacingChild="240" spacingBottom="48">
        <Member
          name="Vitalii Nikishov"
          role="Community Manager"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonstarter/noah.jpeg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() => openNewTab('https://t.me/metaverserk')}
            >
              Telegram
            </Button>
          }
        />
      </Content>
    </>
  )
}

export function tokenDetails(): JSX.Element {
  const isMobileViewport = useMobileLayout()
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'

  return (
    <Text type={textType} color="secondary">
      Tonstarter's token has not been released yet, although it is planned to be
      released in the future.
    </Text>
  )
}
