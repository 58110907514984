import React from 'react'

import { useCountdown } from '@design-system'
import styles from './Countdown.scss'

export const Countdown = ({
  title,
  final,
  className,
}: {
  title?: string
  final: string
  className?: string
}): JSX.Element | null => {
  const { days, hours, minutes, seconds } = useCountdown(final)

  const showMustGoOn =
    days + hours + minutes + seconds === '00000000' ? false : true

  if (!showMustGoOn) {
    return (
      <div className={className}>
        {/* <div className={styles.fallbackTitle}>{title} seconds</div> */}
        <div className={styles.fallbackTitle}>{title}</div>
      </div>
    )
  }

  return (
    <div className={className}>
      <div className={styles.title}>{title}</div>
      <div className={styles.items}>
        <div className={styles.itemWrap}>
          <div className={styles.item}>{days}</div>
          <div className={styles.dots} />
          <div className={styles.period}>days</div>
        </div>
        <div className={styles.itemWrap}>
          <div className={styles.item}>{hours}</div>
          <div className={styles.dots} />
          <div className={styles.period}>hours</div>
        </div>
        <div className={styles.itemWrap}>
          <div className={styles.item}>{minutes}</div>
          <div className={styles.dots} />
          <div className={styles.period}>min</div>
        </div>
        <div className={styles.itemWrap}>
          <div className={styles.item}>{seconds}</div>
          <div className={styles.period}>sec</div>
        </div>
      </div>
    </div>
  )
}
