import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { ConnectWalletButton } from '@components'
import { isWebApp, useQueryTabletLandscapeUp } from '@design-system'
import { useAppDispatch, useAppSelector } from '@store'
import { themeSelector, toggleTheme } from '@store/app/appSlice'
import { isAuthSelector } from '@store/auth'
import { currentUserSelector, fetchMe } from '@store/user'
import styles from './Header.scss'
import { HeaderNote } from './HeaderNote'
import Logo from './Logo'

const navMenu: HeaderMenuItem[] = [
  {
    title: "Let's build",
    link: 'https://launch.tonstarter.com?utm_source=TonstarterHeader',
    external: true,
  },
  { title: 'Projects', link: '/#projects' },
  // { title: 'Applications', link: '/applications', protected: true },
  {
    title: 'Documentation',
    link: 'https://docs.tonstarter.com',
    external: true,
  },
  { title: 'Blog', link: 'https://blog.tonstarter.com', external: true },
]

type HeaderMenuItem = {
  title: string
  link: string
  external?: boolean
  protected?: boolean
}

interface HeaderProps {
  mode?: 'light' | 'normal'
  action?: React.ReactNode
  onlyDarkMode?: boolean
}

export const Header = ({
  mode = 'normal',
  action,
  onlyDarkMode,
}: HeaderProps) => {
  if (mode === 'light') {
    return (
      <div className={styles.root}>
        <div className={styles.container}>
          <a href="/" className={styles.logo}>
            <Logo className={styles.logoIcon} />
            <span>Tonstarter</span>
          </a>

          <div className={styles.actionLight}>{action}</div>
          <ToggleTheme />
        </div>
      </div>
    )
  }

  return <HeaderNormal onlyDarkMode={onlyDarkMode} />
}

const HeaderNormal = ({ onlyDarkMode }: { onlyDarkMode?: boolean }) => {
  const [isMenuOpened, setMenuOpened] = useState<Boolean>(false)
  const isAuth = useSelector(isAuthSelector)

  const currentUser = useSelector(currentUserSelector)
  const dispatch = useAppDispatch()
  const theme = useAppSelector(themeSelector)
  const isTabletLandscapeUpViewport = useQueryTabletLandscapeUp()

  const toggleMenu = () => {
    if (isMenuOpened) {
      setMenuOpened(false)
    } else {
      setMenuOpened(true)
    }
  }

  const menuToRender = navMenu.filter((item) => {
    if (item.protected && !isAuth) {
      return false
    }

    return true
  })

  useEffect(() => {
    dispatch(fetchMe())
  }, [isAuth])

  const isEarlyAdopter = currentUser?.isApprovedEarlyAdopter
  const isWebapp = isWebApp()

  return (
    <div className={classNames(styles.root, isMenuOpened && styles.index102)}>
      <div className={styles.container}>
        <a href="/" className={styles.logo}>
          {/* <img src={logo} /> */}
          <Logo className={styles.logoIcon} />
          {/* <div className={styles.winterLogo}></div> */}
          <span>Tonstarter</span>
        </a>

        {!isTabletLandscapeUpViewport && (
          <div className={styles.buttonWrap}>
            <ConnectWalletButton size="tiny" />
          </div>
        )}

        {!isWebapp ? (
          <div className={styles.menuIcon} onClick={toggleMenu}>
            <svg
              width="24"
              height="18"
              viewBox="0 0 24 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="24" height="3" rx="1.5" fill="black" />
              <rect y="7.5" width="24" height="3" rx="1.5" fill="black" />
              <rect y="14.5" width="24" height="3" rx="1.5" fill="black" />
            </svg>
          </div>
        ) : null}

        {isWebapp && <ToggleTheme onlyDarkMode={onlyDarkMode} />}

        {!isWebapp ? (
          <div
            className={classNames(
              styles.menu,
              `${isMenuOpened ? styles.menuOpened : ''}`
            )}
          >
            <div className={styles.menuContainer}>
              {!isTabletLandscapeUpViewport && (
                <div className={styles.closeMenuIcon} onClick={toggleMenu}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.2394 6.34262L6.33992 16.2421C5.94939 16.6326 5.94939 17.2658 6.33992 17.6563C6.73044 18.0468 7.36361 18.0468 7.75413 17.6563L17.6536 7.75683C18.0442 7.36631 18.0442 6.73314 17.6536 6.34262C17.2631 5.95209 16.6299 5.95209 16.2394 6.34262Z"
                      fill="black"
                    />
                    <path
                      d="M17.6535 16.2423L7.75398 6.34285C7.36346 5.95232 6.73029 5.95232 6.33977 6.34285C5.94924 6.73337 5.94924 7.36654 6.33977 7.75706L16.2393 17.6566C16.6298 18.0471 17.263 18.0471 17.6535 17.6566C18.044 17.266 18.044 16.6329 17.6535 16.2423Z"
                      fill="black"
                    />
                  </svg>
                </div>
              )}
              {!isTabletLandscapeUpViewport && (
                <div className={styles.logo}>
                  {/* <img src={logo} /> */}
                  <Logo className={styles.logoIcon} />
                  <span>Tonstarter</span>
                </div>
              )}
              <div className={styles.navigation}>
                {menuToRender.map((item) =>
                  item.external ? (
                    <a
                      href={item.link}
                      target="_blank"
                      key={item.title}
                      onClick={toggleMenu}
                    >
                      {item.title}
                    </a>
                  ) : (
                    <NavLink
                      key={item.title}
                      to={item.link}
                      onClick={toggleMenu}
                    >
                      {item.title}
                    </NavLink>
                  )
                )}
                {!isWebapp && !isTabletLandscapeUpViewport && !onlyDarkMode && (
                  <div className={styles.toggleThemeWrap}>
                    <ToggleTheme onlyDarkMode={onlyDarkMode} />
                    <span onClick={() => dispatch(toggleTheme())}>
                      {theme === 'dark' ? 'Light' : 'Dark'} theme
                    </span>
                  </div>
                )}
              </div>
              {isEarlyAdopter && (
                <div className={styles.headerNoteWrapper}>
                  <HeaderNote type="earlyAdopter" />
                </div>
              )}
              {isTabletLandscapeUpViewport && (
                <div className={styles.action}>
                  <ConnectWalletButton
                    mobileMenu={!isTabletLandscapeUpViewport}
                    fullWidth={!isTabletLandscapeUpViewport}
                    handleOpenModal={() => {
                      setTimeout(() => {
                        setMenuOpened(false)
                      }, 500)
                    }}
                  />
                </div>
              )}
              {isTabletLandscapeUpViewport && (
                <ToggleTheme onlyDarkMode={onlyDarkMode} />
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

const ToggleTheme = ({ onlyDarkMode }: { onlyDarkMode?: boolean }) => {
  const dispatch = useAppDispatch()
  const theme = useSelector(themeSelector)
  const handleChangeTheme = () => dispatch(toggleTheme())

  if (onlyDarkMode) {
    return null
  }

  return (
    <div className={styles.themeToggle} onClick={handleChangeTheme}>
      {theme === 'light' && (
        <svg width="32px" height="32px" viewBox="0 0 32 32">
          <path d="M13.5025,5.4136A15.0755,15.0755,0,0,0,25.096,23.6082a11.1134,11.1134,0,0,1-7.9749,3.3893c-.1385,0-.2782.0051-.4178,0A11.0944,11.0944,0,0,1,13.5025,5.4136M14.98,3a1.0024,1.0024,0,0,0-.1746.0156A13.0959,13.0959,0,0,0,16.63,28.9973c.1641.006.3282,0,.4909,0a13.0724,13.0724,0,0,0,10.702-5.5556,1.0094,1.0094,0,0,0-.7833-1.5644A13.08,13.08,0,0,1,15.8892,4.38,1.0149,1.0149,0,0,0,14.98,3Z" />
        </svg>
      )}
      {theme === 'dark' && (
        <svg
          width="36px"
          height="36px"
          viewBox="0 0 36 36"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Lager_93" data-name="Lager 93" transform="translate(2 2)">
            <g id="Sun_3_Brightness_3" data-name="Sun 3, Brightness 3">
              <path
                id="Path_68"
                data-name="Path 68"
                d="M32,14H27.033c-2,1.769-.779,4,.967,4h4.967C34.966,16.231,33.746,14,32,14Z"
                fill="#040505"
              />
              <g
                id="Path_69"
                data-name="Path 69"
                fill="none"
                strokeMiterlimit="10"
              >
                <path
                  d="M17.172,10.111a6,6,0,1,0,4.715,4.715A6.01,6.01,0,0,0,17.172,10.111Z"
                  stroke="none"
                />
                <path
                  d="M 15.99852275848389 13.99979972839355 C 15.40029907226563 13.99979972839355 14.83786392211914 14.26465797424316 14.45541763305664 14.72645950317383 C 14.18128776550293 15.05748176574707 13.88667678833008 15.62165832519531 14.04035758972168 16.43178939819336 C 14.1787109375 17.16349411010742 14.83581733703613 17.82003402709961 15.56771087646484 17.958740234375 C 15.71307563781738 17.98624801635742 15.85765266418457 18.00020027160645 15.99740505218506 18.00020027160645 C 16.59555816650391 18.00020027160645 17.15798187255859 17.73542404174805 17.54046440124512 17.27376556396484 C 17.81481742858887 16.94261169433594 18.1097583770752 16.37818908691406 17.95689964294434 15.57052993774414 C 17.81802749633789 14.83748245239258 17.1605224609375 14.17996406555176 16.42829895019531 14.041259765625 C 16.28293609619141 14.01375389099121 16.13835906982422 13.99979972839355 15.99860572814941 13.99979972839355 L 15.99852275848389 13.99979972839355 M 15.99860000610352 9.999795913696289 C 16.38235282897949 9.999801635742188 16.77459716796875 10.03580474853516 17.17200469970703 10.11100006103516 C 19.52100563049316 10.55599975585938 21.44199371337891 12.47699928283691 21.88699340820313 14.82600021362305 C 22.61180877685547 18.65568542480469 19.69624137878418 22.00020408630371 15.99740028381348 22.00020408630371 C 15.61366271972656 22.00020408630371 15.22141265869141 21.96419525146484 14.82400512695313 21.88899993896484 C 12.47600555419922 21.44400024414063 10.55400466918945 19.52299880981445 10.11000442504883 17.17499923706055 C 9.383377075195313 13.34440803527832 12.29961967468262 9.999755859375 15.99860000610352 9.999795913696289 Z"
                  stroke="none"
                  fill="#040505"
                />
              </g>
              <rect
                id="Rectangle_26"
                data-name="Rectangle 26"
                width="8"
                height="4"
                rx="1.993"
                transform="translate(26 14)"
                fill="#040505"
              />
              <rect
                id="Rectangle_27"
                data-name="Rectangle 27"
                width="8"
                height="4"
                rx="1.993"
                transform="translate(18 26) rotate(90)"
                fill="#040505"
              />
              <rect
                id="Rectangle_28"
                data-name="Rectangle 28"
                width="8"
                height="4"
                rx="1.993"
                transform="translate(18 -2) rotate(90)"
                fill="#040505"
              />
              <rect
                id="Rectangle_29"
                data-name="Rectangle 29"
                width="8"
                height="4"
                rx="1.993"
                transform="translate(-2 14)"
                fill="#040505"
              />
              <g id="Group_22" data-name="Group 22">
                <rect
                  id="Rectangle_30"
                  data-name="Rectangle 30"
                  width="6.925"
                  height="3.766"
                  rx="1.883"
                  transform="translate(23.22 6.117) rotate(-45)"
                  fill="#040505"
                />
              </g>
              <g id="Group_23" data-name="Group 23">
                <rect
                  id="Rectangle_31"
                  data-name="Rectangle 31"
                  width="3.766"
                  height="6.925"
                  rx="1.883"
                  transform="matrix(0.707, -0.707, 0.707, 0.707, 23.22, 25.883)"
                  fill="#040505"
                />
              </g>
              <g id="Group_24" data-name="Group 24">
                <rect
                  id="Rectangle_32"
                  data-name="Rectangle 32"
                  width="3.766"
                  height="6.925"
                  rx="1.883"
                  transform="translate(1.22 3.883) rotate(-45)"
                  fill="#040505"
                />
              </g>
              <g id="Group_25" data-name="Group 25">
                <rect
                  id="Rectangle_33"
                  data-name="Rectangle 33"
                  width="6.925"
                  height="3.766"
                  rx="1.883"
                  transform="translate(1.22 28.117) rotate(-45)"
                  fill="#040505"
                />
              </g>
            </g>
          </g>
        </svg>
      )}
    </div>
  )
}
