import classNames from 'classnames'
import React from 'react'

import { Icon } from '@design-system'
import styles from './InfoTip.scss'

interface InfoTipProps {
  icon?: string
  theme: 'info' | 'warn'
  message: string
}

/**
 * @deprecated use {@link src/pages/Project/components/Sidebar/components/Tip/Tip.tsx <Tip />}
 */
export const InfoTip = ({
  icon,
  theme = 'info',
  message,
}: InfoTipProps): JSX.Element => {
  const whichIcon = () => {
    if (icon && icon.length === 2) {
      return <div className={styles.emoji}>{icon}</div>
    } else {
      return <Icon name="infoFilledBlue" />
    }
  }

  return (
    <div className={classNames(styles.root, styles[theme])}>
      <div className={styles.iconContainer}>{whichIcon()}</div>
      <span>{message}</span>
    </div>
  )
}
