import React from 'react'
import { TwitterTweetEmbed } from 'react-twitter-embed'

import { useAppSelector } from '@store'
import { themeSelector } from '@store/app/appSlice'

import hiStyles from '../../HiPage.scss'
import styles from './TonFuture.scss'

export const TonFuture = () => {
  const theme = useAppSelector(themeSelector)

  return (
    <div className={styles.root}>
      <div className={hiStyles.sectionTitle}>
        The future of the TON blockchain
      </div>
      <div className={hiStyles.sectionSubtitle}>
        Telegram has started building on TON and has announced plans to release
        wallets and decentralized exchanges
      </div>
      <div className={styles.tweet}>
        <div className={styles.tweetInner}>
          {theme === 'dark' ? (
            <TwitterTweetEmbed
              placeholder="Loading..."
              tweetId={'1598002700586975232'}
              options={{ theme }}
            />
          ) : null}
          {theme === 'light' ? (
            <TwitterTweetEmbed
              placeholder="Loading..."
              tweetId={'1598002700586975232'}
              options={{ theme }}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}
