// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YdKjJ{position:relative;padding:12px;border-radius:16px;font-size:14px;line-height:20px;display:flex;background-color:#f3f5fb;margin-bottom:15px}.YdKjJ>span{margin-left:9px}.Ety8A{margin-top:3px}.a8Q3y{background-color:#fff}.a8Q3y .WeM7B{fill:#c2cceb}._2dVEh{color:#eb9749;background-color:#f2cca6}._2dVEh .WeM7B{fill:#f2cca6}", "",{"version":3,"sources":["webpack://./src/tonstarter/pages/Project/components/InfoTip/InfoTip.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CACA,gBAAA,CACA,YAAA,CACA,wBAAA,CACA,kBAAA,CAEA,YACE,eAAA,CAIJ,OACE,cAAA,CAGF,OACE,qBAAA,CAEA,cACE,YAAA,CAIJ,QACE,aAAA,CACA,wBAAA,CAEA,eACE,YAAA","sourcesContent":[".root {\n  position: relative;\n  padding: 12px;\n  border-radius: 16px;\n  font-size: 14px;\n  line-height: 20px;\n  display: flex;\n  background-color: #f3f5fb;\n  margin-bottom: 15px;\n\n  & > span {\n    margin-left: 9px;\n  }\n}\n\n.iconContainer {\n  margin-top: 3px;\n}\n\n.info {\n  background-color: #fff;\n\n  .emoji {\n    fill: #c2cceb;\n  }\n}\n\n.warn {\n  color: #eb9749;\n  background-color: #f2cca6;\n\n  .emoji {\n    fill: #f2cca6;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "YdKjJ",
	"iconContainer": "Ety8A",
	"info": "a8Q3y",
	"emoji": "WeM7B",
	"warn": "_2dVEh"
};
export default ___CSS_LOADER_EXPORT___;
