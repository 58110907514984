import classNames from 'classnames'
import React, { useState } from 'react'

import { Menu, useQueryTabletLandscapeUp } from '@design-system'
import { AnalyticsService, TonConnectService } from '@services'
import { disconnectWallet, signOut, useAppDispatch } from '@store'
import { copyToClipboard, tonBalanceToDisplay } from '@utils'
import styles from './ConnectWalletButton.scss'

interface ConnectWalletButtonMenuProps {
  isOpen: boolean
  onClose: () => void
  walletAddress: string
  walletBalance: string
}

export function ConnectWalletButtonMenu({
  isOpen,
  onClose,
  walletAddress,
  walletBalance,
}: ConnectWalletButtonMenuProps): JSX.Element {
  const dispatch = useAppDispatch()
  const [copyText, setCopyText] = useState('Copy address')

  const handleCopy = () => {
    copyToClipboard(walletAddress)
    setCopyText('Copied')
    AnalyticsService.track('wallet_address_copied')

    setTimeout(() => setCopyText('Copy address'), 1000)
  }

  const handleDisconnect = () => {
    dispatch(disconnectWallet())
    AnalyticsService.track('wallet_disconnected')

    dispatch(signOut())
    TonConnectService.disconnect()
  }

  const isTabletLandscapeUpViewport = useQueryTabletLandscapeUp()

  return (
    <Menu open={isOpen} onClose={onClose} className={styles.menuDD}>
      <div className={classNames(styles.menuItem)}>
        <svg
          width="14"
          height="16"
          viewBox="0 0 14 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.3522 3.41333C12.3522 4.46896 9.95606 5.32472 7.00031 5.32472C4.04455 5.32472 1.64844 4.46896 1.64844 3.41333M12.3522 3.41333C12.3522 2.35771 9.95606 1.50195 7.00031 1.50195C4.04455 1.50195 1.64844 2.35771 1.64844 3.41333M12.3522 3.41333V12.588C12.3522 13.6436 9.95606 14.4993 7.00031 14.4993C4.04455 14.4993 1.64844 13.6436 1.64844 12.588V3.41333M12.3522 6.47155C12.3522 7.52717 9.95606 8.38293 7.00031 8.38293C4.04455 8.38293 1.64844 7.52717 1.64844 6.47155M12.3522 9.52976C12.3522 10.5854 9.95606 11.4411 7.00031 11.4411C4.04455 11.4411 1.64844 10.5854 1.64844 9.52976"
            stroke="#496AEB"
            strokeWidth="1.5"
          />
        </svg>

        <div>
          {!walletBalance && '0.0000'}
          {walletBalance && tonBalanceToDisplay(walletBalance)} TON
        </div>
      </div>
      <div className={styles.split}></div>

      <div className={classNames(styles.menuItem)} onClick={handleCopy}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="5.25"
            y="5.24878"
            width="9"
            height="9"
            rx="2.25"
            stroke="#496AEB"
            strokeWidth="1.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 2.5H8.5C9.08892 2.5 9.59857 2.83939 9.84404 3.33325H11.4256C11.1225 1.9973 9.9277 1 8.5 1H5C2.79086 1 1 2.79086 1 5V8.5C1 9.92774 1.99736 11.1225 3.33337 11.4257V9.8441C2.83945 9.59866 2.5 9.08897 2.5 8.5V5C2.5 3.61929 3.61929 2.5 5 2.5Z"
            fill="#496AEB"
          />
        </svg>
        {copyText}
      </div>
      <div className={styles.split}></div>

      <div className={classNames(styles.menuItem)} onClick={handleDisconnect}>
        <svg
          width="14"
          height="16"
          viewBox="0 0 14 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.5 14.75H4C2.34315 14.75 1 13.4069 1 11.75V4.25C1 2.59315 2.34315 1.25 4 1.25H9.5M5.5 8H13M13 8L9.5 4.5M13 8L9.5 11.5"
            stroke="#496AEB"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Disconnect
      </div>
    </Menu>
  )
}
