import { createAsyncThunk } from '@reduxjs/toolkit'

import { ApiService, TonService } from '@services'
import { sleep } from '@utils'
import { Project, ProjectOnChainData } from './types'

export const fetchProjects = createAsyncThunk<Project[]>(
  'projects/fetchProjects',
  async (params, { rejectWithValue }) => {
    const { ok, data, error } = await ApiService.get({
      endpoint: '/projects',
    })

    if (!ok) {
      return rejectWithValue(error)
    }

    if (window.location.search === '?s') {
      const items = [0, 100, 500, 1000, 2000, 3000]
      sleep(items[(items.length * Math.random()) | 0])
    }

    return data as Project[]
  }
)

export const fetchProject = createAsyncThunk<Project, { projectSlug: string }>(
  'projects/fetchProject',
  async ({ projectSlug }, { rejectWithValue }) => {
    const { ok, data, error } = await ApiService.get({
      endpoint: `/projects/slug/${projectSlug}`,
    })

    if (!ok && error === 'not-found') {
      location.href = '/'
    }

    if (!ok) {
      return rejectWithValue(error)
    }

    if (window.location.search === '?s') {
      const items = [0, 100, 500, 1000, 2000, 3000]
      sleep(items[(items.length * Math.random()) | 0])
    }

    return data as Project
  }
)

export const fetchProjectSaleInfo = createAsyncThunk<
  ProjectOnChainData,
  { contractAddress: string }
>('projects/fetchProjectSaleInfo', async (params, { rejectWithValue }) => {
  const { ok, data, error } = await TonService.fetchProjectInfo(params)

  if (!ok) {
    return rejectWithValue(error)
  }

  return data as any
})

export const getFundProgress = createAsyncThunk<string, string>(
  'projects/getFundProgress',
  async (walletAddress, { rejectWithValue }) => {
    const { ok, data, error } = await TonService.fetchWalletInfo({
      walletAddress,
    })

    if (!ok) {
      return rejectWithValue(error)
    }

    return data as any
  }
)

interface SubscribeToProjectParams {
  username: string
  telegramId: string | number
  locale: string
  projectId: string | number
  token: string
}

export const subscribeToProject = createAsyncThunk<
  boolean,
  SubscribeToProjectParams
>('projects/subscribeToProject', async (params, { rejectWithValue }) => {
  const { ok, data, error } = await ApiService.post({
    data: params,
    endpoint: `/projects/${params?.projectId}/subscribe/`,
  })

  if (!ok) {
    return rejectWithValue(error)
  }

  return true
})

export const fetchProjectParticipants = createAsyncThunk<
  number,
  { projectId: number }
>(
  'projects/fetchProjectParticipants',
  async ({ projectId }, { rejectWithValue }) => {
    const { ok, data, error } = await ApiService.get({
      endpoint: `/projects/${projectId}/all-participants`,
    })

    if (!ok) {
      return rejectWithValue(error)
    }

    return data
  }
)

export const fetchProjectLeaders = async (
  projectId: string,
  address: string,
  limit?: number
) => {
  return await ApiService.get({
    params: { address, limit },
    endpoint: `/projects/${projectId}/leaderboard`,
  })
}
