import React from 'react'

import { team } from './data/team'
import { roadmap } from './data/roadmap'
import { Text, useMobileLayout } from '@design-system'

import styles from '../components/About/About.scss'
import { Arrow } from '../icons'

export const FantonDetails = () => {
  const isMobileLayout = useMobileLayout()

  const extraHeaderType = isMobileLayout ? 'header-32' : 'header-44'
  const headerType = isMobileLayout ? 'title-24' : 'title-28'
  const subheaderType = isMobileLayout ? 'title-20' : 'title-24'
  const paragraphType = isMobileLayout ? 'paragraph-16' : 'paragraph-20'
  const paragraphColor = 'secondary'

  return (
    <>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Product
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          Fanton is the first play-to-earn game in the Fantasy Football genre that integrated in Telegram. 
          The game uses hand-drawn NFTs based on TON blockchain. Players can create fantasy football teams and participate in tournaments to win prizes such as NFTs and Toncoin. Players' score in the tournaments depends on the performance of footballers in real matches.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Problem
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
        Bring a user-friendly crypto product for the mass market — using Telegram Web Apps and a proven gameplay.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Solution
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          Users assemble a “dream team” of their favourite football players and if they play well, user wins. This makes emotions more bright. 
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          <ol>
            <li>Customers don’t need to download a mobile app. In 2 clicks customer can start the game. That's why customer acquisition cost is much lower – right now it’s 0,5$ per new customer.</li>
            <li>We could return users back with bot push-notifications which gives higher retention than web-site.</li>
            <li>Moreover we give customers free-to-play mode with prizes to understand a mechanic and convert to payment tournaments with better prizes.</li>
          </ol>
        </Text>
      </div>



      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Business Model
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          Fanton operates on a freemium business model. 
          The core game is free-to-play, but players can play in paid tournaments for higher prizes. 
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          The paid tournaments are the main source of revenue, as players purchase tickets to enter the tournament and compete against other players for the prize pool.
          These tournaments provide a steady stream of revenue for Fanton.
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          Additionally, Fanton also earns revenue through the sale of NFTs. 
          The business model is designed to attract a large player base and engage them through a combination of free and paid content, ultimately leading to a sustainable and profitable revenue stream.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="32">
          Core team
        </Text>
        <div className={styles.team}>
          {team.map((t, i) => (
            <div className={styles.teamMember} key={i}>
              <div className={styles.teamAvatar}>
                <img src={t.image} className={styles.imageCover} />
              </div>
              <Text type={subheaderType}>{t.name}</Text>
              <Text
                type={paragraphType}
                color="tertiary"
                spacingBottom={isMobileLayout ? '8' : '12'}
              >
                {t.role}
              </Text>
              {t.details.map((a, j) => (
                <Text
                  type={paragraphType}
                  color={paragraphColor}
                  spacingBottom="16"
                  key={j}
                >
                  {a}
                </Text>
              ))}
              {t.linkedin && (
                <a
                  className={styles.teamLink}
                  href={t.linkedin}
                  target="_blank"
                >
                  <Text type={paragraphType} color={paragraphColor}>
                    LinkedIn
                  </Text>
                  <Arrow />
                </a>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Roadmap
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="24">
          Our goal is to become #1 fantasy football game in countries with Telegram penetration more than 15%.
        </Text>

        {roadmap.map((r, i) => (
          <div className={styles.roadmapRow} key={i}>
            <Text type={extraHeaderType} spacingBottom="12">
              {r.Q}
              <span>{r.year}</span>
            </Text>
            {r.actions.map((a, j) => (
              <Text
                type={paragraphType}
                color={paragraphColor}
                spacingBottom="16"
                key={j}
              >
                {a}
              </Text>
            ))}
          </div>
        ))}
        <a
          className={styles.teamLink}
          href="https://fan-ton.com/en/#roadmap"
          target="_blank"
        >
          <Text type={paragraphType} color={paragraphColor}>
            Details
          </Text>
          <Arrow />
        </a>
      </div>
    </>
  )
}
