import { RatingBadgeLevel } from "src/openrating/components"

export interface Project {
  id: string
  comingSoon?: boolean
  name: string
  slug: string
  iconUrl: string
  logoUrl: string
  logoDarkUrl?: string
  urlTitle: string
  urlLink: string
  expertRatingLevel: RatingBadgeLevel
  expertRatingMarker: string
  expertRatingIndex: number
  communityRating: string
  contractAddress: string
  contractAddressTestnet: string
  updatedAt: string
  reportUrl?: string
  fundamentalReportUrl?: string
  technicalReportUrl?: string
  reports: Report[]
  category: ProjectCategory
  hideInvest?: boolean
}

export interface Report {
  reportType: string
  updatedAt: string
  totalRate: string
  values: FundamentalReportValues | TechnicalReportValues
  reviewers: ProjectReviewer[]
}

export interface TechnicalReportValues {
  codeAndTeam: string
  docs: string 
  testing: string 
  security: string 
  accessControls: string
}

export interface FundamentalReportValues {
  valueProposition: string
  tokenomics: string
  team: string
  regulatory: string
  nft?: string
  wallet?: string
  api?: string
  governance?: string
  community?: string
  tech?: string
}

export interface ProjectReviewer {
  title: string
  subtitle: string
  avatarUrl: string
  link?: string
}

export type ProjectCategory =
  | 'defi'
  | 'marketplace'
  | 'funding'
  | 'wallet'
  | 'socialfi'
  | 'tools'

export enum ProjectQuoteTypes {
  human = 'human',
  gpt = 'gpt',
}
