import React from 'react'

import { PrivateData } from '../../../../'
import styles from '../../PrivateSidebar.scss'
import { Text } from '@design-system'

interface VestingDetailsProps {
  data: PrivateData
}

export const VestingDetails = ({ data }: VestingDetailsProps) => {
  return (
    <div className={styles.vestingWrapper}>
      <h3 className={styles.vestingTitle}>Vesting details</h3>
      <div className={styles.vestingRows}>
        {data.vesting.map((line, index) => {
          let hr = null

          if (index < data.vesting.length - 1) {
            hr = <hr className={styles.vestingHr} />
          }

          return (
            <React.Fragment key={index}>
              <div className={styles.vestingRow}>
                <Text align="left" color="tertiary">{line.title}</Text>
                <Text align="right">{line.value}</Text>
              </div>
              {hr}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
