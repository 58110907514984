import { Project } from '../projects'

export interface Allocation {
  tokens?: number
  isPurchased?: boolean
  amountUSD?: number
  chanceToWin?: string | number
  amountTon: number
  id: string
  status: AllocationStatus
  contractAddress: string
  projects: Project
  maxTokens: number
  isLotteryWinner: boolean
  userId: number
  fromWaitList: boolean
}

export enum AllocationStatus {
  init = 'init',
  whitelistSoon = 'whitelist_soon',
  whitelistStarted = 'whitelist_started',
  whitelistCompleted = 'whitelist_completed',
  kycRequired = 'kyc_required',
  kycPassed = 'kyc_passed',
  kycFailed = 'kyc_failed',
  saleStarted = 'sale_started',
  deploying = 'deploying',
  deployed = 'deployed',
  joinedPool = 'joined_pool',
  claim = 'claim',
  claiming = 'claiming',
  claimed = 'claimed',
  soldOut = 'soldOut',
}

export interface ClaimState {
  availableTokens: string
  claimedTokens: string
  allTokens: string
  time: string
  timeToLockup: string
  timeToVesting: string
}

export interface PrivateAllocation {
  id: number
  userId: number
  projectSlug: string
  status: 'kyc_pending' | 'kyc_failed' | 'kyc_success' | 'new'
  amountInTon?: number
  amountInUsd?: number
  amountInTokens?: number

  payload: any
  
  updatedAt?: Date
  createdAt: Date
}