import React from 'react'

import { Button } from '@design-system'
import styles from './ThemeButton.scss'

interface ThemeButtonProps {
  onToggleTheme(): void
}

export function ThemeButton({ onToggleTheme }: ThemeButtonProps): JSX.Element {
  return (
    <Button
      icon={
        <svg
          className={styles.sunAndMoon}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle
            className={styles.sun}
            cx="12"
            cy="12"
            r="4"
            mask="url(#moon-mask)"
            fill="currentColor"
          />
          <g className={styles.sunBeams} stroke="currentColor">
            <line x1="12" y1="1" x2="12" y2="3"></line>
            <line x1="12" y1="21" x2="12" y2="23"></line>
            <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
            <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
            <line x1="1" y1="12" x2="3" y2="12"></line>
            <line x1="21" y1="12" x2="23" y2="12"></line>
            <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
            <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
          </g>
          <mask className={styles.moon} id="moon-mask">
            <rect x="0" y="0" width="100%" height="100%" fill="white" />
            <circle cx="24" cy="10" r="4" fill="black" />
          </mask>
        </svg>
      }
      variant="secondary"
      shape="icon-square"
      onClick={onToggleTheme}
    />
  )
}
