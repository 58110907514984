import React from 'react'

import { Button } from '@design-system'
import hiStyles from '../../HiPage.scss'
import { Card, ColorModifier } from '../Card/Card'
import styles from './WhyTon.scss'

const cardsWhy = [
  {
    title: 'Telegram integration',
    description:
      "Gain access to Telegram's audience of 750M active users with a huge crypto community",
    icon: 'tg',
    colorModifier: ColorModifier.fb,
  },
  {
    title: 'Builders support',
    description: (
      <>
        <p>
          Fast track to fundraising and comprehensive support from Tonstarter
        </p>
        <br />
        <Button
          as="a"
          href="https://forms.tonstarter.com/build?source=hi"
          target="_blank"
        >
          Apply here
        </Button>
      </>
    ),
    icon: 'bs',
    colorModifier: ColorModifier.fr,
  },
  {
    title: 'Access to ecosystem funds',
    description:
      'Over $300M is allocated for investments into crypto projects on TON',
    icon: 'fp',
    colorModifier: ColorModifier.fb,
  },
  {
    title: 'Community focused',
    description: '2.5M members in 13 different international TON Communities',
    icon: 'cf',
    colorModifier: ColorModifier.fb,
  },
  {
    title: 'Seamless onboarding',
    description:
      'Quick and easy transition from other blockchains with the support from TON onboarding team',
    icon: 'so',
    colorModifier: ColorModifier.fb,
  },
  {
    title: 'Truly decentralized blockchain',
    description:
      'TON is an ultra-fast layer-1 blockchain with tiny transaction fees',
    icon: 'td',
    colorModifier: ColorModifier.sb,
  },
]

export const WhyTon = () => {
  return (
    <div className={styles.root}>
      <div className={hiStyles.sectionTitle}>The Open Network (TON)</div>
      <div className={hiStyles.sectionSubtitle}>
        Tonstarter helps teams and projects from other blockchains raise funds
        to have a successful start on the TON blockchain 🚀
      </div>
      <div className={styles.cards}>
        {cardsWhy.map((item, i) => (
          <Card {...item} key={i} />
        ))}
      </div>
    </div>
  )
}
