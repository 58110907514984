import { PrivateData } from '../components/PrivateProject'

export const privateData: PrivateData = {
    vesting: [
        {
            title: 'TGE date',
            value: 'Q1 2024'
        },
        {
            title: 'Price per $EVAA',
            value: '$0.2'
        },
        {
            title: 'Round allocation',
            value: '1% - 1.5%'
        },
        {
            title: 'Cliff',
            value: '6 months'
        },
        {
            title: 'Vesting',
            value: '10% every 2 months'
        },
    ],
  prices: {
    tokenPriceInTon: '0.111731844',
    tokenPriceInUsd: '0.2',
    tonPriceInUsd: '1.79',
  },
  ticker: 'EVAA',
  contractAddress: 'EQDamd--WgEkksxJjG-hUZH9wXyyPAqblF0jD_2qRWOPeEzX',
  limits: {
    ton: {
      min: '700',
      max: '40000',
    },
    usd: {
      min: '1200',
      max: '70000',
    },
  },
  sumsub: 'https://tally.so/r/w4BEoY',
  cover: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/evaa/evaa_homepage_banner.jpg',
  docs: 'https://www.canva.com/design/DAFtsBLrHHk/cHqrqxc7JnTdGdyE983Dkg/edit?utm_content=DAFtsBLrHHk&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton',

  dates: {
    start: new Date('2023-09-06'),
    public: new Date(Date.UTC(2023, 9, 9, 15)),
    end: new Date('2023-10-01'),
  },
  fund: '200,000',
  fullName: 'Evaa Protocol',
  usdtAddress: 'TDBhQaBiTJdn7retx2gpoCeVKQFC2i2PZ8',
  usdcAddress: '0xF00FDDB54286efCa9aB932Db0e8e5f0b0D57a497',
}