import classNames from 'classnames'
import React from 'react'

import { Link, Text, useMobileLayout } from '@design-system'
import styles from '../components/About/About.scss'
import { Arrow, TelegramPlane } from '../icons'
import { news } from './data/news'
import { team } from './data/team'

export const DiamondsDetails = () => {
  const isMobileLayout = useMobileLayout()

  const extraHeaderType = isMobileLayout ? 'header-32' : 'header-44'
  const headerType = isMobileLayout ? 'title-24' : 'title-28'
  const subheaderType = isMobileLayout ? 'title-20' : 'title-24'
  const paragraphType = isMobileLayout ? 'paragraph-16' : 'paragraph-16'
  const paragraphColor = 'secondary'

  return (
    <>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          What's the offer?
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          Connect your wallet to join the event and buy a TON Diamonds NFT on the{' '}
          <Link href="https://ton.diamonds/community#sale" type="paragraph-16">
            ton.diamonds website
          </Link>
          {' '}The NFT you buy is random, which means you can get a rare and expensive Big Diamond NFT. Currently, the floor price for Big Diamonds is 1400 TON (~$1960).
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          Every Diamond farms Glint Coin daily depending on size and rarity of NFT. Plus, each new Diamond you buy will bring +100 Glint Coin, +5% farming speed boost for 2 weeks, and a +10% lifetime farming speed boost.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          How much is 100 Glint Coin?
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          The token price has not yet been announced but currently every Small
          TON Diamonds NFT holder can farm a max of 0.3 Glint per day, Medium
          holder — 3 Glints per day and Big one — 30 Glints per day. At the
          moment, there is no other way to get this much Glint Coin at once. It
          is a unique bonus from this collaboration between TON Diamonds and
          Tonstarter.
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          Enter for a chance to win one of the 42 Small TON Diamonds NFT —
          collect 24 words from the wallet's seed phrase. You can get up to 3
          Diamonds NFTs per day, hurry up to be the first!
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          What's TON Diamonds?
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          TON Diamonds is a boutique NFT marketplace and auction house for
          global digital artists on TON Blockchain. The marketplace collaborated
          with world-famous creators including Pokras Lampas, Ellen Sheidlin,
          Sarah McDaniel and participated in exhibitions at The Louvre museum in
          France as well as in New York City with the{' '}
          <Link href="https://t.me/tondiamonds/162" type="paragraph-16">
            grand promo on Times Square
          </Link>
          .
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          TON Diamonds collection consists of 10,000 NFTs with privileges for
          holders, including up to 0% fees for trading, access to a private
          community and exclusive benefits in partnering products in TON
          Ecosystem. Find out about all privileges for TON Diamonds holders{' '}
          <Link href="https://ton.diamonds/community">here</Link>
          .
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Future of Glint Coin utility token
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          Glint Coin is the native token of TON Diamonds marketplace with real
          utilities. Glint Coin holders will receive a share of the marketplace
          fees profits, the opportunity to influence the project's development
          through a DAO voting mechanism, and token staking. The total supply is
          22,000,000 $GLINT. Learn more{' '}
          <Link href="https://ton.diamonds/glint-coin" type="paragraph-16">
            on the Glint Coin landing page
          </Link>
          .
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="32">
          Core team
        </Text>
        <div className={styles.team}>
          {team.map((t, i) => (
            <div className={styles.teamMember} key={`${i}f`}>
              <div className={styles.teamAvatar}>
                {t.image && (
                  <img
                    src={t.image}
                    className={classNames(styles.imageCover, styles.teamAvatar)}
                  />
                )}
                {t.teamLogo && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    viewBox="0 0 56 56"
                    className={styles.teamLogoIcon}
                  >
                    <path d="M32.8215 40.7527C33.1475 40.5269 33.599 40.6718 33.7327 41.0453L38.8018 55.192C38.9647 55.6469 38.554 56.0994 38.0858 55.9808L19.4225 51.2499C18.9206 51.1226 18.8016 50.4634 19.2273 50.1686L32.8215 40.7527Z"></path>
                    <path d="M36.3842 37.0343C36.3611 36.9682 36.3492 36.8985 36.3492 36.8282V22.4007C36.3492 21.9926 35.965 21.6994 35.582 21.815L19.7343 26.5998C19.3514 26.7156 18.9672 26.4223 18.9672 26.0142V16.69C18.9672 16.4994 19.0542 16.3198 19.2027 16.2043L39.8542 0.12507C40.1412 -0.0984021 40.5551 -0.0130729 40.7349 0.306632L53.2104 22.4951C53.2987 22.6523 53.3142 22.8415 53.2526 23.0114L42.7493 51.9885C42.5554 52.5231 41.814 52.519 41.6259 51.9825L36.3842 37.0343Z"></path>
                    <path d="M13.4429 1.72465C13.5421 1.62704 13.6723 1.56737 13.8107 1.55603L32.7751 0.00222417C33.3674 -0.0463117 33.6565 0.711819 33.1835 1.07333L17.0192 13.4264C16.8696 13.5408 16.675 13.5773 16.4944 13.525L5.85011 10.4427C5.41228 10.3159 5.27315 9.76188 5.5987 9.4416L13.4429 1.72465Z"></path>
                    <path d="M2.65609 14.0767C2.70176 13.709 3.0631 13.4694 3.41678 13.5723L14.7771 16.8783C15.0333 16.9528 15.2096 17.189 15.2096 17.4574V30.8424C15.2096 31.2453 15.5949 31.5348 15.9788 31.4204L31.7471 26.7185C32.1309 26.6041 32.5162 26.8936 32.5162 27.2965V35.7983C32.5162 35.9959 32.4202 36.1808 32.2591 36.2934L14.5729 48.6663C14.3422 48.8277 14.0312 48.8061 13.8248 48.6144L0.192671 35.947C0.0506846 35.8151 -0.0193358 35.6223 0.00463002 35.4293L2.65609 14.0767Z"></path>
                  </svg>
                )}
              </div>
              <Text type={subheaderType}>{t.name}</Text>
              <Text
                type={paragraphType}
                color="tertiary"
                spacingBottom={isMobileLayout ? '8' : '12'}
              >
                {t.role}
              </Text>
              {t.details.map((a, j) => (
                <Text
                  type={paragraphType}
                  color={paragraphColor}
                  spacingBottom="16"
                  key={j}
                >
                  {a}
                </Text>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="32">
          More about TON Diamonds
        </Text>

        <div className={styles.news}>
          {news.map((t, i) => (
            <a href={t.href} className={styles.newsItem} key={i}>
              <div className={styles.newsImage}>
                <img src={t.image} />
              </div>
              <Text type="title-16" color={paragraphColor} spacingBottom="8">
                <b>{t.title}</b>
              </Text>
              <Text type="paragraph-16" color={paragraphColor}>
                {t.description}
              </Text>
            </a>
          ))}
        </div>
      </div>
    </>
  )
}
