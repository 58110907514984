export const roadmap = [
  {
    Q: 'Q1-Q4',
    year: '2022',
    actions: [
      'Project idea',
      'Team approval',
      'The first cards have been drawn',
      'Partnership with Telespace, launch of the Telegram channel and website',
      'Mint of the collection',
      'Product MVP (ability to participate in tournaments and manage cards)',
    ],
  },
  {
    Q: 'Q1',
    year: '2023',
    actions: [
      'Integration into Tonkeeper',
      'Integration of card payments for tournaments',
      'History of won prizes',
    ],
  },
  {
    Q: 'Q2',
    year: '2023',
    actions: [
      'Ability to pay with BTC/ETH/USDT(TRC20)',
      'Detailed information about a player when choosing them for a team',
      'Launch of a referral program',
      'Fair scoring in the event of the same number of points',
    ],
  },
  {
    Q: 'Q3',
    year: '2023',
    actions: [
      'Integration with TON Play',
      'NFT rental',
      'Updated scoring (goalkeeper saves, interceptions, etc.)',
      'XP for participating in tournaments + ability to exchange them for prizes',
    ],
  },
]
