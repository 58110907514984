import { createSlice } from '@reduxjs/toolkit'

import { PrivateAllocation, RootState } from '..'
import { Allocation } from '@store'
import {
  checkActiveAllocationPayment,
  fetchAllocation,
  fetchPrivateAllocation,
} from './allocationsActions'

interface AllocationState {
  activeAllocation: Allocation | null | { [key: string]: string }
  privateAllocation: PrivateAllocation[] | null
  isActiveAllocationPurchased: null | boolean
  status: 'idle' | 'loading' | 'fulfilled' | 'rejected'
  error: string | null
}

const initialState: AllocationState = {
  activeAllocation: null,
  privateAllocation: null,
  isActiveAllocationPurchased: null,
  status: 'idle',
  error: null,
}

export const allocationsSlice = createSlice({
  name: 'allocations',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllocation.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchAllocation.fulfilled, (state, action) => {
        state.status = 'fulfilled'
        state.activeAllocation = action.payload
      })
      .addCase(fetchAllocation.rejected, (state, action) => {
        state.status = 'rejected'
        state.activeAllocation = {}
      })

    builder.addCase(checkActiveAllocationPayment.fulfilled, (state, action) => {
      state.isActiveAllocationPurchased = action.payload
    })

    builder
      .addCase(fetchPrivateAllocation.fulfilled, (state, action) => {
        state.privateAllocation = action.payload
      })
      .addCase(fetchPrivateAllocation.rejected, (state, action) => {
        state.privateAllocation = null
      })
  },
})

export const allocationsLoadedSelector = (state: RootState): boolean =>
  state.allocations.status === 'fulfilled'

export const allocationIsNotLoadingSelector = (state: RootState): boolean =>
  state.allocations.status !== 'loading'

export const allocationLoadStatusSelector = (state: RootState): string =>
  state.allocations.status

export const activeAllocationSelector = (
  state: RootState
): Allocation | null | { [key: string]: string } =>
  state.allocations.activeAllocation

export const isActiveAllocationPurchasedSelector = (
  state: RootState
): boolean | null => state.allocations.isActiveAllocationPurchased

export const privateAllocationSelector = (
  state: RootState
): PrivateAllocation[] | null => state.allocations.privateAllocation

export default allocationsSlice.reducer
