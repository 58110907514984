import React from 'react'

import { Link, Text, useMobileLayout } from '@design-system'
import { roadmap } from './data/roadmap'
import { team } from './data/team'

import styles from '../components/About/About.scss'
import { TelegramPlane } from '../icons'

export const DeDustDetails = () => {
  const isMobileLayout = useMobileLayout()

  const extraHeaderType = isMobileLayout ? 'header-32' : 'header-44'
  const headerType = isMobileLayout ? 'title-24' : 'title-28'
  const subheaderType = isMobileLayout ? 'title-20' : 'title-24'
  const paragraphType = isMobileLayout ? 'paragraph-16' : 'paragraph-16'
  const paragraphColor = 'secondary'

  return (
    <>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Product
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          DeDust Protocol is a cutting-edge decentralized exchange (DEX) built
          on TON blockchain, specifically designed for jettons (
          <Link
            href="https://github.com/ton-blockchain/TEPs/blob/master/text/0074-jettons-standard.md"
            textType={paragraphType}
          >
            TEP-74
          </Link>{' '}
          tokens). After six months of dedicated work and a successful testnet
          release in May 2022, it was launched on the mainnet in November 2022.
          DeDust Protocol has incorporated user feedback from its five-month
          operational period to evolve into the powerful DeDust Protocol 2.0.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Value proposition
        </Text>
        <Text type={subheaderType} color={paragraphColor} spacingBottom="16">
          Low Fees
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="32">
          With 80% lower gas consumption than v1.0, DeDust boasts the lowest gas
          consumption among TON-based DEXes. Plus, a minimal 0.01% protocol fee
          is applied to stable swaps.
        </Text>
        <Text type={subheaderType} color={paragraphColor} spacingBottom="16">
          No wrapped TON needed
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="32">
          DeDust is built with user experience in mind. It eliminates the need
          for manual wrapping/unwrapping, integrates smart-contracts to be
          joinable with other contracts, enables multi-hop trades (e.g., SCALE →
          TON → USDT), and removes approval requirements for withdrawals,
          enhancing safety and convenience.
        </Text>
        <Text type={subheaderType} color={paragraphColor} spacingBottom="16">
          DeFi Hub
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          DeDust Protocol serves as a comprehensive DeFi hub, offering portfolio
          management similar to Zapper and Zerion.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Milestones
        </Text>

        {roadmap.map((r, i) => (
          <div className={styles.roadmapRow} key={i}>
            <Text type={extraHeaderType} spacingBottom="12">
              {r.Q}
              <span>{r.year}</span>
            </Text>
            {r.actions.map((a, j) => (
              <Text
                type={paragraphType}
                color={paragraphColor}
                spacingBottom="16"
                key={j}
              >
                {a}
              </Text>
            ))}
          </div>
        ))}
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="32">
          Core team
        </Text>
        <div className={styles.team}>
          {team.map((t, i) => (
            <div className={styles.teamMember} key={i}>
              <div className={styles.teamAvatar}>
                <img src={t.image} className={styles.imageCover} />
              </div>
              <Text type={subheaderType}>{t.name}</Text>
              <Text
                type={paragraphType}
                color="tertiary"
                spacingBottom={isMobileLayout ? '8' : '12'}
              >
                {t.role}
              </Text>
              {t.details.map((a, j) => (
                <Text
                  type={paragraphType}
                  color={paragraphColor}
                  spacingBottom="16"
                  key={j}
                >
                  {a}
                </Text>
              ))}
              {t.tg && (
                <a className={styles.teamLink} href={t.tg} target="_blank">
                  <Text type={paragraphType} color={paragraphColor}>
                    Telegram
                  </Text>
                  <TelegramPlane className={styles.icon} />
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
