import classNames from 'classnames'
import React from 'react'

import styles from './Openrating.scss'

const projects = [
  {
    id: 1,
    title: 'Tonkeeper',
    subtitle: 'Non-custodial "hot" wallet',
    level: 'A+',
    value: 88,
    openratingUrl: 'https://openrating.xyz/tonkeeper',
    coverUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonkeeper/logo.png',
  },
  {
    id: 2,
    title: 'STON.fi',
    subtitle: 'Decentralized AMM',
    level: 'A',
    value: 85,
    openratingUrl: 'https://openrating.xyz/stonfi',
    coverUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/stonfi/stonfi-32.svg',
  },
  {
    id: 3,
    title: 'Getgems',
    subtitle: 'NFT marketplace',
    level: 'A',
    value: 76,
    openratingUrl: 'https://openrating.xyz/getgems',
    coverUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/getgems/getgems-icon.png',
  },
  {
    id: 4,
    title: 'TON API',
    subtitle: 'Reliable API for the TON blockchain',
    level: 'A',
    value: 73,
    openratingUrl: 'https://openrating.xyz/tonapi',
    coverUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonapi/logo.svg',
  },
  {
    id: 5,
    title: 'DeDust',
    subtitle: 'Decentralized exchange (DEX)',
    level: 'C',
    value: 59,
    openratingUrl: 'https://openrating.xyz/dedust',
    coverUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/dedust/dedust.png',
  },
  // {
  //   id: 6,
  //   title: 'Startup Market',
  //   subtitle: 'DeFi crowdfunding platform',
  //   level: 'C',
  //   value: 18,
  //   openratingUrl: 'https://openrating.xyz/startupmarket',
  //   coverUrl:
  //     'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/openrating/startupmarket/startupMarket.svg',
  // },
]

export const Openrating = () => {
  const badgeType = 'finished'

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        check projects expert rating on{' '}
        <a href="https://openrating.xyz" target="_blank">
          openrating.xyz
        </a>
      </div>

      <div className={styles.cards}>
        {projects.map((p) => (
          <a
            key={p.id}
            className={styles.card}
            href={p.openratingUrl}
            target="_blank"
          >
            <div className={styles.cover}>
              <img src={p.coverUrl} alt={p.title} />
            </div>

            <div className={styles.title}>{p.title}</div>
            <div className={styles.subtitle}>{p.subtitle}</div>
            <div className={styles.rate}>
              <div
                className={classNames(
                  styles.badge,
                  p.value >= 90 && styles.badgeAA,
                  p.value >= 70 && p.value < 90 && styles.badgeA,
                  p.value >= 60 && p.value < 70 && styles.badgeB,
                  p.value >= 50 && p.value < 60 && styles.badgeC,
                  p.value <= 40 && styles.badgeC
                )}
              >
                {p.level}
              </div>
              <div className={classNames(styles.value)}>{p.value}</div>
            </div>
          </a>
        ))}
      </div>
    </div>
  )
}
