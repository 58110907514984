import { Project } from '../store'

import * as dedust from './dedust'
import * as fanzee from './fanzee'
import * as getgems from './getgems'
import * as startupmarket from './startupmarket'
import * as stonfi from './stonfi'
import * as tondiamonds from './tondiamonds'
import * as tonhub from './tonhub'
import * as tonstarter from './tonstarter'
import * as tegro from './tegro'
import * as megaton from './megaton'
import * as tonapi from './tonapi'
import * as tonkeeper from './tonkeeper'

export default {
  stonfi,
  dedust,
  startupmarket,
  tondiamonds,
  tonstarter,
  getgems,
  tonhub,
  fanzee,
  tegro,
  megaton,
  tonapi,
  tonkeeper,
}

export const projects: Project[] = [
  stonfi.data,
  startupmarket.data,
  tonstarter.data,
  tondiamonds.data,
  getgems.data,
  tonhub.data,
  dedust.data,
  tegro.data,
  megaton.data,
  tonapi.data,
  tonkeeper.data
]
