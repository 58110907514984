import { Content, Icon, Text, useInterval, useToggle } from "@design-system";
import { ApiService } from '@services'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import { Diamond, Lose, Timer, Win } from './components'
import styles from './Game.scss'

type GameState = 'loading' | 'diamond' | 'win' | 'lose' | 'timer'

interface GameProps {
  projectId: number
  tasks: { [key: string]: any }[]
  telegramId?: string | null
  address?: string | null
}

export const Game = ({ projectId, telegramId, address, tasks }: GameProps) => {
  const rootElem = useRef(null)
  const [gameState, setGameState] = useState<GameState>('loading')
  const [isGameLocked, setIsGameLocked] = useState(true)
  const [hoursBeforeMint, setHoursBeforeMint] = useState(2)
  const [phrase, setPhrase] = useState('')

  useEffect(() => {
    !isGameLocked && fetchMintCycle()
  }, [isGameLocked])

  useInterval(
    () => {
      // Your custom logic here
      console.log(hoursBeforeMint)
      if (hoursBeforeMint < 3) {
        fetchMintCycle()
      } else {
        setHoursBeforeMint(hoursBeforeMint - 1)
      }
    },
    // Delay in milliseconds or null to stop it
    gameState == 'timer' ? 1000 : null,
  )

  useEffect(() => {
    let hasDone = 0

    tasks.forEach((t) => {
      if (
        (t.type === 'ts_tg_channel' ||
          t.type === 'ts_tg_chat' ||
          t.type === 'project_tg_channel' ||
          t.type === 'project_tg_chat') &&
        t.done
      ) {
        hasDone += 1
      }
    })

    if (hasDone === 4) {
      setIsGameLocked(false)
    }
  }, [tasks])

  const fetchMintCycle = async () => {
    const { ok, data } = await ApiService.get({
      endpoint: `/projects/${projectId}/mint-cycle`,
      params: { telegramId, address },
    })

    if (!ok || !data) {
      setGameState('diamond')
    }

    if (ok && data) {
      if (data === 'stop') {
        setGameState('lose')
      } else {
        setHoursBeforeMint(data)
        setGameState('timer')
      }
    }
  }

  const handleMint = (result: { [key: string]: any }) => {
    if (result.type === '1_words') {
      fetchMintCycle()
      setPhrase(result.text)
    }
  }

  const [isOpen, toggleOpen] = useToggle(true)

  return (
    <div>
      {/* <Content
        spacingChild="8"
        onClick={toggleOpen}
        align="center"
        justify="center"
        className={styles.header}
        padding="0-16"
      > */}
        <Text type="paragraph-16" align="center" spacingBottom="12">Get a free TON Diamonds NFT</Text>
        {/* <Content alignSelf="end">
          <Icon
            colorFill="tertiary"
            name="chevron-up"
            className={classNames(styles.chevron, isOpen && styles.chevronDown)}
          />
        </Content>
      </Content> */}

      <div className={classNames(styles.list, !isOpen && styles.listClosed)}>
        {isGameLocked ? (
          <div className={classNames(styles.listItem)}>
            <Text color="secondary">
              Do all follow on tasks 👇 to unlock clicker
            </Text>
            <svg
              width="10"
              height="14"
              viewBox="0 0 10 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.45985 14H8.54015C9.51825 14 10 13.5336 10 12.5018V7.20848C10 6.27562 9.60584 5.80212 8.78832 5.72438V3.9788C8.78832 1.29329 6.94161 0 5 0C3.05839 0 1.21168 1.29329 1.21168 3.9788V5.74558C0.452555 5.85866 0 6.32509 0 7.20848V12.5018C0 13.5336 0.481752 14 1.45985 14ZM2.61314 3.85159C2.61314 2.20495 3.69343 1.30035 5 1.30035C6.29927 1.30035 7.38686 2.20495 7.38686 3.85159V5.71025L2.61314 5.71731V3.85159Z"
                fill="white"
                fillOpacity="0.3"
              />
            </svg>
          </div>
        ) : null}

        {isGameLocked ? null : (
          <>
          <Text color="tertiary" type="paragraph-14" align="center">Click & collect 24 words from a wallet's seed phrase and get a Diamond from this wallet</Text>
          <div className={styles.board}>
            {gameState === 'diamond' ? (
              <Diamond projectId={projectId} onMint={handleMint} />
            ) : null}
            {gameState === 'timer' ? (
              <Timer hoursBefore={hoursBeforeMint} text={phrase} />
            ) : null}
            {gameState === 'win' ? <Win boost={true} /> : null}
            {gameState === 'lose' ? <Lose /> : null}
          </div>
          </>
        )}
      </div>
    </div>
  )
}
