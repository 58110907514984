// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b83mi{font-size:inherit;font-weight:inherit;line-height:inherit;text-align:left;word-wrap:break-word;padding:12px 10px}.b83mi.tm13W{text-align:left}.b83mi._4iatW{text-align:center}.b83mi.DFbVu{text-align:right}.b83mi.zWUKm{user-select:all}.b83mi.klI\\+S{position:sticky;left:0;background:inherit;z-index:2}.b83mi .XQF2d{padding:16px 16px}", "",{"version":3,"sources":["webpack://./src/shared/design-system/components/Table/TableCell.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,mBAAA,CACA,mBAAA,CACA,eAAA,CACA,oBAAA,CACA,iBAAA,CAEA,aACE,eAAA,CAGF,cACE,iBAAA,CAGF,aACE,gBAAA,CAGF,aACE,eAAA,CAGF,cACE,eAAA,CACA,MAAA,CACA,kBAAA,CACA,SAAA,CAGF,cACE,iBAAA","sourcesContent":[".self {\n  font-size: inherit;\n  font-weight: inherit;\n  line-height: inherit;\n  text-align: left;\n  word-wrap: break-word;\n  padding: 12px 10px;\n\n  &.left {\n    text-align: left;\n  }\n\n  &.center {\n    text-align: center;\n  }\n\n  &.right {\n    text-align: right;\n  }\n\n  &.selectable {\n    user-select: all;\n  }\n\n  &.sticky {\n    position: sticky;\n    left: 0;\n    background: inherit;\n    z-index: 2;\n  }\n\n  .withPadding {\n    padding: 16px 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"self": "b83mi",
	"left": "tm13W",
	"center": "_4iatW",
	"right": "DFbVu",
	"selectable": "zWUKm",
	"sticky": "klI+S",
	"withPadding": "XQF2d"
};
export default ___CSS_LOADER_EXPORT___;
