export enum ProjectStatus {
  whitelistSoon = 'whitelistSoon',
  whitelistOpen = 'whitelistOpen',
  whitelistClosed = 'whitelistClosed',
  whitelistFinish = 'whitelistFinish',
  live = 'live',
  soldOut = 'soldOut',
}

export const getProjectStatusTitle = (
  status: ProjectStatus,
  isAirdrop = false
): string => {
  if (isAirdrop) {
    switch (status) {
      case ProjectStatus.whitelistSoon:
        return 'Soon'
      case ProjectStatus.whitelistOpen:
        return 'Active'
      case ProjectStatus.whitelistClosed:
        return 'Counting results'
      case ProjectStatus.soldOut:
        return 'Finished'
    }
  } else {
    switch (status) {
      case ProjectStatus.whitelistSoon:
        return 'Coming soon'
      case ProjectStatus.whitelistOpen:
        return 'Applications opened'
      case ProjectStatus.whitelistClosed:
        return 'Applications closed'
      case ProjectStatus.whitelistFinish:
        return 'Allocation draw'
      case ProjectStatus.live:
        return 'Token sale'
      case ProjectStatus.soldOut:
        return 'Finished'
    }
  }

  return 'Coming soon'
}

export const isProjectActive = (
  status: ProjectStatus,
  isAirdrop = false
): boolean => {
  if (isAirdrop) {
    switch (status) {
      case ProjectStatus.whitelistSoon:
      case ProjectStatus.whitelistOpen:
        return true
      case ProjectStatus.whitelistClosed:
      case ProjectStatus.soldOut:
        return false
    }
  } else {
    switch (status) {
      case ProjectStatus.whitelistSoon:
      case ProjectStatus.whitelistOpen:
      case ProjectStatus.whitelistClosed:
      case ProjectStatus.whitelistFinish:
      case ProjectStatus.live:
        return true
      case ProjectStatus.soldOut:
        return false
    }
  }

  return false
}

export interface Project {
  id: number
  projectDetailsId: number | undefined
  status: ProjectStatus
  slug: string
  title: string
  description: string
  fundsGoal: number
  fundsRaised: number
  fixedTonPrice: string
  isAirdrop: boolean

  walletAddress: string | undefined
  jettonAddress: string | undefined
  allocationUSD: number
  allocationTon: number
  tokenUSD: number
  totalTokens: number
  winnersAmount: number | undefined
  leaderboardAmount: number | undefined

  idoContractAddress: string
  whitelistStartDate: string
  whitelistFinishDate: string
  tokenSaleDate: string
  projectLotteryDate: string
  tokenSaleFinishDate: string

  createdAt: string
  projectDetails: ProjectDetails
}

export interface ProjectDetails {
  id: number
  bannerAvif: string | undefined
  bannerWebp: string | undefined
  bannerPng: string | undefined
  logo: string | undefined
  logoDark: string | undefined
  icon: string | undefined
  about: string | undefined
  linkDiscord: string | undefined
  linkTwitter: string | undefined
  linkTelegram: string | undefined
  linkWebsite: string | undefined
  linkInstagram: string | undefined
  metaTitle:  string | undefined
  metaDescription:  string | undefined
}

export interface ProjectCategory {
  title: string
  id: string
  bannerUrl: string
}

export interface ProjectOnChainData {
  projectAddress: string
  jettonWallet: string
  tokenPriceInNanoTon: number
  amountTokensLeft: number
  tonPriceInUsd: string
}
