export const team = [
  {
    image:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/dedust/nick.jpeg',
    name: 'Nick',
    role: 'Founder',
    tg: 'https://t.me/NickNekilov',
    details: ['12+ years of experience in commercial development at leading IT companies and startups'],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/dedust/john.jpeg',
    name: 'John Fyodor',
    role: 'Core-developer',
    tg: 'https://t.me/daisy',
    details: [
      '8+ years of programming experience and author of the ton3 library',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/dedust/daniil.png',
    name: 'Daniil',
    role: 'Community manager',
    tg: 'https://t.me/menschee',
    details: [
      'Co-founder of The Daily TON, ex-CM at Tonstarter',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/dedust/vitaliy.jpeg',
    name: 'Vitaliy',
    role: 'Advisor',
    tg: 'https://t.me/vkitaev',
    details: [
      '10+ years of experience in digital marketing on both agency and product sides, co-founder of The Daily TON',
    ],
  },
]
