interface Config {
  apiHost: string
  tonAuthApiHost: string
  tonCenterApiHost: string
  tonCenterApiKey: string
  botName: string
  amplitudeApiKey: string
  initAnalytic: boolean
  tonConnectManifestUrl: string
  isLocal?: boolean
  isDev?: boolean
  isTestnet?: boolean
  isProd?: boolean
  isWebapp?: boolean
}

const localConfig: Config = {
  isLocal: true,
  apiHost: 'http://localhost:3000',
  tonAuthApiHost: 'localhost:3000',
  tonCenterApiHost: 'https://testnet.toncenter.com/api/v2/jsonRPC',
  tonCenterApiKey:
    '6b5e1df240303ce9b6918c116b4bae64757d15f12fc374e4d2fc021ea54bb19d',
  isDev: false,
  isProd: false,
  isTestnet: false,
  botName: 'testRocketon2Bot',
  amplitudeApiKey: '5e07aef2254b4224fb0446e554f1c6d3',
  initAnalytic: true,
  tonConnectManifestUrl:
    'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/ton-connect/manifest.json',
}

const developmentConfig: Config = {
  isDev: true,
  apiHost: 'https://testnet-api.tonstarter.com',
  tonAuthApiHost: 'testnet-api.tonstarter.com',
  // tonCenterApiHost: 'https://testnet.toncenter.com/api/v2/jsonRPC',
  tonCenterApiHost: 'https://testnet-toncenter.tonstarter.com/jsonRPC',
  tonCenterApiKey:
    'fa8168cc91994516c201b40ef1f4082b32a9e28bf1a46831e8890f5d9c6f1f73',
  botName: 'testnet_ton_starter_bot',
  amplitudeApiKey: '5e07aef2254b4224fb0446e554f1c6d3',
  initAnalytic: true,
  tonConnectManifestUrl:
    'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/ton-connect/manifest.json',
}

const testnetConfig: Config = {
  isTestnet: true,
  apiHost: 'https://testnet-api.tonstarter.com',
  tonCenterApiHost: 'https://testnet-toncenter.tonstarter.com/jsonRPC',
  tonCenterApiKey:
    '6b5e1df240303ce9b6918c116b4bae64757d15f12fc374e4d2fc021ea54bb19d',
  botName: 'testnet_ton_starter_bot',
  tonAuthApiHost: 'testnet-api.tonstarter.com',
  amplitudeApiKey: '5e07aef2254b4224fb0446e554f1c6d3',
  initAnalytic: true,
  tonConnectManifestUrl:
    'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/ton-connect/manifest.json',
}

const prodConfig: Config = {
  isProd: true,
  apiHost: 'https://api.tonstarter.com',
  tonCenterApiHost: 'https://toncenter.tonstarter.com/jsonRPC',
  tonCenterApiKey:
    'b77931f293905ac6f7ae253d39ff7029d886162c3bc17857f7c592d6adcbf7fb',
  botName: 'ton_starter_bot',
  tonAuthApiHost: 'api.tonstarter.com',
  amplitudeApiKey: '5e07aef2254b4224fb0446e554f1c6d3',
  initAnalytic: true,
  tonConnectManifestUrl:
    'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/ton-connect/manifest.json',
}

let config

switch (process.env.APP_ENV) {
  case 'local':
    config = { ...developmentConfig }
    break
  case 'development':
    config = { ...developmentConfig }
    break
  case 'testnet':
    config = { ...testnetConfig }
    break
  case 'production':
  default:
    config = { ...prodConfig }
}

export default config as Config
