import { TonConnect, WalletInfo } from '@tonconnect/ui-react'

import Config from '@config'

const dappMetadata = {
  manifestUrl: Config.tonConnectManifestUrl,
}

const connector = new TonConnect(dappMetadata)

export class TonConnectService {
  static getConnector(): TonConnect {
    return connector
  }

  static getWallets(): Promise<WalletInfo[]> {
    return TonConnectService.getConnector().getWallets()
  }

  static disconnect(): Promise<void> | null {
    if (TonConnectService.getConnector().connected) {
      return TonConnectService.getConnector().disconnect()
    }

    return null
  }
}
