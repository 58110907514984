import { Content, Text } from '@design-system'
import React from 'react'

import styles from './Win.scss'

export const Win = ({ boost = true }: { boost: boolean }) => {
  return (
    <Content align="center" justify="center" grow className={styles.root}>
      <div className={styles.icon}>📩</div>
      <Text align="center" color="fourth">
        Check assistant for your prize
      </Text>
    </Content>
  )
}
