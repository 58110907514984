import classNames from 'classnames'
import * as React from 'react'

import styles from './Tabs.scss'

type TextVariant = 'default' | 'text'

interface TabsProps {
  className?: string
  fullWidth?: boolean
  children: React.ReactNode
  value: number
  variant: TextVariant
  onChange(newIndex: number): void
}

export const Tabs = ({
  onChange,
  children: childrenProp,
  className,
  value,
  variant = 'default',
  fullWidth = false,
}: TabsProps): JSX.Element => {
  const handleClick = (index: number) => {
    onChange(index)
  }

  const children = React.Children.map(childrenProp, (child, index) => {
    return React.cloneElement(child as any, {
      index,
      value,
      variant,
      onClick: handleClick,
    })
  })

  return (
    <div
      className={classNames(
        className && className,
        variant === 'text' && styles.textTabs,
        fullWidth && styles.fullWidth
      )}
    >
      <div className={styles.inner}>{children}</div>
    </div>
  )
}

Tabs.displayName = 'Tabs'

interface TabProps {
  className?: string
  label: string
  onClick?: (index: number) => void
  index?: number
  value?: number
  variant?: TextVariant
}

export const Tab = ({
  label,
  onClick,
  index,
  className,
  value,
  variant = 'default',
}: TabProps): JSX.Element => {
  const handleClick = (index: number) => onClick && onClick(index)
  const isActiveTab = value === index

  return (
    <button
      className={classNames(
        styles.tabButton,
        className && className,
        variant === 'text' && styles.textTab,
        isActiveTab && styles.isActive
      )}
      onClick={() => handleClick(index ? index : 0)}
      type="button"
      role="tab"
      aria-selected="false"
      tabIndex={-1}
    >
      {label}
    </button>
  )
}

Tab.displayName = 'Tab'

interface TabPanelProps {
  className?: string
  value: number
  index: number
  children: React.ReactNode
}

export const TabPanel = ({
  children,
  value,
  index,
  className,
}: TabPanelProps): JSX.Element | null => {
  const isActiveTab = value === index

  return (
    <div
      className={classNames(
        styles.tabPanel,
        isActiveTab && styles.tabPanelActive,
        className && className
      )}
    >
      {isActiveTab ? children : null}
    </div>
  )
}

TabPanel.displayName = 'TabPanel'
