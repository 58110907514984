const data: TokenInfo[] = [
  {
    id: '0',
    ticker: 'GREAT',
    totalSupply: 100000000,
    initialMarketCap: 7500000,
    tokenPriceTON: 0.0075,
    tokenPriceUSD: 100500,
    saleStartTime: 'April 29th 2022, 1:00 PM UTC',
    saleEndTime: 'March 5th 2022, 1:00 PM UTC',
    tokenAddress: '0x11a819beb0aa3327e39f52f90d65cc9bca499f33',
  },
  {
    id: '1',
    projectSlug: ['1', '6443', 'fanzee'],
    ticker: 'FNZ',
    totalSupply: 2100000000,
    initialMarketCap: 42000000,
    tokenPriceTON: 0.0075,
    tokenPriceUSD: 0.02,
    saleStartTime: 'TBA',
    saleEndTime: 'TBA',
    tokenAddress: 'null',
  },
  {
    id: '2',
    projectSlug: ['megaton'],
    ticker: 'MEGA',
  },
  {
    id: '3',
    projectSlug: ['fanton'],
    ticker: 'NFTs',
  },
  {
    id: '4',
    projectSlug: ['punks'],
    ticker: 'PUNK',
  },
  {
    id: '5',
    projectSlug: ['stonfi'],
    ticker: 'special NFTs',
  },
  {
    id: '6',
    projectSlug: ['dedust'],
    ticker: 'SCALE',
  },
  {
    id: '7',
    projectSlug: ['tondiamonds'],
    ticker: 'GLINT',
  },
]

export type TokenInfo = {
  id: string
  projectSlug?: Array<String>
  ticker: string
  totalSupply?: number
  initialMarketCap?: number
  tokenPriceTON?: number
  tokenPriceUSD?: number
  saleStartTime?: string
  saleEndTime?: string
  tokenAddress?: string
}

export class TokenService {
  static getTokenByProject(projectSlug: string): TokenInfo {
    const tokens = data.filter((token) =>
      token.projectSlug?.includes(projectSlug)
    )

    return tokens.length ? tokens[0] : data[0]
  }
}
