import { Button, isWebApp, Page } from '@design-system'
import { currentUserSelector } from '@store'
import cn from 'classnames'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { PageHeader } from '../../components'
import { Backers, Cards, Openrating, Posts } from './components'
import styles from './Main.scss'

export const MainPage = (): JSX.Element => {
  const currentUser = useSelector(currentUserSelector)
  const isWebapp = isWebApp()

  const [activeTab, setActiveTab] = useState('all')

  window.addEventListener('scroll', function () {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const opacity = 1 - scrollTop / 50

    if (opacity >= 0 && topSignElem.current) {
      topSignElem.current.style.opacity = `${opacity}`
    }
  })

  const topSignElem = useRef<HTMLDivElement>(null)

  return (
    <Page>
      <Page.Head />
      <PageHeader />
      <Page.Body withHeader>
        <div className={styles.topSign} ref={topSignElem}>
          ton-native launchpad
        </div>
        <div className={styles.sectionTabs}>
          <Button
            variant={activeTab === 'all' ? 'contrast' : 'stroke'}
            onClick={() => setActiveTab('all')}
          >
            ALL
          </Button>
          <Button
            className={cn(
              styles.coloredPrivate,
              styles.colored,
              activeTab === 'private' && styles.coloredActive
            )}
            variant="stroke"
            onClick={() => setActiveTab('private')}
          >
            PRIVATE ROUND
          </Button>
          <Button
            className={cn(
              styles.coloredWhitelist,
              styles.colored,
              activeTab === 'whitelist' && styles.coloredActive
            )}
            variant={activeTab === 'whitelist' ? 'contrast' : 'stroke'}
            onClick={() => setActiveTab('whitelist')}
            
          >
            WHITELIST
          </Button>

          <Button
            variant={activeTab === 'ido' ? 'contrast' : 'stroke'}
            onClick={() => setActiveTab('ido')}
          >
            IDO
          </Button>
          <Button
            variant={activeTab === 'open' ? 'contrast' : 'stroke'}
            onClick={() => setActiveTab('open')}
          >
            OPEN
          </Button>
        </div>

        <Cards
          filter={activeTab}
          showPrivate={currentUser?.isPrivateInvestor}
        />
        <Openrating />
        <Backers />
        <Posts />
      </Page.Body>
      {!isWebapp ? <Page.Footer /> : null}
    </Page>
  )
}
