import React from 'react'

import { Page } from '@design-system'
import styles from './legal.scss'

export const PrivacyPolicyPage = (): JSX.Element => {
  return (
    <Page>
      <Page.Header />
      <Page.Body>
        <div className={styles.root}>
          <p className={styles.title}>TONSTARTER PRIVACY POLICY</p>
          <p className={styles.row}>
            Date of original publication: August, 17, 2022
          </p>
          <p className={styles.row}>
            Thank you for visiting our website{' '}
            <a href="https://tonstarter.com" target="_blank">
              https://tonstarter.com
            </a>{' '}
            ("Website"). This Privacy Policy ("Policy") sets out the basis on
            which any Personal Data collected from you, or that you provide to
            us, will be processed by [name of the company, address, registration
            number] ("Tonstarter", "us", "we", "our").
          </p>
          <p className={styles.row}>
            This Privacy Policy together with our Terms of Use and any
            additional provisions that may be incorporated there apply to your
            use of our Website ("Platform") and any of the Services accessible
            through the Website.
          </p>
          <p className={styles.row}>
            Please read the following Policy carefully and make sure you
            understand what Personal Data ("Data") we collect, how this Data is
            stored/used and which way it may be shared by Us.
          </p>
          <p className={styles.row}>
            If you have any questions about this Policy, or any concerns about
            how we treat your Data, you can reach Tonstarter directly via
            support@tonstarter.com contact our Data Protection Officer
            support@tonstarter.com.
          </p>
          <p className={styles.row}>
            You hereby acknowledge and agree that we cannot guarantee that any
            losses or unauthorized access to your Data will not occur.
          </p>
          <p className={styles.row}>
            IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY IN GENERAL OR IN ANY
            PART, YOU SHOULD NOT USE OUR WEBSITE AND OUR SERVICES.
          </p>
          <p className={styles.header}>1. WHAT PERSONAL DATA WE COLLECT</p>
          <p className={styles.row}>
            a) When you access to our Website we may collect the following Data
            automatically:
          </p>
          <ul>
            <li>Internet Protocol (IP) address, OS;</li>
            <li>
              Browser, device information (device ID, hardware model, etc.);
            </li>
            <li>Country, location, language, GPS;</li>
            <li>
              Time of access to Website, clickstream data, time stamps, page
              response time, occurring errors, duration of visits to pages;
            </li>
            <li>
              Services you searched/viewed, information about the relationship
              of pages (scrolling, clicks and mouse hovering), page interaction
              information;
            </li>
            <li>Google Analytics;</li>
            <li>Cookies;</li>
            <li>Other technical info that does not reveal your identity.</li>
          </ul>
          <p className={styles.row}>
            b) When you link your digital wallet ("Wallet") on the Website, in
            addition to Data shown in clause "a" we may collect:
          </p>
          <ul>
            <li>Public blockchain data;</li>
            <li>Wallet type and identification data; </li>
            <li>Transactions data;</li> <li>Stored smart contracts data;</li>
            <li>
              Other information which may become available to us directly or
              through third party Services.
            </li>
          </ul>
          <p className={styles.row}>
            c) When you link your Telegram account ("TG") on the Website, in
            addition to Data shown in clause "a" we may collect the following
            Data:
          </p>
          <ul>
            <li>
              all information which is displayed in your TG (phone number,
              profile picture, name, links to third party resources, etc.).
            </li>
          </ul>
          <p className={styles.header}>2. HOW WE USE YOUR PERSONAL DATA</p>
          <p className={styles.row}>
            Your Data may be used by Us for various purposes including, but not
            limited to:
          </p>
          <ul>
            <li>To provide you timely and full access to our Services;</li>
            <li>To fulfill our contract obligations;</li>
            <li>
              To prevent unlawful activity and to protect your interests.
              Although, Tonstarter is not liable to provide any security
              services we do our best to detect and prevent damage to our Users;
            </li>
            <li>
              To comply with applicable laws and regulatory requirements related
              to protection of Personal Data;
            </li>
            <li>
              To provide you technical support. We may use your Data to send you
              administrative information about changes to our terms, conditions
              and policies;
            </li>
            <li>
              To be able to receive feedback from you and update you with latest
              information about our Platform/Services;
            </li>
            <li>To carry out verification activities;</li>
            <li>For business purposes and marketing.</li>
          </ul>
          <p className={styles.header}>3. HOW WE PROTECT YOUR PERSONAL DATA</p>
          <p className={styles.row}>
            We will do our best to protect your Data from unauthorized access
            with our system of organizational and technical security measures.
            These security measures fully comply with applicable laws and meet
            the highest standards in Data protection sphere. However, despite
            mentioned above facts it is impossible to achieve complete security
            of your Data.
          </p>
          <p className={styles.row}>
            You hereby acknowledge and agree that Tonstarter will not:
          </p>
          <ul>
            <li>
              a) be liable for any loss or damage which may occur due to
              unauthorized access to your Data;
            </li>
            <li>
              b) hold any responsibility for any actions committed by any
              service providers or any other third parties;
            </li>
            <li>
              c) be liable for any leak of Data and any loss or damage it may
              cause.
            </li>
          </ul>
          <p className={styles.header}>
            4. HOW LONG WE KEEP YOUR PERSONAL DATA
          </p>
          <p className={styles.row}>
            We will only keep your Personal Data in an identifiable format for
            the least amount of time necessary to fulfill our legal/regulatory
            obligations and for our business purposes. We may retain your Data
            for longer periods than required by law if it is in our legitimate
            business interests and is not directly prohibited by the applicable
            law. When Personal Data is no longer necessary for the purpose for
            which it was collected, we will remove any details that identifies
            you or we will securely destroy the records, where permissible.
            However, we may need to maintain records for a significant period of
            time (even after you cease using our Services) if it will be
            required by law (tax, accounting, other legal purposes).
          </p>
          <p className={styles.header}>5. COOKIES</p>
          <p className={styles.row}>
            We may log information using "cookies". When you visit the Website,
            we invite you to read and accept our use of cookies. By accepting
            the banner and continuing to use the Website, you agree to the
            placement of cookies in your browser in accordance with this Policy.
            A cookie is a small text file that is stored on your computer to
            help us make your visit to our Website more convenient. Cookies
            provide us with information about your use of the Website that can
            help us to improve it. We will process Personal Data collected
            through cookies in accordance with this Privacy Policy. You may
            refuse cookies by turning them off in your browser, however, you
            should be aware that our Website, like most other sites, may not
            work well with cookies disabled.
          </p>
          <p className={styles.row}>We use the following Cookies:</p>
          <ul>
            <li>
              1. Strictly necessary Cookies. These are Cookies that are required
              for the operation of our Website.
            </li>
            <li>
              Analytical Cookies. They allow us to recognize and count the
              number of visitors and to see how visitors move around our Website
              when they are using it. This helps us to improve the way our
              Website works.
            </li>
            <li>
              Targeting Cookies. These Cookies record your visit to our Website,
              the pages you have visited and the links you have followed. We may
              also share this information with third parties for this purpose.
            </li>
          </ul>
          <p className={styles.header}>6. THIRD PARTY SERVICES</p>
          <p className={styles.row}>
            The Website may include links to third party resources which we have
            no control of. The fact that such information is displayed on the
            Website does not mean that Tonstarter approves content provided on
            such websites or is in any way related to it.
          </p>
          <p className={styles.row}>
            These links provided for your convenience only, once your follow the
            link and leave the Website you will be no longer governed by this
            Policy and you should refer to third party website Privacy Policy.
          </p>
          <p className={styles.row}>
            To be able to use some of our Services you will need to have third
            party Wallet which allows to carry out transactions on TON
            blockchain and active TG account. Your interaction with such
            services are governed by applicable third parties Privacy Policies.
          </p>
          <p className={styles.header}>7. PERSONAL DATA TRANSFER</p>
          <p className={styles.row}>
            To ensure the most effective and seamless delivery of the Services,
            Tonstarter reserves a right to process, store, analyze, transfer
            your Data in different locations, including countries other than
            your home jurisdiction where the data protection laws may differ and
            be not so protective. Tonstarter is a company registered in United
            Arab Emirates and will take all necessary preparations to ensure
            that your Data is protected and treated in accordance with this
            Privacy Policy. Your consent to this Privacy Policy followed by your
            submission or our collection of such Personal Data represents your
            agreement to such transfer.
          </p>
          <p className={styles.header}>8. DISCLOSURE OF YOUR PERSONAL DATA</p>
          <p className={styles.row}>
            You hereby acknowledge and agree that Tonstarter have the right to
            disclose and share your Data with any person/in any circumstances
            defined below:
          </p>
          <ul>
            <li>
              Third Party Service Providers, if it is necessary to supply
              Services you have requested, or to provide additional technical
              and customer support; and/or to provide other support to us and to
              the Services;
            </li>
            <li>
              If Tonstarter will be involved in a business transition or any
              merger projects, Data might be transferred to a new company. Such
              company will possess the Personal Data collected by us and will
              assume the rights and obligations regarding your Personal Data as
              described in this Policy.
            </li>
            <li>
              We may share your Personal Information with law enforcement, and
              government officials when we are compelled to do so: (i)by a
              subpoena, (ii)a court order to prevent financial loss, (iii)to
              report suspected illegal activity, (iv) in connection with any
              legal investigation, (v) to comply with relevant laws or to
              respond to subpoenas, warrants, or other legal process served on
              Tonstarter, (vi) to protect or defend the rights or property of
              Tonstarter or users of the Service, (vii) to investigate or assist
              in preventing any violation or potential violation of the law,
              this Privacy Policy, or our Terms of Service.
            </li>
            <li>
              If it is necessary to fulfill the purpose for which you provided
              Data, or with you prior consent.
            </li>
          </ul>
          <p className={styles.header}>9. PERSONAL DATA ACCESS AND CONTROL</p>
          <p className={styles.row}>
            If you are located in the European Economic Area, United Kingdom or
            Switzerland certain aspects related to your Data will be regulated
            by European and UK General Data Protection Regulations ("GDPR"). In
            this case you will also have additional rights such as: (i) right to
            be informed, (ii) right of access, (iii) right to rectify, (iv)
            right to erasure, (v) right to data portability, (vi) right to
            restrict processing, (vii) right to object, (viii) right to withdraw
            consent.
          </p>
          <p className={styles.row}>
            If you wish to exercise any of your GDPR rights, please contact us
            with you request via email address support@tonstarter.com
          </p>
          <p className={styles.row}>
            Please bear in mind, that we might need to proceed additional checks
            to verify your identity, or we may ask to provide additional
            information with regard to your request.
          </p>
          <p className={styles.row}>
            We cannot edit or delete any Data that is stored on a blockchain due
            to a nature of technology.
          </p>
          <p className={styles.header}>10. LIMITATION</p>
          <p className={styles.row}>
            Tonstarter Website and Services are not directed to individuals who
            are under 18 years or old or under legal age established under
            applicable laws of country where person is using our Services. You
            are not allowed to provide us any Data or in any way interact with
            our Services if you have not attained required age. If we will find
            out that you achieved unauthorized access to our Platform/Services
            we will delete your Data and block your access to our
            Platform/Services.
          </p>
          <p className={styles.header}>11. CHANGES TO THE PRIVACY POLICY</p>
          <p className={styles.row}>
            We reserve the right to update this Privacy Policy in our sole
            discretion from time to time. We will leave mark "Last revised" at
            the beginning of document. If you do not agree, fully or partially
            to the revised version of Privacy Policy, you shall not access to
            our Website or Services, by continuing using our Website or Services
            you agree to be bound by this Privacy Policy, as modified by such
            update.
          </p>
          <p className={styles.header}>
            12. NAME AND ADDRESS OF THE CONTROLLER
          </p>
          <p className={styles.row}>
            Tonstarter is the controller and responsible for your Personal Data.
          </p>
          <p className={styles.row}>Name: Tonstarter</p>{' '}
          <p className={styles.row}>Email: support@tonstarter.com</p>
        </div>
      </Page.Body>
      <Page.Footer />
    </Page>
  )
}
