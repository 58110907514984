// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jNCI5{height:36px;line-height:36px;background-color:var(--color-bg-secondary);text-align:center;font-size:16px;font-weight:400}", "",{"version":3,"sources":["webpack://./src/shared/components/TestnetAlarm/TestnetAlarm.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,gBAAA,CACA,0CAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".root {\n  height: 36px;\n  line-height: 36px;\n  background-color: var(--color-bg-secondary);\n  text-align: center;\n  font-size: 16px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "jNCI5"
};
export default ___CSS_LOADER_EXPORT___;
