import { LocalStorageService } from './LocalStorageService'

interface AuthServiceCredentials {
  accessToken: string | null
  refreshToken: string | null
}

const accessTokenName = 'accessToken'
const refreshTokenName = 'refreshToken'

export class AuthService {
  static setCredentials(credentials: AuthServiceCredentials) {
    LocalStorageService.setItem(accessTokenName, credentials.accessToken)
    LocalStorageService.setItem(refreshTokenName, credentials.refreshToken)
  }

  static getCredentials(): AuthServiceCredentials {
    return {
      accessToken: LocalStorageService.getItem(accessTokenName),
      refreshToken: LocalStorageService.getItem(refreshTokenName),
    }
  }

  static isAuth(): boolean {
    return !!AuthService.getCredentials().accessToken
  }

  static clearCredentials() {
    LocalStorageService.setItem(accessTokenName, null)
    LocalStorageService.setItem(refreshTokenName, null)
  }
}
