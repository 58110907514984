import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '..'
import { LocalStorageService, TonConnectService } from '../../services'
import { WalletAdapterInterface, WalletStatus, WalletType } from './types'
import { getOwnerAndPublicKey, getWalletInfo } from './walletActions'

interface WalletState {
  adapter: WalletAdapterInterface | null
  address: string | null
  walletType: WalletType | null
  connectionStatus: WalletStatus
  balance: string | null
  publicKey: string
  owner: string
}

const initialState: WalletState = {
  adapter: null,
  walletType: null,
  address: null,
  balance: null,
  publicKey: '',
  owner: '',
  connectionStatus: WalletStatus.DISCONNECTED,
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    disconnectWallet: (state) => {
      state.address = null
      state.balance = null
      state.walletType = null
      state.connectionStatus = WalletStatus.DISCONNECTED

      LocalStorageService.setItem('wallet-address', null)
      LocalStorageService.setItem('wallet-balance', null)
      LocalStorageService.setItem('wallet-type', null)
      LocalStorageService.setItem('owner-address', null)

      TonConnectService.disconnect()
    },
    connectWallet: (state, { payload }) => {
      state.address = payload.address
      state.connectionStatus = WalletStatus.CONNECTED
      state.walletType = payload.walletType

      LocalStorageService.setItem('wallet-address', payload.address)
      LocalStorageService.setItem('wallet-type', payload.walletType)
    },
  },
  extraReducers(builder) {
    builder.addCase(getWalletInfo.fulfilled, (state, action) => {
      LocalStorageService.setItem('wallet-state', action.payload.state)
      LocalStorageService.setItem('wallet-balance', action.payload.balance)
      state.balance = action.payload.balance
    })

    builder
      .addCase(getOwnerAndPublicKey.fulfilled, (state, action) => {
        LocalStorageService.setItem('public-key', action.payload.publicKey)
        LocalStorageService.setItem('owner-address', action.payload.address)
        state.publicKey = action.payload.publicKey
        state.owner = action.payload.address
      })
      .addCase(getOwnerAndPublicKey.rejected, (state, action) => {
        state.publicKey = ''
        state.owner = ''
      })
  },
})

export const walletAddressSelector = (state: RootState): string | null =>
  state.wallet.address

export const walletTypeSelector = (state: RootState): WalletType | null =>
  state.wallet.walletType

export const walletBalanceSelector = (state: RootState): string | null =>
  state.wallet.balance

export const currentOwnerSelector = (state: RootState): string =>
  state.wallet.owner

export const publicKeySelector = (state: RootState): string =>
  state.wallet.publicKey

export const walletConnectionStatusSelector = (
  state: RootState
): WalletStatus => state.wallet.connectionStatus

export const { disconnectWallet, connectWallet } = walletSlice.actions

export default walletSlice.reducer
