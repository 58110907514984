import React from 'react'

import {Image, Text } from '@design-system'
import styles from '../../components/About/About.scss'

export const FantonTokenomics = () => {
  const headerType = 'title-24'
  const paragraphType = 'paragraph-16';
  const paragraphColor = 'secondary'

  return (
    <div className={styles.row}>
      <Text type={headerType} spacingBottom="16">Utility</Text>
      <Image
        src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-utility.png"
        alt="Fanton token utility"
        borderRadius="16px"
        lightbox={{
          images: [
            {
              alt: 'Fanton token utility',
              src: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-utility.png',
            },
          ],
        }}
      />
      <br></br>
      <Text type={headerType} spacingBottom="16">Tokenomics</Text>
      <Image
        src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-allocation.png"
        alt="Fanton tokenomics"
        borderRadius="16px"
        lightbox={{
          images: [
            {
              alt: 'Fanton tokenomics',
              src: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-allocation.png',
            },
          ],
        }}
      />
      <br></br>
      <Text type={headerType} spacingBottom="16">Vesting</Text>
      <Image
        src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-vesting.png"
        alt="Fanton token vesting"
        borderRadius="16px"
        lightbox={{
          images: [
            {
              alt: 'Fanton token vesting',
              src: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-vesting.png',
            },
          ],
        }}
      />
      <br></br>
      <Text type={headerType} spacingBottom="16">Deflation</Text>
      <Image
        src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-deflation.png"
        alt="Fanton token deflation"
        borderRadius="16px"
        lightbox={{
          images: [
            {
              alt: 'Fanton token deflation',
              src: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-deflation.png',
            },
          ],
        }}
      />
  </div>
  )
}


