import classNames from 'classnames'
import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Icon } from '@design-system'

import styles from './TokenInput.scss'

interface TokenInputProps {
  value: string | undefined
  label: string
  disabled?: boolean
  allowDecimal?: boolean
  symbol?: string
  placeholder?: string
  hint?: string
  onMax?: () => void
  secondaryValue?: string
  validators?: { (value: string | undefined): string | null }[]
  onValidate?: (isValid: boolean) => void
  onChange?: (value: string) => void
}

const INPUT_REGEXP = RegExp(`^\\d*(?:\\\\[.])?\\d*$`)

export const TokenInput = ({
  value,
  label,
  symbol,
  disabled = false,
  allowDecimal = true,
  onChange,
  hint,
  placeholder,
  secondaryValue,
  onMax,
  validators = [],
  onValidate,
  ...rest
}: TokenInputProps): JSX.Element => {
  const [error, setError] = useState<string | null>(null)
  const inputElem = useRef<HTMLInputElement>(null)

  const classList = classNames(
    styles.root,
    disabled && styles.disabled,
    error && styles.hasError
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (disabled) {
        return
      }

      let clearValue = (value = event.target.value.replaceAll(',', ''))

      if (allowDecimal) {
        clearValue = clearValue.replace(/,/g, '.')
      } else {
        clearValue = clearValue.replace(/\./g, '')
      }

      if (clearValue === '') {
        return onChange && onChange(clearValue)
      }

      if (
        INPUT_REGEXP.test(clearValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
      ) {
        onChange && onChange(clearValue)
      }
    },
    [onChange]
  )

  useEffect(() => {
    let error = null

    validators.every((v) => {
      error = v(value?.replaceAll(',', ''))

      if (onValidate) {
        error ? onValidate(false) : onValidate(true)
      }

      return error ? false : true
    })

    setError(error)
  }, [value])

  const showMaxButton = !!onMax
  const valueDisplay = !value ? '' : new Intl.NumberFormat('en-US').format(parseInt(value))

  const handleFocus = () => {
    if (!disabled && inputElem?.current) {
      inputElem.current.focus()
    }
  }

  const handleMax = () => {
    if (!disabled && onMax) {
      onMax()
    }
  }

  function chooseIcon(symbol?: string): JSX.Element {
    switch (symbol){
      case 'TON':
        return <Icon size="16" colorFill="primary" name="ton-outline" />
      case 'USD':
        return <Icon size="16" colorFill="primary" name="currency-dollar" />
      default:
        return <Icon size="16" colorFill="primary" name="currency-dollar" />
    }
  }

  return (
    <div className={classList} {...rest}>
      <div className={styles.label}>{label}</div>
      <div className={styles.inputWrapper} onClick={handleFocus}>
        <div className={styles.inputLeft}>
          { chooseIcon(symbol) }
          <div className={styles.textWrapper}>
            <div className={styles.textPlaceholder}>{valueDisplay}</div>
            <input
              ref={inputElem}
              onChange={handleChange}
              value={valueDisplay}
              className={styles.input}
              inputMode={allowDecimal ? 'decimal' : 'numeric'}
              autoComplete="off"
              autoCorrect="off"
              type="text"
              spellCheck="false"
              disabled={disabled}
              placeholder={placeholder}
            />
          </div>
        </div>
        {secondaryValue && (
          <div className={styles.inputCourse}>{secondaryValue}</div>
        )}

        {showMaxButton && (
          <div className={styles.maxButton} onClick={handleMax}>
            MAX
          </div>
        )}
      </div>
      {error && <div className={styles.error}>{error}</div>}
      {hint && <div className={styles.hint}>{hint}</div>}
    </div>
  )
}
