import React, { useEffect } from 'react'

import { useMobileLayout } from '@design-system'
import { fetchMe, isAuthSelector, useAppDispatch } from '@store'
import { useSelector } from 'react-redux'
import { HeaderDesktop } from './HeaderDesktop'
import { HeaderMobile } from './HeaderMobile'

export interface HeaderProps {
  className?: string
  classNameContainer?: string
  twitter?: string
  telegram?: string
  logo: JSX.Element
  links: { [key: string]: any }[]
  theme: 'light' | 'dark'
  walletBalance: string | null
  walletAddress: string | null

  onThemeToggle(): void
  onConnectWallet(): void
  onDisconnectWallet(): void
}

export function Header({
  className,
  twitter,
  telegram,
  logo,
  links,
  theme,
  onThemeToggle,
  onConnectWallet,
  onDisconnectWallet,
  walletBalance,
  walletAddress,
  classNameContainer,
}: HeaderProps): JSX.Element {
  const isMobileViewport = useMobileLayout()
  const isAuth = useSelector(isAuthSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchMe())
  }, [isAuth])

  return isMobileViewport ? (
    <HeaderMobile
      className={className}
      classNameContainer={classNameContainer}
      logo={logo}
      links={links}
      theme={theme}
      walletBalance={walletBalance}
      walletAddress={walletAddress}
      onThemeToggle={onThemeToggle}
      onConnectWallet={onConnectWallet}
      onDisconnectWallet={onDisconnectWallet}
    />
  ) : (
    <HeaderDesktop
      className={className}
      classNameContainer={classNameContainer}
      twitter={twitter}
      telegram={telegram}
      logo={logo}
      links={links}
      theme={theme}
      walletBalance={walletBalance}
      walletAddress={walletAddress}
      onThemeToggle={onThemeToggle}
      onConnectWallet={onConnectWallet}
      onDisconnectWallet={onDisconnectWallet}
    />
  )
}
