import React from 'react'

import { AirdropPage } from '../AirdropPage'

export const Atlas = () => {
  return <AirdropPage
    slug='tonatlas'
    communityLink="https://t.me/community_bot?start=ap_3"
    imageCarousel={
      <video
        src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/atlas/atlas-intro-sound.mp4"
        autoPlay
        playsInline
        muted
        loop
        style={{
          width: '100%',
          borderRadius: '16px',
        }}
      />
    }
  />
}