import React from 'react'

import { Link, Text, useMobileLayout } from '@design-system'

import styles from '../components/About/About.scss'

export const StonFaq = () => {
  const isMobileLayout = useMobileLayout()

  const extraHeaderType = isMobileLayout ? 'header-32' : 'header-44'
  const headerType = isMobileLayout ? 'title-24' : 'title-28'
  const subheaderType = isMobileLayout ? 'title-20' : 'title-24'
  const paragraphType = isMobileLayout ? 'paragraph-16' : 'paragraph-20'
  const questionColor = 'primary'
  const answerColor = 'secondary'
  const spacingAfterQuestion = '32'

  return (
    <>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Rewards pool
        </Text>
        <Text type={paragraphType} color={questionColor}>
        <b>What rewards can I get?</b>
        </Text>
        <Text type={paragraphType} color={answerColor} spacingBottom={spacingAfterQuestion}>
          All winners will receive [anti]glitch_1 NFTs from STON.fi.
          <br></br>
          The top 50 leaders on the scoreboard will receive:
          <br></br>
          <ul style={{margin: '0'}}>
            <li>Top 10 by score — <Link href="https://getgems.io/collection/EQBRh_n88N7c_LLO8et2QX92wJHLhJvDuiKKgk9L5cyDCiqa?filter=%7B%22attributes%22%3A%7B%22category%22%3A%5B%221%22%5D%7D%7D" textType={paragraphType}>1 cat.</Link> 10 NFTs</li>
            <li>Top 11-30 by score — <Link href="https://getgems.io/collection/EQBRh_n88N7c_LLO8et2QX92wJHLhJvDuiKKgk9L5cyDCiqa?filter=%7B%22attributes%22%3A%7B%22category%22%3A%5B%223%22%5D%7D%7D" textType={paragraphType}>3 cat.</Link> 20 NFTs</li>
            <li>Top 31-50 by score — <Link href="https://getgems.io/collection/EQBRh_n88N7c_LLO8et2QX92wJHLhJvDuiKKgk9L5cyDCiqa?filter=%7B%22attributes%22%3A%7B%22category%22%3A%5B%224%22%5D%7D%7D" textType={paragraphType}>4 cat.</Link> 20 NFTs</li>
          </ul>
          <br></br>
          We will randomly select 50 lucky users based on their chances. The more points you have, the higher your chance of being selected:
          <ul>
            <li>Random by chance 51-60  — <Link href="https://getgems.io/collection/EQBRh_n88N7c_LLO8et2QX92wJHLhJvDuiKKgk9L5cyDCiqa?filter=%7B%22attributes%22%3A%7B%22category%22%3A%5B%224%22%5D%7D%7D" textType={paragraphType}>4 cat.</Link> 10 NFTs</li>
            <li>Random by chance 61-100 — <Link href="https://getgems.io/collection/EQBRh_n88N7c_LLO8et2QX92wJHLhJvDuiKKgk9L5cyDCiqa?filter=%7B%22attributes%22%3A%7B%22category%22%3A%5B%225%22%5D%7D%7D" textType={paragraphType}>5 cat.</Link> 40 NFTs</li>
          </ul>
          NFT prices change dynamically, but you can learn more about the actual size of the rewards pool by looking at prices on <Link href="https://getgems.io/collection/EQBRh_n88N7c_LLO8et2QX92wJHLhJvDuiKKgk9L5cyDCiqa?filter=%7B%22attributes%22%3A%7B%22category%22%3A%5B%221%22%2C%223%22%2C%224%22%2C%225%22%5D%7D%7D" textType={paragraphType}>Getgems</Link>.
        </Text>

        {/* <Text type={paragraphType} color={questionColor}>
          <b>Can I win with a tiny chance, like 5%?</b>
        </Text>
        <Text type={paragraphType} color={answerColor} spacingBottom={spacingAfterQuestion}>
          Yes, it is possible to win with any chance. Previously, rewards have been received with both a 60% chance and a 9% chance. However, it is certainly better to earn more points and have a higher chance of winning.
        </Text> */}

        <Text type={paragraphType} color={questionColor}>
          <b>How will the winners be chosen?</b>
        </Text>
        <Text type={paragraphType} color={answerColor} spacingBottom={spacingAfterQuestion}>
          <ul style={{margin: '0'}}>
            <li>50 winners will be selected from the leaderboard (available in live mode);</li>
            <li>50 winners will be randomly chosen.</li>
          </ul>
          {/* The results will be available here, and the awards will be sent immediately to the wallets of winners. */}
        </Text>

        {/* <Text type={paragraphType} color={questionColor}>
          <b>What if users have the same amount of points in the leaderboard?</b>
        </Text>
        <Text type={paragraphType} color={answerColor} spacingBottom={spacingAfterQuestion}>
          The one who completes the task first will be at the top of the list. The earlier the task is completed, the more likely it is to be higher in the leaderboard.
        </Text>

        <Text type={paragraphType} color={questionColor}>
          <b>When does the program end?</b>
        </Text>
        <Text type={paragraphType} color={answerColor} spacingBottom={spacingAfterQuestion}>
          The program will end on May 17, 2023, at 12:00 UTC.
        </Text> */}
      </div>

      {/* <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Tasks
        </Text>

        <Text type={paragraphType} color={questionColor}>
          <b>Step-by-step guides for completing the tasks.</b>
        </Text>
        <Text type={paragraphType} color={answerColor} spacingBottom="16">
          <ol style={{margin: '0'}}>
            <li><Link href="https://telegra.ph/STONfi-from-S-to-i-03-20#How-to-exchange-tokens-on-STON.fi" textType={paragraphType}>How to make a swap on STON.fi</Link></li>
            <li><Link href="https://telegra.ph/STONfi-from-S-to-i-03-20#How-to-earn-on-STON.fi-(liquidity-provision)" textType={paragraphType}>How to provide liquidity to STON.fi</Link></li>
          </ol>
        </Text>
        <Text type={paragraphType} color={answerColor} spacingBottom={spacingAfterQuestion}>
          ⚠️ Please be aware that liquidity pools do not guarantee profits. Your earnings, in terms of a stable currency such as USD, rely on the token exchange rate (e.g. oETH).
        </Text>

        <Text type={paragraphType} color={questionColor}>
          <b>How to earn more points?</b>
        </Text>
        <Text type={paragraphType} color={answerColor} spacingBottom={spacingAfterQuestion}>
          Learn more about the available tasks with <Link href='/project/stonfi?VovanMode' textType={paragraphType}>special mode</Link>, which allows to see all available tasks in the task list.
          Additionally, we'll publish special ways to earn points on our Telegram channel, stay tuned.
        </Text>

        <Text type={paragraphType} color={questionColor}>
          <b>What is the “daily on-chain interaction?”</b>
        </Text>
        <Text type={paragraphType} color={answerColor} spacingBottom={spacingAfterQuestion}>
          Just do anything you can on STON.fi: interact with swaps or liquidity pools. 
          For the first day, you can earn 30×1 points, 30×2 for the second, and a maximum of 30×5 for the fifth day. 
          After the fifth day, you will start again from 30×1. You can lose your progress if you interrupt a series.
        </Text>

        <Text type={paragraphType} color={questionColor}>
          <b>Which trading pairs do you monitor?</b>
        </Text>
        <Text type={paragraphType} color={answerColor} spacingBottom="16">
          We track swaps in the next pairs: 
          <br></br>
          TON ⇄ FNZ, BOLT, GGR, oETH, oORBS, oUSDC, oUSDT
        </Text>
        <Text type={paragraphType} color={answerColor} spacingBottom={spacingAfterQuestion}>
          We track liquidity provision in the next pairs. You can withdraw it immediately to earn 300 points:
          <br></br>
          TON ⇄ FNZ, BOLT, GGR, oETH, oORBS, oUSDC, oUSDT
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Referral system
        </Text>

        <Text type={paragraphType} color={questionColor}>
          <b>Where can I find my referral link?</b>
        </Text>
        <Text type={paragraphType} color={answerColor} spacingBottom={spacingAfterQuestion}>
          There are several ways how to find your ref-link:
          <ul style={{margin: '0'}}>
            <li>Put <code className={styles.code}>@ton_starter_bot</code> in any chat, wait till it get your info and paste sexy link with message</li>
            <li>Copy it from the task list on project's page</li>
            <li>Get from the <Link href="https://t.me/ton_starter_bot" textType={paragraphType}>@ton_starter_bot</Link> with the <code className={styles.code}>/ref</code> command</li>
          </ul>
        </Text>

        <Text type={paragraphType} color={questionColor}>
          <b>How to check my referrals?</b>
        </Text>
        <Text type={paragraphType} color={answerColor} spacingBottom={spacingAfterQuestion}>
          Text <code className={styles.code}>/my_refs</code> command in Tonstarter Assistant bot.
        </Text>

        <Text type={paragraphType} color={questionColor}>
          <b>Why don't I get points for a referral?</b>
        </Text>
        <Text type={paragraphType} color={answerColor} spacingBottom={spacingAfterQuestion}>
          Referrals are only eligible if:
          <ol style={{margin: '0'}}>
            <li>They went to the correct link.</li>
            <li>Their wallet and Telegram account aren’t in the Tonstarter system.</li>
            <li>They signed up for the program and confirmed their participation with a transaction in their wallet using the “Apply” button.</li>
            <li>They linked their Telegram account to <Link href="https://t.me/ton_starter_bot" textType={paragraphType}>@ton_starter_bot</Link>.</li>
          </ol>
          <br></br>
          In most cases, our referral system works fine. 
          The problem usually appears during the invitation stage, where referrals use an <i>incorrect link</i> or <i>have already participated in our events</i>.
        </Text>
      </div> */}
    </>
  )
}
