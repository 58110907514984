import React, { useEffect, useState } from 'react'

import { useQueryDesktopUp } from '@design-system'
import { progressiveImage } from '@utils'
import Anim from './anim.js'

import classNames from 'classnames'
import styles from './IntroImage.scss'

function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export const IntroImage = () => {
  const isDesktopViewport = useQueryDesktopUp()
  const [progressiveImg, setProgressiveImg] = useState<
    'avif' | 'webp' | 'png' | null
  >(null)

  progressiveImage().then(setProgressiveImg)

  useEffect(() => {
    if (!isSafari() && isDesktopViewport) {
      Anim()
    }

    const rocketElem = document.getElementById('chel-rocket')

    rocketElem?.addEventListener('click', () => {
      rocketElem?.classList.add(styles.rocketAnimation)

      setTimeout(() => {
        if (rocketElem) {
          rocketElem.classList.remove(styles.rocketAnimation)
          rocketElem.style.transform = 'translateX(150px)'
        }
      }, 4100)
    })
  }, [])

  return (
    <div
      className={classNames(
        styles.image,
        progressiveImg && styles[progressiveImg]
      )}
    >
      <div className={styles.board} id="chel-board"></div>
      <div className={styles.chelik}></div>
      <div className={styles.cloud}></div>
      <div className={styles.coins}></div>
      <div className={styles.rocket} id="chel-rocket"></div>
    </div>
  )
}
