import React, { useEffect, useState } from 'react'

import { Text } from '@design-system'
import {
  activeAllocationSelector,
  Allocation,
  allocationLoadStatusSelector,
  fetchProjectParticipants,
  fetchTickets,
  isAuthSelector,
  Project,
  projectParticipants,
  ProjectStatus,
  useAppDispatch,
  useAppSelector,
  User,
  userCompetitionStateSelector,
  walletAddressSelector,
} from '@store'
import { ApplyView } from './components/ApplyView'
import { CompetitionView } from './components/CompetitionView/CompetitionView'
import { CountResultsView } from './components/CountResultsView/CountResultsView'
import { Follow } from './components/Follow/Follow'
import { SidebarSkeleton } from './components/SidebarSkeleton'
import { SoldOutView } from './components/SoldOutView'
import { WinnerView } from './components/WinnerView'

import { TokenService } from '@services'
import styles from './AirdropSidebar.scss'
import { LockupView } from './components/LockupView'
import { SeregaMode } from './components/SeregaMode/SeregaMode'

interface SidebarProps {
  activeProject: Project
  user: User | null
}

export const AirdropSidebar = ({
  activeProject,
  user,
}: SidebarProps): JSX.Element => {
  const isSeregaMode = window.location.search.indexOf('SeregaMode') > 0
  const debug = false
  const dispatch = useAppDispatch()
  const isAuth = useAppSelector(isAuthSelector)
  const activeAllocation = useAppSelector(
    activeAllocationSelector
  ) as Allocation
  const allocationLoadStatus = useAppSelector(allocationLoadStatusSelector)
  const competitionState = useAppSelector(userCompetitionStateSelector)
  const walletAddress = useAppSelector(walletAddressSelector)
  const [isLoaded, setIsLoaded] = useState(false)
  const amountOfParticipants = useAppSelector(projectParticipants)

  const shouldRenderCompetitionView =
    isAuth &&
    activeProject.status === ProjectStatus.whitelistOpen &&
    activeAllocation?.id &&
    user?.telegramId

  const shouldRenderApplyView =
    activeProject.status === ProjectStatus.whitelistSoon ||
    (activeProject.status === ProjectStatus.whitelistOpen &&
      !shouldRenderCompetitionView)

  const shouldRenderCountResultsView =
    activeProject.status === ProjectStatus.whitelistClosed ||
    activeProject.status === ProjectStatus.whitelistFinish ||
    activeProject.status === ProjectStatus.live

  const shouldRenderSoldOutView =
    activeProject.status === ProjectStatus.soldOut &&
    (!activeAllocation?.isLotteryWinner ||
      Number(activeAllocation?.maxTokens) <= 0 ||
      !isAuth)

  const shouldRenderWinnerView =
    isAuth &&
    activeProject.status === ProjectStatus.soldOut &&
    activeAllocation?.isLotteryWinner &&
    Number(activeAllocation?.maxTokens) > 0 &&
    activeProject.slug !== 'dedust'

  const shouldRenderLockupView =
    isAuth &&
    activeProject.status === ProjectStatus.soldOut &&
    activeAllocation?.isLotteryWinner &&
    Number(activeAllocation?.maxTokens) > 0 &&
    activeProject.slug === 'dedust'

  const ticker = TokenService.getTokenByProject(activeProject.slug).ticker
  const fundsGoalFormatted = new Intl.NumberFormat('en-US').format(
    activeProject.fundsGoal
  )
  const rewardPool = `${fundsGoalFormatted} ${ticker}`

  const debugInfo = [
    {
      name: 'isAuth',
      value: isAuth,
    },
    {
      name: 'activeAllocation.id',
      value: activeAllocation?.id,
    },
    {
      name: 'allocationLoadStatus',
      value: allocationLoadStatus,
    },
    {
      name: 'activeProject.id',
      value: activeProject?.id,
    },
    {
      name: 'telegramId',
      value: user?.telegramId,
    },
    {
      name: 'walletAddress',
      value: walletAddress,
    },
  ]

  if (debug) {
    console.table(debugInfo)
  }

  useEffect(() => {
    if (user && activeProject?.id && activeAllocation?.id) {
      dispatch(
        fetchTickets({
          projectId: activeProject.id,
          telegramId: user?.telegramId,
          address: user?.friendlyAddress,
        })
      )
    }
  }, [user, activeProject, activeAllocation])

  useEffect(() => {
    if (activeProject?.id) {
      dispatch(fetchProjectParticipants({ projectId: activeProject.id }))
    }
  }, [activeProject])

  useEffect(() => {
    if (!isAuth && activeProject?.status) {
      setIsLoaded(true)
    }

    if (isAuth && activeProject?.status && allocationLoadStatus !== 'loading') {
      setIsLoaded(true)
    }
  }, [activeProject, isAuth, allocationLoadStatus])

  if (!isLoaded || competitionState === 'pending') {
    return (
      <>
        {isSeregaMode && <SeregaMode debugInfo={debugInfo} />}
        <SidebarSkeleton />
      </>
    )
  }

  return (
    <div className={styles.container}>
      {isSeregaMode && <SeregaMode debugInfo={debugInfo} />}
      {shouldRenderApplyView && (
        <ApplyView
          isAuth={isAuth}
          activeProject={activeProject}
          activeAllocation={activeAllocation}
          rewardPool={rewardPool}
          user={user}
        />
      )}

      {shouldRenderCompetitionView && (
        <CompetitionView
          competitionState={competitionState}
          project={activeProject}
          allocation={activeAllocation}
        />
      )}

      {shouldRenderCountResultsView && (
        <CountResultsView
          project={activeProject}
          winChance={activeAllocation?.chanceToWin}
          competitionState={competitionState}
          amountOfParticipants={amountOfParticipants}
          rewardPool={rewardPool}
        />
      )}

      {shouldRenderSoldOutView && (
        <SoldOutView
          project={activeProject}
          amountOfParticipants={amountOfParticipants}
        />
      )}

      {shouldRenderWinnerView && (
        <WinnerView
          projectTitle={activeProject.title}
          amountOfTokensWon={activeAllocation?.maxTokens}
          projectTicker={ticker}
          projectSlug={activeProject.slug}
        />
      )}

      {shouldRenderLockupView && (
        <LockupView project={activeProject} projectTicker={ticker} />
      )}

      <div className={styles.bochka}>
        <Text color="tertiary" align="center">
          Follow {activeProject.title} project
        </Text>
        <Follow projectDetails={activeProject.projectDetails} />
      </div>
    </div>
  )
}
