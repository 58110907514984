import React from 'react'

import { Link } from '@design-system'
import { ApiService } from '@services';

const twitterIcon = (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="#496AEB"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.0408 2.19286C15.4576 2.44758 14.8407 2.6168 14.2091 2.69529C14.8749 2.30049 15.3725 1.67503 15.6077 0.937586C14.9846 1.30745 14.3027 1.56794 13.5917 1.70776C13.156 1.2418 12.5901 0.917728 11.9678 0.777705C11.3454 0.637682 10.6953 0.688184 10.102 0.922644C9.50871 1.1571 8.99971 1.56467 8.64119 2.09233C8.28268 2.61999 8.09124 3.24333 8.09179 3.88126C8.08955 4.12476 8.11438 4.36775 8.16581 4.60576C6.90104 4.54364 5.66361 4.2154 4.53433 3.64248C3.40506 3.06957 2.40934 2.26486 1.61224 1.28094C1.20251 1.98088 1.07558 2.81088 1.25737 3.60128C1.43917 4.39169 1.91596 5.08283 2.59031 5.53344C2.08698 5.51987 1.5943 5.38535 1.15391 5.14126V5.17591C1.15533 5.90959 1.40928 6.62041 1.87307 7.1889C2.33687 7.75738 2.98223 8.14887 3.70069 8.29756C3.4288 8.36899 3.14862 8.40395 2.86751 8.40151C2.6651 8.40525 2.46287 8.3873 2.26429 8.34796C2.47012 8.97894 2.86639 9.53065 3.3986 9.92719C3.93082 10.3237 4.57282 10.5457 5.23631 10.5624C4.11196 11.4412 2.72588 11.9186 1.29881 11.9185C1.04454 11.9206 0.790404 11.9059 0.538086 11.8744C1.99318 12.8125 3.68928 13.3082 5.42059 13.3013C11.2717 13.3013 14.4705 8.45506 14.4705 4.25454C14.4705 4.11436 14.4705 3.97891 14.4595 3.84346C15.0825 3.39386 15.6183 2.83453 16.0408 2.19286Z" />
  </svg>
)

const handleTwitterLogin = async (event: React.MouseEvent<HTMLLinkElement, MouseEvent>) => {
  event.preventDefault()
  const { ok, data, error } = await ApiService.post({
    endpoint: '/twitter',
    data: {
      callbackPathname: window.location.pathname
    }
  })
  
  window.location.assign(data.authUrl)

  // const handleAuthentication = (event: MessageEvent) => {
  //   if (event.data === 'twitter-authenticated') {
  //     window.removeEventListener('message', handleAuthentication)
  //   }
  // }

  // window.addEventListener('message', handleAuthentication)
};

export const TwitterLogin = ({className} : {className?: string}) => {
  return <Link onClick={async (event) => await handleTwitterLogin(event)} icon={twitterIcon} iconPosition='right' className={className} rel="opener">
    Login with Twitter
  </Link>
}