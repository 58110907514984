export const news = [
  {
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/diamonds/news-cover-1.png',
    href: 'https://ton.diamonds/news/andrei-blinov-ton-epic-web3-conf',
    title:
      'Andrei Blinov, TON Diamonds CPO, gave a presentation about TON Diamonds',
    description:
      'Andrei Blinov, Head of Product at TON Diamonds, spoke on the topic of "Uniting traditional art and Web3: how to find a Product-Market Fit together with a loyal Community"',
  },
  {
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/diamonds/news-cover-2.png',
    href: 'https://ton.diamonds/news/191889408-the-first-farming',
    title:
      'The first farming season of Glint Coin, TON Diamonds marketplace token, is starting right now',
    description:
      'From this moment, Glint Coin is allocated daily to every TON Diamonds NFT holder, the speed and amount are available in the profile on ton.diamonds. Farming will happen in two seasons, each one lasting approximately 6 months',
  },
  {
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/diamonds/news-cover-3.png',
    href: 'https://ton.diamonds/news/ton-diamonds-new-york-2023',
    title:
      'TON Diamonds in New York: new international artists will present their NFT-artworkds at Focus Art Fair exhibition',
    description:
      'The eagerly-awaited TON Diamonds exhibition, the first of its scale this year, is set to take place from May 18th to May 21st in the bustling metropolis of New York City',
  },
]
