import React from 'react'

import {
  Button,
  Content,
  Icon,
  Member,
  Text,
  useMobileLayout,
} from '@design-system'
import { openNewTab } from '@utils'
import { Project } from '../store'

export const data: Project = {
  comingSoon: false,
  id: 'tonkeeper',
  name: 'Tonkeeper',
  slug: 'tonkeeper',
  iconUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonkeeper/logo.png',
  logoUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonkeeper/logo.png',
  logoDarkUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonkeeper/logo.png',
  urlTitle: 'Tonkeeper',
  urlLink: 'https://tonkeeper.com',
  expertRatingLevel: '1',
  expertRatingIndex: 88,
  expertRatingMarker: 'A+',
  communityRating: '',
  contractAddress: 'EQDuzI_Ut6h4mAQZFyVBCSon_jEKjK0F0rjzf17NwfLw1s8p',
  contractAddressTestnet: 'EQDuzI_Ut6h4mAQZFyVBCSon_jEKjK0F0rjzf17NwfLw1s8p',
  reportUrl: 'https://tonstarter.notion.site/Tonkeeper-a857833c825f4c838e71ef22df198a89?pvs=4',
  category: 'wallet',
  updatedAt: '13 Jul 2023',
  reports: [
    {
      reportType: 'reportV2',
      updatedAt: '13 Jul 2023',
      totalRate: '88%',
      values: {
        valueProposition: '94%',
        tokenomics: 'n/a',
        team: '80%',
        regulatory: '88%',
        community: '56%',
        tech: '95%',
        wallet: '94%',
      },

      reviewers: [
        {
          title: 'EST, TEC Team',
          subtitle: 'Report',
          link: 'https://t.me/TheOpenNetworkExpertClub',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/est.jpeg',
        },
        {
          title: 'Sergey Ch',
          subtitle: 'Review',
          link: 'https://twitter.com/serega_ton',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/sergey.png',
        },
      ],
    },
  ],
}

export const quote = {
  type: 'gpt',
  text: "Tonkeeper wallet is a secure and user-friendly digital wallet designed specifically for the TON blockchain ecosystem. With its intuitive interface and advanced security features, Tonkeeper offers a seamless experience for managing TON-based cryptocurrencies, storing digital assets, and participating in decentralized applications (dApps). Whether you're a seasoned TON enthusiast or a newcomer exploring the world of blockchain, Tonkeeper provides a reliable and convenient solution for your digital asset management needs.",
}

export function aboutProject(): JSX.Element {
  const isMobileViewport = useMobileLayout()

  const headerType = isMobileViewport ? 'title-24' : 'title-28'
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'
  const textSpacingBottom = isMobileViewport ? '32' : '40'

  return (
    <>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Tonkeeper is a non-custodial "hot" wallet that aims to provide users with a user-friendly interface for managing their digital assets. It incorporates features like Ton Connect integration and a built-in browser, enabling easy interaction with decentralized applications.
      </Text>
      
      <Text type={headerType} spacingBottom="24">
        Problem and Solution
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Tonkeeper addresses the challenges faced by users in managing their digital assets by offering a simple and intuitive solution. It provides a secure platform for users to send and receive cryptocurrency, view transaction history, and monitor their account balance while maintaining full control over their funds and assets.
      </Text>
      
      <Text type={headerType} spacingBottom="24">
        Contacts
      </Text>

      <Content
        spacingChild={isMobileViewport ? '40' : '240'}
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Oleg Andreev"
          role="CEO & Co-founder"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonapi/OlegAndreev.jpeg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() => openNewTab('https://t.me/oleganza')}
            >
              Telegram
            </Button>
          }
        />

        <Member
          name="Oleg Illarionov"
          role="Co-founder"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonapi/OlegIllarionov.jpeg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="outer-link" colorFill="primary" />}
              onClick={() =>
                openNewTab(
                  'https://www.linkedin.com/in/oleg-illarionov-76723321/'
                )
              }
            >
              LinkedIn
            </Button>
          }
        />
      </Content>

      <Content
        spacingChild={isMobileViewport ? '40' : '240'}
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Aleksej Paschenko"
          role="Senior Software Engineer"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonkeeper/aleksej.jpeg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="outer-link" colorFill="primary" />}
              onClick={() =>
                openNewTab(
                  'https://www.linkedin.com/in/aleksej-paschenko/'
                )
              }
            >
              LinkedIn
            </Button>
          }
        />
      </Content>
    </>
  )
}
