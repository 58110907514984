// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jtcgH{height:16px;width:90%;background:var(--color-skeleton);animation:_5pX4z 1.5s infinite}.tXujq{height:32px}.i\\+-uW{height:48px}._0w0TT{height:96px}.ADa-r{height:100%}.h\\+FSc{width:100%}._32HVT{border-radius:8px}.VBdQZ{border-radius:16px}@keyframes _5pX4z{0%{background:var(--color-skeleton)}50%{background:var(--color-fill-08)}100%{background:var(--color-skeleton)}}", "",{"version":3,"sources":["webpack://./src/shared/design-system/components/Skeleton/Skeleton.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,SAAA,CACA,gCAAA,CACA,8BAAA,CAGF,OACE,WAAA,CAGF,QACE,WAAA,CAGF,QACE,WAAA,CAGF,OACE,WAAA,CAGF,QACE,UAAA,CAGF,QACE,iBAAA,CAGF,OACE,kBAAA,CAGF,kBACE,GACE,gCAAA,CAEF,IACE,+BAAA,CAEF,KACE,gCAAA,CAAA","sourcesContent":[".root {\n  height: 16px;\n  width: 90%;\n  background: var(--color-skeleton);\n  animation: PLACEHOLDER_PULSE 1.5s infinite;\n}\n\n.height-32px {\n  height: 32px;\n}\n\n.height-48px {\n  height: 48px;\n}\n\n.height-96px {\n  height: 96px;\n}\n\n.height-100 {\n  height: 100%;\n}\n\n.width-100 {\n  width: 100%;\n}\n\n.radius-8px {\n  border-radius: 8px;\n}\n\n.radius-16px {\n  border-radius: 16px;\n}\n\n@keyframes PLACEHOLDER_PULSE {\n  0% {\n    background: var(--color-skeleton);\n  }\n  50% {\n    background: var(--color-fill-08);\n  }\n  100% {\n    background: var(--color-skeleton);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "jtcgH",
	"PLACEHOLDER_PULSE": "_5pX4z",
	"height-32px": "tXujq",
	"height-48px": "i+-uW",
	"height-96px": "_0w0TT",
	"height-100": "ADa-r",
	"width-100": "h+FSc",
	"radius-8px": "_32HVT",
	"radius-16px": "VBdQZ"
};
export default ___CSS_LOADER_EXPORT___;
