import { useMediaQuery } from 'react-responsive'

function useQuerySmallPhone(): boolean {
  return useMediaQuery({ query: '(max-width: 340px)' })
}

function useQueryPhoneOnly(): boolean {
  return useMediaQuery({ query: '(max-width: 599px)' })
}

function useQueryTabletPortraitUp(): boolean {
  return useMediaQuery({ query: '(min-width: 600px)' })
}

function useQueryTabletLandscapeDown(): boolean {
  return useMediaQuery({ query: '(max-width: 959px)' })
}

function useQueryTabletLandscapeUp(): boolean {
  return useMediaQuery({ query: '(min-width: 960px)' })
}

function useQueryDesktopUp(): boolean {
  return useMediaQuery({ query: '(min-width: 1200px)' })
}

function useMobileLayout(): boolean {
  return useQueryTabletLandscapeDown()
}

export {
  useQuerySmallPhone,
  useQueryPhoneOnly,
  useQueryTabletPortraitUp,
  useQueryTabletLandscapeUp,
  useQueryDesktopUp,
  useMobileLayout,
}
