export const partners = [
  {
    id: 'stonfi',
    title: 'STON.fi',
    href: 'https://ston.fi',
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/punks/stonfi.png',
  },
  {
    id: 'tonstarter',
    title: 'Tonstarter',
    href: 'https://tonstarter.com',
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/media/simple-logo.jpg',
  },
]
