import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'

import styles from './Menu.scss'

interface MenuProps {
  className?: string
  onClose(): void
  open: boolean
  children: React.ReactNode
}

export function Menu({ children, className, open, onClose }: MenuProps) {
  const rootElem = useRef<HTMLDivElement>(null)
  useOnClickOutside(rootElem, () => onClose())

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        rootElem.current.style.opacity = '1'
        rootElem.current.style.transform = 'translateY(0)'
      })
    }
  }, [open])

  return open ? (
    <div className={classNames(styles.root, className)} ref={rootElem}>
      {children}
    </div>
  ) : null
}

function useOnClickOutside(ref, handler: () => void) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
