import React from 'react'

import {Image, Text, useMobileLayout} from '@design-system'
import styles from '../components/About/About.scss'

export const MegatonTokenomics = () => {
  const isMobileLayout = useMobileLayout()

  const extraHeaderType = isMobileLayout ? 'header-32' : 'header-44'
  const headerType = isMobileLayout ? 'title-24' : 'title-28'
  const subheaderType = isMobileLayout ? 'title-20' : 'title-24'
  const paragraphType = isMobileLayout ? 'paragraph-16' : 'paragraph-20'
  const paragraphColor = 'secondary'

  return (
    <div className={styles.row}>
      <Text type={headerType} spacingBottom="24">Tokenomics</Text>
      <Image
        src="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/tokenomics.png"
        alt="Megaton tokenomics"
        borderRadius="16px"
        lightbox={{
          images: [
            {
              alt: 'Megaton tokenomics',
              src: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/tokenomics.png',
            },
          ],
        }}
      />
      <br></br>
      <Text type={headerType} spacingBottom="24">
        Utility
      </Text>
      <Text type={paragraphType} color={paragraphColor}>
        MEGA can be distributed through ecosystem contributions such as pool
        deposits and staking, or acquired through transactions, and can be
        used as follows.
      </Text>
      <ol className={styles.list}>
        <li>
          Users can receive inflation reward by using MEGA for pair deposit
          services.
        </li>
        <li>
          Users can participate in MEGA staking and receive MEGA inflation
          reward (TBD).
        </li>
        <li>
          MEGA can be used for governance voting on the protocol’s agenda
          (TBD).
        </li>
      </ol>
  </div>
  )
}


