// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Vq4BH{padding:0 16px 0;display:flex;justify-content:space-between;align-items:center;position:fixed;top:0;width:100%;z-index:100;background-color:var(--body-color);transition:background-color .3s cubic-bezier(0.4, 0, 0.2, 1)}.PmWVs{margin-left:auto}.Qss7V{position:relative}.wUJrK{right:16px;left:16px;top:50px}.aQxsy{margin-right:-16px}", "",{"version":3,"sources":["webpack://./src/shared/design-system/components/Header/HeaderMobile/HeaderMobile.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,cAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CACA,kCAAA,CACA,4DAAA,CAGF,OACE,gBAAA,CAGF,OACE,iBAAA,CAGF,OACE,UAAA,CACA,SAAA,CACA,QAAA,CAGF,OACE,kBAAA","sourcesContent":[".root {\n  padding: 0 16px 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  position: fixed;\n  top: 0;\n  width: 100%;\n  z-index: 100;\n  background-color: var(--body-color);\n  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);\n}\n\n.action {\n  margin-left: auto;\n}\n\n.connectWrapper {\n  position: relative;\n}\n\n.ddMenu {\n  right: 16px;\n  left: 16px;\n  top: 50px;\n}\n\n.burger {\n  margin-right: -16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Vq4BH",
	"action": "PmWVs",
	"connectWrapper": "Qss7V",
	"ddMenu": "wUJrK",
	"burger": "aQxsy"
};
export default ___CSS_LOADER_EXPORT___;
