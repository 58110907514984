import React, { useState } from 'react'

import { Tab, TabPanel, Tabs, useQueryTabletLandscapeUp } from '@design-system'
import { TokenInfo } from '@services'
import { Project } from '@store'
import { beautifyDate, formatCurrency } from '@utils'
import { Follow } from '../Follow/Follow'
import styles from './ProjectInfo.scss'

interface ProjectInfoProps {
  project: Project
  tokenDetails: TokenInfo
}

const ProjectInfoAboutSkeleton = () => {
  return (
    <div className={styles.aboutSkeleton}>
      <div className={styles.aboutSkeletonLine}></div>
      <div className={styles.aboutSkeletonLine}></div>
      <div className={styles.aboutSkeletonLine}></div>
      <div className={styles.aboutSkeletonLine}></div>
      <div className={styles.aboutSkeletonLine}></div>
      <div className={styles.aboutSkeletonLine}></div>
      <div className={styles.aboutSkeletonLine}></div>
      <div className={styles.aboutSkeletonLine}></div>
      <div className={styles.aboutSkeletonLine}></div>
    </div>
  )
}

const ProjectInfoAbout = ({
  title,
  details,
  project,
}: {
  title: string
  details: string | undefined
  project: Project
}) => {
  const isTabletLandscapeUpViewport = useQueryTabletLandscapeUp()

  return (
    <React.Fragment>
      <h1 className={styles.title}>{title}</h1>
      {!isTabletLandscapeUpViewport && (
        <Follow projectDetails={project.projectDetails} />
      )}
      <div
        className={styles.aboutText}
        dangerouslySetInnerHTML={{ __html: details ?? '' }}
      />
    </React.Fragment>
  )
}

export const ProjectInfo = ({
  project,
  tokenDetails,
}: ProjectInfoProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState(0)

  const handleChangeTab = (newIndex: number) => setActiveTab(newIndex)

  const addCommasToNumber = (number: number) => {
    return number.toLocaleString('en-US')
  }

  const isLoaded = project.id && project.status

  return (
    <div className={styles.container}>
      <Tabs
        onChange={handleChangeTab}
        value={activeTab}
        variant="text"
        fullWidth
      >
        <Tab label="About the project" />
        <Tab label="Token details" />
      </Tabs>

      <TabPanel className={styles.tabPanel} value={activeTab} index={0}>
        {!isLoaded && <ProjectInfoAboutSkeleton />}
        {isLoaded && (
          <ProjectInfoAbout
            project={project}
            title={project.title}
            details={project.projectDetails.about}
          />
        )}
      </TabPanel>

      <TabPanel className={styles.tabPanel} value={activeTab} index={1}>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.tableRow}>
              <th className={styles.tableHead} scope="row">
                IDO date
              </th>
              <td className={styles.tableCell}>
                {project.tokenSaleDate
                  ? beautifyDate(project.tokenSaleDate)
                  : 'TBA'}
              </td>
            </tr>
            <tr className={styles.tableRow}>
              <th className={styles.tableHead} scope="row">
                Symbol
              </th>
              <td className={styles.tableCell}>
                <span className={styles.ticker}>{tokenDetails.ticker}</span>
              </td>
            </tr>
            <tr className={styles.tableRow}>
              <th className={styles.tableHead} scope="row">
                Total supply
              </th>
              <td className={styles.tableCell}>
                {addCommasToNumber(tokenDetails.totalSupply)}
              </td>
            </tr>
            <tr className={styles.tableRow}>
              <th className={styles.tableHead} scope="row">
                Token price
              </th>
              <td className={styles.tableCell}>
                {formatCurrency('usd', tokenDetails.tokenPriceUSD, 2)}
              </td>
            </tr>
            <tr className={styles.tableRow}>
              <th className={styles.tableHead} scope="row">
                Max allocation
              </th>
              <td className={styles.tableCell}>
                {project.allocationUSD
                  ? formatCurrency('usd', project.allocationUSD)
                  : 'TBA'}
              </td>
            </tr>
            {/* <tr className={styles.tableRow}>
              <th className={styles.tableHead} scope="row">
                Initial Market Cap
              </th>
              <td className={styles.tableCell}>
                ${addCommasToNumber(tokenDetails.initialMarketCap)}
              </td>
            </tr> */}

            {/* <tr className={styles.tableRow}>
              <th className={styles.tableHead} scope="row">
                Sale End Time
              </th>
              <td className={styles.tableCell}>
                {project.tokenSaleFinishDate &&
                  beautifyDate(project.tokenSaleFinishDate)}
              </td>
            </tr> */}
          </tbody>
        </table>
        <br />
        <picture>
          <source
            srcSet="https://tonstarter-cdn.ams3.digitaloceanspaces.com/fanzee/Tokenomics.avif"
            type="image/avif"
          />
          <source
            srcSet="https://tonstarter-cdn.ams3.digitaloceanspaces.com/fanzee/Tokenomics.webp"
            type="image/webp"
          />
          <img
            decoding="async"
            loading="lazy"
            src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/fanzee/Tokenomics.png"
            alt="Fanzee tokenomics"
          />
        </picture>
      </TabPanel>

      {/* <div
        className={classNames(
          styles.aboutTabContent,
          activeTab === 'about' && styles.aboutTabContentActive
        )}
      >
        {!isLoaded && <ProjectInfoAboutSkeleton />}
        {isLoaded && (
          <ProjectInfoAbout
            project={project}
            title={project.title}
            details={project.projectDetails.about}
          />
        )}
      </div> */}

      {/* <div
        className={classNames(
          styles.aboutTabContent,
          activeTab === 'details' && styles.aboutTabContentActive
        )}
      >

      </div> */}
    </div>
  )
}
