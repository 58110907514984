import classNames from 'classnames'
import React, { AnchorHTMLAttributes, LinkHTMLAttributes } from 'react'

import stylesTypography from '../../styles/common/typography.scss'
import { TextType } from '../core'
import styles from './Link.scss'
import { LinkIconPosition, LinkVariant, LinkTarget } from './types'

interface LinkProps {
  as?: React.ElementType
  children?: React.ReactNode
  className?: string
  variant?: LinkVariant
  animatedUnderline?: boolean
  icon?: React.ReactNode
  iconPosition?: LinkIconPosition
  textType?: TextType
  target?: LinkTarget
}

export function Link({
  as: Component = 'a',
  children,
  className,
  variant = 'default',
  animatedUnderline = false,
  href = '#',
  icon,
  iconPosition = 'left',
  textType = 'paragraph-16',
  target='_blank',
  ...rest
}: LinkProps &
  LinkHTMLAttributes<HTMLLinkElement> &
  AnchorHTMLAttributes<HTMLLinkElement>) {
  return (
    <Component
      {...rest}
      className={classNames(
        styles.root,
        styles[`variant-${variant}`],
        textType && stylesTypography[`typeface-${textType}`],
        animatedUnderline && styles.animatedUnderline,
        className
      )}
      href={href}
      target={target}
    >
      {iconPosition === 'left' && icon}
      <span className={styles.text}>{children}</span>
      {iconPosition === 'right' && icon}
    </Component>
  )
}
