import React from 'react'

import styles from './SuccessProjectItem.scss'

export const SuccessProjectItem = ({
  project,
}: {
  project: { [key: string]: any }
}) => {
  const { title, description, details, image, link } = project

  return (
    <div className={styles.root}>
      <a className={styles.bodyLink} href={link} target="_blank">
        <div className={styles.preview}>
          <img src={image} />
          <span className={styles.title}>{title}</span>
        </div>

        <div className={styles.description}>{description}</div>
      </a>
    </div>
  )
}
