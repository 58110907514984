import React from 'react'

import { Icon } from '@design-system'
import { AllocationStatus, ProjectStatus } from '@store'
import styles from './Tip.scss'

interface TipProps {
  projectStatus?: ProjectStatus
  allocationStatus?: AllocationStatus
  message?: string
}

const projectMessages = {
  [`${ProjectStatus.whitelistSoon}`]:
    'Application period will open soon! Connect your wallet to subscribe for this IDO.',
  [`${ProjectStatus.whitelistOpen}`]:
    'The application period is now open. You can apply for the allocation draw by pressing the button below.',
  [`${ProjectStatus.whitelistClosed}`]: 'Applications period have ended.',
}

const allocationMessages = {
  [`${AllocationStatus.whitelistSoon}`]:
    'You have successfully subscribed to this IDO. We will notify you on Telegram, once the IDO application period opens.',
  [`${AllocationStatus.whitelistStarted}`]:
    'Please proceed to our Telegram bot to complete your application by clicking on “Finish application.”',
  [`${AllocationStatus.whitelistCompleted}`]:
    'The draw for the allocation will be conducted soon. We will notify you via our bot if you’ve been selected.',
  [`${AllocationStatus.kycRequired}`]:
    "You've sucessfully applied. Now you need to complete the KYC verification process.",
  [`${AllocationStatus.kycPassed}`]:
    "Congratulations! You've passed KYC successfully. Token sale will start soon.",
  [`${AllocationStatus.kycFailed}`]:
    'Unfortunately, you are ineligible for this token sale. Get know all details in Tonstarter Assistant bot in Telegram.',
  [`${AllocationStatus.saleStarted}`]:
    'Enter an amount of Toncoin (TON) to see how many tokens you can receive in exchange.',
  [`${AllocationStatus.joinedPool}`]:
    'You will be able to claim 100% of tokens at the end of the vesting period.',
  [`${AllocationStatus.claim}`]:
    'You may sent all tokens right now to your wallet.',
}

export const Tip = ({
  projectStatus,
  allocationStatus,
  message = '',
}: TipProps): JSX.Element | null => {
  let realMessage = message

  // if (allocationStatus === AllocationStatus.saleStarted) {
  //   return null
  // }

  // if (!message) {
  //   if (allocationStatus && allocationMessages[allocationStatus]) {
  //     realMessage = allocationMessages[allocationStatus.toString()]
  //   } else if (projectStatus && projectMessages[projectStatus]) {
  //     realMessage = projectMessages[projectStatus.toString()]
  //   } else {
  //     return null
  //   }
  // }

  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <Icon name="infoFilledBlue" />
      </div>
      <span>{realMessage}</span>
    </div>
  )
}
