import React from 'react'

import { AirdropPage } from '../AirdropPage'
import { PunkDetails } from './PunkDetails'
import { PunkTokenomics } from './PunkTokenomics'

export const Punk = () => {
  return <AirdropPage
    slug='punks'
    colour='#6619D1'
    DetailsComponent={PunkDetails}
    MoreComponent={PunkTokenomics}
  />
}