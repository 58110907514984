export interface TaskType {
  title: string
  description: string
  groupTitle: string
  systemEnum: string
  groupPosition: number
  type: string
  count: number
  max: number
  link: string
  isTask: boolean
  userPoints: number
  taskPoints: number
  isDynamic: boolean
  done: boolean
}

export interface competitionStateInterface {
  balance: number
  tasks: TaskType[]
  taskGroups: string[] | []
}

export const processTicketsToRewards = (
  tasks: TaskType[],
  showAll?: boolean
): competitionStateInterface => {
  let balance = 0
  let groups: string[] = []
  let tasksToShow: TaskType[] = []
  const shouldWeShowAllTasksAndAchievements =
    window.location.search.indexOf('VovanMode') > 0

  tasks.forEach((task, index) => {
    if (!task.isTask && !task.done && !shouldWeShowAllTasksAndAchievements)
      return
    if (groups.indexOf(task.groupTitle) < 0) groups.push(task.groupTitle)
    if (task.done) balance += task.userPoints

    tasksToShow.push(task)
  })

  // const tasks = tableContent
  //   .filter((item) => item?.task === true)
  //   .sort((a, b) => Number(a.done) - Number(b.done))

  return {
    balance: balance,
    tasks: tasksToShow,
    taskGroups: groups,
  }
}
