import classNames from 'classnames'
import React, { CSSProperties, ReactNode } from 'react'

import styles from './TableRow.scss'

interface TableRowProps {
  className?: string
  children?: ReactNode
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  rowIndex?: number
  onMouseOver?: (event: MouseEvent) => void
  highlightable?: boolean
  highlighted?: boolean
  onHighlightSync?: (rowIndex?: number) => void
  onClick?: (rowIndex?: number) => void
  height?: number
  userSelectable?: boolean
  onSelectChange?: (arg0: React.ChangeEvent<HTMLInputElement>) => void
  selectionId?: string
  style?: CSSProperties
}

export const TableRow = ({
  className,
  children,
  onMouseOver,
  onHighlightSync,
  highlightable = false,
  highlighted,
  rowIndex,
  onClick,
  height,
  userSelectable,
  selectionId,
  onSelectChange,
  style,
  ...rest
}: TableRowProps) => {
  const handleMouseOver = () => {}
  const handleMouseLeave = () => {}
  const handleClick = () => {
    if (onClick) {
      onClick(rowIndex)
    }
  }

  return (
    <tr
      className={classNames(
        styles.root,
        highlightable && styles.highlightable,
        // highlighted && styles.highlighted,
        // highlightable && onClick && styles.clickable,
        className
      )}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      style={{ height, ...style }}
      {...rest}
    >
      {children}
    </tr>
  )
}
