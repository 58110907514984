import React from 'react'
import { Image } from '@design-system'
import styles from '../components/About/About.scss'
import { Text } from '@design-system'

export const StormToken = () => {
  return (
    <>
      <div className={styles.row}>
        STORM serves as the governance, incentive, and utility token of Storm Trade. With a fixed supply of 1,000,000 tokens, it plays a crucial role in the platform.
        <ul>
          <li>
            <p>
              <b>Governance: </b>
              Token holders may participate in governance decisions related to the platform's development and direction.
            </p>
          </li>
          <li>
            <p>
              <b>Incentive: </b>
              The token incentivizes liquidity providers and stakers through a share of the protocol's revenue.
            </p>
          </li>
          <li>
            <p>
              <b>Utility: </b>
              Users can utilize STORM tokens for various platform functions, fostering its adoption.
            </p>
          </li>
        </ul>
      </div>
      <div className={styles.row}>
        <Image
          src="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/storm/storm_tokenomics.jpg"
          alt="Storm Tokenomics"
          lightbox={{
            images: [
              {
                alt: 'Storm Tokenomics',
                src: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/storm/storm_tokenomics.jpg',
              },
            ],
          }}
        />
      </div>
    </>
  )
}
