import { PrivateData } from '../../../'

export const privateData: PrivateData = {
  vesting: [
    {
      title: 'TGE・Q4 2023',
      value: '15%'
    },
    {
      title: 'From the 4th month',
      value: '3.54%・mo'
    },
  ],
  prices: {
    tokenPriceInTon: '0.01643',
    tonPriceInUsd: '1.35',
  },
  ticker: 'FNTF',
  contractAddress: 'EQA-amDlEqfVFspM1ML1r46H8cvEnq9D2tnZgXSEyS0MbQGM',
  limits: {
    min: '700',
    max: '40000',
  },
  sumsub: 'https://forms.tonstarter.com/privateKYC',
  cover: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/fanton/fanton-private-cover.png',
  docs: 'https://docs.google.com/document/d/1MfSzFpbIB7ff0NWbZoCZUvlBbFi73NCJ3Unf_D2sqXc',

  dates: {
    start: new Date('2023-06-20'),
    end: new Date('2023-07-07'),
  },
  fund: '200,000',
}