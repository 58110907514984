import clsx from 'classnames'
import React from 'react'

import styles from './Skeleton.scss'

export const SkeletonShapes = ['rect-13', 'square-24', 'circle-40'] as const

export const SkeletonWidths = ['100'] as const

export const SkeletonHeights = ['32px', '48px', '96px', '100'] as const

export const SkeletonRadiuses = ['8px', '16px']
type SkeletonShapeType = typeof SkeletonShapes[number]
type SkeletonWidthType = typeof SkeletonWidths[number]
type SkeletonRadiusType = typeof SkeletonRadiuses[number]
type SkeletonHeightType = typeof SkeletonHeights[number]

export interface SkeletonType {
  type: SkeletonShapeType
  width?: SkeletonWidthType
  height?: SkeletonHeightType
  radius?: SkeletonRadiusType
}

export interface SkeletonProps {
  as?: React.ElementType
  width?: SkeletonWidthType
  height?: SkeletonHeightType
  radius?: SkeletonRadiusType
  type?: SkeletonShapeType
  className?: string
}

export const Skeleton = ({
  as: Component = 'div',
  // type = 'rect-17',
  width = '100',
  height = '48px',
  radius = '8px',
  className,
}: SkeletonProps): JSX.Element => {
  return (
    <Component
      className={clsx(
        styles.root,
        styles[`radius-${radius}`],
        // styles[`type-${type}`],
        styles[`width-${width}`],
        styles[`height-${height}`],
        className
      )}
    />
  )
}
