export const beautifyDate = (date: string): string => {
  const dateObj = new Date(date)
  const timeOptions = {
    dateStyle: 'medium',
    timeStyle: 'short',
  }

  return new Intl.DateTimeFormat('en-US', timeOptions as any).format(dateObj)
}

export const diffInDays = (a: Date, b: Date): number => {
  const msInDay = 24 * 60 * 60 * 1000
  const diff = a.getTime() - b.getTime()
  return Math.ceil(diff / msInDay)
}
