import { isWalletInfoInjected } from '@tonconnect/ui-react'

import { TonConnectService } from '@services'
import { generateTonConnectPayload } from '@store'

export const prepareTonConnectLink = async (): Promise<string | null> => {
  const walletsList = await TonConnectService.getWallets()
  const embeddedWallet = walletsList
    .filter(isWalletInfoInjected)
    .find((wallet) => wallet.embedded)

  const wallets = { walletsList, embeddedWallet }

  const tonkeeperConnectionSource = {
    universalLink: wallets.walletsList[0].universalLink,
    bridgeUrl: wallets.walletsList[0].bridgeUrl,
  }

  const { data: tonProof } = await generateTonConnectPayload()

  if (tonProof) {
    const universalLink = TonConnectService.getConnector().connect(
      tonkeeperConnectionSource,
      { tonProof }
    )

    return universalLink
  }

  return null
}
