export const team = [
  {
    image:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/punks/george.jpeg',
    name: 'George Lozovsky',
    role: 'Found & CEO',
    tg: 'https://t.me/terror240',
    details: [
      '6+ years experience in marketing, community building and launching IT products.',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/punks/nikita.jpeg',
    name: 'Nikita Misharin',
    role: 'CTO',
    tg: '',
    details: [
      '8+ years experience in software development,',
      'experience in building for products with 1M+ users.',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/punks/krokodealer.png',
    name: 'Krokodealer',
    role: 'Full-time Engineer',
    tg: 'https://t.me/Krokodealer',
    details: [
      'Builds what works and fixes what doesn\'t since 2015.',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/punks/kusok.png',
    name: 'Kusok Pesok',
    role: 'Artist',
    tg: '',
    details: [
      'Professionally turning ideas into graphics since 2017.',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/punks/paket.png',
    name: 'Paket',
    role: 'Bot Father',
    tg: '',
    details: [
      'Implementing Asimov\'s laws and conducting Turing tests from 2020.',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/punks/starbucks.webp',
    name: 'Starbucks Duck',
    role: 'Game Dev',
    tg: '',
    details: [
      '10+ years of programming experience, turning hard problems into easy ones.',
    ],
  },
  {
    image:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/punks/2077.jpeg',
    name: 'smbydk',
    role: 'Copywriter',
    tg: 'https://t.me/sqracoon',
    details: [
      '',
    ],
  },
]
