import React from 'react'

import { AirdropPage } from '../AirdropPage'
import { OneDetails } from './OneDetails'
import { OneToken } from './OneToken'

export const One = () => {
  return <AirdropPage
    slug='one'
    colour='#A48D3A'
    DetailsComponent={OneDetails}
    MoreComponent={OneToken}
  />
}