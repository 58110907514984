import BigNumber from 'bignumber.js'

import { multiply } from './currency'

export const TOKEN_PRECISION = 6
export const TON_DECIMALS = 9

export const tokenToDisplay = (amount: string, precision?: number): string => {
  const result = new BigNumber(amount)
  return isNaN(result.toNumber())
    ? amount
    : result.precision(precision || TOKEN_PRECISION).toFixed()
}

export const tonBalanceToDisplay = (value: string) => {
  const normalizedBalance = new BigNumber(value)
    .div(new BigNumber(10).pow(9))
    .toFixed()

  return tokenToDisplay(normalizedBalance, 4)
}

export const numberToNano = (value: string, decimal: number): string => {
  if (!value || value == 'undefined') return 'NaN'
  return new BigNumber(value)
    .multipliedBy(new BigNumber(10).pow(decimal))
    .toString()
}

export const nanoToNumber = (value: string, decimal: number): string => {
  return new BigNumber(value)
    .div(new BigNumber(10).pow(decimal))
    .toFixed(decimal)
}

export const nanoToDisplay = (
  value: string | number,
  decimal: number,
  decimalPlace?: number
): string => {
  const bn = new BigNumber(value).div(new BigNumber(10).pow(decimal))

  if (decimalPlace) {
    // Big.DP = decimalPlace
    return bn.toFixed(decimalPlace)
  }

  return bn.toFixed()
}

export const nano2Ton = (value: string): string =>
  nanoToNumber(value, TON_DECIMALS)

export const ton2Nano = (value: string): string =>
  numberToNano(value, TON_DECIMALS)

export const greaterThan = (valueA: string, valueB: string): boolean => {
  const a = new BigNumber(valueA)
  const b = new BigNumber(valueB)

  return a.gt(b)
}

export const equal = (valueA: string, valueB: string): boolean => {
  const a = new BigNumber(valueA)
  const b = new BigNumber(valueB)

  return a.eq(b)
}

export const tonNanoToDisplay = (
  value: string | number,
  decimalPlace = 2
): string => {
  return nanoToDisplay(value, 9, decimalPlace)
}

export const tonToTokenByTokenPrice = (
  tonAmount: string,
  tokenPriceInTon: string
): string => {
  const a = new BigNumber(tonAmount)
  const b = new BigNumber(tokenPriceInTon)
  const result = a.div(b)

  return result.toFixed(0, 0)
}

export const amountToTokenByPrice = (
  amount: string,
  price: string
): string => {
  const a = new BigNumber(amount)
  const b = new BigNumber(price)
  const result = a.div(b)

  return result.toFixed(0, 0)
}

export const tokenToTonByTokenPrice = (
  tokenAmount: string,
  tokenPriceInTon: string
): string => {
  const a = new BigNumber(tokenAmount)
  const b = new BigNumber(tokenPriceInTon)
  const result = a.multipliedBy(b)

  return result.toFixed()
}

export const getPercentageOfTon = (
  tonAmount: string,
  percentage: number | string
): string => {
  const a = new BigNumber(tonAmount)
  const b = new BigNumber(percentage)
  const c = new BigNumber('100')

  return a.div(c).multipliedBy(b).precision(TON_DECIMALS).toFixed()
}

export const justSum = (a: string, b: string, c: string): string => {
  const bigA = new BigNumber(a)
  const bigB = new BigNumber(b)
  const bigC = new BigNumber(c)

  return bigA.plus(bigB).plus(bigC).precision(TON_DECIMALS).toFixed()
}

export const calculateTokenPriceInUsd = (
  ton2Usd: string,
  tonInputValue: string
) => {
  const tonAmount = tonInputValue ? tonInputValue : 0

  return multiply(ton2Usd, tonAmount.toString(), 2)
}
