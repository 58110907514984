import React, { useState } from 'react'

import { Content, Icon, Text, useMobileLayout } from '@design-system'
import classNames from 'classnames'
import styles from './Footer.scss'

const emojis = [
  '🍿',
  '🧘',
  '🙏🏻',
  '🤞',
  '🧠',
  '🤯',
  '🤖',
  '🌝',
  '☕',
  '👽',
  '💅',
  '🍵',
  '🦄',
]

const getRandFaces = (): string[] => {
  return [...emojis.sort(() => Math.random() - Math.random()).slice(0, 3), '😘']
}

interface FooterProps {
  wide?: boolean
  showTerms?: boolean
  className?: string
}

export function Footer({
  wide = false,
  showTerms = true,
  className,
}: FooterProps): JSX.Element {
  const isMobileViewport = useMobileLayout()
  const [faces, setFaces] = useState(getRandFaces())
  const isDiamonds = window.location.pathname === '/project/tondiamonds'

  return (
    <div
      className={classNames(
        styles.root,
        !wide && styles.base,
        wide && styles.wide,
        className
      )}
    >
      {showTerms && isMobileViewport ? (
        <Content>
          <a href="/terms-of-service" target="_blank">
            Terms
          </a>
          &nbsp;&nbsp;&nbsp;
          <a href="/cookie-policy" target="_blank">
            Cookies
          </a>
          &nbsp;&nbsp;&nbsp;
          <a href="/privacy-policy" target="_blank">
            Privacy
          </a>
        </Content>
      ) : null}

      <Content className={styles.inner} align="center">
        <a href="https://ton.org" target="_blank" className={styles.tonLink}>
          <Text type="paragraph-16" color="tertiary">
            Based on
          </Text>
          &nbsp;
          <Icon
            name="ton-outline"
            colorFill="tertiary"
            size="16"
            className={styles.tonIcon}
          />
        </a>

        {showTerms && !isMobileViewport ? (
          <Content alignSelf="end">
            <a href="/terms-of-service" target="_blank">
              Terms
            </a>
            &nbsp;&nbsp;&nbsp;
            <a href="/cookie-policy" target="_blank">
              Cookies
            </a>
            &nbsp;&nbsp;&nbsp;
            <a href="/privacy-policy" target="_blank">
              Privacy
            </a>
          </Content>
        ) : null}

        <Content
          alignSelf="end"
          className={styles.withEmoji}
          onMouseEnter={() => {
            setFaces(getRandFaces())
          }}
        >
          <Text
            type="paragraph-16"
            color="tertiary"
            className={styles.withText}
          >
            by{' '}
            <a
              href="https://openbuilders.xyz?utm_source=TonstarterBy1"
              target="_blank"
            >
              {isDiamonds ? 'Coast Customs' : 'Open Builders'}
            </a>{' '}
            with ❤️
          </Text>
          {isMobileViewport ? (
            <span>😘</span>
          ) : (
            <span>
              {faces.map((e, i) => (
                <span key={i}>{e}</span>
              ))}
            </span>
          )}
        </Content>
      </Content>
    </div>
  )
}
