import BN from 'bn.js'
import { Address } from 'ton'

import { TonService, TonServiceResponse } from '@services'

export async function fetchProjectVoteApi({
  contractAddress,
}: {
  contractAddress: string
}): Promise<TonServiceResponse<{ votes: number }>> {
  const address = Address.parseFriendly(contractAddress).address
  const response = await TonService.client.callGetMethod(address, 'get_votes')

  return {
    ok: true,
    data: {
      votes: new BN(parseInt(response.stack[0][1], 16)).toNumber(),
    },
  }
}
