import React from 'react'

import { Button } from '@design-system'
import hiStyles from '../../HiPage.scss'
import { Card } from '../Card/Card'
import styles from './WhyTonstarter.scss'

const cardsTs = [
  {
    title: 'Early stage',
    description: 'Provide access to grants, early-stage investments, and IDOs',
    icon: 't4h',
  },
  {
    title: 'Powerful advisors',
    description:
      'Work with advisors from the TON Foundation and founders of crypto projects',
    icon: 'ad',
  },
  {
    title: 'Key partners',
    description: 'Partnered with key projects and funds on TON',
    icon: 'pa',
  },
  {
    title: 'Large investor community',
    description:
      'Over 30,000 retail investors, angel investors, syndicates and funds invest in projects with Tonstarter',
    icon: 't30',
  },
]

export const WhyTonstarter = () => {
  return (
    <div className={styles.root}>
      <div className={hiStyles.sectionTitle}>
        Tonstarter: Connecting builders and investors
      </div>
      <div className={hiStyles.sectionSubtitle}>
        Tonstarter is the main investment platform on TON, enabling crypto
        projects to raise capital in a decentralized, safe, and user-friendly
        environment
      </div>
      <div className={styles.cards}>
        {cardsTs.map((item, i) => (
          <Card {...item} key={i} width="half" />
        ))}
      </div>

      <div className={styles.buttons}>
        <div className={styles.buttonItem}>
          <Button
            as="a"
            href="https://forms.tonstarter.com/build?source=hi"
            target="_blank"
          >
            Builders apply here
          </Button>
        </div>
        <div className={styles.buttonItem}>
          <Button
            as="a"
            href="https://forms.tonstarter.com/investing?source=hi"
            target="_blank"
          >
            Investors apply here
          </Button>
        </div>
      </div>
    </div>
  )
}
