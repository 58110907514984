import React from 'react'

import {
  Button,
  Content,
  Icon,
  Link,
  Member,
  Text,
  useMobileLayout,
} from '@design-system'
import { openNewTab } from '@utils'
import { Project } from '../store'
import styles from '../pages/ProjectPage/ProjectPage.scss'

export const data: Project = {
  id: 'getgems',
  name: 'Getgems',
  slug: 'getgems',
  iconUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/getgems/getgems-icon.png',
  logoUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/getgems/getgems-icon.png',
  logoDarkUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/getgems/getgems-icon.png',
  urlTitle: 'Getgems',
  urlLink: 'https://getgems.io',

  expertRatingLevel: '2',
  expertRatingIndex: 76,
  expertRatingMarker: 'A',
  communityRating: '',
  contractAddress: 'EQD8wTWcrUwS0nePkrM9CxXNRwfDOd-7xUZ3FwXS5o2wqA6b',
  contractAddressTestnet: 'EQD8wTWcrUwS0nePkrM9CxXNRwfDOd-7xUZ3FwXS5o2wqA6b',
  reportUrl:
    'https://tonstarter.notion.site/getgems-cf596f76ca114e45aa2af4d8dd6b1fcb',
  technicalReportUrl: '',
  category: 'marketplace',
  updatedAt: '14 Jun 2023',
  reports: [
    {
      reportType: 'reportV2',
      updatedAt: '14 Jun 2023',
      totalRate: '76%',
      values: {
        valueProposition: '66%',
        tokenomics: 'n/a',
        team: '97%',
        regulatory: 'n/a',
        community: '68%',
        tech: '65%',
        nft: '85%',
      },

      reviewers: [
        {
          title: 'Daily TON',
          subtitle: 'Report',
          link: 'https://t.me/thedailyton',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/dailyton.jpeg',
        },
        {
          title: 'qpwe',
          subtitle: 'Review',
          link: 'https://qpwe.taplink.ws',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/qpwe.jpg',
        },
      ],
    },
  ],
}

export const quote = {
  author: 'Daily TON',
  authorTitle: 'Report',
  authorLink: 'https://t.me/thedailyton',
  authorAvatarUrl: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/dailyton.jpeg',
  text: "Getgems is currently the leading marketplace within the TON ecosystem, boasting an impressive number of users. As the ecosystem's market capacity is limited, the Getgems team is concentrating on attracting new users. The success of Getgems is closely linked to the overall success of the TON ecosystem. As The Open Network continues to evolve and draw in new users, Getgems is well-positioned to maintain its leadership role and benefit from TON's growing adoption.",
}

export function aboutProject(): JSX.Element {
  const isMobileViewport = useMobileLayout()

  const headerType = isMobileViewport ? 'title-24' : 'title-28'
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'
  const textSpacingBottom = isMobileViewport ? '32' : '40'

  return (
    <>
      <Text type={textType} spacingBottom="12" color="secondary">
        <Link
          textType={textType}
          href="https://getgems.io"
          target="_blank"
          animatedUnderline={false}
        >
          Getgems
        </Link>{' '}
        is an NFT marketplace operating within the TON ecosystem that offers an
        open market that evolves over time. The project has managed to build a
        strong community of NFT enthusiasts, with over 50,000 subscribers.
      </Text>

      <Text type={headerType} spacingBottom="12">
        Problem and Solution
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Getgems aims to cater to the needs of TON NFT enthusiasts by providing a
        platform for buying, selling, and trading NFTs. While it does not offer
        groundbreaking solutions, it offers engaging mechanics and has
        successfully hosted popular NFT events.
      </Text>

      <Text type={headerType} spacingBottom="24">
        Contacts
      </Text>

      <Content
        className={styles.teamContent}
        spacingChild="40"
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Axinia Shliakhtina"
          role=""
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/getgems/axinia.jpeg"
          button={<>
            <Button
              variant="secondary"
              icon={<Icon name="outer-link" colorFill="primary" />}
              onClick={() =>
                openNewTab('https://www.linkedin.com/in/kseniashliakhtina')
              }
              style={{marginBottom: '10px'}}
              >
              LinkedIn
            </Button>
            <br></br>
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() =>
                openNewTab('https://t.me/nownesss')
              }
              >
              Telegram
            </Button>
          </>}
        />
        <Member
          name="Pavel Ivanov"
          role=""
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/getgems/pavel.jpeg"
          button={<>
            <Button
              variant="secondary"
              icon={<Icon name="outer-link" colorFill="primary" />}
              onClick={() =>
                openNewTab('https://www.linkedin.com/in/pavel-ivanov-132b48232/')
              }
              style={{marginBottom: '10px'}}
              >
              LinkedIn
            </Button>
            <br></br>
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() =>
                openNewTab('https://t.me/pashatechnique')
              }
              >
              Telegram
            </Button>
          </>}
        />
      </Content>
      <Content
        className={styles.teamContent}
        spacingChild="40"
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Ivan Gusev"
          role=""
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/getgems/ivanGusev.jpeg"
          button={<Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() =>
                openNewTab('https://t.me/ilovecinnabon')
              }
              >
              Telegram
            </Button>}
        />
        <Member
          name="Ivan Nedzvetskii"
          role=""
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/getgems/ivanNedzvetskii.jpeg"
          button={<Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() =>
                openNewTab('https://t.me/botpult')
              }
              >
              Telegram
            </Button>}
        />
      </Content>
    </>
  )
}

export function tokenDetails(): JSX.Element {
  const isMobileViewport = useMobileLayout()
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'

  return (
    <Text type={textType} color="secondary">
      Getgems's token has not been released yet.
    </Text>
  )
}
