export const partners = [
  {
    id: 'ob',
    title: 'Orbit Bridge',
    href: 'https://bridge.orbitchain.io',
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/orbit-logo.png',
  },
  {
    id: 'tf',
    title: 'TON Foundation',
    href: 'https://ton.org',
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/tf-logo.png',
  },
  {
    id: 'top',
    title: 'TOP Labs',
    href: 'https://top.co',
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/top-logo.png',
  },
]
