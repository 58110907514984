import classNames from 'classnames'
import { nanoid } from 'nanoid'
import React, { Fragment, useMemo } from 'react'
import ReactTooltip from 'react-tooltip'

import styles from './Tooltip.scss'

interface TooltipProps {
  className?: string
  position?: 'top' | 'right' | 'left' | 'bottom'
  style?: 'dark' | 'light'
  disable?: boolean
  disableRebuild?: boolean
  content?: string
  title: string
  children: React.ReactElement
}

export function Tooltip({
  children,
  position = 'top',
  title,
  className,
  style = 'dark',
  disable = false,
  disableRebuild = false,
  ...restProps
}: TooltipProps) {
  const id = nanoid()
  const delayShow = 200
  const childrenProps = useMemo(
    () => ({
      ...children.props,
      'data-tip': title,
      'data-for': id,
    }),
    [children.props, title, id]
  )

  return (
    <Fragment>
      {React.cloneElement(children, childrenProps)}

      <ReactTooltip
        id={id}
        className={classNames(styles.root, styles[`style-${style}`], className)}
        effect="solid"
        place={position}
        delayShow={delayShow}
        disable={disable}
        type={style}
        scrollHide={true}
        afterShow={(elem) => {
          if (elem.target.nextSibling) {
            elem.target.nextSibling.style.opacity = '1'
          }
        }}
        {...restProps}
      />
    </Fragment>
  )
}
