import React from 'react'

import styles from '../AirdropSidebar/AirdropSidebar.scss'
import { Follow } from '../AirdropSidebar/components/Follow/Follow'
import { Button, Icon, Text } from '@design-system'
import { Project } from '@store'
import { openNewTab } from '@utils'

interface CommunitySidebarProps {
  activeProject: Project
  communityLink: string
}

const projectsData : {
  [key: string]: {
    title: string,
    desc: string,
    image: string,
  }
} = {
  tonatlas: {
    title: 'Join waitlist',
    desc: 'Don’t miss the launch',
    image: '👾',
  },
  storm: {
    title: 'Join Early Birds',
    desc: 'The earliest bird catches the worm',
    image: '🦅📈',
  }
}

export const CommunitySidebar = ({
  activeProject,
  communityLink,
}: CommunitySidebarProps) => {
  const content = projectsData[activeProject.slug]

  return (
    <div>
      <div className={styles.bochka}>
        <h2 style={{ marginBottom: '16px' }} className={styles.title}>
          {activeProject.title}
        </h2>

        <div className={styles.reward} style={{ marginTop: '16px' }}>
          <div>
            <Text type="title-20">
              {content.title}
            </Text>
            <Text type="paragraph-16" color="tertiary">
              {content.desc}
            </Text>
          </div>
          <div>
            <span className={styles.tada}>{content.image}</span>
          </div>
        </div>

        <Button
          style={{ marginTop: '16px' }}
          fullWidth
          onClick={() => {openNewTab(communityLink)}}
          icon={<Icon name="community" colorFill="white" size="24" />}
        >
          Continue in Community
        </Button>
      </div>
      <div className={styles.bochka}>
        <Text color="tertiary" align="center">
          Follow {activeProject.title} project
        </Text>
        <Follow projectDetails={activeProject.projectDetails} />
      </div>
    </div>
  )
}