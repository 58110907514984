import React from 'react'

export const SeregaMode = ({debugInfo}: {debugInfo: {name: string, value: string | boolean | null | undefined | number}[]}) => {
  return <>
    <b>Only for Serega 🤫</b>
    <code>
    {debugInfo.map((el) => {
      if (typeof el.value === 'boolean') {
        el.value = el.value ? 'true' : 'false'
      } else if (typeof el.value === 'undefined' || el.value === null) {
        el.value = '¯\\_(ツ)_/¯'
      }
      // return (<p key={el.name}>{el.name}: <code>{el.value}</code></p>)
      return (<p key={el.name}><b>{el.name}</b>: <span style={{color: 'tomato'}}>{el.value}</span></p>)
    })}
    </code>
  </>
}