import classNames from 'classnames'
import React from 'react'
import { useTonhubConnect } from 'react-ton-x'

import { Button, Icon, isWebApp } from '@design-system'
import { mobileOrTablet } from '@utils'
import styles from './WalletButtons.scss'

interface ButtonProps {
  onClick: () => void
  label: string
  href?: string
  className?: string
  iconPosition?: 'start' | 'end'
  isPayButton?: boolean
}

export function WalletButtonTonkeeper({
  onClick,
  label,
  href,
  className,
  iconPosition = 'end',
}: ButtonProps) {
  return (
    <Button
      className={classNames(styles.button, styles.buttonTonkeeper, className)}
      icon={<Icon name="tonkeeper" />}
      iconPosition={iconPosition}
      onClick={onClick}
      href={href}
      target="_blank"
      as={href ? 'a' : 'button'}
      fullWidth
    >
      {label}
    </Button>
  )
}

export function WalletButtonTonHub({
  onClick,
  label,
  className,
  iconPosition = 'end',
  isPayButton,
}: ButtonProps) {
  const isMobileOrTablet = mobileOrTablet()
  const tonhubConnect = useTonhubConnect()
  const href = tonhubConnect.state.link
  const isTWA = isWebApp()

  const handleClick = () => {
    onClick()

    if (isMobileOrTablet && !isTWA && !isPayButton) {
      window.location.assign(tonhubConnect.state.link)
    }
  }

  return (
    <Button
      className={classNames(styles.button, styles.buttonTonhub, className)}
      icon={<Icon name="tonhub" />}
      iconPosition={iconPosition}
      onClick={handleClick}
      href={href}
      target="_blank"
      as={isTWA ? 'a' : 'button'}
      fullWidth
    >
      {label}
    </Button>
  )
}
