import React from 'react'

import { Button } from '../../design-system'
import { mobileOrTablet } from '../../utils/mobileOrTablet'
import checkmark from './images/checkmark.png'
import tada from './images/tada.png'
import styles from './SuccessPaymentModal.scss'

interface SuccessPaymentModalProps {
  title: string
  subtitle: string
  iconType: 'checkmark' | 'tada'
  onClose: () => void
}

export function SuccessPaymentModal({
  title,
  subtitle,
  onClose,
  iconType = 'tada',
}: SuccessPaymentModalProps) {
  const isMobileOrTablet = mobileOrTablet()

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.image}>
          {iconType === 'tada' && (
            <img src={tada} className={styles.imageTada} />
          )}
          {iconType === 'checkmark' && (
            <img src={checkmark} className={styles.imageCheckmark} />
          )}
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.details}>{subtitle}</div>
      </div>

      <div className={styles.footer}>
        <Button fullWidth={isMobileOrTablet ? true : false} onClick={onClose}>
          Continue
        </Button>
      </div>
    </div>
  )
}
