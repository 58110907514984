import React from "react"

import { Text } from '@design-system'

import styles from '../../AirdropSidebar.scss'
import { LoaderIcon } from "react-hot-toast"

export const IndexingBlockchain = () : JSX.Element => {
  return (
    <>
      <br></br>
      <LoaderIcon className={styles.loader} />
      <br></br>
      <Text type="paragraph-14" color="tertiary" align="center">Indexing blockchain...</Text>
    </>
  )
}