import React, { useMemo } from 'react'
import { PaymentDisclaimerModal } from '@components'
import { PrivateData } from '../..'
import { calculateTokenPriceInUsd, multiply, tonToTokenByTokenPrice } from '@utils'

interface PrivateDisclaimerModalProps {
  amount: string
  project: string
  onClose: (result?: string) => void
  data: PrivateData
}

export function PrivateDisclaimerModal({
  amount,
  project,
  onClose,
  data,
}: PrivateDisclaimerModalProps) {
  // * project title (from project)
  // * amount in usd (calc)
  // * amount of tokens (calc)
  // * ticker (from data)
  // * usd per token (calc)
  // * project title (from project)
  // * docs link

  const totalUsd = useMemo(() => {
    return calculateTokenPriceInUsd(data.prices.tonPriceInUsd, amount)
  }, [amount])

  const totalTokens = useMemo(() => {
    return tonToTokenByTokenPrice(amount, data.prices.tokenPriceInTon)
  }, [amount])

  const tokenPrice = useMemo(() => {

    return multiply(data.prices.tokenPriceInTon, data.prices.tonPriceInUsd)
  }, [amount])

  return (
    <PaymentDisclaimerModal onClose={onClose}>
      <h4 style={{textAlign: 'center'}}>
        PRIVATE ROUND PARTICIPATION TERMS
      </h4>
      <h5 style={{textAlign: 'center', margin: 0}}>
        for the project Fanton Fantasy Football
      </h5>

      <h5 style={{textAlign: 'center'}}>
        Disclaimer
      </h5>

      <p>
        The information contained in or provided on or through the Website is not intended to be and does not constitute financial advice, trading advice, or any other type of advice.
      </p>

      <br />

      <p>
        The Ecosystem Operator is not liable for any possible misinterpretation of the Terms and Conditions hereunder.
      </p> 

      <h5 style={{textAlign: 'center'}}>
        Risk Statement
      </h5>

      <p>
        Blockchain technology and crypto-assets carry significant risks, including the possible loss of all value allocated in crypto-assets. Such risks arise from the novelty of this technology, the regulatory uncertainty, the possibility of hacking, the high volatility and the information asymmetry characterising the crypto market. Visitors should not purchase crypto-assets with funds they cannot afford to lose. Furthermore, the visitor is strongly encouraged to seek financial and legal advice regarding the use of crypto-assets and the use of our services.
      </p>

      <h5 style={{textAlign: 'center'}}>
        PARTICIPATION TERMS & CONDITIONS
      </h5>

      <p>
        By accepting this Participation Terms & Conditions YOU certify that in exchange for the payment by YOU, (the “Participant” or “Purchaser”) to TON Starter otherwise referred to as “The Ecosystem Operator” in the amount of {amount} TON (“The Purchase Price”), for {totalTokens} (“The Purchase Amount”) of (FNTF) on the basis of exchange rate of TON Price (0.01643) Per Token when generated  (On Token Generation Event) issued by the Company (Fanton Fantasy Football) (“The Issuer”),  and distributed in relation to the Issuer’s platform and/or application and/or broader ecosystem, as defined and explained in the Whitepaper and other project documentation as made available to the Purchaser via this <a href={data.docs}>link</a>, subject to the terms set forth below.
      </p>

      <h5 style={{textAlign: 'center'}}>
        1. Participation And Delivery
      </h5>

      <p>
        <u>Payment</u>  For a successful participation The Purchase Price shall be payable  upon your acceptance to this “Participation Term Sheet”.
      </p>

      <p>
        The Purchase price shall be payable in crypto currency.
      </p>

      <p>
        After the successful transfer of payment, You will be requested to submit the appropriate documents for the conclusion of the mandatory KYC. If Your KYC is not completed, you will automatically receive a full refund.
      </p>

      <br />

      <p>
        <u>Delivery</u>  Tokens purchased under this agreement are to be delivered immediately <b>as per terms of delivery</b>, after the Token Generation Event, which is expected to occur within an appropriate timeframe and will be announced by the Issuer in their social media channels or on the Ecosystem operator’s Telegram announcement channels.
      </p>

      <p>
        Tokens shall be  delivered  to the wallet address used to purchase the tokens under this agreement.  Notwithstanding the foregoing, the Company reserves the right to extend the deadline for delivery of the Tokens to the Purchaser for additional reasonable time, if deemed necessary to address unanticipated technical difficulties.
      </p>

      <br />

      <p>
        <u>Terms of Delivery</u>: All tokens shall be airdropped to the same wallet address used upon purchase, if you wish to change the receiving wallet address, you are required to contact us on the following email Address: legals@tonstarter.com
      </p>

      <br />

      <p>
        <u>Vesting</u>  The Participant explicitly agrees and acknowledges that the Purchase Amount may be unlocked and made available to the Participant in stages, as per Terms of Delivery and as specified in the Terms of Vesting as outlined by the Issuer in their tokenomics, which can be also found on the Tonstarter.com website in the Project’s section. The Parties specifically agree and acknowledge that the vesting schedule is of informative information only and that the Ecosystem Operator “Tonstarter” bears no responsibility over the vesting terms or any changes that may occur.  Notwithstanding the foregoing, the whole Purchase Amount shall be fully unlocked and made available to the Purchaser by the end of vesting period (<b>“End Date”</b>).
      </p> 

      <h5 style={{textAlign: 'center'}}>
        2. Termination
      </h5>
      
      <p>
        This agreement will expire and terminate upon: (a) the delivery of Tokens to the Purchaser pursuant to Section 1; or (b) upon the Company deciding to no longer offer any Tokens to the Purchaser or (c) the dissolution or winding-up of the Company or the Issuer.
      </p>

      <h5 style={{textAlign: 'center'}}>
        3. Acknowledgements and representations
      </h5>

      <p>
        <u>Project Development and Exclusion of Representations</u>. The Participant  hereby acknowledges and confirms that he/she has opened and reviewed the Project’s whitepaper and the website, as well as any other websites or project documents referenced therein,  and obtained and reviewed in detail any and all other material, prospectus, information and documents required by the Company to provide before this purchase (<b>“Project Documentation”</b>), and is therefore familiar with the Token and the Issuer’s Project. The Participant acknowledges that the Project Documentation is of descriptive nature only, is subject to change due to required development work, and does not form part of the terms of this Agreement as binding to the Issuer or the Company. The Purchaser is informed that if, after the Token delivery, an upgrade of Tokens is required and the Purchaser decides to skip this upgrade, the Tokens may lose their functionality in full. The Purchaser acknowledges and understands that Ton Starter is not the owner of Tokens issued by the Issuer and that no warranties or representations, express or implied, with regard to the Issuer or the Project or the Token or its functionalities or utilities are given under this Agreement. To the fullest extent as permitted by law, any liability of the Ecosystem Operator with respect to the sale of the Token under this Agreement shall be excluded.
      </p>

      <br />

      <p>
        <u>Utility Token</u>  The Participant acknowledges that the purchase, ownership, receipt or possession of Tokens carries no rights, express or implied, other than the right to use the Tokens as per Project Documentation, in each case, to the extent that the Issuer’s ecosystem, software, platform or application remains in use after its deployment by the Issuer, and within limitation of the applicable Terms of Use of the Issuer’s ecosystem, software, platform or application. The Token does not represent or confer any ownership right or stake, share or security or equivalent rights, or any right to receive future revenue shares, intellectual property rights or any other form of participation in or relating to the Issuer and its corporate affiliates, including the governance of the Issuer and its corporate affiliates. Tokens are not intended to be a digital currency (except as far as being a means of exchange of value between users within the Issuer’s software, platform or application; or as a means of payment for access to various products and services made available to users on the Issuer’s platform or applications - closed loop currency), security, commodity or any other kind of financial instrument.
      </p>

      <br />

      <p>
        <u>Regulatory</u> The Participant has been advised that the Issuer considers this Agreement to not be a ‘security’ in the jurisdiction of the Issuer and is not required to register this Agreement or the underlying Tokens with any securities regulator in the jurisdiction of the Issuer. The Participant is informed that the Ecosystem Operator nor the Issuer do not provide financial, legal or tax advice and that the Participant has consulted with such professionals as needed and conducted own due diligence. The Participant confirms that accepting these Terms is not unlawful according to the Participant’s jurisdiction and undertakes to comply with all of relevant regulatory demands. The Participant confirms to have a sufficient understanding of the functionality, usage, storage, transmission mechanisms and intricacies associated with cryptographic tokens, token storage facilities, blockchain technology and blockchain-based software systems and are fully aware of the risks involved in the DLT and the cryptocurrency markets. The Participant further confirms the understanding that such risks may incur a complete loss of the Purchase Amount and that this would not impair the Participant’s financial condition. The Participant understands to not have any right against the Ecosystem Operator or the Issuer, their founders and team members or any other party to request the refund of the whole Purchase Amount except if required under this Agreement, applicable law or regulation.
      </p>

      <br />

      <p>
        <u>Exclusion of any U.S. Persons</u>. The Participant warrants not to be a citizen of or resident or domiciled or green card holder in any territory under the jurisdiction of the United States of America, including any state of the United States of America or the District of Columbia (the “USA”).
      </p>

      <h5 style={{textAlign: 'center'}}>
        4. Miscellaneous
      </h5>
      
      <p>
        <u>Entirety and notices</u>. The Terms  and its Schedules represent the entire agreement between the parties with respect to the subject matter hereof and supersedes any prior discussions or stipulations.
      </p>

      <br />

      <p>
        <u>Confidential Information</u>  This Term Sheet  is confidential, and it and any information provided in connection with it or as a part of it constitutes confidential information shall not be disclosed to any third party, without the prior written consent of the other Parties, except as required for tax purposes, or as required by applicable laws. None of the Parties may issue any press release or public announcement in relation to this agreement except as may be agreed to in writing by the Parties.
      </p>

      <br />

      <p>
        <u>Severability</u>  In the event any one or more of the provisions of this Term Sheet  is for any reason held to be invalid, illegal or unenforceable, in whole or in part or in any respect, then and in any such event, such provision(s) only will be deemed null and void and will not affect any other provision of this Term Sheet, and the remaining provisions will remain operative and in full force and effect and will be interpreted according to the true will of the parties.
      </p>
    </PaymentDisclaimerModal>
  )
}