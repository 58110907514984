import React from 'react'
import Helmet from 'react-helmet'

interface HeadPropsType {
  title: string | undefined
  description: string | undefined
  socialImg: string | undefined
  slug: string
}

export const Head = ({
  title,
  description,
  socialImg,
  slug,
}: HeadPropsType) => {
  const metaTitle = title || 'Tonstarter'
  const metaDescription =
    description ||
    'Tonstarter provides outstanding community incentives that can help you create a community and build warm relations.'

  return (
    <Helmet>
      <title>
        {
          slug === 'evaa-waitlist' ?
          'Evaa - Waitlist' :
          `${metaTitle} - CIP`
        }
      </title>
      <meta name="description" content={metaDescription} />

      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:locale" content="en_US" />
      <meta
        property="og:url"
        content={`https://tonstarter.com/project/${slug}`}
      />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Tonstarter" />
      <meta property="og:image" content={socialImg} />

      <meta itemProp="name" content={metaTitle} />
      <meta itemProp="description" content={metaDescription} />
      <meta itemProp="image" content={socialImg} />
    </Helmet>
  )
}
