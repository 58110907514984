import { configureStore } from '@reduxjs/toolkit'

import { LocalStorageService } from '@services'
import { projects as projectsData } from '../data'
import app from './app/appSlice'
import auth from './auth/authSlice'
import projects from './projects/projectsSlice'
import wallet from './wallet/walletSlice'

export const reHydrateStore = () => {
  const address = LocalStorageService.getItem('wallet-address', '')
  const balance = LocalStorageService.getItem('wallet-balance', '')
  const walletType = LocalStorageService.getItem('wallet-type', null)

  return {
    wallet: {
      address,
      balance,
      walletType,
    },
    projects: { projects: [...projectsData] },
  }
}

export const store = configureStore({
  preloadedState: reHydrateStore() as { [key: string]: any },
  reducer: { app, auth, wallet, projects },
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export * from './app'
export * from './auth'
export * from './hooks'
export * from './projects'
export * from './wallet'
