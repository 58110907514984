import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { getTGUserId, Page, useWebApp } from '@design-system'
import { AnalyticsService } from '@services'
import {
  activeAllocationSelector,
  activeProjectSelector,
  currentUserSelector,
  fetchAllocation,
  fetchProject,
  useAppDispatch,
  useAppSelector,
} from '@store'
import { PageHeader } from '../../../../components'
import { AirdropSidebar } from '../../components/AirdropSidebar/AirdropSidebar'
import styles from '../AirdropPage.scss'
import { About } from '../components/About/About'
import { Head } from '../components/Head'
import { projectDetails } from './data/projectDetails'
import { MegatonDetails } from './MegatonDetails'
import { MegatonTokenomics } from './MegatonTokenomics'

export const MegatonPage = (): JSX.Element => {
  const title = 'Megaton Finance'
  const description =
    'Megaton Finance is an AMM-based DEX on TON mainnet that provides various revenue generation opportunities'
  const slug = 'megaton'
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const activeProject = useAppSelector(activeProjectSelector)
  const activeAllocation = useAppSelector(activeAllocationSelector)
  const user = useAppSelector(currentUserSelector)

  useWebApp(({ BackButton }) => {
    BackButton.show()

    BackButton.onClick(() => {
      BackButton.hide()

      navigate('/')
    })
  })

  useEffect(() => {
    dispatch(fetchProject({ projectSlug: slug }))
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const isUserExists = user?.id || getTGUserId()

    if (isUserExists && slug) {
      dispatch(
        fetchAllocation({
          projectSlug: slug,
          telegramId: user?.telegramId ?? getTGUserId(),
          address: user?.friendlyAddress,
        })
      )

      AnalyticsService.trackItEasy('page_shown', {
        projectId: slug,
      })
    }
  }, [user])

  window.addEventListener('scroll', function () {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const opacity = 1 - scrollTop / 50

    if (opacity >= 0 && topSignElem.current) {
      topSignElem.current.style.opacity = `${opacity}`
    }
  })

  const topSignElem = useRef<HTMLDivElement>(null)

  return (
    <Page>
      <Head
        title={title}
        description={description}
        socialImg={projectDetails.bannerPng}
        slug={slug}
      />
      <PageHeader />
      <Page.Body withHeader>
        <div className={styles.topSign} ref={topSignElem}>
          ton-native launchpad
        </div>
        <div className={styles.root}>
          <div className={styles.grid}>
            <div className={styles.aside}>
              <AirdropSidebar activeProject={activeProject} user={user} />
            </div>

            <div className={styles.content}>
              <About
                projectDetails={projectDetails}
                DetailsComponent={MegatonDetails}
                MoreComponent={MegatonTokenomics}
                project={activeProject}
              />
            </div>
          </div>
        </div>
      </Page.Body>
      <Page.Footer />
    </Page>
  )
}
