import classNames from 'classnames'
import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Icon, useQueryTabletPortraitUp } from '@design-system'
import { Project, ProjectCategory } from '@store'
import { CategoryItem } from '../CategoryItem'
import { ProjectItem, ProjectItemSkeleton } from '../ProjectItem'
import { SuccessProjectItem } from '../SuccessProjectItem'
import styles from './Carousel.scss'

interface CarouselListProps {
  data: Project[] | ProjectCategory[]
  type: 'projects' | 'categories' | 'successProjects'
  breakpoints?: { [key: string]: any }
}

const swiperBreakpoints = {
  600: {
    slidesPerView: 2.5,
    spaceBetween: 5,
  },
  960: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
}

export function CarouselList({
  data,
  type,
  breakpoints = swiperBreakpoints,
}: CarouselListProps): JSX.Element {
  const [swiperRef, setSwiperRef] = useState<any>(null)
  const [sliderPosition, setSliderPosition] = useState<
    'start' | 'middle' | 'end'
  >('start')

  return (
    <>
      {data.length > 3 && (
        <div>
          <button
            className={classNames(
              styles.sliderButton,
              styles.sliderButtonBack,
              {
                [`${styles.sliderButtonDisabled}`]: sliderPosition === 'start',
              }
            )}
            onClick={() => swiperRef.slidePrev()}
          >
            <Icon name="chevron-left" />
          </button>
          <button
            className={classNames(
              styles.sliderButton,
              styles.sliderButtonNext,
              {
                [`${styles.sliderButtonDisabled}`]: sliderPosition === 'end',
              }
            )}
            onClick={() => swiperRef.slideNext()}
          >
            <Icon name="chevron-right" />
          </button>
        </div>
      )}

      <Swiper
        slidesPerView={1.5}
        spaceBetween={100}
        onSwiper={(swiper) => {
          setSwiperRef(swiper)
        }}
        breakpoints={breakpoints}
        onSlideChange={(event) => {
          if (event.isBeginning) {
            setSliderPosition('start')
          } else if (event.isEnd) {
            setSliderPosition('end')
          } else {
            setSliderPosition('middle')
          }
        }}
      >
        {data.length
          ? data.map((project, index) => (
              <SwiperSlide key={index}>
                {type === 'projects' && (
                  <ProjectItem project={project as Project} />
                )}
                {type === 'categories' && (
                  <CategoryItem category={project as ProjectCategory} />
                )}
                {type === 'successProjects' && (
                  <SuccessProjectItem project={project as any} />
                )}
              </SwiperSlide>
            ))
          : [1, 2].map((item) => (
              <SwiperSlide key={item}>
                <ProjectItemSkeleton />
              </SwiperSlide>
            ))}
      </Swiper>
    </>
  )
}

interface CarouselProps {
  title: string | null
  description: string | null
  type: 'projects' | 'categories' | 'successProjects'
  data: Project[] | ProjectCategory[] | any[]
  maxLength?: number
  breakpoints?: { [key: string]: any }
  className?: string
}

export const Carousel = ({
  title,
  description,
  type,
  data,
  maxLength = 10,
  breakpoints,
  className,
}: CarouselProps): JSX.Element => {
  const isTabletPortraitUpViewport = useQueryTabletPortraitUp()

  let dataToRender = data

  if (data.length && data.length <= maxLength) {
    dataToRender = data
  } else if (data.length > 0 && data.length > maxLength) {
    dataToRender = [...data].slice(0, maxLength) as any
  }

  const renderSkeleton = !isTabletPortraitUpViewport && !dataToRender.length
  const renderItems = !isTabletPortraitUpViewport && dataToRender.length

  return (
    <section
      className={classNames(styles.projectsSection, className)}
      id="projects"
    >
      {title ? <h2 className={styles.title}>{title}</h2> : null}
      {description ? <p className={styles.subtitle}>{description}</p> : null}

      {isTabletPortraitUpViewport ? (
        <CarouselList
          data={dataToRender}
          type={type}
          breakpoints={breakpoints}
          className={className}
        />
      ) : null}

      {renderSkeleton
        ? [1, 2].map((item) => <ProjectItemSkeleton key={item} />)
        : null}

      {renderItems
        ? dataToRender.map((project, index) => {
            if (type === 'projects') {
              return <ProjectItem project={project as Project} key={index} />
            }

            if (type === 'successProjects') {
              return (
                <SuccessProjectItem project={project as Project} key={index} />
              )
            }

            if (type === 'categories') {
              return (
                <CategoryItem
                  category={project as ProjectCategory}
                  key={index}
                />
              )
            }

            return null
          })
        : null}
    </section>
  )
}
