import React, { ComponentType, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { Page, useWebApp } from '@design-system'
import {
  activeProjectSelector,
  currentUserSelector,
  fetchPrivateAllocation,
  fetchProject,
  PrivateAllocation,
  privateAllocationSelector,
  useAppDispatch,
  useAppSelector,
} from '@store'
import { LoaderIcon, toast } from 'react-hot-toast'
import { About } from '../components/About/About'
import { Head } from '../../../components/Head'
import { PrivateSidebar } from './PrivateSidebar/PrivateSidebar'

import { ApiService } from '@services'
import { PageHeader } from '../../../../../components'
import styles from '../../../PrivateProject.scss'

export interface VestingLine {
  title: string
  value: string
}

export interface PrivatePrices {
  tokenPriceInUsd: string
  tokenPriceInTon: string
  tonPriceInUsd: string
}

export interface PrivateLimits {
  ton: {
    min: string
    max: string
  }
  usd: {
    min: string
    max: string
  }
}

export interface PrivateDates {
  public: Date
  start: Date
  end: Date
}

export interface PrivateData {
  vesting: VestingLine[]
  prices: PrivatePrices
  ticker: string
  contractAddress: string
  limits: PrivateLimits
  sumsub: string
  cover: string
  docs: string
  dates: PrivateDates
  fund: string
  fullName: string
  usdtAddress: string
  usdcAddress: string
}

interface PrivateProjectProps {
  slug: string
  colour?: string
  DetailsComponent: ComponentType<any>
  MoreComponent: ComponentType<any>
  moreTabName?: string
  imageCarousel?: JSX.Element
  data: PrivateData
  Tokenomics?: ComponentType<any>
}

export const updateAllocation = async (
  updatedAllocation: PrivateAllocation
) => {
  if (!updatedAllocation?.id) return

  await ApiService.patch({
    endpoint: `/private-allocation/update/${updatedAllocation.id}`,
    data: updatedAllocation,
  })
    .catch((err) => {
      console.error(err)
      toast.error('Could not update the allocation')
    })
    .then((res) => {
      console.log(res)
    })
}

export const createAllocation = async (userId: string, newAllocation: any) => {
  await ApiService.put({
    endpoint: `/private-allocation/create`,
    data: {
      userId: userId,
      insert: newAllocation,
    },
  })
    .catch((err) => {
      console.error(err)
      toast.error('Could not create the allocation')
    })
    .then((res) => {
      console.log(res)
    })
}

export const PrivateProject = ({
  data,
  slug,
  colour,
  DetailsComponent,
  MoreComponent,
  moreTabName,
  imageCarousel,
  Tokenomics,
}: PrivateProjectProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const activeProject = useAppSelector(activeProjectSelector)
  const user = useAppSelector(currentUserSelector)
  const privateAllocations = useAppSelector(privateAllocationSelector)
  const isLoaded = activeProject.id && activeProject.status

  if (colour) {
    document.documentElement.style.setProperty('--project-colour', colour)
  }

  useWebApp(({ BackButton }) => {
    BackButton.show()

    BackButton.onClick(() => {
      BackButton.hide()

      navigate('/')
    })
  })

  useEffect(() => {
    dispatch(fetchProject({ projectSlug: slug }))
    dispatch(fetchPrivateAllocation())
    window.scrollTo(0, 0)
  }, [])

  window.addEventListener('scroll', function () {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const opacity = 1 - scrollTop / 50

    if (opacity >= 0 && topSignElem.current) {
      topSignElem.current.style.opacity = `${opacity}`
    }
  })

  const topSignElem = useRef<HTMLDivElement>(null)

  if (isLoaded) {
    return (
      <Page>
        <Head
          title={activeProject?.projectDetails?.metaTitle}
          description={activeProject?.projectDetails?.metaDescription}
          socialImg={data.cover}
          slug={slug}
        />
        <PageHeader />
        <Page.Body withHeader>
          <div className={styles.topSign} ref={topSignElem}>
            ton-native launchpad
          </div>

          <div className={styles.root}>
            <div className={styles.grid}>
              <div className={styles.aside}>
                <PrivateSidebar
                  user={user}
                  activeProject={activeProject}
                  data={data}
                />
              </div>

              <div className={styles.content}>
                <About
                  data={data}
                  projectDetails={activeProject.projectDetails}
                  DetailsComponent={DetailsComponent}
                  MoreComponent={MoreComponent}
                  Tokenomics={Tokenomics}
                  moreTabName={moreTabName}
                  project={activeProject}
                  imageCarousel={imageCarousel}
                />
              </div>
            </div>
          </div>
        </Page.Body>
        <Page.Footer />
      </Page>
    )
  } else {
    return (
      <Page>
        <LoaderIcon className={styles.loader} />
      </Page>
    )
  }
}
