import { createSlice } from '@reduxjs/toolkit'

import { getTGUserId } from '@design-system'
import { RootState } from '..'
import {
  competitionStateInterface,
  processTicketsToRewards,
  TaskType,
} from '../../utils/ticketsProcessor'
import { User } from './types'
import {
  fetchMe,
  fetchTicketContractState,
  fetchTicketContractStatus,
  fetchTickets,
  fetchTokensAmount,
  pollTickets,
  fetchLockupDetails,
  fetchLockupPeriods,
} from './userActions'

interface UserState {
  user: User | null
  ticketContractState: string | null
  ticketContractStatus: string | null
  competitionState: competitionStateInterface | 'pending' | null
  tokensAmount: string | null
  lockupDetails: LockupDetails | null
  lockupPeriods: number[] | null
}

export interface LockupDetails {
  common: string
  frozen: string
  available: string
  currentPeriod: number
}

const initialState: UserState = {
  user: null,
  ticketContractState: null,
  ticketContractStatus: null,
  competitionState: null,
  tokensAmount: null,
  lockupDetails: null,
  lockupPeriods: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMe.fulfilled, (state, action) => {
        const telegramId = action.payload.telegramId
          ? action.payload.telegramId
          : getTGUserId()
        state.user = { ...action.payload, telegramId }
      })
      .addCase(fetchMe.rejected, (state, action) => {
        state.user = null
      })

    builder
      .addCase(fetchTicketContractState.fulfilled, (state, action) => {
        state.ticketContractState = action.payload
      })
      .addCase(fetchTicketContractState.rejected, (state, action) => {
        state.ticketContractState = null
      })

    builder
      .addCase(fetchTicketContractStatus.fulfilled, (state, action) => {
        state.ticketContractStatus = action.payload
      })
      .addCase(fetchTicketContractStatus.rejected, (state, action) => {
        state.ticketContractStatus = null
      })

    builder
      .addCase(fetchTickets.fulfilled, (state, action) => {
        const tasks = action.payload

        state.competitionState = processTicketsToRewards(tasks)
      })
      .addCase(fetchTickets.pending, (state, action) => {
        state.competitionState = 'pending'
      })
      .addCase(fetchTickets.rejected, (state, action) => {
        state.competitionState = null
      })

    builder.addCase(pollTickets.fulfilled, (state, action) => {
      const tickets = action.payload

      if (tickets === null) {
        return
      }

      state.competitionState = processTicketsToRewards(tickets)
    })

    builder
      .addCase(fetchTokensAmount.fulfilled, (state, action) => {
        state.tokensAmount = action.payload
      })
      .addCase(fetchTokensAmount.rejected, (state, action) => {
        state.tokensAmount = null
      })

    builder
      .addCase(fetchLockupDetails.fulfilled, (state, action) => {
        state.lockupDetails = action.payload
      })
      .addCase(fetchLockupDetails.rejected, (state, action) => {
        state.lockupDetails = null
      })
    builder
      .addCase(fetchLockupPeriods.fulfilled, (state, action) => {
        state.lockupPeriods = action.payload
      })
      .addCase(fetchLockupPeriods.rejected, (state, action) => {
        state.lockupPeriods = null
      })
  },
})

export const currentUserSelector = (state: RootState): User | null =>
  state.user.user

export const userTicketContractStateSelector = (
  state: RootState
): string | null => state.user.ticketContractState

export const userTicketContractStatusSelector = (
  state: RootState
): string | null => state.user.ticketContractStatus

export const userCompetitionStateSelector = (
  state: RootState
): competitionStateInterface | 'pending' | null => state.user.competitionState

// Remove after airdrop
export const stoneSelector = (state: RootState): TaskType[] => {
  if (
    state.user.competitionState &&
    state.user.competitionState !== 'pending'
  ) {
    return state.user.competitionState.tasks.filter(
      (t) => t.groupTitle === 'Stones'
    )
  }

  return []
}

export const userTokensAmountSelector = (state: RootState): string | null =>
  state.user.tokensAmount

export const lockupDetailsSelector = (state: RootState): LockupDetails | null =>
  state.user.lockupDetails

export const lockupPeriodsSelector = (state: RootState): number[] | null =>
  state.user.lockupPeriods

export default userSlice.reducer
