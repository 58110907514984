import React, { useEffect, useState } from 'react'

import { Button, Text, useMobileLayout } from '@design-system'

import { currentUserSelector, fetchLockupDetails, useAppDispatch, useAppSelector, lockupDetailsSelector, walletTypeSelector, lockupPeriodsSelector, fetchLockupPeriods, WalletType, Project } from '@store'
import { toast } from 'react-hot-toast'
import { sendTransaction } from '@services'

import styles from '../../AirdropSidebar.scss'
import { Tokens } from './Tokens'
import { ClaimFinished } from './ClaimFinished'
import { IndexingBlockchain } from './IndexingBlockchain'

interface LockupViewProps {
  project: Project
  projectTicker: string
}

export type TrxState =
  'initial' |
  'loading' |
  'finished'

const showErrorMessage = (message: string) => toast.error(message)

export const LockupView = ({
  project,
  projectTicker,
}: LockupViewProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const jwtUser = useAppSelector(currentUserSelector)
  const lockupDetails = useAppSelector(lockupDetailsSelector)
  const lockupPeriods = useAppSelector(lockupPeriodsSelector)
  const isMobileViewport = useMobileLayout()
  const walletType = useAppSelector(walletTypeSelector)
  const [trxState, setTrxState] = useState<TrxState>('initial')
  const walletName = walletType === WalletType.tonKeeper ? 'Tonkeeper' : 'Tonhub'
  const lockupContract = project.idoContractAddress

  const claimAction = async () => {
    if (walletType) {
      setTrxState('loading')
      
      const {ok, error} = await sendTransaction({
        address: lockupContract,
        amount: '70000000', //0.07TON
        isMobileViewport: isMobileViewport,
        walletType: walletType
      })
  
      if (!ok) {
        showErrorMessage(error as string)
        setTrxState('initial')
      } else {
        setTrxState('finished')
      }
    }
  }
  
  useEffect(() => {
    dispatch(fetchLockupDetails({
      userWalletAddress: jwtUser?.friendlyAddress as string, 
      lockupContract: lockupContract
    }))
    dispatch(fetchLockupPeriods({lockupContract: lockupContract}))
  }, [])

  const ClaimAction = () : JSX.Element => {
    if(!lockupPeriods || !lockupDetails) return <IndexingBlockchain />

    const currentPeriod = lockupDetails.currentPeriod > 0 ? lockupDetails.currentPeriod : 0
    const closestClaimDate = new Date(lockupPeriods[currentPeriod])
    const monthName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  
    if (Number(lockupDetails?.available) > 0) {
      return <>
        <Text color="tertiary" align="center" spacingBottom='16'>
          Congrats!
        </Text>
        <Tokens projectSlug={project.slug} projectTicker={projectTicker} reward={lockupDetails?.common} isOpen={Number(lockupDetails.available) > 0} />
        <Button
          className={styles.button}
          fullWidth
          loading={trxState === 'loading'}
          onClick={claimAction}
        >
          Claim { lockupDetails?.available } {projectTicker}
        </Button>
        <Text className={styles.tip}>
          You can claim the rest of your tokens once the vesting period ends
        </Text>
      </>
    } else {
      return <>
        <Text color="tertiary" align="center" spacingBottom='16'>
          Congrats!
        </Text>
        <Tokens projectSlug={project.slug} projectTicker={projectTicker} reward={lockupDetails?.common} isOpen={Number(lockupDetails.available) > 0} />
        <Button
          className={styles.button}
          fullWidth
          disabled
        >
          Next claim { closestClaimDate.getDate() } { monthName[closestClaimDate.getMonth()] }
        </Button>
        <Text className={styles.tip}>
          Please wait until the date to claim your tokens
        </Text>
      </>
    }
  }

  const ClaimView = () : JSX.Element | null => {
    if(!lockupPeriods || !lockupDetails) return <IndexingBlockchain />
    const isLastPeriodClaimed = 
      lockupPeriods?.length === lockupDetails?.currentPeriod
      && lockupDetails.frozen === '0'
      && lockupDetails.available === '0'
    
    if(trxState === 'finished') {
      return <ClaimFinished claimedAmount={lockupDetails?.available} projectTicker={projectTicker} />
    } else if (isLastPeriodClaimed) {
      return <ClaimFinished claimedAmount={lockupDetails?.common} projectTicker={projectTicker} />
    } else {
      return <ClaimAction />
    }
  }

  return (
    <div className={styles.bochka}>
      <h2 className={styles.title}>
        {project.title}
      </h2>
      <ClaimView />
      {trxState === 'loading' ? (
        <Button
          variant="contrast"
          loadingIcon={true}
          className={styles.transactionStatusButton}
        >
          Open {walletName} app
        </Button>
      ) : null}
    </div>
  )
}
