import React from 'react'

import { Text, useMobileLayout } from '@design-system'
import { Arrow, TelegramPlane } from '../icons'
import { news } from './data/news'
import { team } from './data/team'

import { roadmap } from './data/roadmap'

import classNames from 'classnames'
import styles from '../components/About/About.scss'

export const StonDetails = () => {
  const isMobileLayout = useMobileLayout()

  const extraHeaderType = isMobileLayout ? 'header-32' : 'header-44'
  const headerType = isMobileLayout ? 'title-24' : 'title-28'
  const subheaderType = isMobileLayout ? 'title-20' : 'title-24'
  const paragraphType = isMobileLayout ? 'paragraph-16' : 'paragraph-16'
  const paragraphColor = 'secondary'

  return (
    <>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Product
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          STON.fi is a decentralized automated market maker (AMM) built on TON
          blockchain, providing virtually zero fees, low slippage, a streamlined
          user interface, and direct integration with TON wallets.
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          STON.fi was founded in 2022 and aims to build a user-friendly crypto
          exchange for mass adoption by tapping into Telegram’s massive user
          base. What’s more, TON Blockchain’s sharding feature allows STON.fi
          DEX users to conduct millions of transactions per second.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          The project's mission
        </Text>
        <Text type={paragraphType} color={paragraphColor} spacingBottom="16">
          Putting the community first, STON.fi is a DEX for the people because
          it values feedback and opinions and provides users with fast support.
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          Since Q4 2022, STON.fi has been focusing on creating the future of
          trading on TON Blockchain by taking advantage of its advanced sharding
          and asynchronous technology.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Business Model
        </Text>
        <Text type={paragraphType} color={paragraphColor}>
          One of the standout features of STON.fi is its low fee structure,
          which sets it apart from other cryptocurrency platforms. Its
          simplicity enables users to swap cryptocurrencies and provide
          liquidity to the platform without incurring high fees. By keeping fees
          low, STON.fi is able to attract a larger user base and remain
          competitive in the market.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Roadmap
        </Text>

        {roadmap.map((r, i) => (
          <div className={styles.roadmapRow} key={`${i}e`}>
            <Text type={paragraphType} spacingBottom="12">
              <b>{r.phase}</b>
            </Text>
            {r.actions.map((a, j) => (
              <React.Fragment key={`${j}a`}>
                <Text
                  type={paragraphType}
                  color={paragraphColor}
                  spacingBottom="12"
                  key={`${i}a`}
                >
                  {a.title}
                </Text>
                {a.text && (
                  <Text
                    type={paragraphType}
                    color="tertiary"
                    spacingBottom="16"
                    key={`${i}b`}
                  >
                    {a.text}
                  </Text>
                )}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="32">
          Core team
        </Text>
        <div className={styles.team}>
          {team.map((t, i) => (
            <div className={styles.teamMember} key={`${i}f`}>
              <div className={styles.teamAvatar}>
                {/* { t.image && <img src={t.image} className={styles.imageCover} />} */}
                {t.image && (
                  <img
                    src={t.image}
                    className={classNames(
                      styles.imageCover,
                      styles.teamAvatarInverted
                    )}
                  />
                )}
                {t.teamLogo && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    viewBox="0 0 56 56"
                    className={styles.teamLogoIcon}
                  >
                    <path d="M32.8215 40.7527C33.1475 40.5269 33.599 40.6718 33.7327 41.0453L38.8018 55.192C38.9647 55.6469 38.554 56.0994 38.0858 55.9808L19.4225 51.2499C18.9206 51.1226 18.8016 50.4634 19.2273 50.1686L32.8215 40.7527Z"></path>
                    <path d="M36.3842 37.0343C36.3611 36.9682 36.3492 36.8985 36.3492 36.8282V22.4007C36.3492 21.9926 35.965 21.6994 35.582 21.815L19.7343 26.5998C19.3514 26.7156 18.9672 26.4223 18.9672 26.0142V16.69C18.9672 16.4994 19.0542 16.3198 19.2027 16.2043L39.8542 0.12507C40.1412 -0.0984021 40.5551 -0.0130729 40.7349 0.306632L53.2104 22.4951C53.2987 22.6523 53.3142 22.8415 53.2526 23.0114L42.7493 51.9885C42.5554 52.5231 41.814 52.519 41.6259 51.9825L36.3842 37.0343Z"></path>
                    <path d="M13.4429 1.72465C13.5421 1.62704 13.6723 1.56737 13.8107 1.55603L32.7751 0.00222417C33.3674 -0.0463117 33.6565 0.711819 33.1835 1.07333L17.0192 13.4264C16.8696 13.5408 16.675 13.5773 16.4944 13.525L5.85011 10.4427C5.41228 10.3159 5.27315 9.76188 5.5987 9.4416L13.4429 1.72465Z"></path>
                    <path d="M2.65609 14.0767C2.70176 13.709 3.0631 13.4694 3.41678 13.5723L14.7771 16.8783C15.0333 16.9528 15.2096 17.189 15.2096 17.4574V30.8424C15.2096 31.2453 15.5949 31.5348 15.9788 31.4204L31.7471 26.7185C32.1309 26.6041 32.5162 26.8936 32.5162 27.2965V35.7983C32.5162 35.9959 32.4202 36.1808 32.2591 36.2934L14.5729 48.6663C14.3422 48.8277 14.0312 48.8061 13.8248 48.6144L0.192671 35.947C0.0506846 35.8151 -0.0193358 35.6223 0.00463002 35.4293L2.65609 14.0767Z"></path>
                  </svg>
                )}
              </div>
              <Text type={subheaderType}>{t.name}</Text>
              <Text
                type={paragraphType}
                color="tertiary"
                spacingBottom={isMobileLayout ? '8' : '12'}
              >
                {t.role}
              </Text>
              {t.details.map((a, j) => (
                <Text
                  type={paragraphType}
                  color={paragraphColor}
                  spacingBottom="16"
                  key={j}
                >
                  {a}
                </Text>
              ))}
              {t.ln && (
                <div>
                  <a className={styles.teamLink} href={t.ln} target="_blank">
                    <Text type={paragraphType} color={paragraphColor}>
                      Linkedin
                    </Text>
                    <Arrow className={styles.icon} />
                  </a>
                </div>
              )}
              {t.tg && (
                <a className={styles.teamLink} href={t.tg} target="_blank">
                  <Text type={paragraphType} color={paragraphColor}>
                    Telegram
                  </Text>
                  <TelegramPlane className={styles.icon} />
                </a>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="32">
          Check out STON.fi's blog
        </Text>

        <div className={styles.news}>
          {news.map((t, i) => (
            <a href={t.href} className={styles.newsItem} key={i}>
              <div className={styles.newsImage}>
                <img src={t.image} />
              </div>
              <Text type="title-16" color={paragraphColor} spacingBottom="8">
                <b>{t.title}</b>
              </Text>
              <Text type="paragraph-16" color={paragraphColor}>
                {t.description}
              </Text>
            </a>
          ))}
        </div>
      </div>
    </>
  )
}
