import React from 'react'

import { Link, Text, useMobileLayout } from '@design-system'
import { Project } from '../store'

export const data: Project = {
  comingSoon: true,
  id: 'fanzee',
  name: 'Fanzee',
  slug: 'fanzee',
  iconUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/fanzee/fanzee-logo.png',
  logoUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/fanzee/fanzee-logo-extended-dark.svg',
  logoDarkUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/fanzee/fanzee-logo-extended.svg',
  urlTitle: 'Fanzee',
  urlLink: 'https://fanz.ee',
  expertRatingLevel: '',
  expertRatingIndex: 0,
  expertRatingMarker: '',
  communityRating: '',
  contractAddress: '',
  contractAddressTestnet: '',
  fundamentalReportUrl: '',
  technicalReportUrl: '',
  category: 'socialfi',
  reports: [
    {
      reportType: 'fundamental',
      updatedAt: ' 2023',
      totalRate: '',
      values: [
        {
          valueProposition: '',
        },
        { tokenomics: '' },
        { team: '' },
        { governance: '' },
        { regulatory: 'n/a' },
      ],

      reviewers: [
        {
          title: 'Daily TON',
          subtitle: 'Report',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/dailyton.jpeg',
        },
        {
          title: 'Sergey, Tonstarter',
          subtitle: 'Review',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/sergey.png',
        },
      ],
    },
    {
      reportType: 'technical',
      updatedAt: '',
      totalRate: '%',
      values: [
        { codeAndTeam: '%' },
        { docs: '%' },
        { testing: '%' },
        { security: '%' },
        { accessControls: '%' },
      ],

      reviewers: [
        {
          title: 'Dario Tarantini',
          subtitle: 'Report',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/vladimir.jpeg',
        },
        {
          title: 'Vladimir, Tonstarter',
          subtitle: 'Review',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/vladimir.jpeg',
        },
      ],
    },
  ],
}

export const quote = {
  author: 'Somebody',
  authorAvatarUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/rating/reviewers/vladimir.jpeg',
  text: "That's nice",
}

export function aboutProject(): JSX.Element {
  const isMobileViewport = useMobileLayout()

  const headerType = isMobileViewport ? 'title-24' : 'title-28'
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'
  const textSpacingBottom = isMobileViewport ? '32' : '40'

  return (
    <>
      <Text type={textType} spacingBottom="12" color="secondary">
        The{' '}
        <Link
          textType={textType}
          href="https://ton.diamonds/"
          target="_blank"
          animatedUnderline={false}
        >
          TON Diamonds
        </Link>{' '}
        NFT marketplace is a decentralized platform that allows users to buy,
        sell, and trade non-fungible tokens (NFTs). The platform is built on the
        TON blockchain, which provides a high level of security and transparency
        for users.
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        One of the unique features of the TON Diamonds NFT marketplace is its
        focus on diamonds as the underlying asset for the NFTs. Each diamond NFT
        represents a real-world diamond that is stored in a secure vault and can
        be redeemed by the owner at any time.
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Users can browse the marketplace to find diamonds that match their
        interests and budget. They can also create their own NFTs by uploading
        information about a diamond they own and setting a price.
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        The platform has a user-friendly interface that makes it easy to buy and
        sell diamonds. Transactions are conducted using TON tokens, which can be
        purchased on cryptocurrency exchanges or earned by participating in the
        TON community.
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Overall, the TON Diamonds NFT marketplace provides a unique opportunity
        for diamond enthusiasts and investors to own and trade NFTs that
        represent real-world assets. The platform's focus on security and
        transparency makes it a trustworthy and reliable option for anyone
        interested in NFTs.
      </Text>
    </>
  )
}

export function tokenDetails(): JSX.Element {
  const isMobileViewport = useMobileLayout()
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'

  return (
    <Text type={textType} color="secondary">
      The TON Diamonds has their own NFT-collection of Diamonds, which provides
      certain token capabilities, such as:
      <ul>
        <li>
          Access to the TON Diamonds NFT collection, which serves as a gateway
          to exploring the world of Web3 and allows holders to enjoy privileges
          within the TON ecosystem.
        </li>
        <li>
          Reduced or zero fees for trading on the TON Diamonds marketplace, with
          0% for Big Diamonds, 1% for Medium ones, and 2% for Small ones.
        </li>
        <li>
          Priority access to sales of partner collections and increased chances
          of winning in an airdrop.
        </li>
        <li>
          Access to a private group where holders can communicate with TON
          Diamonds founders, contribute to the project's development, and
          participate in special contests.
        </li>
      </ul>
    </Text>
  )
}
