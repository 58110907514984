// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pSfOb{display:flex;align-items:center;justify-content:center;padding-top:40px;flex-direction:column}.qhwm-{font-size:52px;margin-bottom:18px}", "",{"version":3,"sources":["webpack://./src/tonstarter/pages/Project/airdrop/Diamonds/components/Game/components/Win/Win.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA,CACA,qBAAA,CAGF,OACE,cAAA,CACA,kBAAA","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-top: 40px;\n  flex-direction: column;\n}\n\n.icon {\n  font-size: 52px;\n  margin-bottom: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "pSfOb",
	"icon": "qhwm-"
};
export default ___CSS_LOADER_EXPORT___;
