export enum WalletType {
  tonWallet = 'tonwallet',
  tonHub = 'tonhub',
  tonKeeper = 'tonkeeper',
}

export enum WalletStatus {
  DISCONNECTED = 'DISCONNECTED',
  CONNECTED = 'CONNECTED',
  CONNECTING = 'CONNECTING',
}

export type WalletAdapterTransferOptions = { to: string; value: number }

export interface WalletAdapterInterface {
  getWalletAddress(): Promise<string>
  getBalance(): Promise<string>
  getType(): WalletType
  transfer(options: WalletAdapterTransferOptions): Promise<boolean>
  signString(string: string): Promise<string>
  getWallets(): Promise<
    { publicKey: string; address: string; walletVersion: string }[]
  >
}
