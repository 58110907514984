import React, { Fragment } from 'react'

import { AirdropPage } from '../AirdropPage'
import { StormDetails } from './StormDetails'
import { StormToken } from './StormToken'

export const Storm = () => {
  return <AirdropPage
    DetailsComponent={StormDetails}
    MoreComponent={StormToken}
    moreTabName='Token info'
    colour="#6619D1"
    slug='storm'
    communityLink="https://t.me/community_bot?start=ap_7"
    imageCarousel={
      <img src='https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/storm/storm_hero.png' />
    }
  />
}