import React from 'react'
import Helmet from 'react-helmet'

import appleTouchIcon from '../../../../assets/apple-touch-icon.png'
import socialImg from '../../../../assets/social/tonstarter.png'

interface HeadProps {
  title?: string
}

export const Head = ({
  title = 'Tonstarter - The Open Network Launchpad',
}: HeadProps) => (
  <Helmet>
    <title>{title}</title>
    <meta
      name="description"
      content="Tonstarter provides community members with early access to Token sales, IDO, and Airdrops"
    />

    <meta
      property="og:title"
      content="Tonstarter - The Open Network Launchpad"
    />
    <meta
      property="og:description"
      content="Tonstarter provides community members with early access to Token sales, IDO, and Airdrops"
    />
    <meta property="og:locale" content="en_US" />
    <meta property="og:url" content="https://tonstarter.com" />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="Tonstarter" />
    <meta property="og:image" content={socialImg} />

    <meta itemProp="name" content="Tonstarter - The Open Network Launchpad" />
    <meta
      itemProp="description"
      content="Tonstarter provides community members with early access to Token sales, IDO, and Airdrops"
    />
    <meta itemProp="image" content={socialImg} />

    <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
  </Helmet>
)
