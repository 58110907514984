import React from 'react'

import classNames from 'classnames'

import styles from './Card.scss'

export enum ColorModifier {
  fb = 'firstBlue',
  sb = 'secondBlue',
  fg = 'firstGreen',
  fr = 'firstRed',
}

interface CardProps {
  title: string
  description: string
  icon: string
  width?: string
  colorModifier?: ColorModifier
}

export const Card = (props: CardProps) => {
  const { title, description, icon, width, colorModifier } = props

  const titleTiles = title.split(' ')

  return (
    <div
      className={classNames(
        styles.cardWrapper,
        colorModifier === ColorModifier.fb && styles.cardColorModifierFB,
        colorModifier === ColorModifier.fr && styles.cardColorModifierFR,
        colorModifier === ColorModifier.sb && styles.cardColorModifierSB,
        colorModifier === ColorModifier.fg && styles.cardColorModifierFG,
        width === 'half' && styles.cardWrapperHalf
      )}
    >
      <div className={styles.card}>
        <div className={styles.cardLeft}>
          <div className={styles.cardTitle}>
            {titleTiles.map((t) => (
              <span key={t}>{t} </span>
            ))}
          </div>
          <div className={styles.cardDescription}>{description}</div>
        </div>
        <div className={styles.cardRight}>
          <div
            className={classNames(styles.cardIcon, {
              [`${styles[icon]}`]: true,
            })}
          />
        </div>
      </div>
    </div>
  )
}
