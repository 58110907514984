import { Text } from "@design-system";
import { ApiService } from "@services";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import React, { useRef, useState } from "react";

import styles from "./Diamond.scss";
import glintAnimation from "./glint.json";
import { heyBots } from "../../../../../../../../../openrating/components";
import { currentUserSelector, useAppSelector } from "@store";

const hitCountToMint = 4;

export const Diamond = ({
                          onMint,
                          projectId
                        }: {
  onMint(result: { [key: string]: any }): void
  projectId: number
}) => {
  const [hitCounter, setHitCounter] = useState(0);
  const [isMinting, setIsMinting] = useState(false);
  const user = useAppSelector(currentUserSelector)

  const fetchStoneToMint = async () => {
    if (isMinting) {
      return;
    }

    setIsMinting(true);
    const time = new Date().getTime();
    const payload = await heyBots(time, user?.id);
    const { ok, data } = await ApiService.post({
      endpoint: `/projects/${projectId}/mint-stone`,
      data: {
        time,
        payload
      }
    });

    if (ok && data) {
      if (data.type === "1_words") {
        onMint(data);
      }
    }

    // onMint({
    //   type: '1_words',
    //   text: 'ololo',
    // })

    setIsMinting(false);
  };

  const handleBite = () => {
    if (hitCounter === hitCountToMint) {
      fetchStoneToMint();
    }

    setHitCounter(hitCounter >= hitCountToMint ? 0 : hitCounter + 1);

    player?.current?.goToAndPlay(0);
  };

  const player = useRef<LottieRefCurrentProps | null>(null);

  const handleClick = () => {
  };

  return (
    <div className={styles.root} onClick={handleClick}>
      <div className={styles.inner}>
        <Lottie
          animationData={glintAnimation}
          loop={false}
          autoplay={false}
          lottieRef={player}
          className={styles.lottie}
          onClick={handleBite}
        />

        <svg
          width="72"
          height="72"
          viewBox="0 0 72 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.svg}
        >
          <mask
            id="mask0_856_29212"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="-20"
            y="-19"
            width="112"
            height="112"
          >
            <path
              d="M-19.3846 -18.2769H91.3846V92.4924H-19.3846V-18.2769Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_856_29212)">
            <mask
              id="mask1_856_29212"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="4"
              y="9"
              width="64"
              height="56"
            >
              <path
                d="M4.98462 9.96924H67.0154V64.2462H4.98462V9.96924Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask1_856_29212)">
              <mask
                id="mask2_856_29212"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="4"
                y="9"
                width="64"
                height="56"
              >
                <path
                  d="M67.0154 26.2523L36 64.2462L4.98462 26.2523L11.9631 15.3969L18.5539 12.2954L27.0831 9.96924H44.9169L53.4462 12.2954L60.0369 15.3969L67.0154 26.2523Z"
                  fill="#4400FF"
                />
                <path
                  d="M67.0154 26.2523L36 64.2462L4.98462 26.2523L11.9631 15.3969L18.5539 12.2954L27.0831 9.96924H44.9169L53.4462 12.2954L60.0369 15.3969L67.0154 26.2523Z"
                  fill="#7646F9"
                />
              </mask>
              <g mask="url(#mask2_856_29212)">
                <mask
                  id="mask3_856_29212"
                  style={{ maskType: "luminance" }}
                  maskUnits="userSpaceOnUse"
                  x="-20"
                  y="-19"
                  width="112"
                  height="112"
                >
                  <path
                    d="M-19.3846 -18.2769H91.3846V92.4924H-19.3846V-18.2769Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask3_856_29212)">
                  <path
                    d="M-19.3846 -18.2769H91.3846V92.4924H-19.3846V-18.2769Z"
                    fill="#926BFD"
                  />
                  <path
                    d="M36 64.2462L67.0154 26.2523L60.0369 15.3969L53.4462 12.2954L44.9169 9.96924H27.0831L18.5539 12.2954L11.9631 15.3969L4.98462 26.2523L36 64.2462Z"
                    fill="#7646F9"
                  />
                  <path
                    d="M25.1447 20.8246H36V33.2307L25.1447 20.8246Z"
                    fill="#C1ABFF"
                  />
                  <path
                    d="M46.8554 20.8246H36V33.2307L46.8554 20.8246Z"
                    fill="#D3C4FF"
                  />
                  <path
                    d="M25.1447 33.2308H36L30.5723 27.0277L25.1447 33.2308Z"
                    fill="#9A75FF"
                  />
                  <path
                    d="M46.8554 33.2308H36L41.4277 27.0277L46.8554 33.2308Z"
                    fill="#BEA8FF"
                  />
                  <path
                    d="M17.5846 27.6092L25.1446 33.2308L30.5723 27.0277L17.5846 27.6092Z"
                    fill="#865CFF"
                  />
                  <path
                    d="M54.4153 27.6092L46.8553 33.2308L41.4276 27.0277L54.4153 27.6092Z"
                    fill="#AC8FFF"
                  />
                  <path
                    d="M25.1446 20.8246L30.5723 27.0277L17.5846 27.6092L25.1446 20.8246Z"
                    fill="#7745FF"
                  />
                  <path
                    d="M46.8553 20.8246L41.4276 27.0277L54.4153 27.6092L46.8553 20.8246Z"
                    fill="#885EFF"
                  />
                  <path
                    d="M4.98462 26.2524L14.6769 30.9047L25.1446 33.2309L17.5846 27.6094L4.98462 26.2524Z"
                    fill="#9A75FF"
                  />
                  <path
                    d="M14.6769 30.9045L25.1446 33.2307L17.5846 27.6091L14.6769 30.9045Z"
                    fill="#AC8FFF"
                  />
                  <path
                    d="M67.0153 26.2524L57.323 30.9047L46.8553 33.2309L54.4153 27.6094L67.0153 26.2524Z"
                    fill="#9D79FF"
                  />
                  <path
                    d="M10.0245 21.9877L17.5845 27.6092L25.1445 20.8246L10.0245 21.9877Z"
                    fill="#8F66FF"
                  />
                  <path
                    d="M61.9753 21.9877L54.4153 27.6092L46.8553 20.8246L61.9753 21.9877Z"
                    fill="#9D79FF"
                  />
                  <path
                    d="M57.323 30.9045L46.8553 33.2307L54.4153 27.6091L57.323 30.9045Z"
                    fill="#BEA8FF"
                  />
                  <path
                    d="M15.0645 17.7231L25.1445 20.8247L10.0245 21.9878L15.0645 17.7231Z"
                    fill="#8052FF"
                  />
                  <path
                    d="M56.9353 17.7231L46.8553 20.8247L61.9753 21.9878L56.9353 17.7231Z"
                    fill="#8052FF"
                  />
                  <path
                    d="M4.98462 26.2523L10.0246 21.9877L17.5846 27.6092L4.98462 26.2523Z"
                    fill="#6C38FF"
                  />
                  <path
                    d="M67.0154 26.2523L61.9754 21.9877L54.4154 27.6092L67.0154 26.2523Z"
                    fill="#6C38FF"
                  />
                  <path
                    d="M36 72L46.8554 33.2308L57.3231 30.9047L36 72Z"
                    fill="#8960F9"
                  />
                  <path
                    d="M36 72.0001L67.0154 26.2524L57.3231 30.9047L36 72.0001Z"
                    fill="#7141F9"
                  />
                  <path
                    d="M25.1447 33.2308L36 72.0001L46.8554 33.2308H25.1447Z"
                    fill="#AC8FFF"
                  />
                  <path
                    d="M46.8554 33.2308H36V72.0001L46.8554 33.2308Z"
                    fill="#BEA8FF"
                  />
                  <path
                    d="M36 33.2308H46.8554L36 42.9231V33.2308Z"
                    fill="#D2C2FF"
                  />
                  <path
                    d="M36 33.2308H25.1447L36 42.9231V33.2308Z"
                    fill="#BEA8FF"
                  />
                  <path
                    d="M36 72L25.1446 33.2308L14.6769 30.9047L36 72Z"
                    fill="#7949F9"
                  />
                  <path
                    d="M36 72.0001L4.98462 26.2524L14.6769 30.9047L36 72.0001Z"
                    fill="#5F28F9"
                  />
                  <path
                    d="M11.9631 15.397L4.98462 26.2524L15.0646 17.7231L11.9631 15.397Z"
                    fill="#7949F9"
                  />
                  <path
                    d="M60.0368 15.397L67.0153 26.2524L56.9353 17.7231L60.0368 15.397Z"
                    fill="#8154F9"
                  />
                  <path
                    d="M44.9169 9.96924H27.083L18.5538 12.2954L11.963 15.3969L15.0646 17.7231L25.1446 20.8246H46.8553L56.9353 17.7231L60.0369 15.3969L53.4461 12.2954L44.9169 9.96924Z"
                    fill="#9F7DFF"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>

      <Text align="center" color="fourth">
        Click & collect
      </Text>
    </div>
  );
};
