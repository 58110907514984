import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Button, Icon, Text } from '@design-system'
import { openNewTab } from '@utils'
import {
  PrivateAllocation,
  Project,
  fetchPrivateAllocation,
  privateAllocationSelector,
  useAppDispatch
} from '@store'
import { PrivateData, updateAllocation } from '../../../../'

import styles from '../../PrivateSidebar.scss'

interface PaidViewProps {
  data: PrivateData
  activeProject: Project
}

export const PaidView = ({
  data,
  activeProject,
}: PaidViewProps) => {
  const privateAllocations = useSelector(privateAllocationSelector)
  const allocation = privateAllocations?.filter(alloc => (
    alloc.projectSlug === activeProject.slug
  ))[0]!
  // exclamation because we render this
  // component only if allocations present

  const tonAmount = useMemo(() => {
    const formatter = Intl.NumberFormat('en-US')
    return formatter.format(allocation.amountInTon ?? 0)
  }, [])

  const dispatch = useAppDispatch()

  const handleKycClick = async () => {
    const updatedAllocation = allocation && {
      ...allocation,
      status:  'kyc_pending',
    }
    delete updatedAllocation.updatedAt
    await updateAllocation(updatedAllocation as PrivateAllocation)
    await dispatch(fetchPrivateAllocation())
    setTimeout(() => openNewTab(data.sumsub), 10)
  }

  const kycStatus = useMemo(() => {
    if (allocation.status === 'kyc_pending') {
      return (
        <div className={styles.stepWrapper}>
          <Text type="paragraph-16" weight="regular">
            Account verification
          </Text>
          <a href={data.sumsub} target="_blank" className={styles.kycLink}>
            <Icon
              name="hourglass"
              colorFill="tertiary"
            />
            <Icon
              name="chevron-right"
              colorFill="tertiary"
            />
          </a>
        </div>
      )
    }

    if (allocation.status === 'kyc_success') {
      return (
        <div className={styles.stepWrapper}>
          <Text type="paragraph-16" weight="regular">
            Account verification
          </Text>
          <Icon
            name="private-check"
            size="28"
          />
        </div>
      )
    }

    if (allocation.status === 'kyc_failed') {
      return (
        <div className={styles.stepWrapper}>
          <Text type="paragraph-16" weight="regular">
            Account verification
          </Text>
          <Icon
            name="private-cross"
            size="28"
          />
        </div>
      )
    }

    return (
      <Button color="primary" onClick={handleKycClick}>
        Verify your account
      </Button>
    )
  }, [allocation])

  const kycText = useMemo(() => {
    if (allocation.status === 'kyc_pending') {
      return (
        <>
          <Text color="tertiary">
            Verification can take up to 7 days.
          </Text>
          <Text color="tertiary">
            If you do not pass the KYC process, the
            funds will be returned to your wallet.
          </Text>
        </>
      )
    }

    if (allocation.status === 'kyc_success') {
      return null
    }

    if (allocation.status === 'kyc_failed') {
      return (
        <>
          <Text color="tertiary">
            Unfortunately, your KYC
            verification did not pass.
          </Text>
          <Text color="tertiary">
            Don't worry, your funds will
            be returned to you within a week.
          </Text>
        </>
      )
    }

    return (
      <>
        <Text color="tertiary">
          Verification can take up to 7 days.
        </Text>
        <Text color="tertiary">
          If you do not pass the KYC process, the
          funds will be returned to your wallet.
        </Text>
      </>
    )
  }, [allocation])

  return (
    <div className={styles.viewWrapper}>
      <div className={styles.stepsWrapper}>

        <div className={styles.stepWrapper}>
          <Text type="paragraph-16" weight="regular">
            <b>{tonAmount}</b> Toncoin received
          </Text>
          <Icon
            name="private-check"
            colorFill="accent"
            size="28"
          />
        </div>

        {kycStatus}
      </div>

      {kycText}
    </div>
  )
}
