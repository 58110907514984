export const roadmap = [
  {
    Q: 'Q4',
    year: '2022',
    actions: [
      'TON <-> WTON (Jetton Standard) Gateway Launch',
      'TON on Orbit Bridge (Two-way Bridge)',
      '11 TON Bridge Validators on Orbit Bridge',
      'Partnership with Blockchain Projects',
      'Marketing strategy establishment and build-up',
    ],
  },
  {
    Q: 'Q1',
    year: '2023',
    actions: [
      'Megaton Finance (AMM) Launch',
      'Wallet (deposit/withdraw), Swap, Yield Farm, Dashboard, Audit (CertiK)',
      'Community Incentives on Tonstarter',
      'More cross-chain assets and liquidity pools',
      'New Function Added: Lend (Single-side Deposit), MEGA Governance',
      'Telegram Integration (Web App & Chatbot)',
      'Additional Wallets (Tonic, OpenMask, etc.)',
      'Additional Partnerships',
    ],
  },
  {
    Q: 'Q2',
    year: '2023',
    actions: [
      'More cross-chain assets on liquidity pools',
      'New Function Added: Staking, Governance',
      'Telegram Integration Acceleration (Web App & Chatbot)',
      'Additional Partnerships',
      'UI/UX Improvements',
    ],
  },
  {
    Q: 'Q3',
    year: '2023',
    actions: [
      'More cross-chain assets on liquidity pools',
      'New Function Added: Drops & Ecopot, Pool Voting, Leverage Farm',
      'Additional Partnerships',
      'UI/UX Improvements',
    ],
  },
  {
    Q: 'Q4',
    year: '2023',
    actions: [
      'More cross-chain assets on liquidity pools',
      'New Function Added: Position Deposit (Long/Short)',
      'Additional Partnerships',
      'UI/UX Improvements',
    ],
  },
]
