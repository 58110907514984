import { useMobileLayout } from '@design-system'
import { themeSelector, useAppSelector } from '@store'
import React from 'react'
import { SwiperSlide } from 'swiper/react'

import SwiperCarousel from '../../../../../shared/design-system/components/SwiperCarousel'
import { AirdropPage } from '../AirdropPage'
import { StonDetails } from './StonDetails'
import { StonFaq } from './StonFaq'

const slides = {
  dark: [
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/stonfi/comics_dark_site_1.png',
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/stonfi/comics_dark_site_2.png',
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/stonfi/comics_dark_site_3.png',
  ],
  light: [
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/stonfi/comics_light_site_1.png',
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/stonfi/comics_light_site_2.png',
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/stonfi/comics_light_site_3.png',
  ],
}

export const Stonfi = () => {
  const theme = useAppSelector(themeSelector)
  const themedSlides = slides[theme]
  const isMobileLayout = useMobileLayout()

  return (
    <AirdropPage
      slug="stonfi"
      colour="#2383EB"
      DetailsComponent={StonDetails}
      MoreComponent={StonFaq}
      moreTabName="FAQ"
      imageCarousel={
        <SwiperCarousel
          slidesPerView={1}
          withButtons={true}
          pagination={true}
          spaceBetween={16}
          initialSlide={0}
          lazy={{ loadPrevNext: true }}
          childrenGrow={false}
          fullHeight={true}
        >
          <SwiperSlide>
            <SwiperCarousel.LazyImage
              borderRadius="16px"
              height={isMobileLayout ? 'auto' : '360px'}
              src="https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/stonfi/cover.png"
              lightbox={{
                images: [
                  {
                    alt: 'Stonfi',
                    src: 'https://tonstarter-cdn.ams3.digitaloceanspaces.com/projects/stonfi/cover.png',
                  },
                ],
              }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SwiperCarousel.LazyImage
              src={themedSlides[0]}
              lightbox={{
                images: [
                  {
                    alt: 'Stonfi',
                    src: themedSlides[0],
                  },
                ],
              }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SwiperCarousel.LazyImage
              src={themedSlides[1]}
              lightbox={{
                images: [
                  {
                    alt: 'Stonfi',
                    src: themedSlides[1],
                  },
                ],
              }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SwiperCarousel.LazyImage
              src={themedSlides[2]}
              lightbox={{
                images: [
                  {
                    alt: 'Stonfi',
                    src: themedSlides[2],
                  },
                ],
              }}
            />
          </SwiperSlide>
        </SwiperCarousel>
      }
    />
  )
}
