import React, { useEffect, useLayoutEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import Config from '@config'
import { getWebAppInitDataString, isWebApp } from '@design-system'
import { AnalyticsService, setUTMPayload, upsertSessionId } from '@services'
import {
  getAuthWebAppSession,
  getTonConnectSession,
  isAuthSelector,
  useAppDispatch,
  useAppSelector,
} from '@store'
import { currentUserSelector } from '@store/user'
import { initTheme } from '@utils'
import {
  CookiePolicyPage,
  DisclaimerPage,
  HiPage,
  MegatonPage,
  PrivacyPolicyPage,
  ProjectPage,
  TermsOfServicePage,
  VotingPage,
} from './pages'

import '../shared/design-system/styles/index.scss'
import { DeDust } from './pages/Project/airdrop/DeDust/DeDustPage'
import { FantonPage } from './pages/Project/airdrop/Fanton/FantonPage'
import { One } from './pages/Project/airdrop/One/OnePage'
import { Punk } from './pages/Project/airdrop/Punk/PunkPage'
import { Stonfi } from './pages/Project/airdrop/Stonfi/StonPage'

import { useSelector } from 'react-redux'
import { MainPage } from './pages'
import { FantonPage as PrivateFantonPage } from './pages/PrivateProject/projects/Fanton/FantonPage'
import { EvaaPage as EvaaPrivatePage } from './pages/PrivateProject/projects/Evaa/EvaaPage'
import { Atlas } from './pages/Project/airdrop/Atlas'
import { Diamonds } from './pages/Project/airdrop/Diamonds'
import { Storm } from './pages/Project/airdrop/Storm'
import { PrivateInvestorRoute } from './routes'
import { useTonConnectUI, useTonWallet } from '@tonconnect/ui-react'

function hashLinkScroll() {
  const { hash } = window.location

  if (hash !== '') {
    setTimeout(() => {
      const splitted = hash.split('#')
      const id = splitted.pop()

      if (id) {
        const element = document.getElementById(id)

        if (element) {
          element.scrollIntoView()
        }
      }
    }, 10)
  }
}

function consoleMessage() {
  console.log(
    '%cHey little explorer 👷',
    'font-family: monospace; font-size: 12px;background-color: rgb(73, 106, 235);color: #ffffff; padding: 10px; border-radius: 11px;'
  )
  console.log(
    '%cWanna test some, apply to our test group: https://t.me/+IMHNu3nKKEUxMDEy',
    'font-family: monospace; font-size: 12px;background-color: rgb(73, 106, 235);color: #ffffff; padding: 10px; border-radius: 11px;'
  )
}

export function App() {
  const location = useLocation()
  const isAuth = useAppSelector(isAuthSelector)
  const dispatch = useAppDispatch()

  useLayoutEffect(() => {
    hashLinkScroll()
    initTheme()
  }, [location])

  useEffect(() => {
    upsertSessionId()
    setUTMPayload(isAuth)
  }, [])

  useEffect(() => {
    AnalyticsService.trackUTM(isAuth)
  }, [isAuth])

  useEffect(() => {
    if (!isAuth && isWebApp()) {
      dispatch(
        getAuthWebAppSession({
          webAppData: getWebAppInitDataString(),
          walletType: 'tonkeeper',
        })
      )
    }
  }, [])

  const [tonConnectUi] = useTonConnectUI() 

  const tonProof = new Date().getTime().toString()

  tonConnectUi.setConnectRequestParameters({
    state: 'ready',
    value: { tonProof },
  })

  const tonWallet = useTonWallet()

  useEffect(() => {
    if (!isAuth && tonWallet) {
      dispatch(getTonConnectSession({ wallet: tonWallet, walletType: 'tonkeeper' }))
    }
  }, [tonWallet, isAuth])

  const isProd = Config.isProd

  return (
    <React.Fragment>
      <Toaster />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/cookie-policy" element={<CookiePolicyPage />} />
        <Route path="/disclaimer" element={<DisclaimerPage />} />
        <Route path="/project/:slug/*" element={<ProjectPage />} />
        <Route path="/project/megaton/*" element={<MegatonPage />} />
        <Route path="/project/fanton/*" element={<FantonPage />} />
        <Route path="/project/one/*" element={<One />} />
        <Route path="/project/punks/*" element={<Punk />} />
        <Route path="/project/stonfi/*" element={<Stonfi />} />
        <Route path="/project/dedust/*" element={<DeDust />} />
        <Route path="/project/tondiamonds/*" element={<Diamonds />} />
        <Route path="/project/tonatlas/*" element={<Atlas />} />
        <Route path="/project/storm/*" element={<Storm />} />
        <Route path="/hi" element={<HiPage />} />
        <Route path="*" element={<Navigate to="/" />} />

        <Route path="/project/evaa-waitlist/*" element={
          <Navigate to="/private/evaa" />
        } />

        <Route path="/private/evaa-waitlist/*" element={
          <Navigate to="/private/evaa" />
        } />

        <Route path="/private/evaa/*" element={<EvaaPrivatePage />} />

        <Route
          path="/private/fanton/*"
          element={<PrivateInvestorRoute component={<PrivateFantonPage />} />}
        />

        {!isProd && <Route path="/voting" element={<VotingPage />} />}
      </Routes>
    </React.Fragment>
  )
}
