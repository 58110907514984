import classNames from 'classnames'
import React from 'react'

import { BaseElement, BaseElementProps } from '../BaseElement/BaseElement'
import styles from './Content.scss'
import { Background, BorderRadius, ContentSpacingChild } from './types'

export interface ContentProps
  extends Omit<BaseElementProps, 'space' | 'direction'> {
  row?: boolean
  column?: boolean
  borderRadius?: BorderRadius
  background?: Background
  spacingChild?: ContentSpacingChild
}

export const Content = React.forwardRef<HTMLDivElement, ContentProps>(
  (
    { className, row, column, borderRadius, background, spacingChild, ...rest },
    ref
  ) => {
    const direction = row === true ? 'row' : column === true ? 'column' : 'row'
    const spacingDirection =
      direction === 'row' ? 'spacing-child-right' : 'spacing-child-bottom'

    return (
      <BaseElement
        className={classNames(
          borderRadius && styles[`border-radius-${borderRadius}`],
          background && styles[`background-${background}`],
          spacingChild && styles[`${spacingDirection}-${spacingChild}`],
          className
        )}
        direction={direction}
        ref={ref}
        {...rest}
      />
    )
  }
)
