import { configureStore } from '@reduxjs/toolkit'

import { LocalStorageService } from '@services'
import allocations from '@store/allocations/allocationsSlice'
import app from '@store/app/appSlice'
import auth from '@store/auth/authSlice'
import projects from '@store/projects/projectsSlice'
import user from '@store/user/userSlice'
import wallet from '@store/wallet/walletSlice'

export const reHydrateStore = () => {
  const address = LocalStorageService.getItem('wallet-address', null)
  const balance = LocalStorageService.getItem('wallet-balance', null)
  const walletType = LocalStorageService.getItem('wallet-type', null)

  return {
    wallet: {
      address,
      balance,
      walletType,
    },
  }
}

export const store = configureStore({
  preloadedState: reHydrateStore() as any,
  reducer: { app, auth, projects, allocations, wallet, user },
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export * from './allocations'
export * from './app'
export * from './auth'
export * from './hooks'
export * from './projects'
export * from './user'
export * from './wallet'
