import classnames from 'classnames'
import React from 'react'

import { Button, useQueryPhoneOnly } from '@design-system'
import { IntroImage } from '../IntroImage'
import styles from './Intro.scss'

export const Intro = () => {
  const tgIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {' '}
      <path
        d="M4.23741 11.9414C9.06923 9.79202 12.2912 8.37501 13.9033 7.69038C18.5063 5.73562 19.4627 5.39605 20.0861 5.38472C20.2232 5.38238 20.5298 5.41707 20.7284 5.58159C20.8961 5.72051 20.9422 5.90818 20.9643 6.03989C20.9864 6.1716 21.0139 6.47164 20.992 6.70609C20.7426 9.38201 19.6633 15.8758 19.1142 18.8728C18.8818 20.141 18.4244 20.5662 17.9815 20.6078C17.0189 20.6983 16.288 19.9584 15.3558 19.3344C13.897 18.3581 13.0729 17.7503 11.6569 16.7976C10.0205 15.6965 11.0813 15.0914 12.0139 14.1024C12.258 13.8435 16.4988 9.90506 16.5809 9.54778C16.5912 9.5031 16.6007 9.33654 16.5038 9.24858C16.4069 9.16063 16.2639 9.19071 16.1606 9.21463C16.0143 9.24854 13.6839 10.8212 9.16938 13.9327C8.5079 14.3965 7.90875 14.6225 7.37193 14.6106C6.78013 14.5976 5.64175 14.269 4.79548 13.9881C3.75749 13.6436 2.93252 13.4615 3.00436 12.8764C3.04178 12.5717 3.45279 12.26 4.23741 11.9414Z"
        fill="white"
      />{' '}
    </svg>
  )
  const twIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2094 8.74396C20.2094 8.91304 20.2094 9.08213 20.2094 9.22705C20.2094 14.2271 16.393 20 9.43649 20C7.28673 20 5.30606 19.372 3.61523 18.3092C3.90509 18.3333 4.2191 18.3575 4.50895 18.3575C6.27224 18.3575 7.91475 17.7536 9.2191 16.7391C7.55243 16.715 6.15147 15.6039 5.69253 14.1063C5.93407 14.1546 6.15147 14.1787 6.41717 14.1787C6.75533 14.1787 7.09349 14.1304 7.4075 14.0338C5.66837 13.6957 4.36403 12.1739 4.36403 10.3382C4.36403 10.314 4.36403 10.314 4.36403 10.2899C4.87127 10.5797 5.45098 10.7488 6.079 10.7729C5.06451 10.0966 4.38818 8.9372 4.38818 7.6087C4.38818 6.90821 4.58142 6.25604 4.89543 5.70048C6.75533 7.99517 9.55726 9.49275 12.6974 9.66184C12.6249 9.39614 12.6007 9.08213 12.6007 8.79227C12.6007 6.69082 14.2916 5 16.393 5C17.48 5 18.4703 5.45894 19.1466 6.18357C20.0162 6.01449 20.8133 5.70048 21.5621 5.2657C21.2722 6.15942 20.6684 6.88406 19.8954 7.36715C20.6684 7.27053 21.393 7.07729 22.0693 6.76329C21.5862 7.53623 20.9582 8.21256 20.2094 8.74396Z"
        fill="#496AEB"
      />
    </svg>
  )

  const isMobileViewport = useQueryPhoneOnly()

  return (
    <section className={styles.root}>
      <div className={styles.description}>
        <h1 className={classnames(styles.title, styles.gradientText)}>
          Create, {!isMobileViewport && <br />} fundraise{' '}
          {!isMobileViewport && <br />}
          and launch
        </h1>
        <h2 className={styles.subtitle}>
          <span>
            Tonstarter is the first TON-native launchpad, enabling crypto
            projects to raise capital in a decentralized, safe, and
            user-friendly environment.
          </span>
        </h2>
        <div className={styles.buttons}>
          <Button
            icon={tgIcon}
            className={classnames(styles.button, styles.buttonTelegram)}
            as="a"
            href="https://t.me/ton_starter"
            target="_blank"
            variant="secondary"
          >
            Telegram
          </Button>
          <Button
            icon={twIcon}
            className={classnames(styles.button, styles.buttonTwitter)}
            as="a"
            href="https://twitter.com/ton_starter"
            target="_blank"
            variant="secondary"
          >
            Twitter
          </Button>
        </div>
      </div>
      <IntroImage />
    </section>
  )
}
