import React from 'react'

import { ConnectWalletButton } from '@components'
import { default as config } from '@config'
import { Button, Text } from '@design-system'
import { Allocation, Project, ProjectStatus, User } from '@store'
import styles from '../../AirdropSidebar.scss'
import { RewardPool } from '../RewardPool/RewardPool'
import { ApplyButton } from './ApplyButton'
import { SubscribeButton } from './SubscribeButton'
import { VestingDetails } from '../../../../../PrivateProject/components/PrivateSidebar/components/VestingDetails'
import { privateData } from '../../../../../PrivateProject/projects/Fanton/data/privateData'
import privateSidebarStyle from '../../../../../PrivateProject/components/PrivateSidebar/PrivateSidebar.scss'

interface ApplyViewProps {
  activeProject: Project
  activeAllocation: Allocation
  isAuth: boolean
  rewardPool: string
  user: User | null
}

export const ApplyView = ({
  activeProject,
  activeAllocation,
  isAuth,
  rewardPool,
  user,
}: ApplyViewProps): JSX.Element => {
  const renderView = () => {
    if (!isAuth) {
      return (
        <>
          <ConnectWalletButton
            className={styles.button}
            title={activeProject.slug === 'tondiamonds' ? "Apply to get rewards" : "Sign in with a TON wallet"}
            fullWidth
          />
        </>
      )
    }

    if (
      activeProject.status === ProjectStatus.whitelistSoon &&
      !activeAllocation?.id
    ) {
      return (
        <>
          <SubscribeButton
            projectId={activeProject.id}
            projectSlug={activeProject.slug}
          />
          <Text className={styles.tip}>
            { activeProject.slug === 'evaa-waitlist' ?
              'Entry deadline in waitlist is September 6, 15:00 UTC.' :
              'Join waitlist to get extra points first!'
            }
          </Text>
        </>
      )
    } else if (
      activeProject.status === ProjectStatus.whitelistSoon &&
      activeAllocation?.id
    ) {
      return (
        <>
          <Button className={styles.button} fullWidth disabled>
            Joined
          </Button>
          <Text className={styles.tip}>
          { activeProject.slug === 'evaa-waitlist' ?
            'Entry deadline in waitlist is September 6, 15:00 UTC.' :
            (<>
                Thanks for joining, our{' '}
                <a href={`https://t.me/${config.botName}`} target="_blank">
                  bot
                </a>{' '}
                will send you a notification when activity starts!
            </>)
          }
          </Text>
        </>
      )
    } else if (
      (activeProject.status === ProjectStatus.whitelistOpen &&
        !activeAllocation?.id) ||
      !user?.telegramId
    ) {
      return (
        <>
          <ApplyButton
            projectId={activeProject.id}
            projectSlug={activeProject.slug}
          />
        </>
      )
    }

    return null
  }

  if (activeProject.slug === 'evaa-waitlist') {
    return (
      <>
        <div className={styles.bochka}>
          <h2 className={styles.title}>Evaa Protocol</h2>
          <Text color="tertiary" align="center" spacingBottom="16">
            🔓 Angel round
          </Text>

          <div className={styles.reward}>
            <div>
              <Text className={styles.rewardAmount}>Opening soon</Text>
            </div>
            <div>
              <span className={styles.giftBig}>💼</span>
              <span className={styles.giftSmall}>💼</span>
            </div>
          </div>

          {renderView()}
        </div>
        <div className={styles.bochka}>
          <VestingDetails
            data={{
              contractAddress: '',
              cover: '',
              dates: {
                end: new Date(),
                start: new Date(),
              },
              docs: '',
              fund: '',
              limits: {
                max: '0',
                min: '0',
              },
              prices: {
                tokenPriceInTon: '0',
                tonPriceInUsd: '0',
              },
              sumsub: '',
              ticker: '',
              vesting: [
                {
                  title: 'TGE date',
                  value: 'Q1 2024'
                },
                {
                  title: 'Price per $EVAA',
                  value: '$0.2'
                },
                {
                  title: 'Round allocation',
                  value: '1% - 1.5%'
                },
                {
                  title: 'Cliff',
                  value: '6 months'
                },
                {
                  title: 'Vesting',
                  value: '10% every 2 months'
                },
              ],
            }}
          />
        </div>
      </>
      
    )
  }

  return (
    <div className={styles.bochka}>
      <h2 style={{ marginBottom: '16px' }} className={styles.title}>
        {activeProject.title}
      </h2>
      <RewardPool rewardPool={rewardPool} slug={activeProject.slug} />

      {renderView()}
    </div>
  )
}
