import React from 'react'

import { roadmap } from './data/roadmap'
import { Text, useMobileLayout } from '@design-system'

import styles from '../components/About/About.scss'
import { Arrow, TelegramPlane } from '../icons'

export const OneDetails = () => {
  const isMobileLayout = useMobileLayout()

  const extraHeaderType = isMobileLayout ? 'header-32' : 'header-44'
  const headerType = isMobileLayout ? 'title-24' : 'title-28'
  const subheaderType = isMobileLayout ? 'title-20' : 'title-24'
  const paragraphType = isMobileLayout ? 'paragraph-16' : 'paragraph-20'
  const paragraphColor = 'secondary'

  return (
    <>
      <div className={styles.row}>
        <Text type="paragraph-14" color={paragraphColor}>
          Cardinal Fuzz are proud to announce the release of the epic “Dust” via Perth quintet Mt. Mountain. Since forming in 2012 Mt. Mountain are already lauded and revered in Australia where they have built a reputation as one of the most compelling live bands, a distinction that has seen them share stages with myriad Australian and international heavyweights including Sleep, King Gizzard and the Lizard Wizard, Thee Oh Sees, Endless Boogie, Tortoise, Bardo Pond and Boris. On “Dust” Mt Mountain have laid down 4 tracks that capture the atmosphere of the red/orange landscapes that consume the Australian Outback. Opening with the mini slow burn epic “Dust” which builds with an incessant drone and flute to form a ghostly menacing and meditative rhythmic and repetitive throb that builds and builds before the release comes and the bands shatters into a heady and thunderous elliptical crunch. Over the entire LP Mt Mountain capture, a dreamlike mood of shimmering dust filled landscapes where slow strummed guitars and single note organ lines ebb and flow and bring to mind Dylan Carson’s ‘Earth’ as played by mushroom ingesting elf’s. ‘Dust’ is psych rock meditation music and It is utterly entrancing.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="24">
          Mt. Mountain
        </Text>

        {roadmap.map((r, i) => (
          <div className={styles.roadmapRow} key={i}>
            <Text type={extraHeaderType} spacingBottom="12">
              {r.Q}
              <span>{r.year}</span>
            </Text>
            {r.actions.map((a, j) => (
              <Text
                type={paragraphType}
                color={paragraphColor}
                spacingBottom="16"
                key={j}
              >
                {a}
              </Text>
            ))}
          </div>
        ))}
        <a
          className={styles.teamLink}
          href="https://mtmountain.bandcamp.com/album/dust"
          target="_blank"
        >
          <Text type={paragraphType} color={paragraphColor}>
            Listen
          </Text>
          <Arrow />
        </a>
        <a
          className={styles.teamLink}
          href="https://t.me/stonston/198"
          target="_blank"
          style={{marginLeft: '15px'}}
        >
          <Text type={paragraphType} color={paragraphColor}>
            Find in Tg
          </Text>
          <TelegramPlane className={styles.icon} />
        </a>
      </div>
    </>
  )
}
