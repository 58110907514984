import React from 'react'

import { Allocation, Project } from '@store'
import { FundGoal } from '../FundGoal/FundGoal'
import { Table } from '../Table/Table'
import { Tip } from '../Tip/Tip'
import { getActionButtonByStep } from './buttonHelpers'
import { getTipMessageByStep } from './tipHelpers'

import styles from '../../Sidebar.scss'

export enum ApplyFormStep {
  'comingSoon',
  'applicationsOpened',
  'applicationsClosed',
  'allocationsDraw',
  'tokenSale',
  'vesting',
}

interface ApplyFormProps {
  activeProject: Project
  activeAllocation: Allocation
  isAuth: boolean
  step: ApplyFormStep
}

export const ApplyForm = ({
  activeProject,
  activeAllocation,
  isAuth,
  step,
}: ApplyFormProps): JSX.Element => {
  const { isLotteryWinner } = activeAllocation

  const tipMessage = getTipMessageByStep({
    step,
    isAuth,
    isLotteryWinner,
    allocation: activeAllocation,
  })

  return (
    <div className={styles.bochka}>
      <FundGoal fundGoal={activeProject.fundsGoal} />
      <Tip message={tipMessage} />
      <Table
        allocationUSD={activeProject.allocationUSD}
        tokenUSD={activeProject.tokenUSD}
      />
      {getActionButtonByStep({
        step,
        isAuth,
        projectId: activeProject.id,
        projectSlug: activeProject.slug,
        allocation: activeAllocation,
      })}
      <a
        href="https://t.me/ton_starter_support_bot"
        target="_blank"
        className={styles.supportLink}
      >
        Support chat
      </a>
    </div>
  )
}
