export const advisors = [
  {
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/punks/ahmad.jpeg',
    name: 'Ahmad Mazen Bitar',
    role: 'Ethereum Core Developer',
    linkedin: 'https://www.linkedin.com/in/ahmad-mazen-bitar-45b1455a/',
  },
  {
    image:
      'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/punks/cryptoCyr.png',
    name: 'Kirill Malev',
    role: 'Jr. Partner at First Stage Labs',
    linkedin: 'https://www.linkedin.com/in/kirillmalev/',
  },
]
