import BN from 'bn.js'
import { Address, beginCell, TonClient } from 'ton'

import config from '@config'
import { ClaimState } from '@store'
import { nanoToDisplay } from '../utils/token'

export interface TonServiceResponse<T> {
  error?: string
  data: T
  ok: boolean
}

interface FetchProjectInfoData {
  tokenPriceInNanoTon: string
  amountTokensLeft: string
}

interface FetchWalletInfoData {
  balance: string
  state: 'active' | 'uninitialized' | 'frozen'
}

interface FetchAvailableTokensData extends ClaimState {}

export class TonService {
  static client: TonClient = new TonClient({
    endpoint: config.tonCenterApiHost,
    apiKey: config.tonCenterApiKey,
  })

  static async fetchProjectInfo({
    contractAddress,
  }: {
    contractAddress: string
  }): Promise<TonServiceResponse<FetchProjectInfoData>> {
    const address = Address.parse(contractAddress)

    const response = await TonService.client.callGetMethod(
      address,
      'get_project_info'
    )

    // const projectAddressBytes = response.stack[0][1]
    // const jettonWalletBytes = response.stack[1][1].bytes
    const tokenPriceInNanoTon = new BN(response.stack[2]).toString()
    const amountTokensLeft = new BN(response.stack[3]).toString()

    return {
      ok: true,
      error: '',
      data: { tokenPriceInNanoTon, amountTokensLeft },
    }
  }

  static async fetchIsPurchased({
    walletAddress,
    contractAddress,
  }: {
    walletAddress: string
    contractAddress: string
  }): Promise<TonServiceResponse<boolean>> {
    const contractAddressParsed = Address.parse(contractAddress)
    const walletAddressParsed = Address.parseFriendly(walletAddress).address

    const userAddressInCell = beginCell()
    userAddressInCell.storeAddress(walletAddressParsed)

    const slice = userAddressInCell
      .endCell()
      .toBoc({ idx: false })
      .toString('base64')

    const response = await TonService.client.callGetMethodWithError(
      contractAddressParsed,
      'is_purchased',
      [['tvm.Slice', slice]]
    )

    const isPurchased =
      new BN(parseInt(response.stack[0][1], 16)).toString() === '-1'

    return {
      ok: true,
      error: '',
      data: isPurchased,
    }
  }

  static async fetchWalletInfo({
    walletAddress,
  }: {
    walletAddress: string
  }): Promise<TonServiceResponse<FetchWalletInfoData>> {
    const address = Address.parse(walletAddress)
    const response = await this.client.getContractState(address)
    const balance = new BN(response.balance).toString()

    return {
      ok: true,
      error: '',
      data: { balance, state: response.state },
    }
  }

  static async fetchAvailableTokens({
    walletAddress,
    contractAddress,
  }: {
    walletAddress: string
    contractAddress: string
  }): Promise<TonServiceResponse<FetchAvailableTokensData>> {
    const contractAddressParsed = Address.parse(contractAddress)
    const walletAddressParsed = Address.parseFriendly(walletAddress).address

    const userAddressInCell = beginCell()
    userAddressInCell.storeAddress(walletAddressParsed)

    const slice = userAddressInCell
      .endCell()
      .toBoc({ idx: false })
      .toString('base64')

    const response = await TonService.client.callGetMethodWithError(
      contractAddressParsed,
      'get_available_tokens',
      [['tvm.Slice', slice]]
    )

    const { stack } = response
    const availableTokens = parseInt(stack[0][1], 16).toString()
    const claimedTokens = parseInt(stack[1][1], 16).toString()
    const allTokens = parseInt(stack[2][1], 16).toString()
    const time = parseInt(stack[3][1], 16).toString()
    const timeToLockup = parseInt(stack[4][1], 16).toString()
    const timeToVesting = parseInt(stack[5][1], 16).toString()

    return {
      ok: true,
      error: '',
      data: {
        availableTokens,
        claimedTokens,
        allTokens,
        time,
        timeToLockup,
        timeToVesting,
      },
    }
  }

  static async fetchClaimState({
    walletAddress,
    contractAddress,
  }: {
    walletAddress: string
    contractAddress: string
  }): Promise<TonServiceResponse<boolean>> {
    const contractAddressParsed = Address.parse(contractAddress)
    const walletAddressParsed = Address.parseFriendly(walletAddress).address

    const userAddressInCell = beginCell()
    userAddressInCell.storeAddress(walletAddressParsed)

    const slice = userAddressInCell
      .endCell()
      .toBoc({ idx: false })
      .toString('base64')

    const response = await TonService.client.callGetMethodWithError(
      contractAddressParsed,
      'get_claim_state',
      [['tvm.Slice', slice]]
    )

    const isReadyToClaim =
      new BN(parseInt(response.stack[1][1], 16)).toString() === '-1'

    return {
      ok: true,
      error: '',
      data: isReadyToClaim,
    }
  }

  static async fetchContractState({
    contractAddress,
  }: {
    contractAddress: string
  }): Promise<TonServiceResponse<{ state: string }>> {
    const address = Address.parseFriendly(contractAddress).address
    const response = await TonService.client.getContractState(address)

    return {
      ok: true,
      error: '',
      data: { state: response.state },
    }
  }

  static async callGetStateMethod({
    contractAddress,
  }: {
    contractAddress: string
  }): Promise<TonServiceResponse<{ statusCode: string }>> {
    const address = Address.parseFriendly(contractAddress).address
    const response = await TonService.client.callGetMethod(address, 'get_state')

    return {
      ok: true,
      error: '',
      data: { statusCode: new BN(response.stack[0]).toNumber().toString() },
    }
  }

  static async callGetPurchasedTokensMethod({
    contractAddress,
  }: {
    contractAddress: string
  }): Promise<TonServiceResponse<{ tokensAmount: string }>> {
    const address = Address.parseFriendly(contractAddress).address
    const response = await TonService.client.callGetMethod(
      address,
      'get_purchased_tokens'
    )
    // (new BN(response.stack[0][1]).toNumber() / 1000000000).toString()
    return {
      ok: true,
      error: '',
      data: {
        tokensAmount: (
          new BN(response.stack[0]).toNumber() / 1000000000
        ).toString(),
      },
    }
  }

  static async getLockUpDetails({
    userWalletAddress,
    lockupContract,
  }: {
    userWalletAddress: string
    lockupContract: string
  }) {
    const userWalletAddressParsed = Address.parseFriendly(userWalletAddress).address
    const lockupContractParsed = Address.parse(lockupContract)
    const jettonDefaultDecimals = 9

    const userAddressInCell = beginCell()
    userAddressInCell.storeAddress(userWalletAddressParsed)

    const slice = userAddressInCell
      .endCell()
      .toBoc({ idx: false })
      .toString('base64')

    const holderInfo = await TonService.client.callGetMethodWithError(
      lockupContractParsed,
      'getHolderInfo',
      [['tvm.Slice', slice]]
    )

    const parsedHolderInfo = {
      common: nanoToDisplay(new BN(parseInt(holderInfo.stack[0][1], 16)).toString(), jettonDefaultDecimals),
      frozen: nanoToDisplay(new BN(parseInt(holderInfo.stack[1][1], 16)).toString(), jettonDefaultDecimals),
      available: nanoToDisplay(new BN(parseInt(holderInfo.stack[2][1], 16)).toString(), jettonDefaultDecimals),
      currentPeriod: new BN(parseInt(holderInfo.stack[3][1], 16)).toNumber(),
    }
    console.log(parsedHolderInfo)

    return {
      ok: true,
      error: '',
      lockupDetails: parsedHolderInfo,
    }
  }

  static async getLockupPeriods({
    lockupContract
  }: {
    lockupContract: string
  }) {
    const lockupContractParsed = Address.parse(lockupContract)
    const periods = await TonService.client.callGetMethodWithError(
      lockupContractParsed,
      'getPeriods'
    )
    let parsedPeriods : number[] = []

    periods.stack.forEach((period) => {
      parsedPeriods.push(new BN(parseInt(period[1], 16)).toNumber() * 1000)
    })
    console.log(parsedPeriods)

    return {
      ok: true,
      error: '',
      lockupPeriods: parsedPeriods,
    }
  }
}
