import classNames from 'classnames'
import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  LinkHTMLAttributes,
} from 'react'
import { Animate } from 'react-move'

import styles from './Button.scss'
import { ButtonShape, ButtonSize, ButtonVariant } from './Button.types'

interface ButtonProps {
  as?: React.ElementType
  variant?: ButtonVariant
  shape?: ButtonShape
  size?: ButtonSize
  icon?: JSX.Element | null
  iconPosition?: 'start' | 'end'
  disabled?: boolean
  loading?: boolean
  loadingIcon?: boolean
  fullWidth?: boolean
  onClick?: () => void
}

const enterParams = {
  opacity: [1],
  timing: { duration: 250, ease: quadInOut },
}
const leaveParams = {
  opacity: [0],
  timing: { duration: 10 },
}

function quadInOut(t: number) {
  return ((t *= 2) <= 1 ? t * t : --t * (2 - t) + 1) / 2
}

export function Button({
  as: Component = 'button',
  variant = 'primary',
  size = 'medium',
  shape = 'square',
  icon,
  iconPosition = 'start',
  children,
  className,
  type = 'button',
  disabled,
  loading = false,
  loadingIcon = false,
  fullWidth,
  onClick,
  ...props
}: ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement> &
  LinkHTMLAttributes<HTMLLinkElement> &
  AnchorHTMLAttributes<HTMLLinkElement>) {
  const classList = classNames(
    styles.root,
    styles[`shape-${shape}`],
    variant !== 'block' && styles[`size-${size}`],
    styles[`variant-${variant}`],
    // !!icon && styles['with-icon'],
    children && iconPosition === 'start' && styles.iconAtStart,
    iconPosition === 'end' && styles.iconAtEnd,
    disabled && styles.disabled,
    fullWidth && styles.fullWidth,
    loading && styles.loading,
    loadingIcon && styles.loadingIcon,
    className
  )

  const handleClick = () => {
    if (onClick && !disabled && !loading && !loadingIcon) {
      onClick()
    }
  }

  return (
    <Component
      className={classNames(classList)}
      onClick={handleClick}
      disabled={disabled}
      {...props}
    >
      <Animate
        show={loadingIcon}
        start={{ opacity: 0 }}
        enter={enterParams}
        leave={leaveParams}
      >
        {({ opacity }) => {
          return (
            <div
              className={styles.loadingIconLoader}
              style={{
                opacity,
              }}
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )
        }}
      </Animate>
      {!!icon && iconPosition === 'start' && !loadingIcon ? icon : null}
      {!!children && <span>{children}</span>}
      {!!icon && iconPosition === 'end' && icon}
      <Animate
        show={loading}
        start={{ opacity: 0 }}
        enter={enterParams}
        leave={leaveParams}
      >
        {({ opacity }) => {
          return (
            <div
              className={styles.ldsRing}
              style={{
                opacity,
              }}
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )
        }}
      </Animate>
    </Component>
  )
}

Button.displayName = 'Button'
