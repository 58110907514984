import classnames from 'classnames'
import * as React from 'react'

import { Footer, Head, Header } from './components'
import styles from './Page.scss'

function Body({
  children,
  className,
  wide,
  withHeader,
}: {
  children: React.ReactElement[] | React.ReactElement | any
  className?: String
  wide?: boolean
  withHeader?: boolean
}): JSX.Element {
  return (
    <div
      className={classnames(
        !wide && styles.normal,
        wide && styles.wide,
        withHeader && styles.withHeader,
        className
      )}
    >
      {children}
    </div>
  )
}

const Page = ({
  children,
}: {
  children: React.ReactElement[] | React.ReactElement | any
}) => {
  return <React.Fragment>{children}</React.Fragment>
}

Page.Head = Head
Page.Header = Header
Page.Body = Body
Page.Footer = Footer

export { Page }
