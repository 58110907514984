import React, { useState } from 'react'
import classNames from 'classnames'
import * as LB from 'react-spring-lightbox'
import { ImagesListType } from 'react-spring-lightbox'

import styles from './Lightbox.scss'

const LightboxHeader = ({ onClose }: { onClose?: () => void }) => {
  return (
    <div className={styles.header}>
      <div className={styles.cross} onClick={onClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>
    </div>
  )
}

const LightboxArrowButton = ({
  type,
  onClick,
}: {
  type: 'prev' | 'next'
  onClick(): void
}) => {
  return (
    <div
      className={classNames(
        styles.button,
        type === 'prev' && styles.buttonPrev,
        type === 'next' && styles.buttonNext
      )}
      onClick={onClick}
    >
      {type === 'prev' ? (
        <svg
          width="51"
          height="20"
          viewBox="0 0 51 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7071 0.792893C10.3166 0.402369 9.68342 0.402369 9.2929 0.792893L0.792896 9.29289C0.40237 9.68342 0.402374 10.3166 0.792896 10.7071L9.2929 19.2071C9.68342 19.5976 10.3166 19.5976 10.7071 19.2071C11.0976 18.8166 11.0976 18.1834 10.7071 17.7929L3.91422 11H50C50.5523 11 51 10.5523 51 10C51 9.44771 50.5523 9 50 9H3.91422L10.7071 2.20711C11.0976 1.81658 11.0976 1.18342 10.7071 0.792893Z"
            fill="white"
          />
        </svg>
      ) : null}
      {type === 'next' ? (
        <svg
          width="51"
          height="20"
          viewBox="0 0 51 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.2929 0.792893C40.6834 0.402369 41.3166 0.402369 41.7071 0.792893L50.2071 9.29289C50.5976 9.68342 50.5976 10.3166 50.2071 10.7071L41.7071 19.2071C41.3166 19.5976 40.6834 19.5976 40.2929 19.2071C39.9024 18.8166 39.9024 18.1834 40.2929 17.7929L47.0858 11H0.999999C0.447715 11 0 10.5523 0 10C0 9.44771 0.447715 9 0.999999 9H47.0858L40.2929 2.20711C39.9024 1.81658 39.9024 1.18342 40.2929 0.792893Z"
            fill="white"
          />
        </svg>
      ) : null}
    </div>
  )
}

export type LightboxImages = ImagesListType

export type LightboxProps = {
  isOpen: boolean
  images: ImagesListType
  onClose?: () => void
}

export const Lightbox = ({
  isOpen,
  onClose,
  images,
}: LightboxProps): JSX.Element => {
  const [currentImageIndex, setCurrentIndex] = useState(0)

  const hasPrevSlide = currentImageIndex > 0
  const hasNextSlide = currentImageIndex + 1 < images.length

  const gotoPrevious = () =>
    hasPrevSlide && setCurrentIndex(currentImageIndex - 1)

  const gotoNext = () => hasNextSlide && setCurrentIndex(currentImageIndex + 1)

  return (
    <LB.default
      isOpen={isOpen}
      onPrev={gotoPrevious}
      onNext={gotoNext}
      images={images}
      currentIndex={currentImageIndex}
      onClose={onClose}
      renderHeader={() => <LightboxHeader onClose={onClose} />}
      renderFooter={() => (
        <div className={styles.footer}>
          {hasPrevSlide ? (
            <LightboxArrowButton type="prev" onClick={gotoPrevious} />
          ) : null}
          {hasNextSlide ? (
            <LightboxArrowButton type="next" onClick={gotoNext} />
          ) : null}
        </div>
      )}
      className={styles.root}
      style={{ background: 'rgba(0, 0, 0, 0.85)' }}
      singleClickToZoom
      pageTransitionConfig={{
        from: { transform: 'scale(0.9)', opacity: 0 },
        enter: { transform: 'scale(1)', opacity: 1 },
        leave: { transform: 'scale(0.9)', opacity: 0 },
        config: { mass: 1, tension: 320, friction: 32 },
      }}
    />
  )
}

Lightbox.displayName = 'Lightbox'
