import React, { ComponentType, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { getTGUserId, Page, useWebApp } from '@design-system'
import { AnalyticsService } from '@services'
import {
  activeProjectSelector,
  currentUserSelector,
  fetchAllocation,
  fetchProject,
  useAppDispatch,
  useAppSelector,
} from '@store'
import { LoaderIcon } from 'react-hot-toast'
import { AirdropSidebar } from '../components/AirdropSidebar/AirdropSidebar'
import { CommunitySidebar } from '../components/CommunitySidebar'
import { About } from './components/About/About'
import { Head } from './components/Head'

import { PageHeader } from '../../../components'
import styles from './AirdropPage.scss'

interface AirdropPageProps {
  slug: string
  colour?: string
  DetailsComponent?: ComponentType<any>
  MoreComponent?: ComponentType<any>
  moreTabName?: string
  imageCarousel?: JSX.Element
  communityLink?: string
}

export const AirdropPage = ({
  slug,
  colour,
  DetailsComponent,
  MoreComponent,
  moreTabName,
  imageCarousel,
  communityLink,
}: AirdropPageProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const activeProject = useAppSelector(activeProjectSelector)
  const user = useAppSelector(currentUserSelector)
  const isLoaded = activeProject.id && activeProject.status

  if (colour) {
    document.documentElement.style.setProperty('--project-colour', colour)
  }

  useWebApp(({ BackButton }) => {
    BackButton.show()

    BackButton.onClick(() => {
      BackButton.hide()

      navigate('/')
    })
  })

  useEffect(() => {
    dispatch(fetchProject({ projectSlug: slug }))
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const userTgId = getTGUserId()
    const isUserExists = user?.id || userTgId

    if (isUserExists && slug) {
      dispatch(
        fetchAllocation({
          projectSlug: slug,
          telegramId: user?.telegramId ?? userTgId,
          address: user?.friendlyAddress,
        })
      )

      AnalyticsService.trackItEasy('page_shown', {
        projectId: slug,
      })
    }
  }, [user])

  window.addEventListener('scroll', function () {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const opacity = 1 - scrollTop / 50

    if (opacity >= 0 && topSignElem.current) {
      topSignElem.current.style.opacity = `${opacity}`
    }
  })

  const topSignElem = useRef<HTMLDivElement>(null)

  if (isLoaded) {
    return (
      <Page>
        <Head
          title={activeProject?.projectDetails?.metaTitle}
          description={activeProject?.projectDetails?.metaDescription}
          socialImg={activeProject?.projectDetails?.bannerPng}
          slug={slug}
        />
        {/* <Page.Header onlyDarkMode={slug === 'tondiamonds'} /> */}
        <PageHeader />
        <Page.Body withHeader>
          <div className={styles.topSign} ref={topSignElem}>
            ton-native launchpad
          </div>
          <div className={styles.root}>
            <div className={styles.grid}>
              <div className={styles.aside}>
                {communityLink ? (
                  <CommunitySidebar
                    activeProject={activeProject}
                    communityLink={communityLink}
                  />
                ) : (
                  <AirdropSidebar activeProject={activeProject} user={user} />
                )}
              </div>

              <div className={styles.content}>
                <About
                  projectDetails={activeProject.projectDetails}
                  DetailsComponent={DetailsComponent}
                  MoreComponent={MoreComponent}
                  moreTabName={moreTabName}
                  project={activeProject}
                  imageCarousel={imageCarousel}
                  communityLink={communityLink}
                />
              </div>
            </div>
          </div>
        </Page.Body>
        <Page.Footer />
      </Page>
    )
  } else {
    return (
      <Page>
        <LoaderIcon className={styles.loader} />
      </Page>
    )
  }
}
