import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

import { useQueryTabletLandscapeUp } from '@design-system'
import styles from './Modal.scss'

interface ModalProps {
  container?: HTMLDivElement
  header?: string
  subheader?: string
  children: React.ReactNode
  open?: boolean
  close?: boolean
  onClose: (result?: any) => void
  onCloseComplete?: () => void
}

export function Modal({
  container = document.createElement('div'),
  header,
  subheader,
  children,
  open,
  onClose,
  onCloseComplete,
}: ModalProps) {
  const backdropElem = useRef<HTMLDivElement>(null)
  const modalElem = useRef<HTMLDivElement>(null)
  const rootElem = useRef<HTMLDivElement>(null)
  const bodyElem = useRef<HTMLDivElement>(null)
  const isTabletPortraitUp = useQueryTabletLandscapeUp()

  useLayoutEffect(() => {
    document.body.classList.add(styles.hasModal)

    if (rootElem.current) {
      rootElem.current.style.display = 'flex'
    }

    setTimeout(() => {
      if (backdropElem.current) {
        backdropElem.current.style.opacity = '1'
      }

      if (modalElem.current) {
        modalElem.current.style.opacity = '1'
        modalElem.current.style.transform = 'translateY(0)'

        setTimeout(() => {
          if (bodyElem.current) {
            bodyElem.current.style.opacity = '1'
          }
        }, 300)
      }
    })
  }, [container])

  const translateTimeout = isTabletPortraitUp ? 0 : 200

  const handleClose = useCallback(() => {
    document.body.classList.remove(styles.hasModal)

    if (!isTabletPortraitUp && bodyElem.current) {
      bodyElem.current.style.opacity = '0'
    }

    setTimeout(() => {
      if (!isTabletPortraitUp) {
        modalElem.current.style.transform = 'translateY(100%)'
      } else {
        modalElem.current.style.transform = 'translateY(20px)'
      }

      if (backdropElem.current) {
        backdropElem.current.style.opacity = '0'
      }

      if (modalElem.current && isTabletPortraitUp) {
        modalElem.current.style.opacity = '0'
      }

      setTimeout(() => {
        if (rootElem.current) {
          rootElem.current.style.display = 'none'
        }

        onCloseComplete && onCloseComplete()
      }, 500)
    }, translateTimeout)
  }, [container, onClose, onCloseComplete])

  useEffect(() => {
    if (!open) {
      handleClose()
    }
  }, [handleClose, open])

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        onClose()
      }
    }

    window.addEventListener('keydown', close)

    return () => window.removeEventListener('keydown', close)
  }, [])

  const handleBackdropClick = useCallback(
    (event) => {
      if (event.target === backdropElem.current) {
        onClose()
      }
    },
    [onClose]
  )

  return (
    <div className={styles.root} ref={rootElem}>
      <div
        className={styles.backdrop}
        ref={backdropElem}
        onClick={handleBackdropClick}
      ></div>
      <div className={styles.modal} ref={modalElem}>
        <div className={styles.header}>
          {header && <div className={styles.title}>{header}</div>}
          {subheader && <div className={styles.subtitle}>{subheader}</div>}

          <div className={styles.cross} onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
        </div>
        <div className={styles.body} ref={bodyElem}>
          {children}
        </div>
      </div>
    </div>
  )
}
