import classNames from 'classnames'
import React from 'react'

import { Link, Text, useMobileLayout } from '@design-system'
import styles from '../components/About/About.scss'
import { Arrow } from '../icons'
import { Image } from '@design-system'

export const EvaaWaitlistDetails = () => {
  const headerType = 'title-24'
  const paragraphType = 'paragraph-16';
  const paragraphColor = 'secondary'

  return (
    <>
      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Product
        </Text>

        <Text type={paragraphType} color={paragraphColor}>
          Evaa Protocol is the pioneering TON blockchain lending platform seamlessly integrated with Telegram. Recognized as an honorary finalist at DoraHacks and nurtured by Tonstarter, it's set to revolutionize the TON DeFi landscape.
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Milestones
        </Text>

        <Text type={paragraphType} color={paragraphColor}>
          <Text spacingBottom="4" type={paragraphType}>🔵 Featured in FS Labs' TON DeFi study (Q1/Q2 2023)</Text>
          <Text spacingBottom="4" type={paragraphType}>🔵 Launched #MotivationalProgram on TON testnet</Text>
          <Text spacingBottom="4" type={paragraphType}>🔵 Collaborated with HackenProof for Bug Bounty</Text>
          <Text spacingBottom="4" type={paragraphType}>🔵 Won TON.Vote with 1,400+ votes</Text>
          <Text spacingBottom="4" type={paragraphType}>🔵 Successful ProductHunt launch</Text>
          <Text spacingBottom="4" type={paragraphType}>🔵 +180k sign-ups on main waitlist</Text>
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Bussiness model
        </Text>

        <Image
          src="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/evaa/evaa_bussiness_model_cropped.png"
          alt="Evaa Bussiness Model"
          borderRadius="16px"
          lightbox={{
            images: [
              {
                alt: 'Evaa Bussiness Model',
                src: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/evaa/evaa_bussiness_model_cropped.png',
              },
            ],
          }}
        />

        <Text type={paragraphType} spacingTop='8'>
          Evaa Protocol offers a seamless and innovative lending solution on the TON blockchain, directly integrated with Telegram. Users benefit from a user-friendly, secure, and efficient lending experience while leveraging the massive Telegram user base. Being recognized at DoraHacks and incubated by Tonstarter emphasizes our commitment to excellence, innovation, and trustworthiness in the DeFi space.
        </Text>
      </div>
      

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Achievements
        </Text>

        <Text type={paragraphType} color={paragraphColor}>
          <Text spacingBottom="4" type={paragraphType}>🔵 Granted by the Ton Foundation</Text>
          <Text spacingBottom="4" type={paragraphType}>🔵 Supported by TonStarter and In5 Incubator</Text>
          <Text spacingBottom="4" type={paragraphType}>🔵 Funded by TON Coin Fund and Existential Capital</Text>
          <Text spacingBottom="4" type={paragraphType}>🔵 Acknowledged at DoraHacks.io with a Hack-a-TONx</Text>
          <Text spacingBottom="4" type={paragraphType}>🔵 Honored with Telegram Web Application Contest</Text>
        </Text>
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Partnership
        </Text>

        <Image
          src="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/evaa/evaa_partnership.png"
          alt="Evaa Partnership image"
          borderRadius="16px"
          lightbox={{
            images: [
              {
                alt: 'Evaa Partnership image',
                src: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/evaa/evaa_partnership.png',
              },
            ],
          }}
        />
      </div>

      <div className={styles.row}>
        <Text type={headerType} spacingBottom="16">
          Core team
        </Text>

        <Image
          src="https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/evaa/evaa_team.jpg"
          alt="Evaa Team image"
          borderRadius="16px"
          lightbox={{
            images: [
              {
                alt: 'Evaa Team 1st image',
                src: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/evaa/evaa_team.jpg',
              },
            ],
          }}
        />

        <a
          className={styles.teamLink}
          href="https://www.canva.com/design/DAFhyVXo5GE/CWcUse_YNtR3JYwIW9wQJg/edit?utm_content=DAFhyVXo5GE&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
          target="_blank"
        >
          <Text type={paragraphType} color={paragraphColor}>
            Details
          </Text>
          <Arrow />
        </a>
      </div>
    </>
  )
}
