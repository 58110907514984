import React, { useEffect, useState } from 'react'

import {
  Allocation,
  checkActiveAllocationPayment,
  isActiveAllocationPurchasedSelector,
  isAuthSelector,
  Project,
  projectSaleInfoSelector,
  ProjectStatus,
  useAppDispatch,
  useAppSelector,
  walletAddressSelector,
} from '@store'
import { ApplyForm, ApplyFormStep } from './components/ApplyForm'
import { ClaimForm } from './components/ClaimForm'
import { FundProgress } from './components/FundProgress/FundProgress'
import { SidebarSkeleton } from './components/SidebarSkeleton'
import { SoldOut } from './components/SoldOut'
import { SwapForm } from './components/SwapForm'
import { Timer } from './components/Timer/Timer'
import styles from './Sidebar.scss'

interface SidebarProps {
  activeAllocation: Allocation
  activeProject: Project
  tokenTicker: string
}

const getStep = (activeProject: Project): ApplyFormStep => {
  if (activeProject.status === 'whitelistSoon') {
    return ApplyFormStep.comingSoon
  }

  if (activeProject.status === 'whitelistOpen') {
    return ApplyFormStep.applicationsOpened
  }

  if (activeProject.status === 'whitelistClosed') {
    return ApplyFormStep.applicationsClosed
  }

  if (activeProject.status === 'whitelistFinish') {
    return ApplyFormStep.allocationsDraw
  }

  if (activeProject.status === 'live') {
    return ApplyFormStep.tokenSale
  }

  if (activeProject.status === 'soldOut') {
    return ApplyFormStep.vesting
  }

  return ApplyFormStep.comingSoon
}

export const Sidebar = ({
  activeAllocation,
  activeProject,
}: SidebarProps): JSX.Element => {
  const isAuth = useAppSelector(isAuthSelector)
  const isActiveAllocationPurchased = useAppSelector(
    isActiveAllocationPurchasedSelector
  )
  const walletAddress = useAppSelector(walletAddressSelector)
  const projectSaleInfo = useAppSelector(projectSaleInfoSelector)
  const [isLoaded, setIsLoaded] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (
      activeProject.id &&
      activeProject.status === 'live' &&
      activeAllocation !== null
    ) {
      setIsLoaded(true)
    }

    if (
      activeProject.id &&
      activeProject.status === 'live' &&
      isActiveAllocationPurchased !== null &&
      activeAllocation !== null
    ) {
      setIsLoaded(true)
    }

    if (
      activeProject.id &&
      activeProject.status !== 'live' &&
      activeAllocation !== null
    ) {
      setIsLoaded(true)
    }

    if (
      activeProject.id &&
      !isAuth &&
      !activeAllocation 
    ) {
      setIsLoaded(true)
    }
    
  }, [activeProject, isActiveAllocationPurchased, activeAllocation])

  useEffect(() => {
    if (activeProject.id) {
      dispatch(
        checkActiveAllocationPayment({
          walletAddress,
          contractAddress: activeProject.idoContractAddress,
        })
      )
    }
  }, [activeProject])

  if (!isLoaded) {
    return <SidebarSkeleton />
  }

  const step = getStep(activeProject)

  const shouldRenderFundProgress =
    step === ApplyFormStep.tokenSale ||
    step === ApplyFormStep.vesting ||
    isActiveAllocationPurchased

  const shouldRenderApplyForm =
    (step === ApplyFormStep.comingSoon ||
      step === ApplyFormStep.applicationsOpened ||
      step === ApplyFormStep.applicationsClosed ||
      step === ApplyFormStep.allocationsDraw ||
      step === ApplyFormStep.tokenSale) &&
    (!activeAllocation.id ||
      activeAllocation.status === 'whitelist_soon' ||
      activeAllocation.status === 'whitelist_started' ||
      activeAllocation.status === 'whitelist_completed' ||
      activeAllocation.status === 'kyc_passed')

  const shouldRenderSwapForm =
    step === ApplyFormStep.tokenSale &&
    isActiveAllocationPurchased === false &&
    activeAllocation &&
    activeAllocation.status === 'sale_started'

  const shouldRenderClaimForm =
    isAuth &&
    (step === ApplyFormStep.tokenSale || step === ApplyFormStep.vesting) &&
    isActiveAllocationPurchased

  const shouldRenderSoldOut =
    (!isAuth || !activeAllocation.id) &&
    (projectSaleInfo.amountTokensLeft < 1000 ||
      activeProject.status === ProjectStatus.soldOut)

  return (
    <div className={styles.container}>
      {shouldRenderFundProgress && <FundProgress />}
      {shouldRenderApplyForm && (
        <ApplyForm
          step={step}
          isAuth={isAuth}
          activeProject={activeProject}
          activeAllocation={activeAllocation}
        />
      )}
      {shouldRenderSwapForm && (
        <SwapForm
          isAuth={isAuth}
          activeProject={activeProject}
          activeAllocation={activeAllocation}
        />
      )}
      {shouldRenderClaimForm && (
        <ClaimForm
          step={step}
          idoContractAddress={activeProject.idoContractAddress}
        />
      )}
      {shouldRenderSoldOut && <SoldOut activeProject={activeProject} />}

      <Timer project={activeProject} />
    </div>
  )
}
