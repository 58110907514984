// const importAll = (require) =>
//   require.keys().reduce((acc, next) => {
//     console.log(require(next))
//     acc[next.replace('./', '').split('.')[0]] = `./library${require(next)}`

//     return acc
//   }, {})

// export const images = importAll(require.context('./library', false, /\.svg$/))

const iconsContext = require.context('../../assets/icons/', true, /svg$/)
export const images = iconsContext.keys().reduce((icons, file) => {
  const SVG = iconsContext(file).default
  const label = file.slice(2, -4) // strip './' and '.svg'
  icons[label] = SVG

  return icons
}, {})
