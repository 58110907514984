import React from "react"

import { Button, Text, Link } from '@design-system'

import styles from '../../AirdropSidebar.scss'
import { openNewTab } from "@utils"
import liquidityPoll from "./liquidityPool.png"

interface ClaimFinishedProps {
  claimedAmount: string
  projectTicker: string
}

export const ClaimFinished = ({claimedAmount, projectTicker} : ClaimFinishedProps) : JSX.Element => {
  return (
    <>
      <Text color="tertiary" spacingBottom="16" align="center">
        Unlock the full potential of $SCALE
      </Text>
      <div className={styles.reward} style={{ margin: '16px 0'}}>
        <div>
          <Text className={styles.rewardAmount}>{claimedAmount} {projectTicker}</Text>
          <Text className={styles.rewardLabel}>claimed</Text>
        </div>
        <div>
          <img src={liquidityPoll} alt="Liqudity pool icon" width="70" style={{marginRight: '12px'}}/>
        </div>
      </div>
      <Button
        variant="contrast"
        className={styles.button}
        fullWidth
        onClick={() => openNewTab('https://dedust.io/earn/deposit/TON/SCALE?utm_source=tonstarterclaimliquidity1')}
      >
        Provide liquidity
      </Button>
      <Text className={styles.tip}>
        <Link href="https://t.me/Scaleton_University/3" textType="paragraph-14">
          What's a liquidity and how can I earn?
        </Link>
      </Text>
    </>
  )
}