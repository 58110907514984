import React from 'react'

import { AirdropPage } from '../AirdropPage'
import { DeDustDetails } from './DeDustDetails'
import { DeDustToken } from './DeDustToken'

export const DeDust = () => {
  return <AirdropPage
    slug='dedust'
    colour='#ff9300'
    DetailsComponent={DeDustDetails}
    MoreComponent={DeDustToken}
  />
}