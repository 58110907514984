// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6dSK3{max-width:100%;max-height:100%;overflow:hidden;position:relative}.qier-player_controller{top:16px;left:16px;right:auto;bottom:auto;padding:0}.qier-player_controller_time{color:#fff;font-size:16px;line-height:24px;font-weight:500;background-color:rgba(62,62,62,.4);border-radius:8px;padding:2px 8px}", "",{"version":3,"sources":["webpack://./src/shared/design-system/components/Video/Video.scss"],"names":[],"mappings":"AAAA,QACE,cAAA,CACA,eAAA,CACA,eAAA,CACA,iBAAA,CAGF,wBACE,QAAA,CACA,SAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CAGF,6BACE,UAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,kCAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":[".root {\n  max-width: 100%;\n  max-height: 100%;\n  overflow: hidden;\n  position: relative;\n}\n\n:global(.qier-player_controller) {\n  top: 16px;\n  left: 16px;\n  right: auto;\n  bottom: auto;\n  padding: 0;\n}\n\n:global(.qier-player_controller_time) {\n  color: #fff;\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: 500;\n  background-color: rgba(#3e3e3e, 0.4);\n  border-radius: 8px;\n  padding: 2px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_6dSK3"
};
export default ___CSS_LOADER_EXPORT___;
