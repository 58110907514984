import { LocalStorageService } from '../LocalStorageService'
import { CookieService } from '../CookieService'
import { rand } from '../../utils'

const utmParamsMap = [
  'ref',
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_content',
  'utm_term',
]

const sessionIdKey = 'tsSessionId'

export const setUTMPayload = (isAuth: boolean) => {
  const url = new URL(location.href)
  const utmSnapshot: { [key: string]: string | boolean } = {
    is_authed: isAuth,
    url: location.pathname,
  }

  utmParamsMap.forEach((key) => {
    const value = url.searchParams.get(key)

    if (value) {
      utmSnapshot[key] = value
    }
  })

  // Ref case
  if (utmSnapshot.ref !== undefined && !isAuth) {
    LocalStorageService.setItem('utm-snapshot', utmSnapshot)
  }

  // Marketing case
  if (Object.keys(utmSnapshot).length > 2 && !utmSnapshot.ref) {
    LocalStorageService.setItem('utm-snapshot', utmSnapshot)
  }
}

export const getUTMPayload = (): {
  [key: string]: string
} | null =>
  LocalStorageService.getItem<{
    [key: string]: string
  } | null>('utm-snapshot', null)

export const removeUTMPayload = () =>
  LocalStorageService.setItem('utm-snapshot', null)

export const upsertSessionId = () => {
  if (CookieService.get(sessionIdKey)) {
    return
  }

  const minutes = 30
  const currentDate = new Date()
  const sessionId = `${currentDate.getTime().toString()}_${rand()}`

  CookieService.set(sessionIdKey, sessionId, {
    expires: new Date(currentDate.getTime() + minutes * 60000),
  })
}

export const getSessionId = () => {
  if (!CookieService.get(sessionIdKey)) {
    upsertSessionId()
  }

  return CookieService.get(sessionIdKey)
}

export const getWalletState = () => {
  return LocalStorageService.getItem<string>('wallet-state')
}

export const getWalletBalance = () => {
  const balance = LocalStorageService.getItem<string>('wallet-balance', '')
  const numberedBalance = parseInt(balance, 10)

  if (!isNaN(numberedBalance)) {
    return String(numberedBalance / 1000000000)
  }

  return null
}
