import React, { useMemo } from 'react'

import { Button, Text } from '@design-system'
import { getPercentage, openNewTab } from '@utils'
import styles from '../../AirdropSidebar.scss'
import soldOutStyles from './SoldOutView.scss'
import { Project } from '@store'

const tgIcon = (
  <svg
    width="15"
    height="26"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {' '}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.04999 5.65769C5.06225 3.9096 7.73775 2.75714 9.07638 2.20037C12.8986 0.61061 13.6928 0.334431 14.2104 0.325291C14.3243 0.323302 14.5789 0.351527 14.7438 0.485343C14.9884 0.683835 14.9898 1.11469 14.9626 1.39985C14.7555 3.57616 13.8593 8.85743 13.4033 11.2949C13.2104 12.3263 12.8305 12.6722 12.4627 12.706C11.6635 12.7795 11.0566 12.1778 10.2824 11.6704C9.07111 10.8763 8.38677 10.382 7.21093 9.60715C5.85207 8.71168 6.73298 8.21954 7.50737 7.41519C7.71006 7.20471 11.2316 4.00158 11.2997 3.711C11.3083 3.67465 11.3162 3.53917 11.2357 3.46767C11.1552 3.39616 11.0365 3.42057 10.9508 3.44003C10.8293 3.46761 8.89413 4.74668 5.14536 7.27718C4.59607 7.65438 4.09855 7.83814 3.65281 7.82852C3.16136 7.81793 2.21611 7.55068 1.51337 7.32224C0.651452 7.04208 -0.0335917 6.89391 0.0260849 6.41811C0.0571597 6.17021 0.398445 5.91677 1.04999 5.65769Z"
      fill="#fff"
    />{' '}
  </svg>
)

export const SoldOutView = ({
  project,
  amountOfParticipants,
}: {
  project: Project
  amountOfParticipants: number
}): JSX.Element => {
  const participants = amountOfParticipants
  const winners = project.winnersAmount || 0
  const winnersPercentage = Number(
    getPercentage(winners, participants).toFixed(2)
  )

  const fundsRaisedFormatted = project.fundsRaised ? new Intl.NumberFormat('en-US').format(
    project.fundsRaised
  ) : null

  const addSomeCommas = (formatThisNumber: number) =>
    new Intl.NumberFormat('en-US').format(formatThisNumber)

  return (
    <div className={styles.bochka}>
      <h2 className={styles.title}>{project.title}</h2>
      <Text color="tertiary" align="center">
        The competition is over
      </Text>

      <table className={soldOutStyles.table}>
        <tbody>
          { fundsRaisedFormatted && <tr>
            <th>Total amount</th>
            <td>{`${fundsRaisedFormatted}$`}</td>
          </tr> }
          <tr>
            <th>Participants</th>
            <td>{addSomeCommas(participants)}</td>
          </tr>
          { project.id === 7 &&<>
            <tr>
              <th>Glint distributed</th>
              <td>30,300</td>
            </tr>
            <tr>
              <th>Lifetime 10% bonuses</th>
              <td>303</td>
            </tr>
          </> }
          { winners > 0 && <tr>
            <th>Winners</th>
            <td>
              {addSomeCommas(winners)}{' '}
              <span className={soldOutStyles.winnersPercent}>
                {winnersPercentage}%
              </span>
            </td>
          </tr> }
          { project.id === 7 && <tr>
            <th>Free Diamonds raffled</th>
            <td>42</td>
          </tr> }
        </tbody>
      </table>

      <Button
        className={styles.button}
        fullWidth
        icon={tgIcon}
        onClick={() => {
          openNewTab('https://t.me/ton_starter')
        }}
      >
        Follow Tonstarter
      </Button>

      <Text className={styles.tip}>
        Subscribe to our channels and we’ll inform you about the next time
      </Text>
    </div>
  )
}
