import { ClaimState } from '@store'
import { beautifyDate, nanoToDisplay } from '@utils'
import { FUNZEE_TOKEN_DECIMALS } from './ClaimForm'

export const normalizeTokens = (
  claimState: ClaimState | null
): {
  availableToClaimTokens: string | number
  allTokens: string | number
  claimedTokens: string | number
  firstClaimDate: string | null
  secondClaimDate: string | null
} => {
  const availableToClaimTokensInNano =
    claimState?.availableTokens && claimState?.availableTokens !== '0'
      ? claimState?.availableTokens
      : 0

  const availableToClaimTokens = availableToClaimTokensInNano
    ? nanoToDisplay(availableToClaimTokensInNano, FUNZEE_TOKEN_DECIMALS, 2)
    : 0

  const allTokensInNano =
    claimState?.allTokens && claimState?.allTokens !== '0'
      ? claimState?.allTokens
      : 0

  const allTokens = allTokensInNano
    ? nanoToDisplay(allTokensInNano, FUNZEE_TOKEN_DECIMALS, 2)
    : 0

  const claimedTokensInNano =
    claimState?.claimedTokens && claimState?.claimedTokens !== '0'
      ? claimState?.claimedTokens
      : 0

  const claimedTokens = claimedTokensInNano
    ? nanoToDisplay(claimedTokensInNano, FUNZEE_TOKEN_DECIMALS, 2)
    : 0

  const time =
    claimState?.time && claimState?.time !== '0'
      ? parseInt(claimState?.time, 10)
      : 0

  const timeToLockup =
    claimState?.timeToLockup && claimState?.timeToLockup !== '0'
      ? parseInt(claimState?.timeToLockup, 10)
      : 0

  const timeToVesting =
    claimState?.timeToVesting && claimState?.timeToVesting !== '0'
      ? parseInt(claimState?.timeToVesting, 10)
      : 0

  const firstClaimDate = timeToLockup
    ? beautifyDate(new Date(time * 1000 + timeToLockup * 1000).toUTCString())
    : null

  const secondClaimDate = timeToLockup
    ? beautifyDate(new Date(time * 1000 + timeToVesting * 1000).toUTCString())
    : null

  return {
    availableToClaimTokens,
    allTokens,
    claimedTokens,
    firstClaimDate,
    secondClaimDate,
  }
}
