import React from 'react'

import {
  Button,
  Content,
  Icon,
  Link,
  Member,
  Text,
  useMobileLayout,
} from '@design-system'
import { openNewTab } from '@utils'
import { Project } from '../store'

export const data: Project = {
  id: 'tegro',
  name: 'Tegro Finance',
  slug: 'tegro',
  iconUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tegro/logo.svg',
  logoUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tegro/logo.svg',
  logoDarkUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tegro/logo.svg',
  urlTitle: 'tegro.finance',
  urlLink: 'https://tegro.finance',
  expertRatingLevel: '7',
  expertRatingIndex: 31,
  expertRatingMarker: 'D',
  communityRating: '',
  contractAddress: 'EQD-Pq5eyVudb5AySLTAkyS5L9yRiAgvNpQfLLWv1GSE0Xc2',
  contractAddressTestnet: 'EQD-Pq5eyVudb5AySLTAkyS5L9yRiAgvNpQfLLWv1GSE0Xc2',
  fundamentalReportUrl:
    'https://tonstarter.notion.site/Tegro-Finance-Fundamental-37ff9ce7526d409882c6d465f4510b55',
  technicalReportUrl:
    'https://tonstarter.notion.site/Tegro-Finance-Technical-6e15960970164b11a50d214b008358a4',
  category: 'defi',
  updatedAt: '30 Mar 2023',
  reports: [
    {
      reportType: 'fundamental',
      updatedAt: '30 Mar 2023',
      totalRate: '41%',
      values: {
        valueProposition: '46%',
        tokenomics: '22%',
        team: '60%',
        governance: 'n/a',
        regulatory: 'n/a',
      },

      reviewers: [
        {
          title: 'Criptic Hood',
          link: 'https://linktr.ee/CripticHood',
          subtitle: 'Report',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/CripticHood.jpeg',
        },
        {
          title: 'Shreshtha Kumar',
          link: 'https://twitter.com/Web3writes',
          subtitle: 'Review',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/ShreshthaKumar.jpeg',
        },
      ],
    },
    {
      reportType: 'technical',
      updatedAt: '30 Mar 2023',
      totalRate: '23%',
      values: {
        codeAndTeam: '62%',
        docs: '40%',
        testing: '0%',
        security: '23%',
        accessControls: '10%',
      },

      reviewers: [
        {
          title: 'Criptic Hood',
          link: 'https://linktr.ee/CripticHood',
          subtitle: 'Report',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/CripticHood.jpeg',
        },
        {
          title: 'Sergey Ch',
          link: 'https://twitter.com/serega_ton',
          subtitle: 'Review',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/sergey.png',
        },
      ],
    },
  ],
  hideInvest: true
}

export const quote = {
  author: 'Criptic Hood',
  authorLink: 'https://linktr.ee/CripticHood',
  authorTitle: 'Report',
  authorAvatarUrl:
  'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/CripticHood.jpeg',
  text:
    'Tegro Finance is building multiple payment and wallet infrastructures on "The Open Network". ' +
    "These infrastructures would prove to be valuable to the Ton community. Most of these projects by Tegro Finance (Wallets, NFTs-Market) are still under development and need to pass security tests to ensure the safety of their users. " +
    "More doubts arise due to the minting of more tokens from the protocol's contract address, which is tracked by Coingecko, without explanation to their community. " +
    "This is a disclaimer to interact with caution and to make sure the users understand the current state of the project at the time of this report.",
}

export function aboutProject(): JSX.Element {
  const isMobileViewport = useMobileLayout()

  const headerType = isMobileViewport ? 'title-24' : 'title-28'
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'
  const textSpacingBottom = isMobileViewport ? '32' : '40'

  return (
    <>
      <Text type={textType} spacingBottom="12" color="secondary">
        Tegro Finance is a blockchain-based platform that provides an all-in-one decentralized finance and e-commerce payment solution. Among the various services that Tegro Finance offers, users can benefit from Tegro.Money, which is a non-custodial wallet, and a decentralized exchange that uses TGR jetton on The Open Network (TON).
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        The platform is working towards building cross-chain capabilities to BNB Smart Chain (BSC), which will serve as a multichain DEX. Currently, Tegro Finance is developing an NFT marketplace, expected to launch in Q2 2023.
      </Text>

      <Text type={headerType} spacingBottom="24">
        Problem & Solution
      </Text>
      <Text type={textType} spacingBottom="12" color="secondary">
        Tegro Finance solves the challenges of traditional finance by offering users greater transparency, security, and control over their finances. 
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        By operating on Blockchain networks, Tegro Finance eliminates the need for intermediaries and centralized authorities, enabling trustless trading of cryptocurrencies and the secure management of digital assets. The team is committed to building a user-friendly platform that empowers users to take control of their financial future.
      </Text>

      
      <Text type={headerType} spacingBottom="24">
        Contacts
      </Text>
      <Content
        spacingChild={isMobileViewport ? '40' : '240'}
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Sviatoslav Gusev"
          role="Developer/Founder"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tegro/gusev.jpeg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() => openNewTab('https://t.me/DeFiTON')}
            >
              Telegram
            </Button>
          }
        />

        <Member
          name="Elizaveta Antropova"
          role="Community manager"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tegro/elizaveta.jpeg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="outer-link" colorFill="primary" />}
              onClick={() => openNewTab('https://www.linkedin.com/in/elizaveta-antropova-699515237/')}
            >
              Linkedin
            </Button>
          }
        />
      </Content>

      <Text type={headerType} spacingBottom="8">
        Links
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        <ul>
          <li>
            <Link
              href="https://defillama.com/protocol/tegro-finance"
              target="_blank"
              textType={textType}
            >
              DefiLlama
            </Link>
          </li>
          <li>
            <Link
              href="https://twitter.com/tegrodex"
              target="_blank"
              textType={textType}
            >
              Twitter
            </Link>
          </li>
          <li>
            <Link
              href="https://t.me/tokentgr"
              target="_blank"
              textType={textType}
            >
              Telegram news channel (RU)
            </Link>
          </li>
        </ul>
      </Text>
    </>
  )
}

export function tokenDetails(): JSX.Element {
  const isMobileViewport = useMobileLayout()

  const headerType = isMobileViewport ? 'title-24' : 'title-28'
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'
  const textSpacingBottom = isMobileViewport ? '32' : '40'

  return (
    <>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        <ul>
          <li><b>Name:</b> Tegro (TGR)</li>
          <li><b>Total Supply:</b> 50,000,000 $TGR</li>
          <li>
            <b>Contract: </b>
            <Link 
              href='https://tonscan.org/EQAvDfWFG0oYX19jwNDNBBL1rKNT9XfaGP9HyTb5nb2Eml6y'
              target="_blank"
              textType={textType}
            >EQAvDfWFG0oYX19jwNDNBBL1rKNT9XfaGP9HyTb5nb2Eml6y</Link>
          </li>
          <li>
            <b>Owner: </b>
            <Link 
              href='https://tonscan.org/EQC9hxkJ9YQVhonPhlIMVMjvojVZlz3cSwggy9EsUUgywsRY'
              target="_blank"
              textType={textType}
            >EQC9hxkJ9YQVhonPhlIMVMjvojVZlz3cSwggy9EsUUgywsRY</Link>
          </li>
        </ul>
      </Text>

      <Text type={headerType} spacingBottom="8">
        Tokenomics
      </Text>
      <Text type={textType} spacingBottom="12" color="secondary">
        Tegro (TGR) is a jetton on the TON Blockchain and BNB Smart Chain (BSC). The token allocation is distributed among various stakeholders, and the token can be burned manually. The token has no built-in inflation, but it can be swapped to other on-chain tokens.
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        The platform is working towards building cross-chain capabilities to BNB Smart Chain (BSC), which will serve as a multichain DEX. Currently, Tegro Finance is developing an NFT marketplace, expected to launch in Q2 2023.
      </Text>

      <Text type={headerType} spacingBottom="8">
        Token Utility
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Tegro (TGR) is the platform jetton of Tegro Finance, which can be used for transactions and swapped to other on-chain tokens on the TON Blockchain and BNB Smart Chain (BSC). It provides governance rights, revenue sharing, and promotional event participation on the TON Blockchain. The token can be used for stacking on Azbit Exchange, but there is no mention of any extrinsic productivity use cases for the token.
      </Text>

      <Text type={headerType} spacingBottom="8">
        Suspense
      </Text>
      <Text type={textType} color="secondary">
        There are various controversial feedback from users, community and even some investigations on Tegro products, $TGR token and founders themselves.
        <br></br>
        Here are some of them:
        <ul>
          <li><Link href="https://t.me/XardasProjects/19" textType={textType}>Exploration of Sviatoslav Gusev's career path (RU)</Link></li>
          <li><Link href="https://t.me/investkingyru/985" textType={textType}>Tegro liquidity leak investigation (RU)</Link></li>
          <li><Link href="https://t.me/tonnull/254270" textType={textType}>$TGR price dynamic (from 12 Apr 2023)</Link></li>
        </ul>
      </Text>
    </>
  )
}