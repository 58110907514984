import React from 'react'

import { Tooltip, useQueryTabletLandscapeUp } from '@design-system'
import styles from './Header.scss'
import tickets from './images/tickets.png'

export type HeaderNoteType = 'earlyAdopter' | 'doubleChance'

interface HeaderNoteProps {
  type: HeaderNoteType
}

const texts: { [key: string]: string } = {
  earlyAdopter:
    'Congratulations! Your Early Adopter (cool dude) status will increase your chances of getting allocation!',
  doubleChance:
    'Lucky you! You have a double chance to win the allocation draw.',
}

export function HeaderNote({ type }: HeaderNoteProps): JSX.Element {
  const title = texts[type] ? texts[type] : ''
  const isTabletLandscapeUpViewport = useQueryTabletLandscapeUp()

  if (isTabletLandscapeUpViewport) {
    return (
      <div className={styles.headerNote}>
        <Tooltip title={title} style="light">
          <div className={styles.headerNoteIcon}>
            <img src={tickets} />
          </div>
        </Tooltip>
      </div>
    )
  }

  return (
    <div className={styles.headerNote}>
      <div className={styles.headerNoteIcon}>
        <img src={tickets} />
      </div>
      {title}
    </div>
  )
}
