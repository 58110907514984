// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._52hbs{background-color:#f3f5fb;padding:10px;border-radius:16px;font-size:15px;line-height:20px;display:flex;margin-bottom:16px}._52hbs>span{margin-left:9px}body[data-theme=dark] ._52hbs{background-color:var(--color-bg-tertiary);color:var(--color-text-secondary)}.TndHQ{margin-top:2px}", "",{"version":3,"sources":["webpack://./src/tonstarter/pages/Project/components/Sidebar/components/Tip/Tip.scss","webpack://./src/shared/design-system/styles/mixins/theme.scss"],"names":[],"mappings":"AAIA,QACE,wBAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CACA,gBAAA,CACA,YAAA,CACA,kBAAA,CAEA,aACE,eAAA,CCKO,8BDDP,yCAAA,CACA,iCAAA,CAIJ,OACE,cAAA","sourcesContent":["@use 'styles/mixins/shared.scss' as shared;\n@use 'styles/mixins/breakpoints.scss' as breakMix;\n@use 'styles/mixins/theme.scss' as themeMix;\n\n.root {\n  background-color: #f3f5fb;\n  padding: 10px;\n  border-radius: 16px;\n  font-size: 15px;\n  line-height: 20px;\n  display: flex;\n  margin-bottom: 16px;\n\n  & > span {\n    margin-left: 9px;\n  }\n\n  @include themeMix.dark {\n    background-color: var(--color-bg-tertiary);\n    color: var(--color-text-secondary);\n  }\n}\n\n.icon {\n  margin-top: 2px;\n}\n","@mixin light() {\n  // @media (prefers-color-scheme: light) {\n  //   @at-root #{selector-nest('body:not([data-theme=light]):not([data-theme=dark])', &)} {\n  //     @content;\n  //   }\n  // }\n\n  @at-root #{selector-nest('body[data-theme=light]', &)} {\n    @content;\n  }\n}\n\n@mixin dark() {\n  // @media (prefers-color-scheme: dark) {\n  //   @at-root #{selector-nest('body:not([data-theme=light]):not([data-theme=dark])', &)} {\n  //     @content;\n  //   }\n  // }\n\n  @at-root #{selector-nest(\"body[data-theme='dark']\", &)} {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_52hbs",
	"icon": "TndHQ"
};
export default ___CSS_LOADER_EXPORT___;
