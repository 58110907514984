import React from 'react'

import styles from './SidebarSkeleton.scss'

export const SidebarSkeleton = (): JSX.Element => {
  return (
    <div className={styles.skeletonContainer}>
      <div className={styles.skeletonLine84}></div>
      <div className={styles.skeletonLineEmpty16}></div>
      <div className={styles.skeletonLine14}></div>
      <div className={styles.skeletonLineEmpty16}></div>
      <div className={styles.skeletonLine14}></div>
      <div className={styles.skeletonLineEmpty16}></div>
      <div className={styles.skeletonLine14}></div>
      <div className={styles.skeletonLine40}></div>
      <div className={styles.skeletonLineEmpty24}></div>
      <div className={styles.skeletonLine14}></div>
      <div className={styles.skeletonLineEmpty16}></div>
      <div className={styles.skeletonLine14}></div>
      <div className={styles.skeletonLineEmpty24}></div>
      <div className={styles.skeletonLine84}></div>
      <div className={styles.skeletonLineEmpty24}></div>
      <div className={styles.skeletonLine14}></div>
      <div className={styles.skeletonLineEmpty16}></div>
      <div className={styles.skeletonLine14}></div>
    </div>
  )
}
