import React from "react";
import SumsubWebSdk from '@sumsub/websdk-react'

import styles from './KycPage.scss'

export const KycPage = function () :JSX.Element {
    const accessToken = window.location.search.split('token=')[1];
    const whenExpireDoNothing = async () => {return 'mazda'};

    return (<div className={styles.container}>
        <h1 className={styles.sectionTitle}>Tonstarter</h1>
        <SumsubWebSdk 
            accessToken={accessToken}
            expirationHandler={whenExpireDoNothing}
        />
    </div>)
}