import React, { useEffect, useState } from 'react'

import {
  Button,
  Content,
  getWebAppInitData,
  Text,
  useCountdown,
} from '@design-system'
import {
  Allocation,
  checkMyTasks,
  currentUserSelector,
  pollTickets,
  Project,
  useAppDispatch,
  useAppSelector,
} from '@store'
import { competitionStateInterface, openNewTab } from '@utils'

import {
  Farming,
  Game,
  GlintAdopter,
} from '../../../../../../pages/Project/airdrop/Diamonds'
import styles from '../../AirdropSidebar.scss'
import { Balance } from '../Balance/Balance'
import { List } from '../List/List'

const unionIcon = (
  <svg
    className={styles.buttonUnionIcon}
    width="14"
    height="30"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 0C13.1543 0 13.3005 0.0349618 13.431 0.0973973C13.4951 0.128033 13.5567 0.165899 13.6144 0.21099C13.6837 0.265015 13.7457 0.327964 13.7986 0.39811C13.8037 0.404895 13.8088 0.411747 13.8137 0.418665C13.8201 0.427588 13.8264 0.436623 13.8325 0.445761C13.838 0.454081 13.8434 0.462489 13.8487 0.470982C13.8522 0.47652 13.8556 0.482105 13.859 0.487715C13.8618 0.49244 13.8646 0.497191 13.8673 0.501966C13.8694 0.505548 13.8714 0.509144 13.8734 0.512753C13.954 0.656923 14 0.823097 14 1L14 13C14 13.5523 13.5523 14 13 14C12.4477 14 12 13.5523 12 13L12 3.41412L1.70698 13.7071C1.31646 14.0976 0.683295 14.0976 0.292771 13.7071C-0.0977531 13.3166 -0.0977531 12.6834 0.292771 12.2929L10.5857 2L0.999971 2C0.447686 2 -2.8573e-05 1.55228 -2.85247e-05 1C-2.84764e-05 0.447715 0.447686 0 0.999972 0H13Z"
      fillOpacity="0.5"
    />
  </svg>
)

interface CompetitionViewProps {
  competitionState: competitionStateInterface | null
  project: Project
  allocation: Allocation
}

export const CompetitionView = ({
  competitionState,
  project,
  allocation,
}: CompetitionViewProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const thirtySecs = 30000
  const { user } = getWebAppInitData()
  const jwtUser = useAppSelector(currentUserSelector)
  const [countdown, setCountdown] = useState('')

  useEffect(() => {
    checkMyResults()
  }, [])

  const checkMyResults = () => {
    if (disabled) {
      return
    }

    const t = new Date(new Date().getTime() + 1000 * 31)
    setCountdown(`${t.toDateString()} ${t.toTimeString()}`)

    setLoading(true)
    setDisabled(true)
    setTimeout(() => setDisabled(false), thirtySecs)

    dispatch(checkMyTasks({ projectId: project.id })).then(() => {
      dispatch(
        pollTickets({
          projectId: project.id,
          telegramId: user?.id,
          address: jwtUser?.friendlyAddress,
        })
      )
      setLoading(false)
    })
  }
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)

  if (competitionState == null) {
    return (
      <div className={styles.bochka}>
        <h2 className={styles.title} style={{ marginBottom: '16px' }}>
          Whoops
        </h2>
        <Text color="secondary" align="center" spacingBottom="16">
          We cant find your competition status
        </Text>
        <div className={styles.reward}>
          <ol style={{ margin: '0' }}>
            <li>Reload the page</li>
            <li>Hardly reload the page</li>
            Still here? Ask{' '}
            <a href="https://t.me/ton_starter_support_bot" target="_blank">
              support bot
            </a>
          </ol>
          <div>
            <span className={styles.error}>🛟</span>
          </div>
        </div>
      </div>
    )
  }

  const { balance, tasks, taskGroups } = competitionState
  const { seconds } = useCountdown(countdown)
  const winChance = allocation.chanceToWin && project.slug === 'tondiamonds'
  const showWinChanceInfo = winChance && project.slug !== 'tondiamonds'

  return (
    <>
      <div className={styles.bochka}>
        {showWinChanceInfo ? (
          <>
            <h2 className={styles.title}>{winChance}% chance to win</h2>
            <Text color="secondary" align="center">
              Points increase chance to win
            </Text>
          </>
        ) : null}
        {!winChance ? (
          <h2 className={styles.title}>Points increase chance to win</h2>
        ) : null}
        {showWinChanceInfo ? (
          <Balance amount={balance} slug={project.slug} />
        ) : null}
        {/* {project.slug === 'tondiamonds' ? (
          <Content margin="0-0-16-0" justify="center">
            <Text type="title-24">TON Diamonds</Text>
          </Content>
        ) : null} */}
        {project.slug === 'tondiamonds' ? <GlintAdopter embedded /> : null}
        {project.slug === 'tondiamonds' ? (
          <>
            <Game
              projectId={project.id}
              telegramId={user?.id}
              address={jwtUser?.friendlyAddress}
              tasks={tasks}
            />
          </>
        ) : null}
        <List
          tasks={tasks}
          taskGroups={taskGroups}
          user={jwtUser}
          showPoints={false}
        />

        <Button
          style={{ marginTop: '24px' }}
          className={styles.button}
          onClick={checkMyResults}
          loading={loading}
          disabled={disabled}
          fullWidth
        >
          {disabled
            ? `Cooldown ${seconds !== 'NaN' ? seconds : '30'} sec`
            : 'Refresh'}
        </Button>
        {/* <Text className={styles.tip}>The results are not updated immediately, we need time to check the task</Text> */}
        <Button
          style={{ marginTop: '16px' }}
          onClick={() =>
            openNewTab(
              'https://docs.tonstarter.com/community-incentives-program/frequently-asked-questions'
            )
          }
          variant="secondary"
          className={styles.button}
          icon={unionIcon}
          iconPosition="end"
          fullWidth
        >
          Need help
        </Button>
      </div>
    </>
  )
}
