import React from 'react'

import Config from '@config'
import { Button } from '@design-system'
import { openNewTab } from '@utils'
import { Project } from '@store'
import styles from '../../Sidebar.scss'
import { Table } from '../Table/Table'
import { Tip } from '../Tip/Tip'

interface SoldOutProps {
  activeProject: Project
}

export const SoldOut = ({ activeProject }: SoldOutProps): JSX.Element => {
  const isFanZee = activeProject.id === 7843 && Config.isProd

  return (
    <div className={styles.bochka}>
      <Tip
        message={`${activeProject.title} token sale is over. All allocations sold out.`}
      />
      <Table
        allocationUSD={activeProject.allocationUSD}
        tokenUSD={activeProject.tokenUSD}
        ath={isFanZee ? '+270%' : null}
      />
      <Button
        fullWidth
        onClick={() => {
          openNewTab('https://t.me/ton_starter')
        }}
      >
        Follow Tonstarter
      </Button>
    </div>
  )
}
