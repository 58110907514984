import {
  divide,
  equal,
  getPercentageOfTon,
  greaterThan,
  justSum,
  multiply,
  nano2Ton,
} from '@utils'

export const errorMessages = {
  insufficient:
    "You either have insufficient funds or must decrease the amount of TON you've entered.",
}

type getValidatorsProps = {
  tonMaxValue: string
  tonMinValue: string
}

export const getValidators = ({
  tonMaxValue,
  tonMinValue,
}: getValidatorsProps) => {
  const maxValueValidator = (value: string | undefined) => {
    let error = null

    if (value && greaterThan(value, tonMaxValue)) {
      error = errorMessages.insufficient
    }

    return error
  }

  const minValueValidator = (value: string | undefined) => {
    let error = null

    if (
      value &&
      !greaterThan(value, tonMinValue) &&
      !equal(value, tonMinValue)
    ) {
      error = `Minimum value is ${tonMinValue} TON`
    }

    return error
  }

  return [maxValueValidator, minValueValidator]
}

interface NormalizedProjectandAllocationData {
  tokenSymbol: string
  tokenPriceInTon: string
  tonMaxValue: string
  tonMinValue: string
  allocationInTon: string
  allocationInUSD: string
  minAllocationInUSD: string
  minAllocationInTon: string
}

export const normalizeProjectandAllocationData = ({
  tokenPriceInNanoTon,
  tonPriceInUsd,
  walletBalanceInNanoTon,
  allocationInToken,
  amountTokensLeft,
}: {
  [key: string]: any
}): NormalizedProjectandAllocationData => {
  const tokenPriceInTon = nano2Ton(tokenPriceInNanoTon)
  const allocationInTon = multiply(tokenPriceInTon, allocationInToken, 2)
  const walletBalanceInTon = nano2Ton(walletBalanceInNanoTon)
  const amountTokensLeftInTon = multiply(tokenPriceInTon, amountTokensLeft, 2)

  const tonMaxValue = greaterThan(allocationInTon, walletBalanceInTon)
    ? greaterThan(walletBalanceInTon, amountTokensLeftInTon)
      ? amountTokensLeftInTon
      : walletBalanceInTon
    : allocationInTon

  const allocationInUSD = multiply(allocationInTon, tonPriceInUsd, 2)

  const minAllocationInUSD = '50'
  const minAllocationInTon = divide(minAllocationInUSD, tonPriceInUsd, 2)

  const normalizedData = {
    tokenSymbol: 'FNZ',
    tokenPriceInTon,
    allocationInTon,
    tonMaxValue,
    tonMinValue: minAllocationInTon,
    allocationInUSD,
    minAllocationInUSD,
    minAllocationInTon,
  }

  return normalizedData
}

export const calculateTotalAmount = (
  tonAmount: string,
  serviceCharge: string,
  networkTonFee: string
): string => {
  const serviceChargeInTon = calculateServiceChargeOf(tonAmount, serviceCharge)

  return justSum(tonAmount, serviceChargeInTon, networkTonFee)
}

export const calculateServiceChargeOf = (
  tonAmount: string,
  serviceCharge: string
): string => {
  return getPercentageOfTon(tonAmount, serviceCharge)
}
