import { Allocation, AllocationStatus } from '@store'
import { ApplyFormStep } from './ApplyForm'

export const getTipMessageByStep = ({
  step,
  isAuth,
  allocation,
  isLotteryWinner,
}: {
  step: ApplyFormStep
  isAuth: boolean
  isLotteryWinner: boolean
  allocation: Allocation
}): string => {
  if (step === ApplyFormStep.comingSoon) {
    if (!isAuth) {
      return 'Application period will open soon! Connect your wallet to subscribe for this IDO.'
    }

    return allocation.id
      ? 'You have subscribed to this IDO. We will notify you on Telegram, once the IDO application period opens.'
      : 'Press "Notify me" to enable Telegram bot notifications. You will be redirected to Telegram afterwards.'
  }

  if (step === ApplyFormStep.applicationsOpened) {
    if (!isAuth) {
      return 'The application period is now open! Connect your wallet to apply for this IDO.'
    }

    if (allocation.status === AllocationStatus.whitelistSoon) {
      return 'The application period is now open. Apply to join the allocations draw.'
    }

    if (
      allocation.status === AllocationStatus.whitelistStarted ||
      allocation.status === AllocationStatus.whitelistCompleted
    ) {
      return 'The allocation draw will take place soon. We will notify you on Telegram, if your wallet was selected.'
    }

    return 'The application period is now open! You can apply for this IDO by pressing the button below.'
  }

  if (step === ApplyFormStep.applicationsClosed) {
    return isAuth
      ? 'The allocation draw will begin soon! Please make sure your wallet has enough funds for the allocation buyout. We will notify you on Telegram, if your wallet was selected.'
      : 'The applications for this IDO are no longer accepted. You can connect your wallet to get ready for the upcoming campaigns.'
  }

  if (step === ApplyFormStep.allocationsDraw) {
    if (isAuth && isLotteryWinner) {
      return "Congratulations! You've won an allocation for the Fanzee IDO! We will notify you on your further steps via the bot!"
    }

    return isAuth
      ? 'The allocation draw is in progress. We will notify you on Telegram, if your wallet was selected.'
      : 'The applications for this IDO are no longer accepted. You can connect your wallet to get ready for the upcoming campaigns.'
  }

  return 'The application period is closed. We are no longer accepting new participants for this IDO.'
}
