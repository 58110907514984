import React from 'react'
import { EvaaWaitlistDetails } from '../../../Project/airdrop/EvaaWaitlist/EvaaWaitlistDetails'
import { EvaaWaitlistToken } from '../../../Project/airdrop/EvaaWaitlist/EvaaWaitlistToken'
import { SwiperSlide } from 'swiper/react'
import SwiperCarousel from '../../../../../shared/design-system/components/SwiperCarousel'
import { PrivateProject } from './components/PrivateProject'
import { privateData } from './data/privateData'

const slides = [
  'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/evaa/evaa_homepage_banner.jpg',
  'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/evaa/evaa_roadmap_1_slider_2.png',
  'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/evaa/evaa_roadmap_2_slider_3.png',
  'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/evaa/evaa_bussiness_model_slider_4.png',
]

export const EvaaPage = () => {
  return <PrivateProject
    data={privateData}
    slug='evaa-waitlist'
    MoreComponent={EvaaWaitlistToken}
    moreTabName='Token info'
    DetailsComponent={EvaaWaitlistDetails}
    imageCarousel={
      <SwiperCarousel
        slidesPerView={1}
        withButtons={true}
        pagination={true}
        spaceBetween={16}
        initialSlide={0}
        lazy={{ loadPrevNext: true }}
        childrenGrow={false}
        fullHeight={true}
      >
        <SwiperSlide>
          <SwiperCarousel.LazyImage
            borderRadius="16px"
            src={slides[0]}
            lightbox={{
              images: [
                {
                  alt: 'Evaa Slide 1',
                  src: slides[0],
                },
              ],
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <SwiperCarousel.LazyImage
            src={slides[1]}
            lightbox={{
              images: [
                {
                  alt: 'Evaa Slide 2',
                  src: slides[1],
                },
              ],
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <SwiperCarousel.LazyImage
            src={slides[2]}
            lightbox={{
              images: [
                {
                  alt: 'Evaa Slide 3',
                  src: slides[2],
                },
              ],
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <SwiperCarousel.LazyImage
            src={slides[3]}
            lightbox={{
              images: [
                {
                  alt: 'Evaa Slide 4',
                  src: slides[3],
                },
              ],
            }}
          />
        </SwiperSlide>
      </SwiperCarousel>
    }
  />
}
