import React from 'react'

import {
  Button,
  Content,
  Icon,
  Member,
  Text,
  useMobileLayout,
} from '@design-system'
import { openNewTab } from '@utils'
import { Project } from '../store'

export const data: Project = {
  comingSoon: false,
  id: 'tonapi',
  name: 'TON API',
  slug: 'tonapi',
  iconUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonapi/logo.svg',
  logoUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonapi/logo.svg',
  logoDarkUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonapi/logo.svg',
  urlTitle: 'tonapi.io',
  urlLink: 'https://tonapi.io',
  expertRatingLevel: '2',
  expertRatingIndex: 73,
  expertRatingMarker: 'A',
  communityRating: '',
  contractAddress: 'EQCbjgD3uuxk9xpDlNULmFcxhtrpijH5XKtAs5aQTI4Abwsi',
  contractAddressTestnet: 'EQCbjgD3uuxk9xpDlNULmFcxhtrpijH5XKtAs5aQTI4Abwsi',
  reportUrl:
    'https://tonstarter.notion.site/Tonapi-Report-v2-4daeccf1d8d0435bb1229a0a92a4ef60',
  category: 'tools',
  updatedAt: '25 Apr 2023',
  reports: [
    {
      reportType: 'reportV2',
      updatedAt: '25 Apr 2023',
      totalRate: '80%',
      values: {
        valueProposition: '86%',
        tokenomics: 'n/a',
        team: '75%',
        regulatory: 'n/a',
        community: '20%',
        tech: '90%',
      },

      reviewers: [
        {
          title: 'qpwe',
          subtitle: 'Report',
          link: 'https://qpwe.taplink.ws/',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/qpwe.jpg',
        },
        {
          title: 'Vladimir, Tonstarter',
          subtitle: 'Review',
          link: 'https://t.me/pewpewgogo/',
          avatarUrl:
            'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/vladimir.jpeg',
        },
      ],
    },
  ],
}

export const quote = {
  author: 'qpwe',
  authorLink: 'https://qpwe.taplink.ws/',
  authorTitle: 'Report',
  authorAvatarUrl:
    'https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/reviewers/qpwe.jpg',
  text: 'TONAPI.io is a reliable and user-friendly blockchain explorer and API for the TON ecosystem. It offers unique features such as detailed transaction tracing, filtering transactions by date, and displaying user-friendly asset transfers like NFTs and tokens. TONAPI.io caters to developers and is widely used in the community for various projects, making it a valuable resource in the TON ecosystem.',
}

export function aboutProject(): JSX.Element {
  const isMobileViewport = useMobileLayout()

  const headerType = isMobileViewport ? 'title-24' : 'title-28'
  const textType = isMobileViewport ? 'paragraph-16' : 'paragraph-20'
  const textSpacingBottom = isMobileViewport ? '32' : '40'

  return (
    <>
      <Text type={textType} spacingBottom="12" color="secondary">
        Tonapi.io is a reliable and well-designed API for the TON blockchain,
        providing unique features and valuable methods for developers and users.
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        The API enables detailed transaction traces, date-based transaction
        filtering, and user-friendly asset transfers, including NFTs and tokens.
      </Text>

      <Text type={headerType} spacingBottom="16">
        Potential
      </Text>
      <Text type={textType} spacingBottom="12" color="secondary">
        Tonapi.io targets the growing TON market by offering a reliable API for
        the TON ecosystem. It competes with Toncenter.com and Everspace.center.
      </Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        However, Tonapi.io serves as the primary API provider for the TON
        ecosystem, positioning itself as a key player. The increasing need for
        dependable APIs presents potential growth opportunities for Tonapi.io.
      </Text>

      {/* <Text type={headerType} spacingBottom="16">Impact</Text>
      <Text type={textType} spacingBottom={textSpacingBottom} color="secondary">
        Tonapi.io showcases its value through strong integration and partnerships within the TON blockchain ecosystem. It supports projects like Tonkeeper and Getgems and provides information for community projects, including NFT TONificator and Jetton Rates. Tonapi.io is popular within the community and its integration into major projects highlights a robust network of partnerships and collaborations, solidifying its position in the market.
      </Text> */}

      <Text type={headerType} spacingBottom="24">
        Contacts
      </Text>

      <Content
        spacingChild={isMobileViewport ? '40' : '240'}
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Oleg Andreev"
          role="CEO of TON Apps"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonapi/OlegAndreev.jpeg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() => openNewTab('https://t.me/oleganza')}
            >
              Telegram
            </Button>
          }
        />

        <Member
          name="Oleg Illarionov"
          role="Chief Executive Officer"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonapi/OlegIllarionov.jpeg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="outer-link" colorFill="primary" />}
              onClick={() =>
                openNewTab(
                  'https://www.linkedin.com/in/oleg-illarionov-76723321/'
                )
              }
            >
              LinkedIn
            </Button>
          }
        />
      </Content>

      <Content
        spacingChild={isMobileViewport ? '40' : '240'}
        spacingBottom="48"
        column={isMobileViewport ? true : false}
      >
        <Member
          name="Rostislav Rudakov"
          role="Business Developer for TON Apps"
          avatar="https://tonstarter-cdn.ams3.digitaloceanspaces.com/openrating/tonapi/RostislavRudakov.jpeg"
          button={
            <Button
              variant="secondary"
              icon={<Icon name="telegram" colorFill="primary" />}
              onClick={() => openNewTab('https://t.me/tonrostislav')}
            >
              Telegram
            </Button>
          }
        />
      </Content>
    </>
  )
}
