import { LocalStorageService } from '../services/LocalStorageService'

export const getTheme = (): 'dark' | 'light' => {
  return LocalStorageService.getItem('theme', 'dark')
}

export const setTheme = (theme: 'dark' | 'light', remember = true) => {
  if (!document.body.dataset.theme) {
    document.body.style.transition = 'none'
  } else {
    document.body.style.transition =
      'background 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
  }

  if (remember) {
    LocalStorageService.setItem('theme', theme)
  }

  document.body.dataset.theme = theme

  setTimeout(() => {
    document.body.style.transition = 'none'
  }, 300)
}

export const initTheme = () => {
  setTheme(getTheme())
}
