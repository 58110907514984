import React from 'react'
import { formatCurrency } from '@utils'

import styles from './FundGoal.scss'

interface FundGoalProps {
  fundGoal: number
}

export const FundGoal = ({ fundGoal }: FundGoalProps): JSX.Element => {
  return (
    <>
      <div className={styles.note}>Fundraise goal</div>
      <div className={styles.fundGoal}>
        {fundGoal > 0 ? formatCurrency('usd', fundGoal) : 'TBD'}
      </div>
    </>
  )
}
