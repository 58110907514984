import React from 'react'

import styles from './Posts.scss'

const posts = [
  {
    id: 1,
    author: 'Tonstarter',
    slug: '@ton_starter',
    avatarUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/tonstarter/assets/twitter-tonstarter.jpeg',
    tweetUrl: 'https://twitter.com/ton_starter/status/1633411330630733825',
    content: (
      <>
        Awesome news! We've closed a $1.5-million investment seed round from
        Kingsway Capital, <span>@gate_io</span>, <span>@DwfLabs</span>,{' '}
        <span>@hexitcapital</span>, <span>@orbs_network</span>,{' '}
        <span>@BaboonVC</span> and others partners...
      </>
    ),
  },
  {
    id: 1,
    author: 'Tonstarter',
    slug: '@ton_starter',
    avatarUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/tonstarter/assets/twitter-tonstarter.jpeg',
    tweetUrl: 'https://twitter.com/ton_starter/status/1633743373382086658',
    content: (
      <>
        💫 $500,000 in $MEGA distributed to Tonstarter Community Incentives
        participants!
        <br />
        <br />
        With the completion of the program and the token distribution last
        night, the MEGA token was listed on the <span>@Megaton_Fi</span>{' '}
        exchange! The total reward amount for participating in the Tonstarter
        Community Incentives program reached $500,000...
      </>
    ),
  },
  {
    id: 3,
    author: 'TON',
    slug: '@ton_blockchain',
    avatarUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/tonstarter/assets/twitter-ton.png',
    tweetUrl: 'https://twitter.com/ton_blockchain/status/1634111414502604800',
    content: (
      <>
        In case you missed it - the market deserves some light and more positive
        news👇🏻
        <br />
        <br />
        <span>@ton_starter</span> Closes $1.5 million Seed Round as Primary
        Fundraising Platform for The Open Network (#TON)
        <br />
        <br />
        <span>
          Tonstarter Closes $1.5 million Seed Round as Primary Fundraising
          Platform for The Open Network (TON)
        </span>
      </>
    ),
  },
  {
    id: 4,
    author: 'Open Builders',
    slug: '@open_builders',
    avatarUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/tonstarter/assets/twitter-openbuilders.jpeg',
    tweetUrl: 'https://twitter.com/ton_blockchain/status/1638451522777276417',
    content: (
      <>
        👷 Meet the Open Builders!
        <br />
        <br />
        Our team's work spans a wide range of initiatives beyond the
        http://Tonstarter.com Launchpad. To better represent our diverse
        efforts, we've decided to bring everything we do together under one
        name: Open Builders.
      </>
    ),
  },
  {
    id: 5,
    author: 'Megaton Finance',
    slug: '@Megaton_Fi',
    avatarUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/tonstarter/assets/twitter-megaton.jpeg',
    tweetUrl: 'https://twitter.com/Megaton_Fi/status/1614887255784685571',
    content: (
      <>
        🔹Partnership News with Tonstarter🔹
        <br />
        <br />
        Megaton Finance 🤝 Tonstarter
        <br />
        <br />
        Tonstarter is the main fundraising platform for investing in the most
        promising crypto projects at various stages.
        <br />
        <br />
        TON Ecosystem will be expanded with Tonstarter💪
      </>
    ),
  },
  {
    id: 6,
    author: 'Dormint',
    slug: '@Dormint_io',
    avatarUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/tonstarter/assets/twitter-dormint.png',
    tweetUrl: 'https://twitter.com/Dormint_io/status/1630524318445715459',
    content: (
      <>
        Yo, yo, yo! The big cheese of <span>@Dormint_io</span>, Nick Kelland, is
        spilling the tea on our upcoming hookup with <span>@telegram</span> and{' '}
        <span>@ton_blockchain</span>
        deployment to none other than <span>@takoy_sasha</span>, CEO of{' '}
        <span>@ton_starter</span>.
      </>
    ),
  },
  {
    id: 7,
    author: 'HUMANS🫂',
    slug: '@humanfromweb3_',
    avatarUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/tonstarter/assets/twitter-humanfromweb3.jpeg',
    tweetUrl: 'https://twitter.com/humanfromweb3_/status/1673285869254463492',
    content: (
      <>
        Only for those who aren't afraid of honest feedback 👇
        <br />
        <br />
        Pitch your Web3 startup to top VCs and get your ideas grilled at Startup
        Pitch Roast!
        <br />
        <br />
        Registration available ➡️ http://bit.ly/449yPY1
        <br />
        <br />
        🧑‍⚖️ Featuring awesome judges:
        <br />
        <br />- Valeriy Bukharkov from <span>@ton_starter</span>
        <br />- @eloisamarcheson from <span>@shimacapital</span>
        <br />- Hugo Bettencourt from <span>@CV_Labs</span>
        <br />- @shacheng from <span>@ConsenSysMesh</span>
        <br />- @SchorNatalia from <span>@1inch</span>
        <br />- @pe4enable from <span>@gnosischain</span>
        <br />- Jess Sloss from <span>@seedclubhq</span>
      </>
    ),
  },
  {
    id: 8,
    author: 'OPTUS',
    slug: '@OPTUS_dex',
    avatarUrl:
      'https://tonstarter-cdn.ams3.digitaloceanspaces.com/tonstarter/assets/twitter-optus.jpeg',
    tweetUrl: 'https://twitter.com/OPTUS_dex/status/1668975290234683394',
    content: (
      <>
        Big shoutout to <span>@ton_starter</span> for spreading the word about
        our DEX! You guys rock ⚡️
      </>
    ),
  },
]

const Post = ({ p }: { p: { [key: string]: any } }) => {
  return (
    <a className={styles.card} href={p.tweetUrl} target="_blank">
      <div className={styles.cardHeader}>
        <div className={styles.avatar}>
          <img src={p.avatarUrl} alt={p.author} />
        </div>
        <div>
          <div className={styles.username}>{p.author}</div>
          <div className={styles.slug}>{p.slug}</div>
        </div>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0222 7.27491L15.9785 0.5H14.5671L9.39516 6.38256L5.26437 0.5H0.5L6.74656 9.39547L0.5 16.5H1.91155L7.37321 10.2878L11.7356 16.5H16.5L10.0218 7.27491H10.0222ZM8.08887 9.47384L7.45596 8.58805L2.42015 1.53974H4.5882L8.65216 7.22795L9.28507 8.11374L14.5677 15.5075H12.3997L8.08887 9.47418V9.47384Z"
            fill="black"
          />
        </svg>
      </div>

      <div className={styles.content}>{p.content}</div>
    </a>
  )
}

export const Posts = () => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>posts about us</div>

      <div className={styles.cards}>
        <div className={styles.cardColumn}>
          {[posts[0], posts[7], posts[2]].map((p) => (
            <Post p={p} key={p.id} />
          ))}
        </div>

        <div className={styles.cardColumn}>
          {[posts[3], posts[4], posts[5]].map((p) => (
            <Post p={p} key={p.id} />
          ))}
        </div>

        <div className={styles.cardColumn}>
          {[posts[1], posts[6]].map((p) => (
            <Post p={p} key={p.id} />
          ))}
        </div>
      </div>
    </div>
  )
}
