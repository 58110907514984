import classNames from 'classnames'
import React from 'react'

import { Text } from '@design-system'
import { competitionStateInterface } from '@utils'
import { SoldOutView } from '../SoldOutView'
import { Project, User, currentUserSelector, useAppSelector } from '@store'

import styles from '../../AirdropSidebar.scss'
import { getIcon } from '../Balance/Balance'
import { List } from '../List/List'
import { CompletionInfoFlags } from 'typescript'

interface CountResultsViewProps {
  project: Project
  winChance: string | number | undefined
  competitionState: competitionStateInterface | null
  amountOfParticipants: number
  rewardPool: string
}

export const CountResultsView = ({
  project,
  winChance,
  competitionState,
  amountOfParticipants,
  rewardPool
}: CountResultsViewProps): JSX.Element => {
  if (competitionState == null) {
    return <SoldOutView project={project} amountOfParticipants={amountOfParticipants} />
  }
  const jwtUser = useAppSelector(currentUserSelector)
  const { balance } = competitionState
  const icon = getIcon(balance, project.slug)
  const bonusPointsGroup = competitionState.taskGroups.slice(competitionState.taskGroups.length - 2)

  return (
    <>
      <div className={styles.bochka}>
        <h2 className={styles.title}>{project.title}</h2>
        <Text type="paragraph-16" color="tertiary" align="center">
          Counting the results...
        </Text>
        <div className={styles.blocksContainer}>
          <div className={styles.blocksLine}>
            <div className={styles.blocksCell}>
              <Text type="paragraph-20">
                {rewardPool}
              </Text>
              <Text type="paragraph-16" color="tertiary">
                Rewards pool
              </Text>
            </div>
          </div>
          <div className={styles.blocksLine}>
            <div className={styles.blocksCell}>
              <Text type="paragraph-20">
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                  <div className={styles.blocksCellIcon}>{icon}</div> {balance}
                </div>
              </Text>
              <Text type="paragraph-16" color="tertiary">
                Your points
              </Text>
            </div>
            <div className={styles.blocksCell}>
              <Text type="paragraph-20">
                {winChance}%
              </Text>
              <Text type="paragraph-16" color="tertiary">
                Chance to win
              </Text>
            </div>
          </div>
        </div>

        <Text className={styles.tip}>
          Play CS <code>connect play.dedust.io</code><br></br>while we count AMA bonuses and&nbsp;referrals...
        </Text>
      </div>
      <div className={styles.bochka}>
        <Text color="primary" align="center">
          View only
        </Text>
        <List tasks={competitionState.tasks} taskGroups={bonusPointsGroup} user={jwtUser} />
      </div>
    </>
  )
}
