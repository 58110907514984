import React from 'react'

import { AirdropPage } from '../AirdropPage'
import { FantonDetails } from './FantonDetails'
import { FantonGameDetails } from './FantonGameDetails'

export const FantonPage = () => {
  return <AirdropPage
    slug='fanton'
    DetailsComponent={FantonDetails}
    MoreComponent={FantonGameDetails}
    moreTabName="Game details"
  />
}