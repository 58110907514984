export const projectDetails = {
  id: 0,
  about: '',
  icon: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/megaton_symbol.svg',
  bannerSet: [],
  bannerAvif: '',
  bannerWebp:
    'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/mega-2x.webp',
  bannerPng:
    'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/mega-2x.jpg',
  logo: 'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/megaton_logo_b.svg',
  logoDark:
    'https://tonstarter-cdn.ams3.cdn.digitaloceanspaces.com/projects/megaton/megaton_logo_w.svg',
  linkTelegram: 'https://t.me/MegatonFinanceOfficial',
  linkTwitter: 'https://twitter.com/Megaton_Fi',
  linkDiscord: 'https://discord.com/invite/megatonfinance',
  linkWebsite: 'http://megaton.fi/',
  metaTitle: 'Mega',
  metaDescription: 'Megaton'
}
